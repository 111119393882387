//element actions
export const ELEMENT_REQUEST = "[MODULE][SURVEYS][ELEMENT] REQUEST";
export const ELEMENT_SUCCESS = "[MODULE][SURVEYS][ELEMENT] SUCCESS";
export const ELEMENT_FAILURE = "[MODULE][SURVEYS][ELEMENT] FAILURE";
//catalogs actions
export const CATALOGS_REQUEST = "[MODULE][SURVEYS][CATALOGS] REQUEST";
export const CATALOGS_SUCCESS = "[MODULE][SURVEYS][CATALOGS] SUCCESS";
export const CATALOGS_FAILURE = "[MODULE][SURVEYS][CATALOGS] FAILURE";

export const SAVE_REQUEST = "[MODULE][SURVEYS][SAVE] REQUEST";
export const SAVE_SUCCESS = "[MODULE][SURVEYS][SAVE] SUCCESS";
export const SAVE_FAILURE = "[MODULE][SURVEYS][SAVE] FAILURE";

export const LIST_REQUEST = "[MODULE][SURVEYS][LIST] REQUEST";
export const LIST_SUCCESS = "[MODULE][SURVEYS][LIST] SUCCESS";
export const LIST_FAILURE = "[MODULE][SURVEYS][LIST] FAILURE";

//module actions
export const RESET = "[MODULE][SURVEYS] RESET";
export const DETAIL = "[MODULE][SURVEYS] DETAIL";
export const CREATE = "[MODULE][SURVEYS] CREATE";
export const EDIT = "[MODULE][SURVEYS] EDIT";
export const STORE = "[MODULE][SURVEYS] STORE";
export const UPDATE = "[MODULE][SURVEYS] UPDATE";
export const REMOVE = "[MODULE][SURVEYS] REMOVE";
export const LIST = "[MODULE][SURVEYS] LIST";

export const list = ()=> {
  return { type: LIST  };
}

export const create = ()=> {
  return { type: CREATE };
}

export const edit = (payload)=> {
  return { type: EDIT, payload };
}

export const store = (payload)=> {
  return { type: STORE, payload };
}

export const update = (payload)=> {
  return { type: UPDATE, payload };
}

export const remove = (payload)=> {
  return { type: REMOVE, payload };
}

export const detail = (payload)=> {
  return { type: DETAIL, payload};
}

export const catalogs = ()=> {
  return { type: CATALOGS_REQUEST };
}

export const reset = ()=> {
  return { type: RESET };
}
