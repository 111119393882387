import { Validators } from '@lemoncode/fonk';
import { createFinalFormValidation } from '@lemoncode/fonk-final-form';

export const validationSchema = {
  field: {
    username: [Validators.required.validator, Validators.email.validator],
    password: [Validators.required.validator],
  }
};

export const formValidation = createFinalFormValidation(validationSchema);

