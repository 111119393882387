import { Validators } from '@lemoncode/fonk';
import { createFinalFormValidation } from '@lemoncode/fonk-final-form';

export const validationSchema = {
  field: {
    shopper_id:[Validators.required.validator],
    date:[Validators.required.validator],
    hour:[Validators.required.validator],
    status:[Validators.required.validator],
  }
};

export const formValidation = createFinalFormValidation(validationSchema);