import React from 'react';
import PropTypes from 'prop-types';
import clsx from 'clsx';
import { lighten } from '@mui/material/styles';
import makeStyles from '@mui/styles/makeStyles';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import Button from '@mui/material/Button';
import SearchIcon from '@mui/icons-material/Search';
import AddIcon from '@mui/icons-material/Add';
import { Paper, Grid} from '@mui/material';
import InputBase from '@mui/material/InputBase';
import Skeleton from '@mui/material/Skeleton';

function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) return order;
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}



function EnhancedTableHead(props) {
  const { classes, order, orderBy, onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };
  const hideSortIcon = true;

  const styles = {
    icon: {
        display: 'none',
    },
    active: {
        '& $icon': {
            display: 'inline',
        },
    },
};

  return (
    <TableHead>
      <TableRow>
        {props.columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            { headCell.sortable 
            ? 
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
              hideSortIcon={hideSortIcon}
              style={styles}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <span className={classes.visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </span>
              ) : null}
            </TableSortLabel>
            
            :
              headCell.label
            }
          </TableCell>
        ))}

        

        {props.actions
          ? 
          <TableCell
            align={'right'}
            padding={'normal'}
            sortDirection={false}
          >Acciones</TableCell>
          : null
        }    
        
      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  classes: PropTypes.object.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
  root: {
    paddingLeft: theme.spacing(2),
    paddingRight: theme.spacing(1),
  },
  highlight:
    theme.palette.mode === 'light'
      ? {
          color: theme.palette.secondary.main,
          backgroundColor: lighten(theme.palette.secondary.light, 0.85),
        }
      : {
          color: theme.palette.text.primary,
          backgroundColor: theme.palette.secondary.dark,
        },
  title: {
    flex: '1 1 100%',
  },
  margin: {
    margin: theme.spacing(1),
  },
}));

const searchButtonStyles = makeStyles((theme) => ({
  root: {
    padding: '2px 4px',
    display: 'flex',
    alignItems: 'center',
    borderRadius: '24px',
    border: '1px solid rgba(44, 46, 46, 0.5);',
    height: '31px'
  },
  input: {
    marginLeft: theme.spacing(1),
    flex: 1,
  },
  iconButton: {
    padding: 10,
  },
  divider: {
    height: 28,
    margin: 4,
  },
}));

const EnhancedTableToolbar = ({searching, ...props}) => {
  const classes = useToolbarStyles();
  const classes_button = searchButtonStyles();
  const [searchInput, setSearchInput] = React.useState('');

  const handleRequestSearch = (event, property) => {
    setSearchInput(event.target.value);
    props.onSearch(event.target.value);
  };

  // let history = useHistory();

  
  
  return (
    <Toolbar
      className={clsx(classes.root)}
    >
        <Typography className={classes.title} variant="h5" id="tableTitle" component="div">
          {props.title}
        </Typography>

     
        <Grid container spacing={3} direction="row" justifyContent="flex-end">
        {/* search buttton */}
        <Grid item xs={12} md={6} lg={6} >
          {/* <Button variant="outlined" size="small" color="primary">
            <SearchIcon fontSize="small" />
            Buscar Datos
          </Button> */}

          {/* <TextField label="Buscar Datos" /> */}
          {/* <div className={classes.margin}>
            <Grid container spacing={1} alignItems="flex-end">
              <Grid item>
                <SearchIcon />
              </Grid>
              <Grid item>
                <TextField size="small" id="outlined-basic" label="Buscar Datos" variant="outlined" />
              </Grid>
            </Grid>
          </div> */}
          { searching &&

            <Paper component="form" variant="outlined" className={classes_button.root}>
            
              {/* <IconButton type="submit" className={classes_button.iconButton} aria-label="search"> */}
                <SearchIcon />
              {/* </IconButton> */}
              <InputBase
                className={classes_button.input}
                placeholder="Buscar Datos"
                inputProps={{ 'aria-label': 'Buscar Datos' }}
                name="searchInput"
                value={searchInput}
                onChange={handleRequestSearch}
              />
            </Paper>
          }
        </Grid>

        {/* create buttton */}
        <Grid item xs={12} md={6} lg={4}>
        {props.filter ?
        props.filter
        :null
        }
        {props.addAction ? 
          <Button 
            variant="contained" 
            size="small" 
            color="primary" 
            // onClick={() => {history.push("/clients/create");}}
            onClick={(event) => { props.addAction(event)}}
          >
            <AddIcon fontSize="small" />
            {props.titleAction ? props.titleAction : "Crear nuevo"}
          </Button>
        :null
        }
        </Grid>
        </Grid>
    </Toolbar>
  );
};


const useStyles = makeStyles((theme) => ({
  root: {
    width: '100%',
  },
  paper: {
    width: '100%',
    marginBottom: theme.spacing(2),
  },
  table: {
    minWidth: 750,
  },
  visuallyHidden: {
    border: 0,
    clip: 'rect(0 0 0 0)',
    height: 1,
    margin: -1,
    overflow: 'hidden',
    padding: 0,
    position: 'absolute',
    top: 20,
    width: 1,
  },
  iconButton: {
    '&:hover': {
      backgroundColor: theme.palette.secondary.main,
      // Reset on touch devices, it doesn't add specificity
      color: 'white',
      '@media (hover: none)': {
        backgroundColor: 'transparent',
      }
    }
  }
}));


export default function EnhancedTable({title, data, columns,actions, filter, searching=true,...props}) {
  const classes = useStyles();
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [page, setPage] = React.useState(0);
  const [rowsPerPage, setRowsPerPage] = React.useState(10);
  const [rows, setRows] = React.useState(data);



  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  React.useEffect(() => {
    if(data.length) {
      setRows(data);
    }
  }, [data]);


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRequestSearch = (value) => {
    const filteredRows = data.filter((row) => {
      return columns.map((column, index) => {
        const regex = new RegExp(value, 'gi');
        if(typeof row[column.id] == "string"){
          if(row[column.id].match(regex)){
            return true
          }
        }
        if(typeof row[column.id] === "object"){
          if (renderRow(row[column.id],column.render).match(regex)){
            return true
          }
        }
        return false;
      }).includes(true);
      // for (const column of columns) {
      //   const regex = new RegExp(value, 'gi');
      //   if(typeof row[column.id] == "string"){
      //     if(row[column.id].match(regex)){
      //       return true
      //     }
      //   }
      //   if(typeof row[column.id] === "object"){
      //     if (renderRow(row[column.id],column.render).match(regex)){
      //       return true
      //     }
      //   }
      // }
    });
    setRows(filteredRows);
  }

  const renderRow = (data,render) =>{
    let content = JSON.stringify(data); 
    if(render){
      content =  render(data);
    }
    return content;
  }

  const skeletonArray = Array(10).fill('');

  const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

  return (
    <div className={classes.root}>
      <Paper className={classes.paper} elevation={0} sx={{ width: '100%', overflow: 'hidden' }}>
        <EnhancedTableToolbar onSearch={handleRequestSearch} 
          title ={title} 
          addAction={props.addAction ? (event) => { props.addAction(event)} :null}
          titleAction={props.titleAction ? props.titleAction : null}
          filter={filter}
          searching={searching}
        />
          <TableContainer>
            <Table
              className={classes.table}
              aria-labelledby="tableTitle"
              size='medium'
              aria-label="enhanced table"
              stickyHeader 
            >
              <EnhancedTableHead
                classes={classes}
                order={order}
                orderBy={orderBy}
                onRequestSort={handleRequestSort}
                rowCount={rows.length ? rows.length : 0}
                columns={columns}
                actions={actions && actions.length ? true : false}
              />
              <TableBody>

              {props.loading ? 
                skeletonArray.map((item, index) => (
                  <TableRow key={index}>
                    {columns.map((column, index) => {
                      return (
                      <TableCell key={index}><Skeleton /></TableCell>
                      )
                      })
                    }
                    {(actions && actions.length) && 
                      <TableCell key={index}><Skeleton /></TableCell>
                    }
                  </TableRow>
                ))
                :
                  rows.length > 0 ? 
                  stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => { 
                    return (
                      <TableRow
                        hover
                        key={index}
                      >
                        {columns.map((column, index) => {
                          return (
                            <TableCell key={index} align={column.numeric ? 'right' : 'left'} >
                              {
                                typeof row[column.id] === "object" && row[column.id] !== null ? 
                                renderRow(row[column.id],column.render)
                                : (column.render ? renderRow(row[column.id],column.render)  : row[column.id] )
                              }
                              </TableCell>
                          )
                          }
                        )}
                        {actions && actions.length?
                        (
                          <TableCell align="right">
                            {
                              actions.map((action, index) => (
                                <IconButton 
                                    key={index}
                                    size="small" 
                                    className={classes.iconButton}
                                    variant="outlined" 
                                    aria-label="delete"
                                    color="secondary"
                                    disabled={typeof action.disabled === "function" ? action.disabled(row) : false}
                                    onClick={(event) => { action.onClick(event, row)}}
                                  >
                                  <Tooltip title={action.tooltip} >
                                    <action.icon />
                                  </Tooltip>
                                  </IconButton>
                              ))
                            }
                          </TableCell>
                        )
                        :null
                        }
                      </TableRow>
                    );
                  })
                  :
                  emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={columns.length + (actions && actions.length ? 1: 0) }>
                      </TableCell>
                    </TableRow>
                  )
                }

              </TableBody>
            </Table>
          </TableContainer>
          <TablePagination
            rowsPerPageOptions={[5, 10, 25]}
            component="div"
            count={rows.length}
            rowsPerPage={rowsPerPage}
            page={page}
            onPageChange={handleChangePage}
            onRowsPerPageChange={handleChangeRowsPerPage}
            />
      </Paper>
    </div>
  );
}
