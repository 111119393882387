import http from '../utils/http-common';

const brands = () => {
  return http.get("/results");
};

const businessBranches = ({brand, survey}) =>{
  return http.get(`/getBusinessBranchesBySurvey/${brand}/${survey}`);
}

const byBrand = ({ brand, survey, month = 1, data }) => {
  return http.get(`/getResultsBrand/${brand}/${survey}/${month}`, data);
};

const shareByBrand = ({ brand, survey, month = 1 }) => {
  return http.get(`/getResultsBrand/share/${brand}/${survey}/${month}`);
};

const externalByBrand = ({ token }) => {
  return http.get(`/getResultsBrand/${token}`);
};

const byBusinessBranch = ({ contract, month = 1 }) => {
  return http.get(`/getResults/${contract}/${month}`);
};

const ShareByBusinessBranch = ({ contract, month = 1 }) => {
  return http.get(`/getResults/share/${contract}/${month}`);
};

const externalByBusinessBranch = ({ token }) => {
  return http.get(`/getResults/${token}`);
};

const service = {
  brands,
  businessBranches,
  byBrand,
  shareByBrand,
  externalByBrand,
  byBusinessBranch,
  ShareByBusinessBranch,
  externalByBusinessBranch
};

export default service;