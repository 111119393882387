import React, {Component} from 'react';
import {
  Paper,
  Grid,
  Typography,
} from '@mui/material';
import styles from './index.module.css';
import { Field } from 'react-final-form';
import {Input, AutoComplete} from '../../../components/inputs';
import { connect } from 'react-redux';

class FormComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      customer:null,
    }
  }
  
  render() {
    return (
      <Grid item container spacing={3}>
        <Grid item lg={12}>
          <Typography variant="h6" component="div">
            Datos Generales
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
            required
            name="name"
            fullWidth
            component={Input}
            type="text"
            label="Nombre"
          />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
            required
            name="customer_id"
            component={AutoComplete}
            label="Cliente"
            type="text"
            options={this.props.customers.data}
            loading={this.props.customers.loading}
            />
          </Paper>
        </Grid>

      </Grid>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    customers: state.module_brands.customers
  };
}

const _component = connect(
  mapStateToProps,
  null
)(FormComponent);

export default _component;
