import mainSaga from '../../sagas/clients.saga';
import mainReducer from '../reducers/modules/clients.reducer';
import {clients as actions} from '../actions/modules';

export function getModule() {
    return {
        id: "module_clients",
        reducerMap: {
          module_clients: mainReducer,
        },
        sagas: [mainSaga],
        finalActions: [actions.reset()],
    }; 
}