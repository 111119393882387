import * as React from 'react';
import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import Box from '@mui/material/Box';

export default function TimelineComponent({ items = []}) {
  return (
    <React.Fragment>
      <Box component="div"sx={{p: 1, fontSize: '0.875rem', fontWeight: '700'}}>
        <Stack direction={{ xs: 'column', sm: 'row' }} spacing={{ xs: 1, sm: 2, md: 4 }}>
          {items.map((option, index) => {
            const [day, rest] = option.split(" de ");
            return (
              <Chip key={index} label={`${day} - ${rest}`} />
            )})}
        </Stack>
      </Box>
    </React.Fragment>
  );
}