  
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormHelperText,
  FormControl,
  FormLabel,
  TextField,
  Skeleton 
} from '@mui/material';
import Autocomplete from '@mui/material/Autocomplete';
import CircularProgress from '@mui/material/CircularProgress';

const useStyles = makeStyles((theme) => ({
  
  formControl: {
    minWidth: '100%',
  },
  TextField:{
    marginTop: '16px',
  }
}));

const AutoCompleteWrapper = ({input: {name, onChange, value, ...restInput}, meta, waiting=false, ...props}) => {
  // const serverError = props.errors && props.errors.hasOwnProperty.call(props.errors, name) ? props.errors[name]: null;
  // const showServerError =  Boolean(serverError && !meta.dirtySinceLastSubmit);
  const showError = Boolean( ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched );
  const classes = useStyles();

  // const [value, setValue] = React.useState([]);
  // const [inputValue, setInputValue] = React.useState('');

  // const getProducts = (event, newValue) => {
  //     setValue(newValue);
  // }

	return <>
<FormControl disabled={props.disabled} className={classes.formControl}  error={Boolean(showError) } >
  <FormLabel required={props.required}>{props.label}</FormLabel>
  {waiting?<Skeleton variant="rectangular"/> :
    <Autocomplete
      disabled={props.disabled}
      value={value ? value : null}
      onChange={(event, option) => {
          onChange(option);
          props.onChange && props.onChange(option);
        }
      }
      isOptionEqualToValue={(option, element) => option.value === element.value ? option.value : false }
      getOptionLabel={(option) => option.text ? option.text: '' }
      options={props.options}
      loading={props.loading}
      renderInput={(params) => (
          <TextField
            variant="standard"
            className={classes.TextField}
            {...params}
            {...restInput}
            error={ Boolean(showError) }
            InputProps={{
              ...params.InputProps,
              endAdornment: (
                <React.Fragment>
                  {props.loading ? <CircularProgress color="secondary" size={20} /> : null}
                  {params.InputProps.endAdornment}
                </React.Fragment>
              ),
            }}
          />
        )}
      />
  }
    {showError &&
      <FormHelperText>
        {meta.error || meta.submitError}
      </FormHelperText>
    }
    {/* <pre>{JSON.stringify(meta, 0, 2)}</pre> */}
  </FormControl>
    </>;
};

export default AutoCompleteWrapper;
