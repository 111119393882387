import React, {Component} from 'react';
import {
  Container,
  FormControl,
  MenuItem,
  Select,
  Paper
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import { green } from '@mui/material/colors';
import { contract_visits } from '../../../store/actions/modules';
import { connect } from 'react-redux';
import Table from '../../../components/table';
import ConfirmDialog from '../../../components/dialog/Confirm';

import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import SendOutlinedIcon from '@mui/icons-material/SendOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import PaymentOutlinedIcon from '@mui/icons-material/PaymentOutlined';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  list:{
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  }
});

class ContractVisitsComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sku_contract: null,
      sku_visit: null,
      dialog_open:false,
      month:''
    }
  }
  
  async componentDidMount(){
    this.initialLoad();
  }

  initialLoad =()=>{
    try {
      const {sku} = this.props.match.params;
      this.setState({
        sku_contract: sku
      });
      this.props.getInfo({sku: sku});

    } catch (error) {
      this.props.history.push("/visits");
    }
  }
  componentDidUpdate(prevProps) {
    if (prevProps.resend !== this.props.resend) {
      if(this.props.resend.success){
        this.initialLoad();
        this.setState({dialog_open: false, record_slug : null }); 
      }else{
        if(!this.props.resend.loading){
          this.setState({dialog_open: false, record_slug : null }); 
        }
      }
    }

    if ( (prevProps.info !== this.props.info) &&  this.props.info.success) {
      this.setState({month:this.props.info.data.default});
      this.props.getModuleData({sku: this.props.info.data.sku, month: this.props.info.data.default});
    }

  }

  payment = (data)=>{
    return data ? "Pagado" : "No pagado";
  }

  isPayment = (row) =>{
    return Boolean(row.payment);
  }

  isApproved = (row) =>{
    return Boolean(row.status === "Approved" ? true : false);
  }

  isPending = (row) =>{
    return Boolean(row.status === "Pending" ? true : false);
  }

  isConfirm = (row) =>{
    return Boolean(row.status === "Confirm" ? true : false);
  }

  hangleChange = (event) =>{
    const month = event.target.value;
    this.props.getModuleData({sku: this.state.sku_contract, month: month});
    this.setState({dialog_open: false, sku_visit: null,month:month });
  }

  resendSurvey = () =>{
    this.props.resendSurvey({data:{visit_id:this.state.sku_visit}});
  }

  status(data){
    const status = {
      Approved:"Aprobada",
      Pending:"Pendiente",
      Programmed:"Programada",
      Confirmed:"Confirmada",
      Successed:"Exitoso"
    };
    return status[data] ? status[data] : data;
  };

  fillNullData(data){
    return data !== null ? data : "---";
  };


  // { id: 'address_code', numeric: true, disablePadding: false, label: 'Dirección',sortable: true, render: (data)=>this.address(data)}


  render() {

    const maxWidth = false;

    const columns = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Shopper',sortable: true, render: (data)=>this.fillNullData(data) },
      { id: 'date', numeric: false, disablePadding: true, label: 'Fecha de visita',sortable: true, render: (data)=>this.fillNullData(data) },
      { id: 'hour_visit', numeric: false, disablePadding: true, label: 'Hora',sortable: true,render: (data)=>this.fillNullData(data)  },
      { id: 'status', numeric: true, disablePadding: false, label: 'Estado',sortable: true, render: (data)=>this.status(data)},
      { id: 'payment', numeric: true, disablePadding: false, label: 'Pagado',sortable: true, render: (data)=>this.payment(data)}
    ];

    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>
          {/* Dialog  */}
          <ConfirmDialog  
            open={this.state.dialog_open} 
            title={"Reenviar encuesta"}
            loading={this.props.resend.loading}
            confirm={() => { this.resendSurvey()} } 
            cancel={() => this.setState({dialog_open: false, sku_visit: null }) } 
            message={"Se borrarán todas las respuestas de la encuesta para esta visita ¿Está seguro que desea reenviar?"}
            />
          {/* table */}
          <Paper elevation={0} className={styles.paper}>
            <Table
              title = {this.props.info.data.name}
              data = {this.props.element}
              columns={columns}
              filter={
              <FormControl label="Mes" fullWidth size="small">
                  <Select value={this.state.month}  onChange={event =>this.hangleChange(event)}>
                  {(this.props.info.data.options).map((option, index) => (
                      <MenuItem key={index} value={option.value}>{option.label || option.text}</MenuItem>
                    ))}
                  </Select>
              </FormControl>
              }
              actions={[
                {
                  icon: PaymentOutlinedIcon,
                  tooltip: 'Pagar',
                  disabled: (row) => { return !this.isApproved(row) || this.isPayment(row)},
                  onClick: (event, row) => {this.props.history.push(`/visits/${this.state.sku_contract}/contractVisits/${row.sku}/payment`)},
                },
                {
                  icon: QuestionAnswerOutlinedIcon,
                  tooltip: 'Respuestas',
                  onClick: (event, row) => {this.props.history.push(`/visits/${this.state.sku_contract}/contractVisits/${row.sku}/answers`)},
                  disabled: (row) => { return !row.flag }
                },
                {
                  icon: SendOutlinedIcon,
                  tooltip: 'Reenviar Encuestas',
                  onClick: (event, row) => {this.setState({ dialog_open:true, sku_visit:row.sku })},
                  disabled: (row) => { return !row.flag }
                },
                {
                  icon: EditOutlinedIcon,
                  tooltip: 'Editar',
                  onClick: (event, row) => {this.props.history.push(`/visits/${this.state.sku_contract}/contractVisits/${row.sku}/update`)},
                  disabled: (row) => { return this.isApproved(row) }
                }
              ]}
              loading={this.props.status_element.loading}
            />
          </Paper>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getInfo:(payload) =>{
      dispatch(contract_visits.info(payload))
    },
    getModuleData:(payload) =>{
      dispatch(contract_visits.detail(payload))
    },
    resendSurvey:(payload) =>{
      dispatch(contract_visits.resend(payload))
    }
  }
};


const mapStateToProps = (state) => {
  return {
    info: state.module_contract_visits.info,
    months: state.module_contract_visits.months,
    status_element: state.module_contract_visits.status_element,
    element: state.module_contract_visits.element,
    resend: state.module_contract_visits.resend
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(ContractVisitsComponent);


export default withRouter(withStyles(useStyles)(_component));
