import React from 'react';
import {
  Container, 
  Grid,
  Toolbar,
  Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import { results } from '../../../../store/actions/external';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import ResultsComponent  from '../../../../components/ResultsByBrand';
//dialog


class MainComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      token: null
    };
  }

  async componentDidMount() {
    const { token} = this.props.match.params;
    this.props.getModuleData({token:token});
    this.setState({token:token});
  }

  componentWillUnmount(){
    this.props.clearModuleData();
  }

  render() {
    const maxWidth = false;


    return (
      <Container maxWidth={maxWidth} className={styles['client-container']}>
        
        
        <Grid container spacing={2} direction="column">
          

              <Grid container item spacing={3} justifyContent="flex-end">
                {/* Title */}
                <Grid item xs>
                  <Toolbar className={styles['toolbar']}>
                    <Typography variant="h4" id="tableTitle" component="div">
                      {this.props.results.marca}
                    </Typography>
                  </Toolbar>
                </Grid>
                {/* Buttons */}
              </Grid>
              {/* Form */}
        </Grid>

        <Grid item xs>
          <Toolbar className={styles['toolbar']}>
            <Typography variant="h4" id="tableTitle" component="div">
              {'PRESENTACIÓN DE RESULTADOS'}
              <Typography variant="caption" display="block" gutterBottom>
              {`El siguiente reporte muestra los resultados del estudio realizado a los restaurantes de ${this.props.results.marca} durante el mes ${this.props.results.mes}`}
              </Typography>
            </Typography>
          </Toolbar>
        </Grid>

        <Grid item >
          <ResultsComponent
            global_results={this.props.results.resultados_globales}
            catalog_results={this.props.results.resultados_categorias}
            multiple_results={this.props.results.resultados_multiple}
            catalogs={this.props.results.categorias}
            business_branches={this.props.results.establecimientos}
            loading={this.props.status_results.loading}
          />
        </Grid>
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleData:(payload)=>{
      dispatch(results.byBrand(payload));
    },
    clearModuleData:()=>{
      dispatch(results.reset());
    },
  }
};

const mapStateToProps = (state) => {
  return {
    business_branches: state.external_results.business_branches.list,
    status_business_branches: state.external_results.business_branches,
    results: state.external_results.results_by_brand.element,
    status_results: state.external_results.results_by_brand,
  };
}


const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent);

export default withRouter(_component);