// action types
export const RESET = "[EXTERNAL][USERS] RESET";
export const SAVE = "[EXTERNAL][USERS] SAVE";
//element actions
export const ELEMENT_REQUEST = "[EXTERNAL][USERS][ELEMENT] REQUEST";
export const ELEMENT_SUCCESS = "[EXTERNAL][USERS][ELEMENT] SUCCESS";
export const ELEMENT_FAILURE = "[EXTERNAL][USERS][ELEMENT] FAILURE";
//module actions
//catalogs actions

export const SAVE_REQUEST = "[EXTERNAL][USERS][SAVE] REQUEST";
export const SAVE_SUCCESS = "[EXTERNAL][USERS][SAVE] SUCCESS";
export const SAVE_FAILURE = "[EXTERNAL][USERS][SAVE] FAILURE";

export const DETAIL = "[EXTERNAL][USERS] DETAIL";

export const detail = (payload)=> {
  return { type: DETAIL, payload};
}

export const store = (payload)=> {
  return { type: SAVE, payload};
}

export const reset = ()=> {
  return { type: RESET };
}
