// action types
export const RESET = "[MODULE][RESULTS] RESET";

//module actions
export const BY_BRAND = "[MODULE][RESULTS] BY_BRAND";
export const BY_BUSINESS_BRANCH = "[MODULE][RESULTS] BY_BUSINESS_BRANCH";
export const SHARE = "[MODULE][RESULTS] SHARE";
export const SHARE_BY_BRAND = "[MODULE][RESULTS] SHARE_BY_BRAND";
export const SHARE_BY_BUSINESS_BRANCH = "[MODULE][RESULTS] SHARE_BY_BUSINESS_BRANCH";
export const BRANDS_LIST = "[MODULE][RESULTS] BRANDS_LIST";

//element actions
export const BRANDS_LIST_REQUEST = "[MODULE][RESULTS][BRANDS_LIST] REQUEST";
export const BRANDS_LIST_SUCCESS = "[MODULE][RESULTS][BRANDS_LIST] SUCCESS";
export const BRANDS_LIST_FAILURE = "[MODULE][RESULTS][BRANDS_LIST] FAILURE";

export const BUSINESS_BRANCHES_REQUEST = "[MODULE][RESULTS][BUSINESS_BRANCHES] REQUEST";
export const BUSINESS_BRANCHES_SUCCESS = "[MODULE][RESULTS][BUSINESS_BRANCHES] SUCCESS";
export const BUSINESS_BRANCHES_FAILURE = "[MODULE][RESULTS][BUSINESS_BRANCHES] FAILURE";

export const BY_BRAND_REQUEST = "[MODULE][RESULTS][BY_BRAND] REQUEST";
export const BY_BRAND_SUCCESS = "[MODULE][RESULTS][BY_BRAND] SUCCESS";
export const BY_BRAND_FAILURE = "[MODULE][RESULTS][BY_BRAND] FAILURE";

export const BY_BUSINESS_BRANCH_REQUEST = "[MODULE][RESULTS][BY_BUSINESS_BRANCH] REQUEST";
export const BY_BUSINESS_BRANCH_SUCCESS = "[MODULE][RESULTS][BY_BUSINESS_BRANCH] SUCCESS";
export const BY_BUSINESS_BRANCH_FAILURE = "[MODULE][RESULTS][BY_BUSINESS_BRANCH] FAILURE";

export const SHARE_REQUEST = "[MODULE][RESULTS][SHARE] REQUEST";
export const SHARE_SUCCESS = "[MODULE][RESULTS][SHARE] SUCCESS";
export const SHARE_FAILURE = "[MODULE][RESULTS][SHARE] FAILURE";

export const brands = () => {
  return { type: BRANDS_LIST };
}

export const byBrand = (payload)=> {
  return { type: BY_BRAND, payload };
}

export const shareByBrand = (payload)=> {
  return { type: SHARE_BY_BRAND, payload };
}

export const byBusinessBranch = (payload)=> {
  return { type: BY_BUSINESS_BRANCH, payload };
}

export const shareByBusinessBranch = (payload)=> {
  return { type: SHARE_BY_BUSINESS_BRANCH, payload };
}

export const reset = () => {
  return { type: RESET };
}
