  
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormHelperText,
  FormControl,
  FormLabel,
  Skeleton 
} from '@mui/material';

import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';


const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
}));

const InputWrapper = ({input: {name, onChange, value, ...restInput}, inputOnChange, meta, waiting=false, ...rest}) => {
  // const serverError = rest.errors && rest.errors.hasOwnProperty.call(rest.errors, name) ? rest.errors[name]: null;
  // const showServerError =  Boolean(serverError && !meta.dirtySinceLastSubmit);
	const showError = Boolean( ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched );
  const classes = useStyles();
  const modules = {
    toolbar: [
      [{ 'header': [1, 2, false] }],
      ['bold', 'italic', 'underline','strike', 'blockquote'],
      [{'list': 'ordered'}, {'list': 'bullet'}, {'indent': '-1'}, {'indent': '+1'}],
      // ['link', 'image'],
      // ['clean'] 
    ],
  };

	return (
		<>
      <FormControl disabled={rest.disabled} className={classes.formControl}  error={ Boolean(showError) } >
        <FormLabel required={rest.required}>{rest.label}</FormLabel>
        {waiting?<Skeleton variant="rectangular"/> :
        <ReactQuill
          theme="snow"
          modules={modules} 
          {...rest}
          name={name}
          // error={showServerError }
          error={ Boolean(showError) }
          inputProps={restInput}
          onChange={ e => {
            onChange(e);
            inputOnChange && inputOnChange(e);
          }}
          // onChange={onChange}
          value={value}
        />
        }
        {showError &&
          <FormHelperText>
            {meta.error || meta.submitError}
          </FormHelperText>
        }
        {/* {showServerError  && <FormHelperText >{meta.error || meta.submitError || serverError}</FormHelperText> } */}
      </FormControl>
		</>
	);
};

export default InputWrapper;
