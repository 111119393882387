import mainSaga from '../../sagas/shoppers.saga';
import mainReducer from '../reducers/modules/shoppers.reducer';
import {shoppers as actions} from '../actions/modules';

export function getModule() {
    return {
        id: "module_shoppers",
        reducerMap: {
          module_shoppers: mainReducer,
        },
        sagas: [mainSaga],
        finalActions: [actions.reset()],
    }; 
}