import { TextField,
  FormControl,
  FormLabel,
  Skeleton,
  IconButton,
  Tooltip  } from '@mui/material';
import { Controller } from 'react-hook-form';
import ClearIcon from '@mui/icons-material/Clear';

export default function TextFieldElement({
  validation = {},
  parseError,
  type,
  required,
  name,
  control,
  clearable=false, 
  waiting=false,
  ...rest
}) {
  if (required) {
    validation.required = 'This field is required'
  }
  if (type === 'email') {
    validation.pattern = {
      // eslint-disable-next-line no-useless-escape
      value: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
      message: 'Please enter a valid email address'
    }
  }
  return (
    
        <Controller
          name={name}
          control={control}
          rules={validation}
          render={({ field, fieldState: { error } }) =>
            {
              return (
                <FormControl disabled={rest.disabled} sx={{minWidth: '100%'}}  error={ Boolean(error) } >
                  <FormLabel required={rest.required}>{rest.label}
                    {(clearable && ! waiting)?
                    <IconButton
                      onClick={ () => {field.onChange(null)}}
                      edge="end"
                      sx={{float: 'right', marginRight: '0px'}}
                      size="small"
                    >
                      <Tooltip title={"Borrar respuesta"} >
                        <ClearIcon fontSize="small"/>
                      </Tooltip>
                    </IconButton>:null}
                  </FormLabel>
                  {waiting?<Skeleton variant="rectangular"/> :
                    <TextField
                      size="small"
                      variant="standard"
                      fullWidth
                      {...field}
                      error={ Boolean(error) }
                      value={ field.value || ''}
                      helperText={error ? (typeof parseError === 'function' ? parseError(error) : error.message) : rest.helperText}></TextField>
                    }
                    {/* {showServerError  && <FormHelperText >{meta.error || meta.submitError || serverError}</FormHelperText> } */}
                  </FormControl>
              )
            }
          }
        />
    
  )
}
