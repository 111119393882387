// action types
export const RESET = "[EXTERNAL][SHOPPERS] RESET";
//element actions
export const CREATE = "[EXTERNAL][SHOPPERS] CREATE";
export const CITIES = "[EXTERNAL][SHOPPERS] CITIES";
export const STATES = "[EXTERNAL][SHOPPERS] STATES";
//module actions
export const CREATE_REQUEST = "[EXTERNAL][SHOPPERS][CREATE] REQUEST";
export const CREATE_SUCCESS = "[EXTERNAL][SHOPPERS][CREATE] SUCCESS";
export const CREATE_FAILURE = "[EXTERNAL][SHOPPERS][CREATE] FAILURE";

//neighborhoods actions
export const CITIES_REQUEST = "[EXTERNAL][SHOPPERS][CITIES] REQUEST";
export const CITIES_SUCCESS = "[EXTERNAL][SHOPPERS][CITIES] SUCCESS";
export const CITIES_FAILURE = "[EXTERNAL][SHOPPERS][CITIES] FAILURE";

//states actions
export const STATES_REQUEST = "[EXTERNAL][SHOPPERS][STATES] REQUEST";
export const STATES_SUCCESS = "[EXTERNAL][SHOPPERS][STATES] SUCCESS";
export const STATES_FAILURE = "[EXTERNAL][SHOPPERS][STATES] FAILURE";

export const create = (payload)=> {
  return { type: CREATE, payload};
}

export const cities = (payload)=> {
  return { type: CITIES, payload };
}

export const states = (payload)=> {
  return { type: STATES, payload };
}

export const reset = ()=> {
  return { type: RESET };
}