import React from 'react';
import {Container, Paper, Grid } from '@mui/material';
import styles from './index.module.css';
import Table from '../../components/table';
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import VpnKeyOutlinedIcon from '@mui/icons-material/VpnKeyOutlined';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { withRouter } from "react-router-dom";
import { backdropActions } from '../../store/actions';
import { clients } from '../../store/actions/modules';
import { connect } from 'react-redux';

class Clients extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      clients: [],
      client: null,
      loading:false
    };
  }

  componentDidMount() {
    this.props.getList();
  }

  status(data){
    const content =  data === "Active" ? "Activo": "Inactivo";
    return content;
  };

  render() {
    const columns = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Nombre',sortable: true },
      { id: 'email', numeric: true, disablePadding: false, label: 'Email',sortable: true },
      { id: 'status', numeric: true, disablePadding: false, label: 'Estado',sortable: true, render: (data)=>this.status(data) }
    ];

    const maxWidth = false;
    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>
            
            <Grid container spacing={3} direction="row">
              {/* table */}
              <Grid item xs={12}>
                <Paper elevation={1} className={styles.paper}>
                  <Table
                    title = {"Comisionistas"}
                    data = {this.props.list.data}
                    columns={columns}
                    addAction ={(event) => {this.props.history.push("/clients/create");}}
                    actions={[
                      {
                        icon: InfoOutlinedIcon,
                        tooltip: 'Info',
                        onClick: (event, row) => {this.props.history.push(`/clients/${row.slug}/detail`)}
                      },
                      {
                        icon: VpnKeyOutlinedIcon,
                        tooltip: 'Cambiar Contraseña',
                        onClick: (event, row) => {this.props.history.push(`/clients/${row.slug}/restore`)}
                      },
                      {
                        icon: EditOutlinedIcon,
                        tooltip: 'Editar',
                        onClick: (event, row) => {this.props.history.push(`/clients/${row.slug}/update`)}
                      }
                    ]}
                    loading={this.props.list.loading}
                  />
                </Paper>
              </Grid>
            </Grid>


          
          
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getList: () =>{
      dispatch(clients.list());
    },
    backdropOpen: () => {
      dispatch(backdropActions.backdropOpen())
    },
    backdropClose: () => {
      dispatch(backdropActions.backdropClose())
    }
  }
};

const mapStateToProps = (state) => {
  return {
    list: state.module_clients.list
  };
}



const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(Clients);

export default withRouter(_component);
