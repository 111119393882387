import mainSaga from '../../../sagas/external/results.saga';
import mainReducer from '../../reducers/external/results.reducer';
import {results as actions} from '../../actions/external';

export function getModule() {
    return {
        id: "external_results",
        reducerMap: {
          external_results: mainReducer,
        },
        sagas: [mainSaga],
        finalActions: [actions.reset()],
    }; 
}