import {visits as actions} from '../../actions/external';

const initialState = {
  element: {
    contract_id:"",
    id:"",
    month:"",
    name:"",
    schedules:[]
  },
  status_element: {
    loading: false,
    success: false,
  },
  status_save: {
    loading: false,
    success: false,
  },
};

const reducer = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.ELEMENT_REQUEST:
    {
      return {
        ...state,
        status_element: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.ELEMENT_SUCCESS:
    {
      return {
        ...state,
        element: action.payload,
        status_element: {
          loading: false,
          success: true,
        },
      }
    }
    case actions.ELEMENT_FAILURE:
    {
      return {
        ...state,
        element: initialState.element,
        status_element: {
          loading: false,
          success: false,
        },
      }
    }
    case actions.SAVE_REQUEST:
    {
      return {
        ...state,
        status_save: {
          loading: true,
          success: false,
          response: []
        },
      }
    }
    case actions.SAVE_SUCCESS:
    {
      return {
        ...state,
        status_save: {
          loading: false,
          success: true,
          response: action.payload
        },
      }
    }
    case actions.SAVE_FAILURE:
    {
      return {
        ...state,
        status_save: {
          loading: false,
          success: false,
          response: action.payload
        },
      }
    }
    case actions.RESET:
    {
      return initialState;
    }
    default:
    {
      return state;
    }
  }
};

export default reducer;