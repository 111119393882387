import React from 'react';
import {
  Container, 
  Grid,
  Toolbar,
  Typography,
} from '@mui/material';
import { connect } from 'react-redux';
import { results } from '../../../../store/actions/external';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import ResultsComponent  from '../../../../components/ResultsByBusinessBranch';


class MainComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      token:null
    };
  }

  async componentDidMount() {
    const { token} = this.props.match.params;
    this.props.getModuleData({token:token});
    this.setState({token:token});
  }

  componentWillUnmount(){
    this.props.clearList();
  }

  render() {
    const maxWidth = false;


    return (
      <Container maxWidth={maxWidth} className={styles['client-container']}>
          {/* <Paper elevation={1} className={styles.paper}>
          
          </Paper> */}

        <Grid container spacing={2} direction="column">

              <Grid container item spacing={3} justifyContent="flex-end">
                {/* Title */}
                <Grid item xs>
                  <Toolbar className={styles['toolbar']}>
                    <Typography variant="h4" id="tableTitle" component="div">
                      {this.props.results.resultados_extra.encuesta}
                    </Typography>
                  </Toolbar>
                </Grid>
                {/* Buttons */}
                
              </Grid>
        </Grid>

        <Grid item xs>
          <Toolbar className={styles['toolbar']}>
            <Typography variant="h4" id="tableTitle" component="div">
              {'PRESENTACIÓN DE RESULTADOS'}
              <Typography variant="caption" display="block" gutterBottom>
              {`El siguiente reporte muestra los resultados del estudio realizado a los restaurantes de ${this.props.results.resultados_extra.establecimiento} durante el mes ${this.props.results.resultados_extra.mes}`}
              </Typography>
            </Typography>
          </Toolbar>
        </Grid>

        <Grid item >
          <ResultsComponent
            global_results={this.props.results.resultados_globales}
            catalog_results={this.props.results.resultados_categoria}
            numeric_results={this.props.results.resultados_numericos}
            open_questions_results={this.props.results.resultados_abiertas}
            commentaries={this.props.results.resultados_comentarios}
            images={this.props.results.resultados_imagenes}
            multiple_results={this.props.results.resultado_multiple}
            loading={this.props.status_results.loading}
          />
        </Grid>


      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleData:(payload)=>{
      dispatch(results.byBusinessBranch(payload));
    },
    clearList:()=>{
      dispatch(results.reset());
    }
  }
};

const mapStateToProps = (state) => {
  return {
    results: state.external_results.results_by_business_branch.element,
    status_results: state.external_results.results_by_business_branch,
  };
}


const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent);

export default withRouter(_component);