import {Component} from 'react';
import {
  Paper,
  Grid,
  Typography,
} from '@mui/material';
import styles from './index.module.css';
import { TextFieldElement } from '../../../components/form-mui';
class FormComponent extends Component {

  render() {
    return (
      <Grid item container>
        <Grid item lg={12}>
          <Typography variant="h6" component="div">
            Datos Generales
          </Typography>
        </Grid>

        <Grid item xs={12}>
          <Paper className={styles.paper} variant="outlined">
            <TextFieldElement
              control={this.props.control}
              waiting={this.props.waiting}
              required
              name="title"
              type="text"
              label="Titulo de la encuesta"
            />
          </Paper>
        </Grid>
      </Grid>
    );
  }

}


export default FormComponent;
