import React, {Component} from 'react';
import {
  Paper,
  Grid,
} from '@mui/material';
import styles from './index.module.css';
import { Field } from 'react-final-form';
import {Input} from '../../../components/inputs';

class FormComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }
  
  render() {
    return (
      <>
        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
            required
            name="password"
            fullWidth
            component={Input}
            type="password"
            label="Contraseña"
          />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            waiting={this.props.waiting}
            name="password_confirmation"
            fullWidth
            component={Input}
            type="password"
            label="Confirmar Contraseña"
          />
          </Paper>
        </Grid>
      </>
    );
  }

}

export default FormComponent;
