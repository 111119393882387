import React from 'react';
import {Container, Paper } from '@mui/material';
import { connect } from 'react-redux';
import styles from './index.module.css';
import Table from '../../components/table';
import { withRouter } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmDialog from '../../components/dialog/Confirm';
import { shoppers } from '../../store/actions/modules';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

class Shoppers extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dialog_open: false,
      record_slug:null
    };
  }

  componentDidMount() {
    this.initialLoad();
  }
  
  initialLoad(){
    this.props.getList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.remove !== this.props.remove) {
      if(this.props.remove.success){
        this.initialLoad();
        this.setState({dialog_open: false, record_slug : null }); 
      }else{
        if(!this.props.remove.loading){
          this.setState({dialog_open: false, record_slug : null }); 
        }
      }
    }
  }

  address(data){
    const content =  `${data?.city}, ${data?.state}`;
    return content;
  };

  status(data){
    const content =  data === "Pending" ? "Pendiente": "Activo";
    return content;
  };

  openDeleteConfirm(slug){
    this.setState({dialog_open: true, record_slug: slug});
  }

  deleteRecord(slug){
    this.props.deleteRecord({slug:slug});
  }

  render() {

    const columns = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Nombre',sortable: true },
      { id: 'social_status', numeric: true, disablePadding: false, label: 'Status Social',sortable: true },
      { id: 'address_code', numeric: true, disablePadding: false, label: 'Dirección',sortable: true, render: (data)=>this.address(data)},
      { id: 'status', numeric: true, disablePadding: false, label: 'Estado',sortable: true, render: (data)=>this.status(data) }
    ];
    const maxWidth = false;
    return (
      <Container maxWidth={maxWidth} className={styles['client-container']}>
          {/* Dialog  */}
          <ConfirmDialog  
              open={this.state.dialog_open} 
              title={"Eliminar Shopper"}
              loading={this.props.remove.loading}
              confirm={() =>  this.deleteRecord(this.state.record_slug) } 
              cancel={() => this.setState({dialog_open: false, record_slug : null }) } 
              message={"¿Desea eliminar el siguiente registro?"}
            />
            <Paper elevation={1} className={styles.paper}>
              <Table
                title = {"Shoppers"}
                data = {this.props.list.data}
                columns={columns}
                addAction ={(event) => {this.props.history.push("/shoppers/create");}}
                actions={[
                  {
                    icon: InfoOutlinedIcon,
                    tooltip: 'Info',
                    onClick: (event, row) => {this.props.history.push(`/shoppers/${row.slug}/detail`)}
                  },
                  {
                    icon: DeleteOutlineOutlinedIcon,
                    tooltip: 'Eliminar',
                    onClick: (event, row) => this.openDeleteConfirm(row.slug)
                  },
                  {
                    icon: EditOutlinedIcon,
                    tooltip: 'Editar',
                    onClick: (event, row) => {this.props.history.push(`/shoppers/${row.slug}/update`)}
                  }
                ]}
                loading={this.props.list.loading}
              />
            </Paper>
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getList: () =>{
      dispatch(shoppers.list());
    },
    deleteRecord:(payload)=>{
      dispatch(shoppers.remove(payload));
    }
  }
};

const mapStateToProps = (state) => {
  return {
    list: state.module_shoppers.list,
    remove: state.module_shoppers.remove
  };
}


const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(Shoppers);

export default withRouter(_component);