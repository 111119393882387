import React, {Component} from 'react';
import {
  Paper,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Stack,
  Typography,
  FormHelperText
} from '@mui/material';
import styles from './index.module.css';
import { Field } from 'react-final-form';
import {Input, TextEditor,Select, Slider, TimePicker, DatePicker, RadioGroup, File} from '../../../../../components/inputs';
import { connect } from 'react-redux';
class FormComponent extends Component {

  constructor(props) {
    super(props);
    const types = {
      Binaria: { component: RadioGroup , type:"text" },
      Escala: { component: Slider , type:"text" },
      Abierta: { component: TextEditor , type:"text" },
      Imagen: { component: File , type:"file" },
      Hora: { component: TimePicker , type:"text" },
      Fecha: { component: DatePicker , type:"text" },
      Multiple: { component: Select , type:"text" },
      Numerica: { component: Input , type:"text" },
    };

    const marks  =[
      {
        value: 1,
        label: '😡'
      },
      {
        value: 2,
        label: '🙁'
      },
      {
        value: 3,
        label: '🤔'
      },
      {
        value: 4,
        label: '🙂'
      },
      {
        value: 5,
        label: '🥰'
      }
    ];

    const form  = {
      Binaria: true,
      Escala: 1,
      Abierta: null,
      Imagen: [],
      Hora: null,
      Fecha: null,
      Multiple: null,
      Numerica: null,
    };

    this.state = {
      slug:null,
      types: types,
      marks:marks,
      form:form,
      disabled:{}
    }
  }

  getTitle = (key) => {
    const catalog = this.props.catalogs.find( catalog => catalog.value === key || catalog.id === Number(key) );
    return catalog ? catalog.text : key;
  }

  getJumps = (value, question, key, index) => {
    for (const salto of question.saltos) {
      const question_to = this.props.element.json_questions[key].findIndex( x => x.id ===salto.ir_a);
      const questionTodisapear = {};
      if(question_to){
        for (let i = (index + 1); i < question_to; i++) {
          const element = this.props.element.json_questions[key][i];
          if (value.target.defaultValue === salto.en_respuesta ){
            questionTodisapear[element.id]=true;
            this.props.clearAnswer(`answers.question_${element.id}.respuesta`);
          }else{
            questionTodisapear[element.id]=false;
          }
        }
        this.setState({disabled: {...this.state.disabled, ...questionTodisapear }});
      }
    }
  }
  
  getOptions(question){
    let options = [];
    if(question.tipo === "Multiple"){
      if(question.opciones.length > 0){
        options = question.opciones.map( (element) => ({ text:element.opcion, value:element.opcion}))
        if(question.preset_opciones.otro){
          options.push({text:"Otro", value:"Otro"});
        }
      }
      if(question.preset_opciones.no_aplica){
        options.push({text:"No aplica", value:"NA"});
      }
    }
    if(question.tipo === "Binaria"){
      options=[{value: 'Sí',label: 'Si'},{value: 'No',label: 'No'}];
      if(question.preset_opciones.no_aplica){
        options.push({label:"No aplica", value:"NA"});
      }
    }
    return options;
  }

  render() {

    const required = (value) => (value ? undefined : "Respuesta requerida");

    return (
      <>
      {
        Object.keys(this.props.element.json_questions).map((key,i)=>{
        return (
          <Card variant="outlined" sx={{width: '100%'}} key={i}>
            <CardHeader title={this.getTitle(key)}/>
            <CardContent>
              <Grid item xs={12} container spacing={2}>
              {
                this.props.element.json_questions[key].length > 0
                ?
                  this.props.element.json_questions[key].map((question, index) => (
                  <Grid item xs={12} key={index}>
                    { !(this.state.disabled[question.id] ? this.state.disabled[question.id] : false) &&
                    <Paper sx={{width: '100%'}} className={styles.paper} variant="outlined">
                      
                      <Stack spacing={3} direction="row" sx={{ mb: 1 }} alignItems="center">
                        { question.tipo === "Escala"? <Typography sx={{display: { xs: 'none', sm: 'block' }}} variant="overline" display="block" gutterBottom>{question.etiquetas.inicial}</Typography>: null}
                        { question.tipo !== "Imagen"?
                          <Field
                            disabled={ this.state.disabled[question.id] ? this.state.disabled[question.id] : false }
                            required={question.obligatorio}
                            validate={(question.obligatorio ? required : null) }
                            waiting={this.props.waiting}
                            name={`answers.question_${question.id}.respuesta`}
                            clearable={1}
                            // name={question.tipo}
                            // name={`[${question.id - 1}].test`}
                            component={this.state.types[question.tipo].component}
                            type={this.state.types[question.tipo].type}
                            label={question.titulo}
                            //for slider
                            marks ={this.state.marks}
                            min={0}
                            max={5}
                            // for select
                            options={ question.tipo === "Multiple" || question.tipo === "Binaria" ? this.getOptions(question) : null}
                            onChange={ (v) => (question.tipo === "Binaria" &&  question.saltos.length > 0)? this.getJumps(v, question, key, index) : null}
                          />
                          :
                          <Field
                            validate={(question.obligatorio ? required : null) }
                            required={question.obligatorio}
                            waiting={this.props.waiting}
                            name={`images.question_${question.id}.images`}
                            component={this.state.types[question.tipo].component}
                            type={this.state.types[question.tipo].type}
                            label={question.titulo}
                            //for slider
                            accept="image/*"
                            marks ={this.state.marks}
                            min={0}
                            max={5}
                            // for select
                            options={ question.tipo === "Multiple" || question.tipo === "Binaria" ? this.getOptions(question) : null}
                          />
                        }
                        { question.tipo === "Escala"? <Typography sx={{display: { xs: 'none', sm: 'block' }}} variant="overline" display="block" gutterBottom>{question.etiquetas.final}</Typography>: null}
                      </Stack>
                      { question.descripcion ? <FormHelperText> {question.descripcion} </FormHelperText>: null}
                    </Paper>
                      }
                  </Grid>
                  ))
                :
                null
              }
              </Grid>
            </CardContent>
            </Card>
          )
        })
      }
      </>
    );
  }

}

const mapStateToProps = (state) => {
  // console.log(state.external_surveys.element.data)
  return { 
    catalogs: state.external_surveys.catalogs.data,
    status_element: state.external_surveys.element,
    element: state.external_surveys.element.data
  };
}

const _component = connect(
  mapStateToProps,
  null
)(FormComponent);

export default _component;
