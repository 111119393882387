import React from 'react';
import {Container, Paper } from '@mui/material';
import { connect } from 'react-redux';
import { results } from '../../store/actions/modules';
import styles from './index.module.css';
import Table from '../../components/collapsible_table';
import { withRouter } from "react-router-dom";
import TimelineOutlinedIcon from '@mui/icons-material/TimelineOutlined';

class MainComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
    };
  }

  async componentDidMount() {
    this.initialLoad();
  }

  initialLoad(){
    this.props.getList();
  }

  componentWillUnmount(){
    this.props.clearList();
  }

  brand(data){
    const content =  `${data.name}`;
    return content;
  };

  render() {

    const columns = [
      { id: 'brand', numeric: false, disablePadding: true, label: 'Marca',sortable: true,render: (data)=>this.brand(data) },
    ];

    const child_columns = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Establecimiento',sortable: true },
    ]

    const child_actions = [
      {
        icon: TimelineOutlinedIcon,
        tooltip: 'Resultados por Establecimiento',
        onClick: ({event,row,data}) => {this.props.history.push(`/results/byBusinessBranch/${data.contract_id}`)}
      }
    ]
    const maxWidth = false;
    return (
      <Container maxWidth={maxWidth} className={styles['client-container']}>
          {/* table */}
          <Paper elevation={1} className={styles.paper}>
          <Table
            title = {"Marcas"}
            data = {this.props.list}
            columns={columns}
            child={{key:"businessBranches",title:"Establecimientos",columns:child_columns, actions:child_actions}}
            // actions={[
            //   {
            //     icon: TimelineOutlinedIcon,
            //     tooltip: 'Resultados por marca',
            //     onClick: (event, row) => {this.props.history.push(`/surveys/${row.slug}/detail`)}
            //   },
            // ]}
            menu={{key:"surveys",icon:TimelineOutlinedIcon, tooltip:'Resultados por marca', onClick: ({event,row,data}) => {this.props.history.push(`/results/byBrand/${row.brand.brand_id}/${data.survey_id}`)} }}
            loading={this.props.status_list.loading}
          />
          </Paper>
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getList:()=>{
      dispatch(results.brands());
    },
    clearList:()=>{
      dispatch(results.reset());
    }
  }
};

const mapStateToProps = (state) => {
  // console.log(state);
  return {
    list: state.module_results.brands_list.list,
    status_list: state.module_results.brands_list
  };
}


const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent);

export default withRouter(_component);