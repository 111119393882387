import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import data_service from "../services/auth";
import token_service from "../services/token";
import {auth as actions} from '../store/actions';
import {snackbarActions as snackbar} from '../store/actions';
import {success ,failure} from "../utils/api_responses";


export default function* root() {
  yield all([
    fork(watcherLogin),
    fork(watcherLogout),
    fork(watcherUser),
  ])
}

function* watcherLogin() {
  yield takeEvery(actions.LOGIN, workerLogin);
}

function* watcherLogout() {
  yield takeEvery(actions.LOGOUT, workerLogout);
}

function* watcherUser() {
  yield takeEvery(actions.USER, workerUser);
}

function* workerLogin(action) {
  try {
    yield put({ type: actions.LOGIN_REQUEST });
    const user_data = yield call(login, action.payload);

    token_service.setTokens({
      access_token:user_data.access_token,
      refresh_token:user_data.refresh_token
    });

    yield put({ type: actions.LOGIN_SUCCESS ,payload: user_data});
  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.LOGIN_FAILURE, payload: e });
  }
}

function* workerLogout() {
  try {
    yield put({ type: actions.LOGOUT_REQUEST });
    const user_data = yield call(logout);

    token_service.removeTokens();

    yield put({ type: actions.LOGOUT_SUCCESS ,payload: user_data});
  } catch (e) {
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.LOGOUT_FAILURE, payload: e });
  }
}


function* workerUser() {
  try {
    yield put({ type: actions.USER_REQUEST });
    const user_data = yield call(user);
    yield put({ type: actions.USER_SUCCESS ,payload: user_data});
  } catch (e) {
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.USER_FAILURE, payload: e });
  }
}


function login(payload) {
  return new Promise( (resolve, reject) => {
    data_service.login(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function logout() {
  return new Promise( (resolve, reject) => {
    data_service.logout()
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function user() {
  return new Promise( (resolve, reject) => {
    data_service.user()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}
