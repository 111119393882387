import * as React from 'react';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import List from '@mui/material/List';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import Toolbar from '@mui/material/Toolbar';
import Routes from '../../../routes';
import { NavLink } from 'react-router-dom';
import ListItemButton from '@mui/material/ListItemButton';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import Collapse from '@mui/material/Collapse';

import withStyles from '@mui/styles/withStyles';
import { useTheme } from "@mui/styles";
import clsx from 'clsx';

import {
  useLocation
} from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';

import { navbarActions} from '../../../store/actions';

const drawerWidth = 220;


const useStyles = (theme) => ({
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
    borderRight : '0px'
  },
  paper: {
    background: theme.palette.primary.main,
    color : "white",
    borderRadius: '0px 95px 95px 0px'

  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,

  navlinkActive:{
    fontWeight: "bold",
    color: `white !important`
  },
  navlink:{
    color: "#969797",
    textDecoration:"none"
  },
  itemColor:{
    color: '#969797',
  },
  iconActive:{
    color: '#ffffff',
  },
  listItem:{
    paddingLeft: '4px',
    paddingRight: '0px',
  },
  listItemTextprimary:{
    fontWeight: 200,
  },
  arrowLeft:{
    width: '0',
    height: '0',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight:'10px solid #ffffff',
  }
});


const ListItem = withStyles({
  root: {
    "&$selected": {
      backgroundColor: "black",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
    "&$selected:hover": {
      backgroundColor: "black",
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
  },
  selected: {}
})(ListItemButton);


const ParentListItem = withStyles({
  root: {
    "&$selected": {
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
    "&$selected:hover": {
      color: "white",
      "& .MuiListItemIcon-root": {
        color: "white"
      }
    },
  },
  selected: {}
})(ListItemButton);

const styles = {
  navlinkActive:{
    fontWeight: "bold",
    color: `white !important`
  },
  navlink:{
    color: "#969797",
    textDecoration:"none"
  },
  itemColor:{
    color: '#969797',
  },
  iconActive:{
    color: '#ffffff',
  },
  listItem:{
    paddingLeft: '34px',
    paddingRight: '0px',
  },
  listItemTextprimary:{
    fontWeight: 200,
  },
  arrowLeft:{
    paddingRight: '34px',
    width: '0',
    height: '0',
    borderTop: '10px solid transparent',
    borderBottom: '10px solid transparent',
    borderRight:'10px solid #ffffff',
  }
};



function ResponsiveDrawer(props) {
  const theme = useTheme();

  const { window } = props;
  const classes = useStyles(theme);

  const [selected, setSelected] = React.useState(null);
  
  const handleDrawerToggle = () => {
    dispatch(navbarActions.navbarToggleFolded());
  };
  
  const navbar_state = useSelector(state => state.navbar);
  const dispatch = useDispatch();


  const location = useLocation();
  const drawer = (
    <div>
      <Toolbar />
      <List>
        {Routes.map((route, index) => (
          <div key={index}>
            { route.childs? 
              <>
                <ParentListItem
                  selected={selected === index }
                  className={classes.navlink} 
                  onClick={() => setSelected(prevState=>(prevState === index ? null : index)) } 
                >
                  <ListItemIcon 
                  sx={{minWidth:'35px'}}
                  className={clsx(classes.itemColor, {[classes.iconActive]: selected === index})}
                  ><route.icon/></ListItemIcon>
                  <ListItemText primary={route.sidebarName} />
                  {selected === index ? <ExpandLess /> : <ExpandMore />}
                </ParentListItem>
                <Collapse in={selected === index} timeout="auto" unmountOnExit>
                  <List component="div" disablePadding>
                    {route.childs.map((child, index) => (
                      <NavLink
                        key={index}
                        to={child.path}
                        style={classes.navlink}
                        activeStyle={classes.navlinkActive}
                      >
                        <ListItem 
                        sx={{pl: 4, paddingRight: '0px'}}
                        selected={location.pathname.includes(child.path)}>
                          <ListItemIcon 
                          sx={{minWidth:'35px'}}
                          className={clsx(classes.itemColor, {[classes.iconActive]: location.pathname.includes(route.path)})}
                          ><child.icon/></ListItemIcon>
                          <ListItemText primary={child.sidebarName} />
                          {location.pathname.includes(child.path) ?<Box 
                          sx={[styles.arrowLeft]}
                          ></Box>:null}
                        </ListItem>
                      </NavLink>
                    ))}
                  </List>
                </Collapse>
              </>
            :
              <NavLink 
                to={route.path} 
                style={classes.navlink} 
                activeStyle={classes.navlinkActive}
              >
                <ListItem 
                sx={{ paddingRight: '0px'}} selected={location.pathname.includes(route.path)}
                >
                  <ListItemIcon 
                  className={clsx(classes.itemColor, {[classes.iconActive]: selected === index})}
                  >{ <route.icon/>}</ListItemIcon>
                  <ListItemText  
                  classes={{ primary: classes.listItemTextprimary }} primary={route.sidebarName} 
                  />
                  {location.pathname.includes(route.path) ?<Box 
                   sx={[styles.arrowLeft]} 
                  ></Box>:null}
                </ListItem>
              </NavLink>
            }
            </div>
        ))}
      </List>
    </div>
  );

  const container = window !== undefined ? () => window().document.body : undefined;

  return (
      <Box
        component="nav"
        sx={{ width: { sm: drawerWidth }, flexShrink: { sm: 0 } }}
        aria-label="mailbox folders"
      >
        {/* The implementation can be swapped with js to avoid SEO duplication of links. */}
        <Drawer
          container={container}
          variant="temporary"
          open={navbar_state.foldedOpen}
          onClose={handleDrawerToggle}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}
          sx={{
            display: { xs: 'block', sm: 'none' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
            backgroundColor: 'primary.main',
            color : "white",
            borderRadius: '0px 95px 95px 0px'
          }}
        >
          {drawer}
        </Drawer>
        <Drawer
          variant="permanent"
          sx={{
            display: { xs: 'none', sm: 'block' },
            '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
          }}
          open
        >
          {drawer}
        </Drawer>
      </Box>
  );
}

// ResponsiveDrawer.propTypes = {
//   /**
//    * Injected by the documentation to work in an iframe.
//    * You won't need it on your project.
//    */
//   window: PropTypes.func,
// };

export default ResponsiveDrawer;

