import React, {Component} from 'react';
import {
  Paper,
  Grid,
} from '@mui/material';
import styles from './index.module.css';
import { Field } from 'react-final-form';
import { DatePicker, Input, TimePicker} from '../../../../../components/inputs';
import { connect } from 'react-redux';
class FormComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      
      <Grid item container spacing={3}>

        <Grid item sm={12} xs={12}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              required
              waiting={this.props.waiting}
              name="email"
              component={Input}
              label="E-mail"
              type="text"
            />
          </Paper>
        </Grid>
          
        <Grid item sm={12} xs={12}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              required
              waiting={this.props.waiting}
              name="date"
              component={DatePicker}
              label="Fecha de visita"
              type="text"
            />
          </Paper>
        </Grid>

        <Grid item sm={12} xs={12}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              required
              waiting={this.props.waiting}
              name="hour"
              component={TimePicker}
              label="Hora de visita"
              type="text"
            />
          </Paper>
        </Grid>

      </Grid>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    status_element: state.external_visits.status_element,
    element: state.external_visits.element
  };
}

const _component = connect(
  mapStateToProps,
  null
)(FormComponent);

export default _component;
