import { Validators } from '@lemoncode/fonk';
import { createFinalFormValidation } from '@lemoncode/fonk-final-form';

export const validationSchema = {
  field: {
    bussiness_branch_id:[Validators.required.validator],
    contract_beginning:[Validators.required.validator],
    package_id:[Validators.required.validator],
    survey_id:[Validators.required.validator],
    consumption_expenses:[Validators.required.validator],
  }
};

export const formValidation = createFinalFormValidation(validationSchema);

