  
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormHelperText,
  FormControl,
  FormLabel,
  TextField,
  Skeleton,
  IconButton,
  Tooltip 
} from '@mui/material';
import { TimePicker, LocalizationProvider } from '@mui/x-date-pickers'
import esLocale from "date-fns/locale/es";
// import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles((theme) => ({
  
  formControl: {
    minWidth: '100%',
  }
}));

const TimePickerWrapper = ({input: {name, onChange, value, ...restInput}, meta, clearable=false, waiting=false, ...rest}) => {
	const showError = Boolean( ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched );
  const classes = useStyles();

	return (
		<>
    <FormControl className={classes.formControl}  error={showError } >
      <FormLabel sx={{ paddingBottom: '16px'}} required={rest.required}>{rest.label}
        {(clearable && ! waiting)?
          <IconButton
            onClick={ () => {onChange({target:{value:null}})}}
            edge="end"
            sx={{float: 'right', marginRight: '0px'}}
            size="small"
          >
            <Tooltip title={"Borrar respuesta"} >
              <ClearIcon fontSize="small"/>
            </Tooltip>
          </IconButton>:null}
      </FormLabel>
      {waiting?<Skeleton variant="rectangular"/> :
        <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
          <TimePicker 
            {...restInput}
            error={showError}
            ampm={false}
            margin="normal"
            value={value.split(":").length >= 2 ? new Date().setHours(value.split(":")[0],value.split(":")[1]) : value}
            onChange={e => {
              if(e){
                // if(e instanceof Date && !isNaN(e)){
                  const d = e.toLocaleTimeString('en-GB');
                  onChange(d);
                // }
                // onChange(e);
              }
            }}
            renderInput={(params) => <TextField variant="standard" {...restInput} {...params} />}
          />
        </LocalizationProvider>
        }
        {/* <pre>{JSON.stringify(value, 0, 2)}</pre> */}
      {showError  && <FormHelperText >{meta.error || meta.submitError}</FormHelperText> }
      </FormControl>
		</>
	);
};

export default TimePickerWrapper;
