import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, useDispatch, } from 'react-redux';
import token_service from '../../services/token';
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
import './index.css';
import background_image from '../../../assets/images/Fondo.png';

import {snackbarActions} from '../../store/actions';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';


const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});

const MainRoute = ({ component: Component, ...rest }) => {
  
  const auth_data = useSelector(state => state.auth);
  let history = useHistory();

  // useEffect(() => {
  //   if (auth_data.logout.success) {
  //     if(!token_service.getLocalAccessToken()){
  //       history.push("/login");
  //     }
  //   }
  // }, [auth_data.logout.success, history]);

  const snackbarState = useSelector(state => state.snackbar);

  const dispatch = useDispatch();

  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    dispatch(snackbarActions.snackbarClose())
  };

  useEffect(() => {
    if (auth_data.login.success && !auth_data.login.loading) {
      if(token_service.getLocalAccessToken()){
        history.push("/");
      }
    }
  }, [auth_data.login, history]);

  return (
    <Route
      {...rest}
      render={({ location, ...props }) => (
        ! token_service.getLocalAccessToken() ? (
          <div style={{ 
            // // height: "100vh",
            // height: '100%',
            // margin: 0,
            // width: "100%",
            // padding: "10px",
            // fontSize: "20px",
            backgroundImage: `url(${background_image})`,
            backgroundPosition: 'center',
            backgroundRepeat: 'no-repeat',
            backgroundSize: 'cover',
          }} className="auth-layout">
            <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={handleClose}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
              <Alert onClose={handleClose} severity={snackbarState.severity}>
                {snackbarState.message}!
              </Alert>
            </Snackbar>
            <Component {...props} />
          </div>
        ) : (
          <Redirect
            to={{
              pathname: "/",
              state: { from: location }
            }}
          />
        )
      )}
    />
  );
};

export default MainRoute;