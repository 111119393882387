import http from '../utils/http-common';

const list = () => {
  return http.get("/catalogs/categories");
};

const detail = ({slug}) => {
  return http.get(`/catalogs/${slug}/edit`);
};

const create = ({data}) => {
  return http.post("/catalogs/store", data);
};

const update = ({slug, data}) => {
  return http.put(`/catalogs/${slug}/update`, data);
};

const remove = ({slug}) => {
  return http.delete(`catalogs/${slug}/delete`);
};


const catalogsService = {
  list,
  detail,
  create,
  update,
  remove
};

export default catalogsService;