import React from 'react';
import { connect } from 'react-redux';
import { diary } from '../../store/actions/modules';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";

import Calendar from '../../components/calendar';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import {
  Container,
  Chip,
  Stack,
  Grid,
  Box,
  IconButton,
  Button,
} from '@mui/material';
// calendar
// import Calendar from 'tui-calendar'; /* ES6 */
import "tui-calendar/dist/tui-calendar.css";

// // If you use the default popups, use this.
import 'tui-date-picker/dist/tui-date-picker.css';
import 'tui-time-picker/dist/tui-time-picker.css';
import { endMonth, startMonth, dateToString} from "../../utils/time";

import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';


class MainComponent extends React.Component {
  calendarRef = React.createRef();

  constructor(props) {
    super(props);

    this.state = {
      date: new Date(),
      selectedView:"month" //month || day
    };
  }

  async componentDidMount() {

    this.moduleDate({
      start_date: startMonth(this.state.date),
      end_date: endMonth(this.state.date)
    });
  }

  moduleDate({start_date, end_date}){
    this.props.getSchedules({
      start_date: dateToString(start_date),
      end_date: dateToString(end_date)
    });
  }

  componentWillUnmount(){
    this.props.clearList();
  }

  handleClickNextButton = () => {
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.next();
    this.setState({
      date:calendarInstance.getDate().toDate()
    }, ()=>{
      this.moduleDate({
        start_date: startMonth(this.state.date),
        end_date: endMonth(this.state.date)
      });
    });
  };

  handleClickBackButton = () => {
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.prev();
    this.setState({
      date:calendarInstance.getDate().toDate()
    }, ()=>{
      this.moduleDate({
        start_date: startMonth(this.state.date),
        end_date: endMonth(this.state.date)
      });
    });
  };

  handleClickMore = (event) =>{
    // console.log(event);
    // event.target.stopPropagation();
    // const calendarInstance = this.calendarRef.current.getInstance();
    // calendarInstance.setDate(date.toDate());
    // this.setState({selectedView:'day'});
  }

  handleClickTodayButton =({event}) =>{
    const today = new Date();
    const calendarInstance = this.calendarRef.current.getInstance();
    calendarInstance.setDate(today);
    this.setState({
      date:today
    }, ()=>{
      this.moduleDate({
        start_date: startMonth(this.state.date),
        end_date: endMonth(this.state.date)
      });
    });
  }



  render() {

    const buttonSx = {
      ...(this.props.schedules_status.loading && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    const myTheme = {
      // Theme object to extends default dark theme.
    };

    const monthNames = ["Enero", "Febrero", "Marzo", "Abril", "Mayo", "Junio","Julio", "Agosto", "Septiembre", "Octubre", "Noviembre", "Diciembre"];


    const calendars = [
      {
        id: '0',
        name: 'Programada/Confirmada',
        bgColor: '#7B52AB',
        borderColor: '#7B52AB'
      },
      {
        id: '1',
        name: 'Pagada',
        bgColor: '#17BA95',
        borderColor: '#17BA95'
      },
      {
        id: '2',
        name: 'Pendiente',
        bgColor: '#FF822E',
        borderColor: '#FF822E'
      },
      {
        id: '3',
        name: 'Exitosa',
        bgColor: '#3DB52A',
        borderColor: '#3DB52A'
      },
      {
        id: '4',
        name: 'Inicio Estudio',
        bgColor: '#11646E',
        borderColor: '#11646E'
      }
    ];
    const maxWidth = false;
    return (
      <Container maxWidth={maxWidth} className={styles['client-container']}>

        <Box sx={{ width: '100%' }}>
          <Grid container rowSpacing={1} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
            <Grid item container justifyContent="space-between" xs={12}>
              <Grid item xs={6}>

                <Button
                  variant="contained"
                  size="small"
                  sx={buttonSx}
                  disabled={this.props.schedules_status.loading}
                  type="submit"
                  onClick={ (event) => this.handleClickTodayButton(event) }
                >
                Hoy
                {this.props.schedules_status.loading && (
                  <CircularProgress
                    size={24}
                    sx={{
                      color: green[500],
                      position: 'absolute',
                      top: '50%',
                      left: '50%',
                      marginTop: '-12px',
                      marginLeft: '-12px',
                    }}
                  />
                )}
              </Button>
                {/* <Button onClick={ (event) => this.handleClickTodayButton(event) } variant="outlined">Hoy</Button> */}
                {this.state.selectedView==="day" && <Button onClick={ () => this.setState({selectedView:"month"}) } variant="outlined">Mes</Button>}
                <IconButton disabled={this.props.schedules_status.loading} onClick={ () =>this.handleClickBackButton() }>
                  <ChevronLeftIcon />
                </IconButton>
                <IconButton disabled={this.props.schedules_status.loading} onClick={ () => this.handleClickNextButton() }>
                  <ChevronRightIcon />
                </IconButton>
                {monthNames[this.state.date.getMonth()]} {this.state.date.getFullYear()}
                {/* <pre>{JSON.stringify(calendar.getDate(), 0, 2)}</pre> */}
              </Grid>
              <Grid container justifyContent="flex-end" item xs={6}>
                <Stack direction="row" spacing={1}>
                  <Chip label="Programada/Confirmada" sx={{color:"white", backgroundColor:"#7B52AB", borderBlockColor:"#7B52AB"}}variant="outlined"/>
                  <Chip label="Pagada" sx={{color:"white", backgroundColor:"#17BA95", borderBlockColor:"#17BA95"}}variant="outlined"/>
                  <Chip label="Pendiente" sx={{color:"white", backgroundColor:"#FF822E", borderBlockColor:"#FF822E"}}variant="outlined"/>
                  <Chip label="Exitosa" sx={{color:"white", backgroundColor:"#3DB52A", borderBlockColor:"#3DB52A"}}variant="outlined"/>
                  <Chip label="Inicio Estudio" sx={{color:"white", backgroundColor:"#11646E", borderBlockColor:"#11646E"}}variant="outlined"/>
                </Stack>
              </Grid>
            </Grid>
            <Grid item xs={12}>
              <Calendar
                ref={this.calendarRef}
                height="650px"
                calendars={calendars}
                disableDblClick={true}
                month={{
                  startDayOfWeek: 0,
                  daynames: ["Do","Lu","Ma","Mi","Ju","Vi","Sa"],
                }}
                schedules={this.props.schedules}
                isReadOnly
                scheduleView={['time']}
                taskView={false}
                template={{
                  time: function(schedule) {
                    return `<span style="color:white;background-color: ${calendars[schedule.calendarId].bgColor};padding: 9px">${
                      schedule.title
                    }</span>`;
                  },
                  monthGridHeaderExceed: function(hiddenSchedules) {
                    return '<span class="weekday-grid-more-schedules" style="background-color: #17ba95;color: white;border-radius: 54px;">&nbsp; &nbsp;+' + hiddenSchedules + ' más  &nbsp; &nbsp;</span>';
                  },
                }}
                onClickMore={(event) => this.handleClickMore(event)}
                theme={myTheme}
                // timezones={[
                //   {
                //     timezoneOffset: 540,
                //     displayLabel: 'GMT+09:00',
                //     tooltip: 'Seoul'
                //   },
                //   {
                //     timezoneOffset: -420,
                //     displayLabel: 'GMT-08:00',
                //     tooltip: 'Los Angeles'
                //   }
                // ]}
                useDetailPopup
                disableClick
                // useCreationPopup
                view={this.state.selectedView} // You can also set the `defaultView` option.
                // view="month"
                week={{
                  showTimezoneCollapseButton: false,
                  timezonesCollapsed: false
                }}
              />
              {/* <div id="calendar" sx={{height: '800px'}}></div> */}
            </Grid>
          </Grid>
        </Box>

      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getSchedules:(payload)=>{
      dispatch(diary.schedules(payload));
    },
    clearList:()=>{
      dispatch(diary.clear());
    }
  }
};

const mapStateToProps = (state) => {
  return {
    schedules: state.module_diary.schedules.list,
    schedules_status: state.module_diary.schedules
  };
}


const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent);

export default withRouter(_component);