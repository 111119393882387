import { Validators } from '@lemoncode/fonk';
import { createFinalFormValidation } from '@lemoncode/fonk-final-form';

export const validationSchema = {
  field: {
    name:[Validators.required.validator],
    last_name:[Validators.required.validator],
    sex:[Validators.required.validator],
    birthdate:[Validators.required.validator],
    marital_status:[Validators.required.validator],
    children:[Validators.required.validator],
    occupation:[Validators.required.validator],
    address_state:[Validators.required.validator],
    address_city:[Validators.required.validator],
    email:[Validators.required.validator, Validators.email.validator],
    feeding_behaviours:[Validators.required.validator],
    alcohol:[Validators.required.validator],
    "social_test.last_study":[Validators.required.validator],
    "social_test.toilets":[Validators.required.validator],
    "social_test.cars":[Validators.required.validator],
    "social_test.internet":[Validators.required.validator],
    "social_test.workers":[Validators.required.validator],
    "social_test.rooms":[Validators.required.validator],
    enso_code:[Validators.required.validator],
    phone:[Validators.required.validator],
    first_contact:[Validators.required.validator],
    type:[Validators.required.validator],
    status:[Validators.required.validator]
  }
};

export const formValidation = createFinalFormValidation(validationSchema);

