// action types
export const RESET = "[MODULE][SHOPPERS] RESET";
//element actions
export const CREATE = "[MODULE][SHOPPERS] CREATE";
export const LIST = "[MODULE][SHOPPERS] LIST";
export const UPDATE = "[MODULE][SHOPPERS] UPDATE";
export const DETAIL = "[MODULE][SHOPPERS] DETAIL";
export const REMOVE = "[MODULE][SHOPPERS] REMOVE";
export const CITIES = "[MODULE][SHOPPERS] CITIES";
export const STATES = "[MODULE][SHOPPERS] STATES";
//module actions
export const LIST_REQUEST = "[MODULE][SHOPPERS][LIST] REQUEST";
export const LIST_SUCCESS = "[MODULE][SHOPPERS][LIST] SUCCESS";
export const LIST_FAILURE = "[MODULE][SHOPPERS][LIST] FAILURE";

export const CREATE_REQUEST = "[MODULE][SHOPPERS][CREATE] REQUEST";
export const CREATE_SUCCESS = "[MODULE][SHOPPERS][CREATE] SUCCESS";
export const CREATE_FAILURE = "[MODULE][SHOPPERS][CREATE] FAILURE";

export const UPDATE_REQUEST = "[MODULE][SHOPPERS][UPDATE] REQUEST";
export const UPDATE_SUCCESS = "[MODULE][SHOPPERS][UPDATE] SUCCESS";
export const UPDATE_FAILURE = "[MODULE][SHOPPERS][UPDATE] FAILURE";

export const DETAIL_REQUEST = "[MODULE][SHOPPERS][DETAIL] REQUEST";
export const DETAIL_SUCCESS = "[MODULE][SHOPPERS][DETAIL] SUCCESS";
export const DETAIL_FAILURE = "[MODULE][SHOPPERS][DETAIL] FAILURE";

export const REMOVE_REQUEST = "[MODULE][SHOPPERS][REMOVE] REQUEST";
export const REMOVE_SUCCESS = "[MODULE][SHOPPERS][REMOVE] SUCCESS";
export const REMOVE_FAILURE = "[MODULE][SHOPPERS][REMOVE] FAILURE";

//neighborhoods actions
export const CITIES_REQUEST = "[MODULE][SHOPPERS][CITIES] REQUEST";
export const CITIES_SUCCESS = "[MODULE][SHOPPERS][CITIES] SUCCESS";
export const CITIES_FAILURE = "[MODULE][SHOPPERS][CITIES] FAILURE";

//states actions
export const STATES_REQUEST = "[MODULE][SHOPPERS][STATES] REQUEST";
export const STATES_SUCCESS = "[MODULE][SHOPPERS][STATES] SUCCESS";
export const STATES_FAILURE = "[MODULE][SHOPPERS][STATES] FAILURE";

export const create = (payload)=> {
  return { type: CREATE, payload};
}

export const update = (payload)=> {
  return { type: UPDATE, payload};
}

export const detail = (payload)=> {
  return { type: DETAIL, payload};
}

export const list = ()=> {
  return { type: LIST  };
}

export const remove = (payload)=> {
  return { type: REMOVE, payload};
}

export const cities = (payload)=> {
  return { type: CITIES, payload };
}

export const states = (payload)=> {
  return { type: STATES, payload };
}

export const reset = ()=> {
  return { type: RESET };
}