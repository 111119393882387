import http from '../utils/http-common';

const user = ({data}) => {
  return http.post("/panelists/user/store", data);
};

const service = {
  user
};

export default service;