// action types
export const RESET = "[EXTERNAL][VISITS] RESET";
//element actions
export const ELEMENT_REQUEST = "[EXTERNAL][VISITS][ELEMENT] REQUEST";
export const ELEMENT_SUCCESS = "[EXTERNAL][VISITS][ELEMENT] SUCCESS";
export const ELEMENT_FAILURE = "[EXTERNAL][VISITS][ELEMENT] FAILURE";
//module actions
export const SAVE_REQUEST = "[EXTERNAL][VISITS][SAVE] REQUEST";
export const SAVE_SUCCESS = "[EXTERNAL][VISITS][SAVE] SUCCESS";
export const SAVE_FAILURE = "[EXTERNAL][VISITS][SAVE] FAILURE";

export const DETAIL = "[EXTERNAL][VISITS] DETAIL";

export const save = (payload)=> {
  return { type: SAVE_REQUEST, payload };
}

export const detail = (payload)=> {
  return { type: DETAIL, payload};
}

export const reset = ()=> {
  return { type: RESET };
}
