import businessBranchesSaga from '../../sagas/business_branches.saga';
import businessBranchesReducer from '../reducers/modules/business_branches.reducer';
import {business_branches as actions} from '../actions/modules';

export function getModule() {
    return {
        id: "module_business_branches",
        reducerMap: {
          module_business_branches: businessBranchesReducer,
        },
        sagas: [businessBranchesSaga],
        finalActions: [actions.reset()],
    }; 
}