export const CREATE = "[MODULE][BRANDS] CREATE";
export const UPDATE = "[MODULE][BRANDS] UPDATE";
export const REMOVE = "[MODULE][BRANDS] REMOVE";
export const LIST = "[MODULE][BRANDS] LIST";
export const CUSTOMERS = "[MODULE][BRANDS] CUSTOMERS";
export const ELEMENT = "[MODULE][BRANDS] ELEMENT";

export const CREATE_REQUEST = "[MODULE][BRANDS][CREATE] REQUEST";
export const CREATE_SUCCESS = "[MODULE][BRANDS][CREATE] SUCCESS";
export const CREATE_FAILURE = "[MODULE][BRANDS][CREATE] FAILURE";

export const UPDATE_REQUEST = "[MODULE][BRANDS][UPDATE] REQUEST";
export const UPDATE_SUCCESS = "[MODULE][BRANDS][UPDATE] SUCCESS";
export const UPDATE_FAILURE = "[MODULE][BRANDS][UPDATE] FAILURE";

export const REMOVE_REQUEST = "[MODULE][BRANDS][REMOVE] REQUEST";
export const REMOVE_SUCCESS = "[MODULE][BRANDS][REMOVE] SUCCESS";
export const REMOVE_FAILURE = "[MODULE][BRANDS][REMOVE] FAILURE";

export const LIST_REQUEST = "[MODULE][BRANDS][LIST] REQUEST";
export const LIST_SUCCESS = "[MODULE][BRANDS][LIST] SUCCESS";
export const LIST_FAILURE = "[MODULE][BRANDS][LIST] FAILURE";


export const ELEMENT_REQUEST = "[MODULE][BRANDS][ELEMENT] REQUEST";
export const ELEMENT_SUCCESS = "[MODULE][BRANDS][ELEMENT] SUCCESS";
export const ELEMENT_FAILURE = "[MODULE][BRANDS][ELEMENT] FAILURE";

export const CUSTOMERS_REQUEST = "[MODULE][BRANDS][CUSTOMERS] REQUEST";
export const CUSTOMERS_SUCCESS = "[MODULE][BRANDS][CUSTOMERS] SUCCESS";
export const CUSTOMERS_FAILURE = "[MODULE][BRANDS][CUSTOMERS] FAILURE";

export const CLEAR = "[MODULE][BRANDS] CLEAR";

export const create = (payload)=> {
  return { type: CREATE, payload };
}

export const update = (payload)=> {
  return { type: UPDATE, payload };
}

export const remove = (payload)=> {
  return { type: REMOVE, payload };
}

export const list = ()=> {
  return { type: LIST  };
}

export const customers = ()=> {
  return { type: CUSTOMERS };
}

export const detail = (payload)=> {
  return { type: ELEMENT, payload };
}

export const reset = ()=> {
  return { type: CLEAR  };
}