import React, {Component} from 'react';
import {
  Paper,
  Grid,
  Typography
} from '@mui/material';
import styles from './index.module.css';
import { Field } from 'react-final-form';
import {Input} from '../../../components/inputs';

class FormComponent extends Component {
  render() {
    return (
      <Grid item container spacing={3}>
        <Grid item lg={12}>
          <Typography variant="h6" component="div">
            Datos Generales
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            name="name"
            fullWidth
            component={Input}
            type="text"
            label="Nombre"
            waiting={this.props.waiting}
          />
          </Paper>
        </Grid>
      </Grid>
    );
  }

}

export default FormComponent;
