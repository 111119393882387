import mainSaga from '../../sagas/catalogs.saga';
import mainReducer from '../reducers/modules/catalogs.reducer';
import {catalogs as actions} from '../actions/modules';

export function getModule() {
    return {
        id: "module_catalogs",
        reducerMap: {
          module_catalogs: mainReducer,
        },
        sagas: [mainSaga],
        finalActions: [actions.reset()],
    }; 
}