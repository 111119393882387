  
import React, {useEffect} from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormHelperText,
  FormControl,
  Input,
  FormLabel,
  Skeleton,
  IconButton,
  Tooltip 
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';
import { useDebounce } from 'use-debounce';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '100%',
  },
}));

const InputWrapper = ({input: {name, onChange, value, ...restInput}, inputOnChange, meta, clearable=false, waiting=false, ...rest}) => {
  // const serverError = rest.errors && rest.errors.hasOwnProperty.call(rest.errors, name) ? rest.errors[name]: null;
  // const showServerError =  Boolean(serverError && !meta.dirtySinceLastSubmit);
	const showError = Boolean( ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched );
  const classes = useStyles();

  const [text] = useDebounce(value, 1000);

  useEffect(() => {
    if(inputOnChange){
      inputOnChange({value: text});
    } 
    // eslint-disable-next-line
  }, [text]);

	return (
		<>
      <FormControl disabled={rest.disabled} className={classes.formControl}  error={ Boolean(showError) } >
        <FormLabel required={rest.required}>{rest.label}
        {(clearable && ! waiting)?
          <IconButton
            onClick={ () => {onChange(null)}}
            edge="end"
            sx={{float: 'right', marginRight: '0px'}}
            size="small"
          >
            <Tooltip title={"Borrar respuesta"} >
              <ClearIcon fontSize="small"/>
            </Tooltip>
          </IconButton>:null}
        </FormLabel>
        {waiting?<Skeleton variant="rectangular"/> :
        <Input
          {...rest}
          name={name}
          // error={showServerError }
          error={ Boolean(showError) }
          inputProps={restInput}
          onChange={ e => {
            onChange(e);}}
          // onChange={onChange}
          value={value}
        />
        }
        {showError &&
          <FormHelperText>
            {meta.error || meta.submitError}
          </FormHelperText>
        }
        {/* {showServerError  && <FormHelperText >{meta.error || meta.submitError || serverError}</FormHelperText> } */}
      </FormControl>
		</>
	);
};

export default InputWrapper;
