// action types
export const DETAIL = "[MODULE][BUSINESS_BRANCHES] DETAIL";
export const CREATE = "[MODULE][BUSINESS_BRANCHES] CREATE";
export const EDIT = "[MODULE][BUSINESS_BRANCHES] EDIT";
export const STORE = "[MODULE][BUSINESS_BRANCHES] STORE";
export const UPDATE = "[MODULE][BUSINESS_BRANCHES] UPDATE";
export const REMOVE = "[MODULE][BUSINESS_BRANCHES] REMOVE";
export const LIST = "[MODULE][BUSINESS_BRANCHES] LIST";  
export const ELEMENT = "[MODULE][BUSINESS_BRANCHES] ELEMENT";
export const NEIGHBORHOODS = "[MODULE][BUSINESS_BRANCHES] NEIGHBORHOODS";
export const BRANDS = "[MODULE][BUSINESS_BRANCHES] BRANDS";
export const NEIGHBORHOOD = "[MODULE][BUSINESS_BRANCHES] NEIGHBORHOOD";
export const RESET = "[MODULE][BUSINESS_BRANCHES] RESET";
//element actions
export const ELEMENT_REQUEST = "[MODULE][BUSINESS_BRANCHES][ELEMENT] REQUEST";
export const ELEMENT_SUCCESS = "[MODULE][BUSINESS_BRANCHES][ELEMENT] SUCCESS";
export const ELEMENT_FAILURE = "[MODULE][BUSINESS_BRANCHES][ELEMENT] FAILURE";
//brands actions
export const BRANDS_REQUEST = "[MODULE][BUSINESS_BRANCHES][BRANDS] REQUEST";
export const BRANDS_SUCCESS = "[MODULE][BUSINESS_BRANCHES][BRANDS] SUCCESS";
export const BRANDS_FAILURE = "[MODULE][BUSINESS_BRANCHES][BRANDS] FAILURE";
//neighborhoods actions
export const NEIGHBORHOODS_REQUEST = "[MODULE][BUSINESS_BRANCHES][NEIGHBORHOODS] REQUEST";
export const NEIGHBORHOODS_SUCCESS = "[MODULE][BUSINESS_BRANCHES][NEIGHBORHOODS] SUCCESS";
export const NEIGHBORHOODS_FAILURE = "[MODULE][BUSINESS_BRANCHES][NEIGHBORHOODS] FAILURE";
export const NEIGHBORHOODS_RESET = "[MODULE][BUSINESS_BRANCHES][NEIGHBORHOODS] RESET";
//neighborhood actions
export const NEIGHBORHOOD_REQUEST = "[MODULE][BUSINESS_BRANCHES][NEIGHBORHOOD] REQUEST";
export const NEIGHBORHOOD_SUCCESS = "[MODULE][BUSINESS_BRANCHES][NEIGHBORHOOD] SUCCESS";
export const NEIGHBORHOOD_FAILURE = "[MODULE][BUSINESS_BRANCHES][NEIGHBORHOOD] FAILURE";
export const NEIGHBORHOOD_RESET = "[MODULE][BUSINESS_BRANCHES][NEIGHBORHOOD] RESET";

export const SAVE_REQUEST = "[MODULE][BUSINESS_BRANCHES][SAVE] REQUEST";
export const SAVE_SUCCESS = "[MODULE][BUSINESS_BRANCHES][SAVE] SUCCESS";
export const SAVE_FAILURE = "[MODULE][BUSINESS_BRANCHES][SAVE] FAILURE";

export const LIST_REQUEST = "[MODULE][BUSINESS_BRANCHES][LIST] REQUEST";
export const LIST_SUCCESS = "[MODULE][BUSINESS_BRANCHES][LIST] SUCCESS";
export const LIST_FAILURE = "[MODULE][BUSINESS_BRANCHES][LIST] FAILURE";

//module actions

export const list = ()=> {
  return { type: LIST  };
}

export const create = ()=> {
  return { type: CREATE };
}

export const edit = (payload)=> {
  return { type: EDIT, payload };
}

export const store = (payload)=> {
  return { type: STORE, payload };
}

export const update = (payload)=> {
  return { type: UPDATE, payload };
}


export const remove = (payload)=> {
  return { type: REMOVE, payload };
}


export const detail = (payload)=> {
  return { type: DETAIL, payload};
}

export const brands = ()=> {
  return { type: BRANDS };
}

export const neighborhoods = (payload)=> {
  return { type: NEIGHBORHOODS, payload };
}

export const neighborhood = (payload)=> {
  return { type: NEIGHBORHOOD, payload };
}

export const reset = ()=> {
  return { type: RESET };
}
