import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import makeStyles from '@mui/styles/makeStyles';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import PropTypes from 'prop-types';

const useStyles = makeStyles((theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  formControl: {
    minWidth: '100%',
  },
}));

function ConfirmDialog(props) {

  const classes = useStyles();
  const [loading, setLoading] = React.useState(false);

  const handleClose = () => {
    props.cancel();
  };

  const handleConfirm = async () =>{
    props.confirm();
  }

  React.useEffect(() => {
    if (props.loading !== loading) {
      setLoading(props.loading);
    }
  }, [props.loading,loading]);

  return (
    <div>
      <Dialog
        open={props.open}
        onClose={(event, reason) => {
          if (reason !== 'backdropClick') {
            handleClose();
          }
        }}

        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{props.title}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            {props.message}
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose} variant="outlined" 
            size="small"  
            color="primary"
            disabled={loading}
          >
            Cancelar
          </Button>
          <Button 
            onClick={handleConfirm} variant="contained" 
            size="small"  
            color="primary" 
            autoFocus
            disabled={loading}
          >
            Confirmar
          {loading && <CircularProgress size={24} className={classes.buttonProgress} />}
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
}

ConfirmDialog.propTypes = {
  open: PropTypes.bool.isRequired,
  title: PropTypes.string.isRequired,
  confirm: PropTypes.func.isRequired,
  cancel: PropTypes.func.isRequired,
  message: PropTypes.string.isRequired,
};

export default ConfirmDialog;