import {shoppers as actions} from '../../actions/external';

const initialState = {
  create: {
    response:{},
    loading: false,
    success: false
  },
  cities: {
    data:[],
    loading: false,
    success: false
  },
  states: {
    data:[],
    loading: false,
    success: false
  }
};

const navbar = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.CREATE_REQUEST:
    {
      return {
        ...state,
        create:{
          response: {},
          loading: true,
          success: false
        }
      }
    }
    case actions.CREATE_SUCCESS:
    {
      return {
        ...state,
        create:{
          response: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.CREATE_FAILURE:
    {
      return {
        ...state,
        create:{
          response: {},
          loading: false,
          success: false
        }
      }
    }
    case actions.CITIES_REQUEST:
    {
      return {
        ...state,
        cities:{
          data: [],
          loading: true,
          success: false
        }
      }
    }
    case actions.CITIES_SUCCESS:
    {
      return {
        ...state,
        cities:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.CITIES_FAILURE:
    {
      return {
        ...state,
        cities:{
          data: [],
          loading: false,
          success: false
        }
      }
    }
    case actions.STATES_REQUEST:
    {
      return {
        ...state,
        states:{
          data: [],
          loading: true,
          success: false
        }
      }
    }
    case actions.STATES_SUCCESS:
    {
      return {
        ...state,
        states:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.STATES_FAILURE:
    {
      return {
        ...state,
        states:{
          data: [],
          loading: false,
          success: false
        }
      }
    }
    case actions.CLEAR:
    {
      return initialState;
    }
    default:
    {
      return state;
    }
  }
};

export default navbar;