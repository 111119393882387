import {apiCall as actions} from '../actions';

const initialState = {
  loading: false,
  success: false,
  response: [],
};

const customer = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.API_CALL_REQUEST:
    {
      return {
        ...state,
        loading: true,
        success: false,
      }
    }
    case actions.API_CALL_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        success: true,
        response: action.payload
      }
    }
    case actions.API_CALL_FAILURE:
    {
      return {
        ...state,
        loading: false,
        success: false,
        response: action.payload
      }
    }
    default:
    {
      return state;
    }
  }
};

export default customer;