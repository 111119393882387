import { Validators } from '@lemoncode/fonk';
import { createFinalFormValidation } from '@lemoncode/fonk-final-form';
// import { iban } from '@lemoncode/fonk-iban-validator';
// import { rangeNumber } from '@lemoncode/fonk-range-number-validator';
// import { ibanBlackList, switzerlandTransfer } from './custom-validators';

export const validationSchema = {
  field: {
    name: [Validators.required.validator],
    zip_code: [Validators.required.validator],
    neighborhood_id: [Validators.required.validator],
    email: [Validators.required.validator, Validators.email.validator],
    street: [Validators.required.validator],
    ext_number : [Validators.required.validator],
    phone : [Validators.required.validator]
  }
};

export const formValidation = createFinalFormValidation(validationSchema);
