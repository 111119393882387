import {navbarActions} from '../actions';

const initialState = {
  foldedOpen: true,
};

const navbar = function (state = initialState, action) {
  switch ( action.type )
  {
    case navbarActions.TOGGLE_FOLDED_NAVBAR:
    {
      return {
        ...state,
        foldedOpen: !state.foldedOpen
      }
    }
    case navbarActions.OPEN_FOLDED_NAVBAR:
    {
      return {
        ...state,
        foldedOpen: true
      }
    }
    case navbarActions.CLOSE_FOLDED_NAVBAR:
    {
      return {
        ...state,
        foldedOpen: false
      }
    }
    default:
    {
      return state;
    }
  }
};

export default navbar;