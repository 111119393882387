import { takeEvery, call, put } from "redux-saga/effects";
import { failure, success } from "../utils/api_responses";
import { snackbarActions as snackbar} from '../store/actions';

export default function* watcherSaga() {
  yield takeEvery("API_CALL_REQUEST", workerSaga);
}

function* workerSaga(action) {
  try {
    const payload = yield call(callApi, action.service, action.payload);
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:  payload,
      severity:'success'
    } });

    yield put({ type: "API_CALL_SUCCESS", payload: payload });
  } catch (e) {
    console.log(e);
    // yield put({ type: "[SNACKBAR] OPEN", payload: {
    //   message:'Oops, Se ha producido un error',
    //   severity:'error'
    // } });
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: "API_CALL_FAILURE", payload: e });

    
  }
}

function callApi(service, payload) {
  return new Promise( (resolve, reject) => {
    service(payload)
      .then((response) => {
        resolve(success(response));
      })
      .catch((e) => {
        console.log(e);
        reject(failure(e))
      });
  });
}