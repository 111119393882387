  
import React, { useCallback } from "react";

import { useDropzone } from "react-dropzone";

import AddOutlinedIcon from '@mui/icons-material/AddOutlined';

import {
  FormHelperText,
  FormControl,
  FormLabel,
  Skeleton,
  Chip,
  Stack,
  Button ,
} from '@mui/material';

function FileFieldInput({ required, input, dropZoneProps, meta, accept, waiting=false, ...props }) {

  const showError = Boolean( ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched );

  const onDrop = useCallback(
    (files) => {
      input.onChange(files);
    },
    [input]
  );


  // const { getRootProps, getInputProps } = useDropzone({
  //   onDrop,
  //   noDrag: true,
  //   accept:accept,
  //   ...dropZoneProps
  // });

  const { getRootProps, getInputProps, acceptedFiles } = useDropzone({
    onDrop,
    noDrag: true,
    accept:accept,
    ...dropZoneProps
  });

  const removeFile = file => () => {
    const newFiles = [...input.value];
    newFiles.splice(file, 1);
    acceptedFiles.splice(file, 1);  
    input.onChange(newFiles);
  };
  


  const label_files = acceptedFiles.map((file,index) => (
      <Chip key={index} label={`${file.path}`} variant="outlined" onDelete={removeFile(index)} />
      // <Chip key={index} label={`${file.path} - ${file.size} bytes`} variant="outlined" onDelete={removeFile(index)} />
      // <Chip key={index} label={`${file.path} - ${file.size} bytes`} variant="outlined" onDelete={removeFile(file)} />
  ));

  return (
    <FormControl disabled={props.disabled} sx={{minWidth: '100%'}}  error={Boolean(showError) } >
      <FormLabel  sx={{ paddingBottom: '16px'}} required={props.required}>{props.label}</FormLabel>
        {waiting?<Skeleton variant="rectangular"/> :
        <Stack direction={{ xs: 'column', sm: 'row' }}  spacing={1}>
          {label_files}
          <Button aria-label="delete" size="small" {...getRootProps()}>
            <input {...getInputProps()} />
            <AddOutlinedIcon fontSize="small"/>
          </Button>
        </Stack>
        }
      {showError &&
          <FormHelperText>
            {meta.error || meta.submitError}
          </FormHelperText>
      }
    </FormControl>
  );
}

export default FileFieldInput;

