import React from 'react';
import {Container, Paper } from '@mui/material';
import { connect } from 'react-redux';
import { surveys as actions } from '../../store/actions/modules';
import styles from './index.module.css';
import Table from '../../components/table';
import { withRouter } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmDialog from '../../components/dialog/Confirm';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

class MainComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      dialog_open: false,
      element: null,
      record_slug:null,
      loading:false
    };
  }

  componentDidMount() {
    this.initialLoad();
  }

  initialLoad(){
    this.props.getList();
  }

  componentWillUnmount(){
    this.props.clearList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.remove !== this.props.remove) {
      if(this.props.remove.success){
        this.initialLoad();
        this.setState({dialog_open: false, record_slug : null }); 
      }else{
        if(!this.props.remove.loading){
          this.setState({dialog_open: false, record_slug : null }); 
        }
      }
    }
  }

  openDeleteConfirm(slug){
    this.setState({dialog_open: true, record_slug: slug});
  }
  
  deleteRecord(slug){
    this.props.deleteRecord({slug:slug});
  }

  render() {

    const columns = [
      { id: 'title', numeric: false, disablePadding: true, label: 'Titulo',sortable: true },
      { id: 'count_questions', numeric: true, disablePadding: true, label: 'Preguntas',sortable: true }
    ];
    const maxWidth = false;
    return (
      <Container maxWidth={maxWidth} className={styles['client-container']}>
          {/* Dialog  */}
          <ConfirmDialog  
            open={this.state.dialog_open} 
            title={"Eliminar Encuesta"}
            loading={this.props.remove.loading}
            confirm={() =>  this.deleteRecord(this.state.record_slug) } 
            cancel={() => this.setState({dialog_open: false, record_slug : null }) } 
            message={"¿Desea eliminar el siguiente registro?"}
            />
          {/* table */}
          <Paper elevation={1} className={styles.paper}>
            <Table
              title = {"Encuestas"}
              data = {this.props.list.data}
              columns={columns}
              addAction ={(event) => {this.props.history.push("/surveys/create");}}
              actions={[
                {
                  icon: InfoOutlinedIcon,
                  tooltip: 'Info',
                  onClick: (event, row) => {this.props.history.push(`/surveys/${row.slug}/detail`)}
                },
                {
                  icon: DeleteOutlineOutlinedIcon,
                  tooltip: 'Eliminar',
                  onClick: (event, row) => this.openDeleteConfirm(row.slug)
                },
                {
                  icon: EditOutlinedIcon,
                  tooltip: 'Editar',
                  onClick: (event, row) => {this.props.history.push(`/surveys/${row.slug}/update`)}
                },
                {
                  icon: ContentCopyIcon,
                  tooltip: 'Copiar',
                  onClick: (event, row) => {this.props.history.push(`/surveys/${row.slug}/copy`)}
                }
              ]}
              loading={this.props.list.loading}
            />
          </Paper>
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getList:()=>{
      dispatch(actions.list());
    },
    clearList:()=>{
      dispatch(actions.reset());
    },
    deleteRecord:(payload) =>{
      dispatch(actions.remove(payload))
    }
  }
};

const mapStateToProps = (state) => {
  return {
    list: state.module_surveys.list,
    remove: state.module_surveys.save
  };
}


const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent);

export default withRouter(_component);