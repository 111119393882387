  
import React from 'react';
import {
  FormHelperText,
  Checkbox,
  FormControlLabel,
  FormGroup,
  Skeleton 
} from '@mui/material';


const SliderWrapper = ({input: {name, onChange, value, checked, ...restInput}, inputOnChange, meta, required, labelPlacement = "end",label = "", waiting=false,...rest}) => {
	const showError = Boolean(  ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched );

	return (
		<>
        <FormGroup>
        {waiting?<Skeleton variant="rectangular"/> :
          <FormControlLabel 
            control={
              <Checkbox
                    name={name}
                    value={value}
                    onChange={(event) => {
                      onChange(event);
                      rest.onChange && rest.onChange(event.target);
                      }
                    }
                    checked={checked}
                    // disabled={item.disabled}
                    required={required}
                    inputProps={{ required, ...restInput }}
                    {...rest}
                  />
                } 
              label={label} 
              labelPlacement={labelPlacement}
          />}
        </FormGroup>
        {showError &&
          <FormHelperText>
            {meta.error || meta.submitError}
          </FormHelperText>
        }
		</>
	);
};

export default SliderWrapper;
