import http from '../utils/http-common';

const list = () => {
  return http.get("/bussinessBranches");
};

const create = ({ data }) => {
  return http.post("/bussinessBranches/store", data);
};

const detail = ({ slug }) => {
  return http.get(`/bussinessBranches/${slug}/edit`);
};

const show = ({ slug }) => {
  return http.get(`/bussinessBranches/${slug}/show`);
};

const update = ({ slug, data }) => {
  return http.put(`/bussinessBranches/${slug}/update`, data);
};

const remove = ({ slug }) => {
  return http.delete(`bussinessBranches/${slug}/delete`);
};


const BussinessBranchesService = {
  list,
  detail,
  show,
  create,
  update,
  remove
};

export default BussinessBranchesService;