import { takeEvery, call, put } from "redux-saga/effects";

export default function* watcherSaga() {
  yield takeEvery("[DATA_LIST] REQUEST", workerSaga);
}

function* workerSaga(action) {
  try {
    const payload = yield call(dataList, action.payload);
    yield put({ type: "[DATA_LIST] SUCCESS", payload: payload });
  } catch (e) {
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[DATA_LIST] FAILURE", payload: e });

    
  }
}

function dataList({service}) {
  return new Promise( (resolve, reject) => {
    service()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}