import React, { useEffect, useRef} from 'react';
import {
  Paper,
  Grid,
  Button,
  Typography
} from '@mui/material';
import styles from './index.module.css';
//
import { TextFieldElement, AutoCompleteFieldElement, SelectElement, CheckboxElement } from '../../../components/form-mui';
import { styled } from '@mui/material/styles';
import Card from '@mui/material/Card';
import CardHeader from '@mui/material/CardHeader';
import CardContent from '@mui/material/CardContent';
import CardActions from '@mui/material/CardActions';
import IconButton from '@mui/material/IconButton';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ClearOutlinedIcon from '@mui/icons-material/ClearOutlined';
//
import { useFieldArray, useWatch } from "react-hook-form";

const Item = styled(Card)(({ theme }) => ({
  ...theme.typography.body2,
  padding: theme.spacing(1),
  color: theme.palette.text.secondary,
  margin: '20px',
}));

const createEmptyOption = () =>({
  opcion: null,
  ponderacion: null
});

const createEmptySalto = () =>({
  ir_a:null,
  en_respuesta:null,
  disponible:[]
});

const Form = ({
  index,
  waiting,
  control,
  catalogs,
  remove,
  questions = [],
  edit=false,
  ...props}) => {

  const state = {
    types: [
      {
        value: 'Binaria',
        text: 'Binaria (Sí/No)'
      },
      {
        value: 'Escala',
        text: 'Escala (Del 1 al 5)'
      },
      {
        value: 'Abierta',
        text: 'Abierta (Texto libre)'
      },
      {
        value: 'Imagen',
        text: 'Subir imagen'
      },
      {
        value: 'Hora',
        text: 'Hora (hh:mm)'
      },
      {
        value: 'Fecha',
        text: 'Fecha (dd/mm/aaaa)'
      },
      {
        value: 'Multiple',
        text: 'Opción multiple'
      },
      {
        value: 'Numerica',
        text: 'Numérica'
      },
    ],
    priorities: [
      {
        value: 1,
        text: 1
      },
      {
        value: 2,
        text: 2
      },
      {
        value: 3,
        text: 3
      },
    ],
    weightings: [
      {
        value: 0,
        text: '0'
      },
      {
        value: 0.25,
        text: 0.25
      },
      {
        value: 0.5,
        text: 0.5
      },
      {
        value: 0.75,
        text: 0.75
      },
      {
        value: 1,
        text: 1
      },
    ],
    in_questions:[
      {
        value: 'Sí',
        text: 'Sí'
      },
      {
        value: 'No',
        text: 'No'
      },
      {
        value: 'NA',
        text: 'No aplica'
      },
    ],
    questions:[]
  }; 

  const opciones = useFieldArray({ control, name: `questions.${index}.opciones` });

  const saltos = useFieldArray({ control, name: `questions.${index}.saltos` });

  const required = (value) => (value ? undefined : "Este campo es obligatorio");

  const watch_type = useWatch({control, name: `questions[${index}].tipo`});

  // const watch_catalog = useWatch({control, name: `questions[${index}].categoria_id`, defaultValue:{"value": null, "text": null}});

  const getLisQuestionOptions = ()=>{
    let options = [];
    // options= questions
    // .filter( x => (x.categoria_id ? x.categoria_id.value : null) === questions[index].categoria_id.value)
    // .filter(x => x.id != questions[index].id)
    // .map((question,index) => ({text:`Pregunta:  ${question.titulo}`, value: question.id, categoria_value: question.categoria_id.value}));

    options= questions
    .map((question,index) => ({id: question.id, text:`Pregunta ${index + 1}`, value: question.id, categoria_value: question.categoria_id ? question.categoria_id.value : null}))
    .filter( x => x.categoria_value === questions[index].categoria_id.value)
    .filter(x => x.id !== questions[index].id)
    return options;
  }
  
  
 
  const rowRef = useRef({});

  useEffect(() => {
    if (rowRef.current) {
      props.setRowHeight(index, rowRef.current.clientHeight);
    }
    // eslint-disable-next-line
  }, [rowRef]);

  useEffect(() => {
    if (rowRef.current) {
      props.setRowHeight(index, rowRef.current.clientHeight);
    }
    // eslint-disable-next-line
  }, [rowRef.current.clientHeight]);
  
  return (
    <Item ref={rowRef}>
      {/* {`height(${rowRef.current? rowRef.current.clientHeight : ""})`} */}
      <CardHeader
        title={
          `Pregunta ${index + 1}` 
        }
      />
      {/* <pre>{JSON.stringify(watch_catalog, null, 2)}</pre> */}
      <CardContent>
      <Grid item container spacing={3}>

      <Grid item xs={12}>
        <Paper className={styles.paper} variant="outlined">
          <TextFieldElement
            required
            name={`questions[${index}].titulo`}
            waiting={waiting}
            label="Pregunta"
            type="text"
            control={control}
          />
        </Paper>
      </Grid>

      <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
        <Paper className={styles.paper} variant="outlined">
          <AutoCompleteFieldElement
            name={`questions[${index}].categoria_id`}
            waiting={waiting}
            control={control}
            label="Categoría"
            type="text"
            options={catalogs}
            required
          />
        </Paper>  
      </Grid>

      <Grid item xs={6} sm={6} md={6} lg={6} xl={6}>
        <Paper className={styles.paper} variant="outlined">
          <TextFieldElement
            name={`questions[${index}].descripcion`}
            waiting={waiting}
            control={control}
            label="Descripción"
            type="text"
          />
        </Paper>
      </Grid>

    <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
      <Paper className={styles.paper} variant="outlined">
        <SelectElement
          disabled={edit}
          name={`questions[${index}].tipo`}
          waiting={waiting}
          control={control}
          label="Tipo"
          type="text"
          options={state.types} 
          required
        />
      </Paper>
    </Grid>

    {/* opcion multiple */}
    { (watch_type || watch_type )=== "Multiple"
          ? 
          <React.Fragment>
            <Grid container item spacing={3} justifyContent="flex-end">
              {/* Title */}
              <Grid item xs>
                <Typography variant="caption" display="block" gutterBottom>
                  Opciones
                </Typography>
              </Grid>
              {/* Buttons */}
              <Grid item>
                <Button
                  variant="contained"
                  size="small"
                  onClick={(e) => {  opciones.append(createEmptyOption() ) }}
                  color="primary"
                  disabled={waiting}
                  >
                  Agregar Opción
                </Button>
              </Grid>
            </Grid>
            <Grid item container spacing={3}>
            { opciones.fields.map((field, i) => (
              <Grid item container spacing={3} key={i}>
                <Grid item xs={5}>
                  <Paper className={styles.paper} variant="outlined">
                    <TextFieldElement
                      required={(watch_type === "Multiple" ? required : null) }
                      name={`questions[${index}].opciones[${i}].opcion`}
                      waiting={waiting}
                      label="Opción"
                      type="text"
                      control={control}
                    />
                  </Paper>
                </Grid>
                <Grid item xs={5}>
                  <Paper className={styles.paper} variant="outlined">
                    <SelectElement
                      required={(watch_type === "Multiple" ? required : null) }
                      name={`questions[${index}].opciones[${i}].ponderacion`}
                      waiting={waiting}
                      label="Ponderación"
                      type="text"
                      options={state.weightings}
                      control={control}
                    />
                  </Paper>
                </Grid>
                <Grid item xs>
                  <IconButton onClick={() => opciones.remove(i)} disabled={opciones.fields.length < 2}>
                    <ClearOutlinedIcon />
                  </IconButton>
                </Grid> 
              </Grid>
            ))}
            </Grid>
          </React.Fragment>
          : null
    }

    { (watch_type) === "Binaria"
      ? 
        <React.Fragment>
          <Grid container item spacing={3} justifyContent="flex-end">
            {/* Title */}
            <Grid item xs>
              <Typography variant="caption" display="block" gutterBottom>
                Saltos
              </Typography>
            </Grid>
            {/* Buttons */}
            <Grid item>
              <Button
                variant="contained"
                size="small"
                onClick={(e) => {  saltos.append(createEmptySalto() ) }}
                color="primary"
                disabled={saltos.length > 2 || waiting}
                >
                Agregar Salto
              </Button>
            </Grid>
          </Grid>
          <Grid item container spacing={3}>
          {saltos.fields.map((field, i) => (
            <Grid item container spacing={3} key={i}>
              <Grid item xs={5}>
                <Paper className={styles.paper} variant="outlined">
                  <SelectElement
                    required={(watch_type === "Binaria" ? required : null) }
                    name={`questions[${index}].saltos[${i}].en_respuesta`}
                    waiting={waiting}
                    label="En respuesta"
                    type="text"
                    options={state.in_questions}
                    control={control}
                  />
                </Paper>
              </Grid>
              <Grid item xs={5}>
                <Paper className={styles.paper} variant="outlined">
                  <SelectElement
                    required={(watch_type === "Binaria" ? required : null) }
                    name={`questions[${index}].saltos[${i}].ir_a`}
                    waiting={waiting}
                    label="Saltar a la pregunta"
                    type="text"
                    // options={questions}
                    options={getLisQuestionOptions()}
                    // options={questions.filter( x => (x.categoria_id ? x.categoria_id.value : null) === watch_catalog.value).map((question,index) => ({text:`Pregunta ${index + 1}`, value: question.id}))}
                    control={control}
                  />
                </Paper>
              </Grid>
              <Grid item xs>
                <IconButton onClick={() => saltos.remove(i)}>
                  <ClearOutlinedIcon />
                </IconButton>
              </Grid> 
            </Grid>
          ))}
          </Grid>
        </React.Fragment>
      : null
    }

    { (watch_type)  !== "Multiple"
      ? 
      <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
        <Paper className={styles.paper} variant="outlined">
          <SelectElement
            required={(watch_type === "Multiple" ? required : null) }
            name={`questions[${index}].ponderacion`}
            waiting={waiting}
            label="Ponderación"
            type="text"
            options={state.weightings}
            control={control}
          />
        </Paper>
      </Grid>
      : null
    }

    { (watch_type) === "Escala"
      ? 
      <>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <Paper className={styles.paper} variant="outlined">
          <TextFieldElement
            required={(watch_type === "Escala" ? required : null) }
            name={`questions[${index}].etiquetas[inicial]`}
            waiting={waiting}
            label="Etiqueta Inicial"
            type="text"
            control={control}
          />
        </Paper>
      </Grid>

      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <Paper className={styles.paper} variant="outlined">
          <TextFieldElement
            required={(watch_type === "Escala" ? required : null) }
            name={`questions[${index}].etiquetas[final]`}
            waiting={waiting}
            label="Etiqueta Final"
            type="text"
            control={control}
          />
        </Paper>
      </Grid>
      </>
      : null
    }

    { (watch_type) === "Numerica"
      ? 
      <>
      <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
        <Paper className={styles.paper} variant="outlined">
          <TextFieldElement
            required={(watch_type === "Numerica" ? required : null) }
            name={`questions[${index}].unidad`}
            waiting={waiting}
            label="Unidad"
            type="text"
            control={control}
          />
        </Paper>
      </Grid>
      </>
      : null
    }



    </Grid>
      </CardContent>
      <CardActions disableSpacing>
        
        <Grid
          container
          direction="row"
          justifyContent="space-between"
          alignItems="center"
        >
            <Grid item xs={5}
              container
              direction="row"
              justifyContent="flex-start"
              alignItems="center"
            >
              <Grid item xs>
                <IconButton onClick={() => remove(index)}>
                  <DeleteOutlineOutlinedIcon />
                </IconButton>
              </Grid>
              <Grid item xs>
              { watch_type === "Multiple" || watch_type === "Binaria" ? 
                <CheckboxElement
                  name={`questions[${index}].preset_opciones[no_aplica]`}
                  waiting={waiting}
                  label="Agregar 'No aplica'"
                  type="checkbox"
                  control={control}
                />
                : null }
                
              </Grid>
              <Grid item xs>
              { watch_type === "Multiple" ? 
                <CheckboxElement
                  name={`questions[${index}].preset_opciones[otro]`}
                  waiting={waiting}
                  label="Agregar 'Otro'"
                  control={control}
                /> : null }
              </Grid>
            </Grid>

          <Grid item xs
            container
            direction="row"
            justifyContent="flex-end"
            alignItems="center"
          >
            { ( watch_type === "Binaria" ||  watch_type === "Escala")  ? 
                
            <CheckboxElement
              name={`questions[${index}].invertir`}
              waiting={waiting}
              label="Invertir Ponderación"
              type="checkbox"
              control={control}
            /> : null }
          
            <CheckboxElement
              name={`questions[${index}].ver_encuesta`}
              waiting={waiting}
              label="Ver en encuesta"
              type="checkbox"
              control={control}
            />

            <CheckboxElement
              name={`questions[${index}].obligatorio`}
              waiting={waiting}
              label="Obligatorio"
              type="checkbox"
              control={control}
            />

            <CheckboxElement
              name={`questions[${index}].ver_resultados`}
              waiting={waiting}
              label="Ver en resultados"
              type="checkbox"
              control={control}
            />
          </Grid>
        </Grid>
      </CardActions>
      
    </Item>
  );
}

export default Form;
