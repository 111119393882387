import http from '../utils/http-common';

const list = () => {
  return http.get("/instructives");
};

const create = ({ data }) => {
  return http.post("/instructives/store", data);
};

const detail = ({ slug }) => {
  return http.get(`/instructives/${slug}/edit`);
};

const update = ({ slug, data }) => {
  return http.put(`/instructives/${slug}/update`, data);
};

const remove = ({ slug }) => {
  return http.delete(`instructives/${slug}/delete`);
};


const service = {
  list,
  detail,
  create,
  update,
  remove
};

export default service;