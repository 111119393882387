import {business_branches as actions} from '../../actions/modules';

const initialState = {
  list: {
    data:[],
    loading: false,
    success: false
  },
  save: {
    response: {},
    loading: false,
    success: false
  },
  element: {
    data:{
      schedules: [
        {
          days: [],
          opening_time: "",
          closing_time: ""
        }
      ],
      social_status:[2,3]
    },
    loading: false,
    success: false,
  },
  status_element: {
    loading: false,
    success: false,
  },
  brands: [],
  status_brands: {
    loading: false,
    success: false,
  },
  neighborhoods: [],
  status_neighborhoods: {
    loading: false,
    success: false,
  },
  neighborhood: {
    city: {id: null, country_code:null, state_code:null, name:null},
    country_code:null,
    id: null,
    state_code:null,
    city_id: null,
    name: null,
    zip_code: null,
  },
  status_neighborhood: {
    loading: false,
    success: false,
  },
};

const reducer = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.LIST_REQUEST:
    {
      return {
        ...state,
        list:{
          data: [],
          loading: true,
          success: false
        }
      }
    }
    case actions.LIST_SUCCESS:
    {
      return {
        ...state,
        list:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.LIST_FAILURE:
    {
      return {
        ...state,
        list:{
          data: [],
          loading: false,
          success: false
        }
      }
    }
    case actions.SAVE_REQUEST:
    {
      return {
        ...state,
        save:{
          response: {},
          loading: true,
          success: false
        }
      }
    }
    case actions.SAVE_SUCCESS:
    {
      return {
        ...state,
        save:{
          response: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.SAVE_FAILURE:
    {
      return {
        ...state,
        save:{
          response: {},
          loading: false,
          success: false
        }
      }
    }
    case actions.ELEMENT_REQUEST:
    {
      return {
        ...state,
        element:{
          data: initialState.element.data,
          loading: true,
          success: false
        }
      }
    }
    case actions.ELEMENT_SUCCESS:
    {
      return {
        ...state,
        element:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.ELEMENT_FAILURE:
    {
      return {
        ...state,
        element:{
          data: initialState.element.data,
          loading: false,
          success: false
        }
      }
    }
    case actions.BRANDS_REQUEST:
    {
      return {
        ...state,
        status_brands: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.BRANDS_SUCCESS:
    {
      return {
        ...state,
        status_brands: {
          loading: false,
          success: true,
        },
        brands: action.payload
      }
    }
    case actions.BRANDS_FAILURE:
    {
      return {
        ...state,
        status_brands: {
          loading: false,
          success: false,
        },
        brands: null
      }
    }
    case actions.NEIGHBORHOODS_REQUEST:
    {
      return {
        ...state,
        status_neighborhoods: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.NEIGHBORHOODS_SUCCESS:
    {
      return {
        ...state,
        status_neighborhoods: {
          loading: false,
          success: true,
        },
        neighborhoods: action.payload
      }
    }
    case actions.NEIGHBORHOODS_FAILURE:
    {
      return {
        ...state,
        status_neighborhoods: {
          loading: false,
          success: false,
        },
        neighborhoods: null
      }
    }
    case actions.NEIGHBORHOOD_REQUEST:
    {
      return {
        ...state,
        status_neighborhood: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.NEIGHBORHOOD_SUCCESS:
    {
      return {
        ...state,
        status_neighborhood: {
          loading: false,
          success: true,
        },
        neighborhood: action.payload
      }
    }
    case actions.NEIGHBORHOOD_FAILURE:
    {
      return {
        ...state,
        status_neighborhood: {
          loading: false,
          success: false,
        }
      }
    }
    case actions.RESET:
    {
      return {
        ...state
      }
    }
    default:
    {
      return state;
    }
  }
};

export default reducer;