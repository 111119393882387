  
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormHelperText,
  FormControl,
  FormLabel,
  FormControlLabel,
  Radio,
  RadioGroup,
  IconButton,
  Tooltip,
  Skeleton 
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles((theme) => ({
  radioGroup:{
    flexDirection: 'row',
    marginTop: '6px'
  },
  formControl: {
    minWidth: '100%',
  }
}));

const RadioWrapper = ({input: {name, onChange, value, ...restInput}, meta, clearable=false, waiting=false, ...rest}) => {
	const showError = Boolean( ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched );
  const classes = useStyles();

	return (
		<>
    <FormControl className={classes.formControl}  error={showError} >
      <FormLabel required={rest.required}>
        {rest.label}
        {(clearable && ! waiting)?
          <IconButton
            onClick={ () => {onChange(null)}}
            edge="end"
            sx={{float: 'right', marginRight: '0px'}}
            size="small"
          >
            <Tooltip title={"Borrar respuesta"} >
              <ClearIcon fontSize="small"/>
            </Tooltip>
          </IconButton>:null}
      </FormLabel>
      {waiting?<Skeleton variant="rectangular"/> :
        <RadioGroup 
          {...rest}
          {...restInput}
          className={classes.radioGroup} 
          name={name}
          // onChange={onChange}
          onChange={(v) => {
              onChange(v);
              rest.onChange && rest.onChange(v);
            }
          }
          value={String(value)}
        >
          {(rest.options ? rest.options: []).map((option, index) => (
            <FormControlLabel key={index} value={option.value} control={<Radio/>} label={option.label} />
          ))
          }
        </RadioGroup>}
      {/* <pre>{JSON.stringify(meta, 0, 2)}</pre> */}
      {showError  && <FormHelperText >{meta.error || meta.submitError}</FormHelperText> }
      </FormControl>
		</>
	);
};

export default RadioWrapper;
