import diarySaga from '../../sagas/diary.saga';
import diaryReducer from '../reducers/modules/diary.reducer';
import {diary as actions} from '../actions/modules';

export function getModule() {
    return {
        id: "module_diary",
        reducerMap: {
          module_diary: diaryReducer,
        },
        sagas: [diarySaga],
        finalActions: [actions.clear()],
    }; 
}