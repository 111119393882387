

import React from 'react';
import {
  Grid,
} from '@mui/material';
import {Input, Checkbox} from '../../../components/inputs';
import { Field } from 'react-final-form';

export default function SignIn({wating=false,...props}) {

  return(

    <Grid item container spacing={3}>
      <Grid item xs={12}>
        <Field
          waiting={wating}
          name="username"
          fullWidth
          component={Input}
          type="text"
          label="Usuario"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          waiting={wating}
          name="password"
          fullWidth
          component={Input}
          type="password"
          label="Contraseña"
        />
      </Grid>
      <Grid item xs={12}>
        <Field
          waiting={wating}
          name="remember_me"
          component={Checkbox}
          type="checkbox"
          label="Recuerdame"
        />
      </Grid>
    </Grid>
  );
}