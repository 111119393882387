import http from '../utils/http-common';

const catalogs = ({element}) => {
  return http.get(`/getCatalogs/${element}`);
};

const packages = () => {
  return http.get("/getPackages");
};

const customers = () => {
  return http.get("/getCustomers");
};

const brands = () => {
  return http.get("/getBrands");
};

const brandsByCustomer = ({slug}) => {
  return http.get(`/getBrandsByCustomer/${slug}`);
};

const businessBranches = () => {
  return http.get("/getBussinessBranches");
};

const businessBranchesByBrand = ({slug}) => {
  return http.get(`/getBusinessBranchesByBrand/${slug}`);
};

const surveys = () => {
  return http.get("/getSurveys");
};

const shoppers = ({visit}) => {
  return http.get(`/getShoppers/${visit}`);
};

const service = {
  catalogs,
  packages,
  customers,
  brands,
  brandsByCustomer,
  businessBranches,
  businessBranchesByBrand,
  surveys,
  shoppers
};

export default service;