import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import service_brands from "../services/brands";
import api_address from "../services/address_picker";
import data_service from "../services/business_branches";
import { business_branches as actions } from '../store/actions/modules';
import { snackbarActions as snackbar} from '../store/actions';
import { failure, success } from "../utils/api_responses";

export default function* root() {
  yield all([
    fork(watcherList),
    fork(watcherCreate),
    fork(watcherUpdate),
    fork(watcherDetail),
    fork(watcherStore),
    fork(watcherEdit),
    fork(watcherRemove),
    fork(watcherNeighborhoods),
    fork(watcherNeighborhood),
  ])
}


function* watcherList() {
  yield takeEvery(actions.LIST, workerList);
}

function* watcherCreate() {
  yield takeEvery(actions.CREATE, workerCreate);
}

function* watcherEdit() {
  yield takeEvery(actions.EDIT, workerEdit);
}

function* watcherDetail() {
  yield takeEvery(actions.DETAIL, workerDetail);
}

function* watcherUpdate() {
  yield takeEvery(actions.UPDATE, workerUpdate);
}

function* watcherStore() {
  yield takeEvery(actions.STORE, workerStore);
}

function* watcherRemove() {
  yield takeEvery(actions.REMOVE, workerRemove);
}


function* watcherNeighborhoods() {
  yield takeEvery(actions.NEIGHBORHOODS, workerNeighborhoods);
}

function* watcherNeighborhood() {
  yield takeEvery(actions.NEIGHBORHOOD, workerNeighborhood);
}

function* workerList() {
  try {
    
    yield put({ type: actions.LIST_REQUEST });
    const packages = yield call(list);
    yield put({ type: actions.LIST_SUCCESS ,payload: packages});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.LIST_FAILURE, payload: e });
  }
}

function* workerStore(action) {
  try {
    yield put({ type: actions.SAVE_REQUEST }); 
    const response = yield call(store, setterBusinessBranch(action.payload) );
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.SAVE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.SAVE_FAILURE, payload: e });
  }
}

function* workerUpdate(action) {
  try {
    yield put({ type: actions.SAVE_REQUEST }); 
    const response = yield call(update, setterBusinessBranch(action.payload) );
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.SAVE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.SAVE_FAILURE, payload: e });
  }
}

function* workerRemove(action) {
  try {
    yield put({ type: actions.SAVE_REQUEST }); 
    const response = yield call(remove, action.payload);
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.SAVE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.SAVE_FAILURE, payload: e });
  }
}

function* workerDetail(action) {
  try {
    yield put({ type: actions.RESET });
    yield put({ type: actions.ELEMENT_REQUEST});
    const data = yield call(detail, action.payload);
    yield put({ type: actions.ELEMENT_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.ELEMENT_FAILURE, payload: e });
  }
}

function* workerEdit(action) {
  try {
    yield put({ type: actions.RESET });
    yield put({ type: actions.ELEMENT_REQUEST});

    yield put({ type: actions.BRANDS_REQUEST});
    const brands = yield call(getBrands);
    yield put({ type: actions.BRANDS_SUCCESS,payload: brands});

    yield put({ type: actions.NEIGHBORHOOD_REQUEST});
    const element = yield call(getElement, action.payload);
    const neighborhood = yield call(getNeighborhood, element.neighborhood_id);
    yield put({ type: actions.NEIGHBORHOOD_SUCCESS,payload: neighborhood});

    yield put({ type: actions.NEIGHBORHOODS_REQUEST});
    const neighborhoods = yield call(getNeighborhoods, neighborhood.zip_code);
    yield put({ type: actions.NEIGHBORHOODS_SUCCESS,payload: neighborhoods});

    const data = getterBusinessBranch(brands,element,neighborhood);
    yield put({ type: actions.ELEMENT_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.ELEMENT_FAILURE, payload: e });
  }
}

function* workerCreate() {
  try {
    yield put({ type: actions.RESET });
    yield put({ type: actions.BRANDS_REQUEST });
    const brands = yield call(getBrands);
    yield put({ type: actions.BRANDS_SUCCESS,payload: brands});
  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.BRANDS_FAILURE, payload: e });
  }
}

function* workerNeighborhoods(action) {
  try {
    yield put({ type: actions.NEIGHBORHOODS_REQUEST });
    const neighborhoods = yield call(getNeighborhoods, action.payload);
    yield put({ type: actions.NEIGHBORHOODS_SUCCESS,payload: neighborhoods});
  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.NEIGHBORHOODS_FAILURE, payload: e });
  }
}

function* workerNeighborhood(action) {
  try {
    yield put({ type: actions.NEIGHBORHOOD_REQUEST });
    const neighborhood = yield call(getNeighborhood, action.payload);
    yield put({ type: actions.NEIGHBORHOOD_SUCCESS,payload: neighborhood});
  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.NEIGHBORHOOD_FAILURE, payload: e });
  }
}

function list() {
  return new Promise( (resolve, reject) => {
    data_service.list()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function store(payload) {
  return new Promise( (resolve, reject) => {
    data_service.create(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function update(payload) {
  return new Promise( (resolve, reject) => {
    data_service.update(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function remove(payload) {
  return new Promise( (resolve, reject) => {    
    data_service.remove(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function detail( payload) {
  return new Promise( (resolve, reject) => {
    data_service.show(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getElement( payload) {
  return new Promise( (resolve, reject) => {
    data_service.detail(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getBrands() {
  return new Promise( (resolve, reject) => {
    service_brands.list()
      .then((response) => {
        resolve(response.data.map(function(element){
          return {
            text:element.name,
            value:element.slug
          };
       }));
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getNeighborhood(zip_code) {
  return new Promise( (resolve, reject) => {
    api_address.neighborhood_detail(zip_code)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getNeighborhoods(neighborhood_id) {
  return new Promise( (resolve, reject) => {
    api_address.neighborhoods(neighborhood_id)
      .then((response) => {
        resolve(response.data.map(function(element){
          return {
            text:element.name,
            value:element.id
          };
       }));
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getterBusinessBranch(brands,element,neighborhood){
  const brand = brands.find( brand => brand.value === element.brand_id );
  return {
    ...element,
    ...{
      brand_id: brand ? {text: brand.text, value: brand.value} : null,
      neighborhood_id: neighborhood ? {text: neighborhood.name, value: neighborhood.id} : null,
      social_status:  getteSocialStatus(element.social_status),
      schedules : element.schedules.map((element => ({ 
        days: days(element.days),
        opening_time: element.opening_time,
        closing_time: element.closing_time
       }))),
       zip_code: neighborhood ? neighborhood.zip_code : null,
    }
  }
}

function setterBusinessBranch({data, neighborhood, ...rest}){
  return {
    ...rest,
    data:{
      ...data, 
      ...{
        brand_id: data.brand_id.value,
        neighborhood_id: data.neighborhood_id.value,
        social_status:  setterSocialStatus(data.social_status),
        schedules : data.schedules.map((element => ({ 
          days: element.days.toString(),
          opening_time: element.opening_time,
          closing_time: element.closing_time
        }))),
        address_code:{
          state: neighborhood.city.state_code,
          address_code: neighborhood.city.id,
          city: neighborhood.city.name,
          neighborhood_id: neighborhood.id,
          neighborhood: neighborhood.name
        }
      }
    }
  }
}

function getteSocialStatus(data = ''){
  const status = {'E':0,'D':1,'D+':2,'C-':3,'C':4,'C+':5,'A/B':6};
  const values = data.split(",").filter((x) => status[x] ? status[x] : false );
  return values.map((x) => status[x])
}

function setterSocialStatus(value = []){
  const status = ['E','D','D+','C-','C','C+','A/B'];
  return value.map((x) => status[x]).toString()
}

function days(data = ''){
  return data.split(",").filter((x) => x ? x : false );
}