import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import data_service from "../services/brands";
import customer_service from "../services/customers";
import { brands as actions } from '../store/actions/modules';
import { snackbarActions as snackbar} from '../store/actions';
import { failure, success } from "../utils/api_responses";


export default function* root() {
  yield all([
    fork(watcherList),
    fork(watcherCustomer),
    fork(watcherDetail),
    fork(watcherCreate),
    fork(watcherRemove),
    fork(watcherUpdate),
  ])
}

function* watcherList() {
  yield takeEvery(actions.LIST, workerList);
}

function* watcherCustomer() {
  yield takeEvery(actions.CUSTOMERS, workerCustomer);
}

function* watcherDetail() {
  yield takeEvery(actions.ELEMENT, workerDetail);
}

function* watcherCreate() {
  yield takeEvery(actions.CREATE, workerCreate);
}

function* watcherRemove() {
  yield takeEvery(actions.REMOVE, workerRemove);
}

function* watcherUpdate() {
  yield takeEvery(actions.UPDATE, workerUpdate);
}


function* workerList() {
  try {
    
    yield put({ type: actions.LIST_REQUEST });
    const packages = yield call(list);
    yield put({ type: actions.LIST_SUCCESS ,payload: packages});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.LIST_FAILURE, payload: e });
  }
}

function* workerCustomer() {
  try {
    
    yield put({ type: actions.CUSTOMERS_REQUEST });
    const data = yield call(getCustomers);
    yield put({ type: actions.CUSTOMERS_SUCCESS ,payload: getterCustomers(data) });
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.CUSTOMERS_FAILURE, payload: e });
  }
}

function* workerDetail(action) {
  try {

    yield put({ type: actions.ELEMENT_REQUEST }); 
    yield put({ type: actions.CUSTOMERS_REQUEST });
    const customers = getterCustomers(yield call(getCustomers));
    yield put({ type: actions.CUSTOMERS_SUCCESS ,payload: customers});

    const data = yield call(detail, action.payload);
    yield put({ type: actions.ELEMENT_SUCCESS ,payload: getterBrand(data, customers)});

  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.ELEMENT_FAILURE, payload: e });
    yield put({ type: actions.CUSTOMERS_FAILURE, payload: e });
  }
}

function* workerCreate(action) {
  try {
    yield put({ type: actions.CREATE_REQUEST }); 
    const response = yield call(create, setter(action.payload));
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.CREATE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.CREATE_FAILURE, payload: e });
  }
}


function* workerUpdate(action) {
  try {
    yield put({ type: actions.UPDATE_REQUEST }); 
    const response = yield call(update, setter(action.payload));
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.UPDATE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.UPDATE_FAILURE, payload: e });
  }
}

function* workerRemove(action) {
  try {
    yield put({ type: actions.REMOVE_REQUEST }); 
    const response = yield call(remove, action.payload);
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.REMOVE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.REMOVE_FAILURE, payload: e });
  }
}

function list() {
  return new Promise( (resolve, reject) => {
    data_service.list()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function getCustomers() {
  return new Promise( (resolve, reject) => {
    customer_service.list()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function detail(payload) {
  return new Promise( (resolve, reject) => {
    data_service.detail(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function create(payload) {
  return new Promise( (resolve, reject) => {
    data_service.create(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function update(payload) {
  return new Promise( (resolve, reject) => {
    data_service.update(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function remove(payload) {
  return new Promise( (resolve, reject) => {    
    data_service.remove(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function setter({data, ...rest}){
  return {
    ...rest,
    data: {...data, ...{customer_id:data.customer_id.value}}
  }
}

function getterBrand(data, customers){
  const customer = customers.find( customer => customer.value === data.customer_id );
  return {
    ...data,
    ...{
      customer_id : customer
    }
  }
}

function getterCustomers(data){
  return data.map(function(element){
    return {
      text:element.name,
      value:element.slug
    };
 });
}


