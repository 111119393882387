export const OPEN_FOLDED_NAVBAR = '[NAVBAR] OPEN FOLDED';
export const CLOSE_FOLDED_NAVBAR = '[NAVBAR] CLOSE FOLDED';
export const TOGGLE_FOLDED_NAVBAR = '[NAVBAR] TOGGLE FOLDED';

export const navbarToggleFolded = () =>
{
  return {
    type: TOGGLE_FOLDED_NAVBAR
  }
}

export const navbarOpenFolded = () =>
{
  return {
    type: OPEN_FOLDED_NAVBAR
  }
}

export const navbarCloseFolded = () =>
{
  return {
    type: CLOSE_FOLDED_NAVBAR
  }
}