import React from 'react';
import { connect } from 'react-redux';
import { payableAccount as mainActions } from '../../store/actions/modules';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import Table from '../../components/table';
import {
  Container,
  Paper,
} from '@mui/material';



class MainComponent extends React.Component {

  
  constructor(props) {
    super(props);

    this.state = {
      date: new Date(),
      selectedView:"month" //month || day
    };
  }

  componentDidMount() {
    this.moduleData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.status_export_file !== this.props.status_export_file) {
      if(this.props.status_export_file.success && !this.props.status_export_file.loading){
        this.forceFileDownload(this.props.export_file);
      }
    }
  }


  moduleData(){
    this.props.getPendingPayments();
  }

  forceFileDownload(data){
    const url = window.URL.createObjectURL(new Blob([data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'Pagos.xlsx')
    document.body.appendChild(link)
    link.click();
  }


  render() {
    
    const columns = [
      { id: 'bussiness_branch', numeric: false, disablePadding: true, label: 'Establecimiento',sortable: true },
      { id: 'name', numeric: false, disablePadding: false, label: 'Shopper',sortable: true},
      // { id: 'enso_code', numeric: true, disablePadding: true, label: 'Cuenta',sortable: true },
      { id: 'consumption_expenses', numeric: true, disablePadding: true, label: 'Monto',sortable: true },
      { id: 'date', numeric: false, disablePadding: true, label: 'Fecha',sortable: true }
    ];

    const maxWidth = false;
    return (
      <Container maxWidth={maxWidth} className={styles['client-container']}>
        {/* table */}
        <Paper elevation={1} className={styles.paper}>
          <Table
            title = {"Cuentas por pagar"}
            data = {this.props.list}
            columns={columns}
            addAction ={(event) => { this.props.getReportFile()}}
            titleAction={"Exportar"}
            loading={this.props.status_list.loading}
          />
        </Paper>
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getPendingPayments:(payload)=>{
      dispatch(mainActions.list());
    },
    getReportFile:()=>{
      dispatch(mainActions.exportFile());
    }
  }
};

const mapStateToProps = (state) => {
  return {
    list: state.module_payable_accounts.list.data,
    export_file: state.module_payable_accounts.export_file.data,
    status_list: state.module_payable_accounts.list,
    status_export_file: state.module_payable_accounts.export_file,
  };
}


const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent);

export default withRouter(_component);