import * as React from 'react';
import PropTypes from 'prop-types';
import Toolbar from '@mui/material/Toolbar';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import Box from '@mui/material/Box';
import Fab from '@mui/material/Fab';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';
import Zoom from '@mui/material/Zoom';

//menu

import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import ListItemIcon from '@mui/material/ListItemIcon';

import { useSelector } from 'react-redux';
import MenuOpenOutlinedIcon from '@mui/icons-material/MenuOpenOutlined';

Element.prototype.scrollIntoViewPromise = function(options){

  // "this" refers to the current element (el.scrollIntoViewPromise(options): this = el)
  this.scrollIntoView(options);
  
  // I create a variable that can be read inside the returned object ({ then: f() }) to expose the current element 
  let parent = this;
  
  // I return an object with just a property inside called then
  // then contains a function which accept a function as parameter that will be execute when the scroll ends 
  return { 
    then: function(x){
      // Check out https://developer.mozilla.org/en-US/docs/Web/API/Intersection_Observer_API for more informations  
      const intersectionObserver = new IntersectionObserver((entries) => {
        let [entry] = entries;
        
        // When the scroll ends (when our element is inside the screen)
        if (entry.isIntersecting) {
        
          // Execute the function into then parameter and stop observing the html element
          setTimeout(() => {x(); intersectionObserver.unobserve(parent)}, 100)
        }
      });
      
      // I start to observe the element where I scrolled 
      intersectionObserver.observe(parent);
    }
  };
}

function ScrollTop(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    target: window ? window() : undefined,
    disableHysteresis: true,
    threshold: 100,
  });

  const handleClick = (event) => {
    // const anchor = (event.target.ownerDocument || document).querySelector(
    //   '#back-to-top-anchor',
    // );

    // console.log(anchor);

    // if (anchor) {
    //   anchor.scrollIntoView({
    //     behavior: 'smooth',
    //     block: 'center',
    //   });
    // }
  };

  return (
    <Zoom in={trigger}>
      <Box
        onClick={handleClick}
        role="presentation"
        sx={{ position: 'fixed', bottom: 16, right: 16 }}
      >
        {children}
      </Box>
    </Zoom>
  );
}

ScrollTop.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


export default function FloatingMenuComponent(props) {

  const menu_options = useSelector(state => state.floating_menu.options );

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  const handleClickOption = ({event, option}) =>{

    // const anchor = (event.target.ownerDocument || document).querySelector(
    //   '#global_results',
    // );

    const anchor = (event.target.ownerDocument || document).querySelector(
      `#${option.id}`,
    );
    // console.log(anchor);

    if (anchor) {
      anchor.scrollIntoViewPromise({
        behavior: 'smooth',
        block: 'end',
        alignToTop:false
      }).then(()=>setAnchorEl(null));
    }
  };


  return (
    <React.Fragment>
      <Toolbar id="back-to-top-anchor" />
      <ScrollTop {...props}>
        <>
          <Fab color="secondary" size="small" onClick={handleClick} aria-label="scroll back to top">
            <KeyboardArrowUpIcon />
          </Fab>
          <Menu
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            // onClick={handleClose}
            PaperProps={{
              elevation: 0,
              sx: {
                overflow: 'visible',
                filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                mt: -7.5,
                '& .MuiAvatar-root': {
                  width: 32,
                  height: 32,
                  ml: -0.5,
                  mr: 1,
                },
                '&:before': {
                  content: '""',
                  display: 'block',
                  position: 'absolute',
                  // top: 0,
                  bottom: -10,
                  right: 14,
                  width: 10,
                  height: 10,
                  bgcolor: 'background.paper',
                  transform: 'translateY(-50%) rotate(45deg)',
                  zIndex: 0,
                },
              },
            }}
            transformOrigin={{ horizontal: 'right', vertical: 'top' }}
            anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
          >
            {menu_options.map((option,i)=>(
              <MenuItem key={i} onClick={ (event) => handleClickOption({event:event, option:option})}>
                <ListItemIcon>
                  <MenuOpenOutlinedIcon fontSize="small" />
                </ListItemIcon>
                {option.label}
              </MenuItem>
            ))}  
          </Menu>
        </>
      </ScrollTop>
      
    </React.Fragment>
  );
}