  
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormHelperText,
  FormControl,
  FormLabel,
  TextField,
  Skeleton,
  IconButton,
  Tooltip 
} from '@mui/material';
import AdapterDateFns from '@mui/lab/AdapterDateFns';
import esLocale from "date-fns/locale/es";
import {DatePicker} from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles(() => ({
  formControl: {
    minWidth: '100%',
  }
}));

const TimePickerWrapper = ({input: {name, onChange, value, ...restInput}, meta, label, clearable=false, waiting=false, ...rest}) => {
	const showError = Boolean(  ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched );
  const classes = useStyles();

	return (
<FormControl disabled={rest.disabled} className={classes.formControl}  error={showError} >
  <FormLabel sx={{ paddingBottom: '16px'}} required={rest.required}>{label}
    {(clearable && ! waiting)?
    <IconButton
      onClick={ () => {onChange({target:{value:""}})}}
      edge="end"
      sx={{float: 'right', marginRight: '0px'}}
      size="small"
    >
      <Tooltip title={"Borrar respuesta"} >
        <ClearIcon fontSize="small"/>
      </Tooltip>
    </IconButton>:null}
  </FormLabel>
  {waiting?<Skeleton variant="rectangular"/> :
    <LocalizationProvider dateAdapter={AdapterDateFns} locale={esLocale}>
      <DatePicker
        disabled={rest.disabled}
        {...restInput}
        error={showError}
        margin="normal"
        mask="____-__-__"
        inputFormat="yyyy-MM-dd"
        name="birthdate"
        // value={ console.log(value)  }
        // value={ console.log(value ? value : null)  }
        value={ !isNaN(Date.parse(value)) ? new Date(value.replace(/-/g, '/')): value }
        // value={ !isNaN(Date.parse(value)) ? new Date(value.replace(/-/g, '/')): (value ? value : null) }
        onChange={e => {
          console.log(e);
          if(e instanceof Date && !isNaN(e)){
            var dateString = new Date(e.getTime() - (e.getTimezoneOffset() * 60000 ))
            .toISOString()
            .split("T")[0];
            onChange({target:{value:dateString}});
          }else{
            onChange({target:{value:e}});
          }
        }}
        renderInput={(params) => <TextField variant="standard" {...params} />}
      />
        {/* <pre>{JSON.stringify(value, 0, 2)}</pre> */}

    </LocalizationProvider>
  }

  {showError  && <FormHelperText >{meta.error || meta.submitError}</FormHelperText> }
  </FormControl>);
};

export default TimePickerWrapper;
