import * as React from 'react';
import DataTable from '../table';
import BasicDataTable from '../table_result_catalogs';
import MultipleResultTable from '../table_result_multiple';
import MultipleResultTableByBrand from '../table_result_multiple_by_brand';


import ImageList from '../image-list';
import {
  Grid, 
  Paper, 
  Divider,
  Card,
  CardContent,
  Typography
} from '@mui/material';
import {VerticalBarChart, LineChart} from '../charts'

export default function DataGridDemo({
  global_results = [], 
  catalog_results = [], 
  multiple_results=[], 
  numeric_results={}, 
  open_questions_results={},
  commentaries=[],
  images=[],
  loading = false, 
  ...props}) {

  const columns_gobal_results = [
    {
      id: 'categoria',
      label: 'Categoría',
      sortable: true,
      numeric: false,
      disablePadding: true
    },
    {
      id: 'anterior',
      label: 'Mes anterior',
      sortable: true,
      numeric: true,
      disablePadding: true
    },
    {
      id: 'actual',
      label: 'Mes actual',
      sortable: true,
      numeric: true,
      disablePadding: true
    },
    {
      id: 'diferencia',
      label: 'Diferencia',
      sortable: true,
      numeric: true,
      disablePadding: true
    }
  ];

  const columns_calatogs_results = [
    {
      id: 'criterio',
      label: 'Criterio',
      sortable: true,
      numeric: false,
      disablePadding: true
    },
    {
      id: 'mesAnterior',
      label: 'Mes anterior',
      sortable: true,
      numeric: true,
      disablePadding: true
    },
    {
      id: 'mesActual',
      label: 'Mes actual',
      sortable: true,
      numeric: true,
      disablePadding: true
    },
    {
      id: 'diferencia',
      label: 'Diferencia',
      sortable: true,
      numeric: true,
      disablePadding: true
    }
  ];

  const columns_numeric_results = [
    {
      id: 'criterio',
      label: 'Criterio',
      sortable: true,
      numeric: false,
      disablePadding: true
    },
    {
      id: 'promedio',
      label: 'Promedio',
      sortable: true,
      numeric: true,
      disablePadding: true
    }
  ];

  const options_bar_char = ( {title = ""}) =>(  {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      },
      title: {
        display: true,
        text: title,
      },
    }
  });

  const options_line_char = {
    responsive: true,
    plugins: {
      legend: {
        position: 'top',
      }
    }
  };

  return (
    <div style={{ width: '100%' }}>

      <Grid item container spacing={3}>
        
        <Grid item xs={12} id={"global_results"}>
          <Paper elevation={1} sx={{padding: '16px',display: 'flex',overflow: 'auto',flexDirection: 'column'}}>
            <DataTable
              title = {"Resultados Globales"}
              data = {global_results}
              columns={columns_gobal_results}
              loading={loading}
              searching={false}
            />

            <LineChart options={options_line_char} data={
                {
                  labels: global_results.map(item => item.categoria),
                  datasets: [
                    {
                      label: 'Mes anterior',
                      data: global_results.map(item => item.anterior !== "NA" ? item.anterior : 0),
                      borderColor: 'rgb(255, 99, 132)',
                      backgroundColor: 'rgba(255, 99, 132, 0.5)',
                    },
                    {
                      label: 'Mes actual',
                      data: global_results.map(item => item.actual !== "NA" ? item.actual : 0),
                      borderColor: 'rgb(53, 162, 235)',
                      backgroundColor: 'rgba(53, 162, 235, 0.5)',
                    },
                  ],
                }
              }
            />
          </Paper>
        </Grid>

        {catalog_results.map((catalog, index) => (

          catalog.categoria &&
          <Grid id={`catalog_${catalog.id}`} item xs={12} key={index}>
            <Paper elevation={1} sx={{padding: '16px',display: 'flex',overflow: 'auto',flexDirection: 'column'}}>

              {multiple_results[catalog.id] &&
                <MultipleResultTableByBrand
                  title = {catalog.categoria}
                  data={multiple_results[catalog.id]}
                  loading={loading}
                />
              }  
              
              <BasicDataTable
                catalog_id={catalog.id}
                title = {multiple_results[catalog.id] ? null :  catalog.categoria}
                data = {catalog.resultados}
                columns={columns_calatogs_results}
                open_answers={open_questions_results[catalog.id] ? open_questions_results[catalog.id] : []}
                loading={loading}
                searching={false}
              />
              
              { numeric_results[catalog.id] &&
              <>
              <Divider />

              <DataTable
                data = {numeric_results[catalog.id]}
                columns={columns_numeric_results}
                loading={loading}
                searching={false}
              />
              </>
              }

              { open_questions_results[catalog.id] && 
              
              <Card variant="outlined">
                <CardContent>
                  { 
                    Object.keys(open_questions_results[catalog.id]).map((key,x)=>(
                       ( ! (catalog.resultados.findIndex( x => parseInt(x.id) === parseInt(key)) >= 0) &&
                      //  ( catalog.resultados.find( x => x.id == key) &&

                      <div key={key}>
                      <Typography gutterBottom>
                        {/* {catalog.resultados.findIndex( x => parseInt(x.id) === parseInt(key)) >= 0 ? "true": "false"} */}
                        {open_questions_results[catalog.id][key].titulo}
                      </Typography>
                      <Grid sx={{marginBottom:"30px"}} container key={x} spacing={2} direction="column" justifyContent="space-between">
                      {open_questions_results[catalog.id][key].respuestas.filter(x =>x !==null).map((answer,i)=>(
                          <Grid key={i} item xs={10} >
                            <Card variant="outlined">
                              <CardContent>
                                  <Typography variant="button" display="block" gutterBottom>
                                    Visita {i+1}
                                  </Typography>
                                    <div dangerouslySetInnerHTML={{__html: answer}}/>
                              </CardContent>
                            </Card>
                          </Grid >
                      ))}
                    </Grid>
                      </div> 
                       )
                      
                    ))
                  }
                </CardContent>
              </Card>
              }

              <Divider />

              

              {/* {multiple_results[catalog.id] && Object.keys(multiple_results[catalog.id]).map((key,i)=>(
                <VerticalBarChart key={i} options={options_bar_char({ title: multiple_results[catalog.id][key].criterio})} data={
                    {
                      labels: multiple_results[catalog.id][key].labels,
                      datasets: multiple_results[catalog.id][key].respuestas
                    }
                  }
                />
              ))}   */}
            </Paper>
          </Grid>
        )
        )}

        <Grid item xs={12} id={"commentaries"}>
          <Paper elevation={1} sx={{padding: '16px',display: 'flex',overflow: 'auto',flexDirection: 'column'}}>
            <Typography variant="h5" gutterBottom component="div">
             Comentarios
            </Typography>
            {
              commentaries.map((commentary, index) => (
              <Card key={index} variant="outlined">
                <CardContent>
                  <Grid container spacing={2} direction="column" justifyContent="space-between">
                    <Grid item xs={2}>
                      <Typography variant="button" display="block" gutterBottom>
                        Visita {commentary.id}
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      <div dangerouslySetInnerHTML={{__html: commentary.comentario}} />
                    </Grid>
                  </Grid>
                </CardContent>
              </Card>
              ))
            }
           </Paper>
        </Grid>

        {
          images.map((element, index) => (
            <Grid key={index}  item xs={12} id={"images"} justifyContent="center" >
              <Paper elevation={1} sx={{padding: '16px',display: 'flex',overflow: 'auto',flexDirection: 'column'}}>
              <Typography variant="h5" gutterBottom component="div">
                Imagenes {element.titulo}
              </Typography>
                {element.imagenes.length && <ImageList images={element.imagenes}/>}
              </Paper>
            </Grid>
          ))
        }
        
      </Grid>
    </div>
  );
}