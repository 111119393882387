import React, {Component} from 'react';
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button,
  CircularProgress,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import { green } from '@mui/material/colors';
import FormShopper from '../form';
import FormAddress from '../form/address';
import FormSocialTest from '../form/social_test';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { formValidation } from '../../../validations/ShoppersCreate';
import { shoppers } from '../../../store/actions/modules';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
});

class ShopperCreate extends Component {

  constructor(props) {
    super(props);

    let form = {
      // "name": "sOy uN pAnelisTaaaaaa",
      // "last_name": "pruEbA",
      // "sex": "Male",
      // "birthdate": "1994-12-12",
      // "marital_status": "Single",
      // "children": "1",
      // "occupation": "Professional",
      // "address_code": {
      //   "state": "SLP",
      //   "address_code": 1842,
      //   "city": "San Luis Potosí"
      // },
      // "email": "panelista@gMaiL.commmmmm",
      // "feeding_behaviours": "Omnivore",
      // "alcohol": "0",
      // "social_test": {
      //   "last_study": "Licenciatura completa",
      //   "toilets": "1",
      //   "cars": "1",
      //   "internet": "Sí tiene",
      //   "workers": "2",
      //   "rooms": "3"
      // },
      // "enso_code": 1122334455667788,
      // "phone": "4444444444",
      // "first_contact": "FB",
      // "type": "Both",
      // "status": "Pending",
      // "address_state": {
      //   "value": "AGU",
      //   "text": "Aguascalientes"
      // },
      // "address_city": {
      //   "value": 5,
      //   "text": "Jesús María"
      // }

      // address_city: {value: 1842, text: "San Luis Potosí"},
      // address_code: {state: "SLP", address_code: 1842, city: "San Luis Potosí"},
      // address_state: {value: "SLP", text: "San Luis Potosí"},
      // alcohol: "0",
      // birthdate: "1998-12-10",
      // children: "0",
      // email: "hola@gol.com",
      // enso_code: "4477889966552211",
      // feeding_behaviours: "Omnivore",
      // first_contact: "Survey",
      // last_name: "Yntech",
      // marital_status: "Single",
      // name: "Miguel Luis",
      // occupation: "Professional",
      // phone: "4488556633",
      // sex: "Male",
      // social_test: {
      //   cars: "1",
      //   internet: "Sí tiene",
      //   last_study: "Licenciatura incompleta",
      //   rooms: "2",
      //   toilets: "1",
      //   workers: "2",
      // },
      // status: "Active",
      // type: "Both",
    };

    this.state = {
      form: form
    }
  }

  componentDidMount(){
    this.props.getModuleData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.create !== this.props.create) {
      if(this.props.create.success){
        this.props.history.push("/shoppers");
      }
    }
  }

  onSubmit = async values => {
    this.props.createShopper({data: values});
  }


  render() {

    const maxWidth = false;

    const { classes } = this.props;

    const buttonSx = {
      ...(this.props.create.loading && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>
          <Grid container spacing={2} direction="column">
            <Grid item xs>
              <Toolbar className={styles['toolbar']}>
                <Typography variant="h4" id="tableTitle" component="div">
                Nuevo shopper
                <Typography variant="caption" display="block" gutterBottom>
                * Campos requeridos
              </Typography>
                </Typography>
              </Toolbar>
            </Grid>

            <Form
              initialValues={this.state.form}
              // validate={this.state.errors}
              validate={formValidation.validateForm}
              onSubmit={this.onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  {/* Shopper */}
                  <FormShopper waiting={this.props.create.loading}/>
                  {/* address */}
                  <FormAddress waiting={this.props.create.loading}/>
                  {/* social studies */}
                  <FormSocialTest waiting={this.props.create.loading}/>
                {/* Buttons */}
                <Grid container item spacing={3}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={() => {this.props.history.push("/shoppers");}}
                      color="primary">
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      sx={buttonSx}
                      disabled={this.props.create.loading}
                      type="submit"
                    >
                    Guardar
                    {this.props.create.loading && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: green[500],
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )}
                  </Button>
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                </form>
              )}
            />

          </Grid>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleData:() =>{
      dispatch(shoppers.states())
    },
    createShopper:(payload)=>{
      dispatch(shoppers.create(payload))
    }
  }
};


const mapStateToProps = (state) => {
  return {
    create: state.module_shoppers.create
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(ShopperCreate);


export default withRouter(withStyles(useStyles)(_component));
