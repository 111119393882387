import React, {Component} from 'react';
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button,
  Card,
  CardContent,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import { green } from '@mui/material/colors';
import { contracts as actions } from '../../../store/actions/modules';
import { connect } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  list:{
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  }
});

class DetailComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug:null,
    }
  }
  
  async componentDidMount(){
    const {slug} = this.props.match.params;
    this.setState({
      slug: slug
    });
    this.props.getModuleData({slug: slug});
  }

  getNestedProperty(key,object){
    return key.split('.').reduce(function(o, key) {
      return Object.hasOwnProperty.call((o ? o : {}), key) ? o[key] : "NA";
    }, object);
  }

  render() {

    const maxWidth = false;

    const { classes } = this.props;

    const data = [
      {
        title:"Contrato",
        elements : [
          { label: "ESTABLECIMIENTO" , key: "bussiness_branch_id.text" },
          { label: "INICIO DE CONTRATO" , key: "contract_beginning" },
          { label: "PAQUETE" , key: "package_id.text" },
          { label: "ENCUESTA" , key: "survey_id.text" },
          { label: "INVERSIÓN PARA CONSUMO" , key: "consumption_expenses" },
          { label: "DESCUENTO" , key: "discount" },
        ]
      }
    ];

    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>

            <Card variant="outlined">
              <CardContent>
                <Grid container spacing={2} direction="column" justifyContent="space-between">

                  {/* <Grid item lg={12}>
                    <Typography variant="h6" component="div">
                      Datos Generales
                    </Typography>
                  </Grid> */}

                  <Grid container item spacing={3} justifyContent="flex-end">
                    {/* Title */}
                    <Grid item xs>
                      <Toolbar className={styles['toolbar']}>
                        <Typography variant="h4" component="div">
                          Datos Generales
                        </Typography>
                      </Toolbar>
                    </Grid>
                    {/* Buttons */}
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => {this.props.history.push("/contracts")}}
                        color="primary">
                        Regresar
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm container spacing={1}>
                    {data.map((item, index) => (
                      <Grid key={index} item xs={12} sm container spacing={1}>
                        <Grid item xs={12}>
                          <Typography variant="h6" display="center" gutterBottom>
                            {item.title}
                          </Typography>
                        </Grid>
                        {item.elements.map((subitem, index) => (
                          <Grid key={index} item xs={12} container>
                            <Grid item xs={5}>
                              <Typography sx={{fontWeight: "bold"}}variant="overline" display="block" gutterBottom>
                              {subitem.label}
                              </Typography>
                            </Grid>
                            <Grid item xs={5}>
                              {
                                (this.props.status_element.loading && ! this.props.status_element.success)
                                ? <Skeleton variant="text" /> 
                                : <Typography variant="button" display="block" gutterBottom>{this.getNestedProperty(subitem.key, this.props.element)}</Typography>
                              }
                            </Grid>
                          </Grid>
                        ))}
                      </Grid>
                      ))}
                  </Grid>
                </Grid>
              </CardContent>
            </Card>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleData:(payload) =>{
      dispatch(actions.detail(payload))
    }
  }
};


const mapStateToProps = (state) => {
  return {
    status_element: state.module_contracts.status_element,
    element: state.module_contracts.element
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailComponent);


export default withRouter(withStyles(useStyles)(_component));
