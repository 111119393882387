import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import service from "../../services/panelists";
import {users as actions} from '../../store/actions/external';
import {snackbarActions as snackbar} from '../../store/actions';
import {success, failure} from "../../utils/api_responses";

export default function* root() {
  yield all([
    fork(watcherSave),
  ])
}

function* watcherSave() {
  yield takeEvery(actions.SAVE, workerSave);
}

function* workerSave(action) {
  try {
    //element
    yield put({ type:actions.SAVE_REQUEST});
    const response = yield call(createUser, action.payload);
    yield put({ type: actions.SAVE_SUCCESS, payload: response });
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:  response,
      severity:'success'
    }});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type:actions.SAVE_FAILURE, payload: e });
  }
}

  function createUser( payload) {
    return new Promise( (resolve, reject) => {
      service.user(payload)
        .then((response) => {
          resolve(success(response));
        })
        .catch((e) => {
          reject(failure(e));
        });
    });
  }
  