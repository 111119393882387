import axios from "axios";
import token_service from "../services/token";
const url = process.env.REACT_APP_API;

const instance = axios.create({
  baseURL: `${url}/api`,
  headers: {
    "Content-type": "application/json"
  }
});


instance.interceptors.request.use(
  (config) => {
    const token = token_service.getLocalAccessToken();
    if (token) {
      config.headers["Authorization"] = 'Bearer ' + token;  // for Spring Boot back-end
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);


instance.interceptors.response.use(
  (res) => {
    return res;
  },
  async (err) => {
    const originalConfig = err.config;
    if (err.response) {
      // Access Token was expired
      if (err.response.status === 401 && !originalConfig._retry) {
        originalConfig._retry = true;
        token_service.removeTokens();
        // try {
        //   const rs = await refreshToken();
        //   const { accessToken } = rs.data;
        //   window.localStorage.setItem("access_token", accessToken);
        //   instance.defaults.headers.common["access_token"] = accessToken;
        //   return instance(originalConfig);
        // } catch (_error) {
        //   if (_error.response && _error.response.data) {
        //     return Promise.reject(_error.response.data);
        //   }
        //   return Promise.reject(_error);
        // }
      }
      if (err.response.status === 403 && err.response.data) {
        return Promise.reject(err.response.data);
      }
    }
    return Promise.reject(err);
  }
);

// function refreshToken() {
//   return instance.post("/auth/refreshtoken", {
//     refreshToken: token_service.getLocalRefreshToken(),
//   });
// }

export default instance;