import {auth as actions} from '../actions';

const initialState = {
  user: {
    element:{
      "email": "",
      "name": ""
    },
    loading: false,
    success: false
  },
  login:{
    response: '',
    loading: false,
    success: false,
  },
  logout:{
    response: '',
    loading: false,
    success: false,
  },
  token:''
};

const navbar = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.LOGIN_REQUEST:
    {
      return {
        ...state,
        login:{
          response: {},
          loading: true,
          success: false
        }
      }
    }
    case actions.LOGIN_SUCCESS:
    {
      return {
        ...state,
        login:{
          response: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.LOGIN_FAILURE:
    {
      return {
        ...state,
        login:{
          response: {},
          loading: false,
          success: false
        }
      }
    }
    case actions.LOGOUT_REQUEST:
    {
      return {
        ...state,
        logout:{
          response: {},
          loading: true,
          success: false
        }
      }
    }
    case actions.LOGOUT_SUCCESS:
    {
      return {
        ...state,
        logout:{
          response: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.LOGOUT_FAILURE:
    {
      return {
        ...state,
        logout:{
          response: {},
          loading: false,
          success: false
        }
      }
    }
    case actions.USER_REQUEST:
    {
      return {
        ...state,
        user:{
          ...initialState.user,
          loading: true,
          success: false
        }
      }
    }
    case actions.USER_SUCCESS:
    {
      return {
        ...state,
        user:{
          element: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.USER_FAILURE:
    {
      return {
        ...state,
        user:{
          ...initialState.user,
          loading: false,
          success: false
        }
      }
    }
    case actions.CLEAR:
    {
      return initialState;
    }
    default:
    {
      return state;
    }
  }
};

export default navbar;