// action types
export const RESET = "[EXTERNAL][RESULTS] RESET";

//module actions
export const BY_BRAND = "[EXTERNAL][RESULTS] BY_BRAND";
export const BY_BUSINESS_BRANCH = "[EXTERNAL][RESULTS] BY_BUSINESS_BRANCH";

export const BUSINESS_BRANCHES_REQUEST = "[EXTERNAL][RESULTS][BUSINESS_BRANCHES] REQUEST";
export const BUSINESS_BRANCHES_SUCCESS = "[EXTERNAL][RESULTS][BUSINESS_BRANCHES] SUCCESS";
export const BUSINESS_BRANCHES_FAILURE = "[EXTERNAL][RESULTS][BUSINESS_BRANCHES] FAILURE";

export const BY_BRAND_REQUEST = "[EXTERNAL][RESULTS][BY_BRAND] REQUEST";
export const BY_BRAND_SUCCESS = "[EXTERNAL][RESULTS][BY_BRAND] SUCCESS";
export const BY_BRAND_FAILURE = "[EXTERNAL][RESULTS][BY_BRAND] FAILURE";

export const BY_BUSINESS_BRANCH_REQUEST = "[EXTERNAL][RESULTS][BY_BUSINESS_BRANCH] REQUEST";
export const BY_BUSINESS_BRANCH_SUCCESS = "[EXTERNAL][RESULTS][BY_BUSINESS_BRANCH] SUCCESS";
export const BY_BUSINESS_BRANCH_FAILURE = "[EXTERNAL][RESULTS][BY_BUSINESS_BRANCH] FAILURE";

export const byBrand = (payload)=> {
  return { type: BY_BRAND, payload };
}

export const byBusinessBranch = (payload)=> {
  return { type: BY_BUSINESS_BRANCH, payload };
}

export const reset = () => {
  return { type: RESET };
}