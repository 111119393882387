import React, {Component} from 'react';
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import FormRecord from '../form';
import FormSchedules from '../form/schedules';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { formValidation } from './validator';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';
import { business_branches as actions } from '../../../store/actions/modules';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
});

class CreateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      social_status:[2,3],
      schedules:[this.createEmptySchedule()]
    }
  }
  componentDidMount(){
    this.props.getModuleData();
  }

  componentWillUnmount(){
  }

  componentDidUpdate(prevProps) {
    if (prevProps.save !== this.props.save) {
      if(this.props.save.success){
        this.props.history.push("/businessBranches");
      }
    }
  }

  createEmptySchedule = () => ({
    days:[],
    name: '',
    quantity: '',
    price: '',
  });

  onSubmit = values => {
    this.props.storeRecord({data: values, neighborhood: this.props.neighborhood});
  }

  render() {

    const maxWidth = false;

    const { classes } = this.props;


    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>

          <Grid container spacing={2} direction="column">

            

            <Form
              initialValues={this.state}
              validate={formValidation.validateForm}
              onSubmit={this.onSubmit}
              mutators={{ ...arrayMutators }}
              render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container item spacing={3} justifyContent="flex-end">
                    {/* Title */}
                    <Grid item xs>
                      <Toolbar className={styles['toolbar']}>
                        <Typography variant="h4" id="tableTitle" component="div">
                          Nuevo Establecimiento
                          <Typography variant="caption" display="block" gutterBottom>
                          * Campos requeridos
                          </Typography>
                        </Typography>
                      </Toolbar>
                    </Grid>
                    {/* Buttons */}
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => {this.props.history.push("/businessBranches")}}
                        disabled={this.props.save.loading}
                        color="primary">
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        disabled={this.props.save.loading}
                        type="submit"
                        className={classes.button}
                        color="primary"
                      >
                        Guardar
                        {this.props.save.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </Button>
                    </Grid>
                  </Grid>
                  {/* Form */}
                  <FormRecord waiting={this.props.save.loading}/>
                  <FieldArray
                    name="schedules"
                    render={({ fields }) => (
                      <React.Fragment>
                        {/* buttons */}
                        <Grid container item spacing={3} justifyContent="space-between">
                          <Grid item>
                            <Typography variant="h6" component="div">
                              Horarios
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              size="small"
                              className={classes.button}
                              onClick={(e) => { fields.pop()  }}
                              disabled = { (values.schedules.length < 2) ||  this.props.save.loading}
                              color="primary">
                              Eliminar
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              className={classes.button}
                              onClick={(e) => {  fields.push(this.createEmptySchedule() ) }}
                              color="primary"
                              disabled = { this.props.save.loading}

                            >
                              Agregar
                            </Button>
                          </Grid>
                        </Grid>
                        {/* buttons */}
                        {values.schedules.map((_, index) => (
                          <FormSchedules key={index} index={index} waiting={this.props.save.loading}/>
                        ))}
                      </React.Fragment>
                    )}/>
                  {/* {
                    (this.state.schedules).map((option, index) => (
                      <FormSchedules key={index} index={index}/>
                    ))
                  } */}
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                </form>
              )}
            />

          </Grid>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    storeRecord:(payload) =>{
      dispatch(actions.store(payload))
    },
    getModuleData:() =>{
      dispatch(actions.create())
    }
  }
};

const mapStateToProps = (state) => {
  return {
    neighborhood: state.module_business_branches.neighborhood,
    save: state.module_business_branches.save
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateComponent);


export default withRouter(withStyles(useStyles)(_component));
