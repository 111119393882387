import http from '../utils/api_address';

const neighborhoods = (zip_code) => {
  return http.get(`/neighborhoods/zip_code/${zip_code}`);
};

const neighborhood_detail = (id) => {
  return http.get(`/neighborhood/${id}`);
};

const states = (country = "MX") => {
  return http.get(`/getStates/${country}`);
};

const cities = (country = "MX", state) => {
  return http.get(`/getCities/${country}/${state}`);
};


const shoppersService = {
  neighborhoods,
  neighborhood_detail,
  states,
  cities
};

export default shoppersService;