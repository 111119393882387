import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import data_service from "../services/surveys";
import service_catalogs from "../services/lists";
import { failure, success } from "../utils/api_responses";
import { surveys as actions } from '../store/actions/modules';
import { snackbarActions as snackbar} from '../store/actions';
// import { element } from "../store/actions/modules/clients.action";

export default function* root() {
  yield all([
    fork(watcherList),
    fork(watcherCreate),
    fork(watcherEdit),
    fork(watcherDetail),
    fork(watcherUpdate),
    fork(watcherStore),
    fork(watcherRemove),
  ])
}

function* watcherList() {
  yield takeEvery(actions.LIST, workerList);
}

function* watcherCreate() {
  yield takeEvery(actions.CREATE, workerCreate);
}

function* watcherDetail() {
  yield takeEvery(actions.DETAIL, workerDetail);
}

function* watcherStore() {
  yield takeEvery(actions.STORE, workerStore);
}

function* watcherUpdate() {
  yield takeEvery(actions.UPDATE, workerUpdate);
}

function* watcherEdit() {
  yield takeEvery(actions.EDIT, workerEdit);
}

function* watcherRemove() {
  yield takeEvery(actions.REMOVE, workerRemove);
}


function* workerList() {
  try {
    yield put({ type: actions.RESET });
    yield put({ type: actions.LIST_REQUEST });
    const surveys = yield call(list);
    yield put({ type: actions.LIST_SUCCESS ,payload: surveys});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.LIST_FAILURE, payload: e });
  }
}

function* workerCreate() {
  try {
    yield put({ type: "[MODULE][SURVEYS] RESET" });
    yield put({ type: "[MODULE][SURVEYS][CATALOGS] REQUEST" });
    const catalogs = yield call(getCatalogs);
    yield put({ type: "[MODULE][SURVEYS][CATALOGS] SUCCESS",payload: catalogs});
  } catch (e) {
    console.log(e);
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][SURVEYS][CATALOGS] FAILURE", payload: e });
  }
}

function* workerDetail(action) {
  try {
    yield put({ type: actions.RESET });
    yield put({ type: actions.CATALOGS_REQUEST });
    yield put({ type: actions.ELEMENT_REQUEST });
    const catalogs = yield call(getCatalogs);
    yield put({ type: actions.CATALOGS_SUCCESS,payload: catalogs});
    const element = yield call(getElement, action.payload);
    const data = mutatorDetail(catalogs,element);
    yield put({ type: actions.ELEMENT_SUCCESS, payload: data });
  } catch (e) {
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });

    yield put({ type: actions.ELEMENT_FAILURE, payload: e });
  }
}

function* workerStore(action) {
  try {
    console.log(action.payload);
    yield put({ type: actions.SAVE_REQUEST }); 
    const response = yield call(store, setterStore(action.payload) );
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.SAVE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.SAVE_FAILURE, payload: e });
  }
}

function* workerUpdate(action) {
  try {
    yield put({ type: actions.SAVE_REQUEST }); 
    const response = yield call(update, setterStore(action.payload) );
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.SAVE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.SAVE_FAILURE, payload: e });
  }
}

function* workerEdit(action) {
  try {
    yield put({ type: actions.RESET });
    yield put({ type: actions.ELEMENT_REQUEST });
    yield put({ type: actions.CATALOGS_REQUEST });
    const catalogs = yield call(getCatalogs);
    yield put({ type: actions.CATALOGS_SUCCESS,payload: catalogs});
    const element = yield call(getElement, action.payload);
    const data = mutator(catalogs,element);
    yield put({ type: actions.ELEMENT_SUCCESS, payload: data });
  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });

    yield put({ type: actions.ELEMENT_FAILURE, payload: e });
  }
}

function* workerRemove(action) {
  try {
    yield put({ type: actions.SAVE_REQUEST }); 
    const response = yield call(remove, action.payload);
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.SAVE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.SAVE_FAILURE, payload: e });
  }
}

function list() {
  return new Promise( (resolve, reject) => {
    data_service.list()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}


function getElement( payload) {
  return new Promise( (resolve, reject) => {
    data_service.detail(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function store(payload) {
  return new Promise( (resolve, reject) => {
    data_service.create(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function update(payload) {
  return new Promise( (resolve, reject) => {
    data_service.update(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function remove(payload) {
  return new Promise( (resolve, reject) => {    
    data_service.remove(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function getCatalogs(data = {element:"Categories"}) {
  return new Promise( (resolve, reject) => {
    service_catalogs.catalogs(data)
      .then((response) => {
        resolve(response.data.map(element => ({ value: element.id, text: element.text})));
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function setterStore({data, ...rest}){
  return{
    ...rest,
    data:{ 
      ...data, 
      ...{
        questions: data.questions.map((value,index)=>({ ...value, categoria_id:value.categoria_id.value ? value.categoria_id.value : null}))
      }
    }
  }
}

function mutator(catalogs,element){

  const data = {
    ...element, 
    ...{
      questions: element.json_questions.map(function(value, index){
        const catalog = catalogs.find( catalog => catalog.value === value.categoria_id || catalog.id === value.categoria_id);
        return {
          ...value, 
          categoria_id: catalog ? {text: catalog.text, value: catalog.value} : null,
        };
     })
    }
  };
  return data;
}

function mutatorDetail(catalogs, element){
  // let questions = [];
  // questions = element.json_questions.reduce((acc, d) => {
  //   if (Object.keys(acc).includes(d.categoria_id)) return acc;

  //   acc[d.categoria_id] = element.json_questions.filter(g => g.categoria_id === d.categoria_id); 
  //   return acc;
  //   }, {});

  // console.log(element.json_questions);
  let questions = element.json_questions.reduce((acc, d) => {
    if (Object.keys(acc).includes(`${d.categoria_id}-key`)) return acc;

    acc[`${d.categoria_id}-key`] = element.json_questions.filter(g => g.categoria_id === d.categoria_id); 
    return acc;
  }, {});
    
  // for (const key in questions_2) {
  //   if (Object.hasOwnProperty.call(questions_2, key)) {
  //     const element = questions_2[key];
  //     console.log(key);
  //   }
  // }

  return {title: element.title, questions: questions};
}
