import React, {Component} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import withStyles from '@mui/styles/withStyles';
import logo from '../../../../assets/images/logotipo_color.svg';
import logo_merca from '../../../../assets/images/logotipo-grupo-merca-color.png';
import userIcon from '../../../../assets/icons/clientes.svg';
import { Paper, Grid} from '@mui/material';
import styles from './index.module.css';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import SettingsOutlinedIcon from '@mui/icons-material/SettingsOutlined';
import { auth, navbarActions } from '../../../store/actions';
import { connect } from 'react-redux';
import MenuIcon from '@mui/icons-material/Menu';

const drawerWidth = 140;

const useStyles = (theme) => ({
  toolbar: {
    // minHeight: 128,
    // alignItems: 'flex-start',
    // paddingLeft: '160px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(1),
    paddingRight: '0px !important',
    marginLeft: '70px',
  },
  appBar: {
    width: `calc(100% - ${drawerWidth}px)`,
    marginLeft: drawerWidth,
  },

  speedDial: {
    // position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: '4px',
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    fab: {
      color: '#2c2e2e',
      backgroundColor: '#2c2e2e00',
      border: '1px solid rgba(44, 46, 46, 0.5)',
      boxShadow: 'none'
    }
  },
  avatar:{
    width: '100%',
    height: '100%',
    color: "#2c2e2e",
    backgroundColor: "inherit"
  },
  paper:{
    outlined:{
      borderRadius: '24px 0px 0px 24px'
    },
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  git: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '26px',
    marginLeft: 7,
  },
  flexGrow: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton:{
    padding: '0px !important',
    marginTop: 'auto'
  }
});

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


class Header extends Component {
  constructor(props) {
    // console.log(props);
    super(props);
    this.state = {
      open: true,
      speed_dial_open: false,
      speed_dial_hidden: false,
      anchorEl: null
    };
  }

  componentDidMount() {
    this.props.getUser();
  }

  componentDidUpdate(previousProps, previousState) {
    // console.log(previousState);
    
    if (previousState.open !== this.state.open) {
    }
      // if (previousProps.data !== this.props.data) {
      //     this.setState({/*....*/})
      // }
  }

  logout = () =>{
    this.setState({anchorEl:null});
    this.props.logoutAction();
  }

  render() {
    const { classes } = this.props;
    
    return (
      <ElevationScroll {...this.props}>
        <AppBar color="inherit" position="fixed" sx={{
          width: { sm: `calc(100% - ${drawerWidth}px)` },
          ml: { sm: `${drawerWidth}px` },
        }}>
          <Toolbar className={classes.toolbar}>

            <IconButton
              size="large"
              edge="start"
              color="inherit"
              aria-label="menu"
              // sx={{ }}
              sx={{
                mt: 2,
                display: { xs: 'block', sm: 'none' },
                '& .MuiDrawer-paper': { boxSizing: 'border-box', width: drawerWidth },
              }}
              onClick={()=>{ this.props.toggleNavBar()}}
            >
              <MenuIcon/>
            </IconButton>

            <img src={logo} width="236px" alt={""} height = "35px"/>
            <div className={classes.grow} />
          
            <Paper className={`${styles.fixedHeight} ${styles.paper}`}>
              <Grid container direction="row" justifyContent="flex-end" alignItems="center">
                <Grid item>
                  <img src={userIcon} width="35px" alt={""} height = "35px" />
                </Grid>
                <Grid item>
                  <span className={classes.git}>{this.props.user.element.name}</span>
                </Grid>

                <Grid item className={classes.menuButton}>
                  <IconButton
                    aria-label="delete"
                    color="primary"
                    onClick={(event)=>{this.setState({anchorEl:event.currentTarget})}}
                    size="large">
                    <SettingsOutlinedIcon />
                  </IconButton>
                  <Menu
                    id="simple-menu"
                    anchorEl={this.state.anchorEl}
                    keepMounted
                    open={Boolean(this.state.anchorEl)}
                    onClose={()=>{this.setState({anchorEl:null})}}
                  >
                    <MenuItem onClick={()=>{this.setState({anchorEl:null})}}>Profile</MenuItem>
                    <MenuItem onClick={()=>{this.setState({anchorEl:null})}}>My account</MenuItem>
                    <MenuItem onClick={()=>{this.logout()}}>Logout</MenuItem>
                  </Menu>
                </Grid>
                <Grid item >
                  <img src={logo_merca} width="100px" alt={""} height = "27px"/>
                </Grid>
              </Grid>
            </Paper>
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getUser: () => dispatch(auth.user()),
    logoutAction: () => dispatch(auth.logout()),
    toggleNavBar: () => dispatch(navbarActions.navbarToggleFolded()),
  }
};

const mapStateToProps = (state) => {
  return {
    user: state.auth.user,
    logout: state.auth.logout,
    navbar: state.navbar
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(Header);

export default withStyles(useStyles)(_component)