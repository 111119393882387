import React, {Component} from 'react';
import {
  Paper,
  Grid,
  Typography,
  Grow
} from '@mui/material';
import styles from './index.module.css';
import {Input, AutoComplete, DebounceInput} from '../../../components/inputs';
import { customers } from '../../../store/actions/modules';
import { Field , FormSpy } from 'react-final-form';
import { connect } from 'react-redux';

class FormComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      zip_code:null,
    }
  }

  handleSearchNeighborhood = (data, form, values) => {
    // const result  = this.props.neighborhoods.data.find( x => x.value === values.neighborhood_id.value);
    // if(!result){
    //   form.reset({
    //     ...values,
    //     neighborhood_id: null,
    //   });
    // }
    this.props.getNeighborhoods(data);
  }

  onChangeHandlerNeighborhood = (option) =>{
    if(option){
      this.props.getNeighborhood(option.value);
    }
  };

  render() {
    

    return (
      <Grid item container spacing={3}>
        <Grid item lg={12}>
          <Typography variant="h6" component="div">
            Datos Generales
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            name="name"
            fullWidth
            component={Input}
            type="text"
            label="Nombre"
            waiting={this.props.waiting}
          />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            waiting={this.props.waiting}
            name="phone"
            fullWidth
            component={Input}
            type="text"
            label="Telefono"
          />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            name="email"
            fullWidth
            component={Input}
            type="text"
            label="Correo"
            waiting={this.props.waiting}
          />
          </Paper>
        </Grid>

        <Grid item lg={12}>
          <Typography variant="h6" component="div">
            Dirección
          </Typography>
        </Grid>


        <FormSpy
          render={({ form, values }) => (
            <>
              <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
                <Paper className={styles.paper} variant="outlined">
                <Field
                  required
                  name="zip_code"
                  fullWidth
                  component={DebounceInput}
                  type="text"
                  label="Codigo Postal"
                  waiting={this.props.waiting}
                  // inputOnChange={ ({value}) => console.log(value)}
                  inputOnChange={ ({value}) => value ? this.handleSearchNeighborhood(value, form, values) : null}
                />
                </Paper>
              </Grid>
            </>
          )}
        />

        

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              required
              name="neighborhood_id"
              component={AutoComplete}
              label="Colonia"
              waiting={this.props.waiting}
              type="text"
              options={this.props.neighborhoods.data}
              loading={this.props.neighborhoods.loading}
              // setValue={this.state.neighborhood}
              onChange={(option) => this.onChangeHandlerNeighborhood(option)}
            />
          </Paper>  
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            name="street"
            fullWidth
            component={Input}
            type="text"
            label="Calle"
            waiting={this.props.waiting}
          />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            name="ext_number"
            fullWidth
            component={Input}
            type="text"
            label="Número Exterior"
            waiting={this.props.waiting}
          />
          </Paper>
        </Grid>

        <Grow in={this.props.neighborhood.success && true}>
          <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
            <Paper className={styles.paper} variant="outlined">
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography className={styles.title} color="textSecondary" gutterBottom>
                  Municipio
                </Typography>
                <Typography variant="h6" component="h2" color="secondary">
                  {this.props.neighborhood.data.city ? this.props.neighborhood.data.city.name : null}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={styles.title} color="textSecondary" gutterBottom>
                  Estado
                </Typography>
                <Typography variant="h6" component="h2" color="secondary">
                  {this.props.neighborhood.data.city ? this.props.neighborhood.data.city.state_code : null}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={styles.title} color="textSecondary" gutterBottom>
                  País 
                </Typography>
                <Typography variant="h6" component="h2" color="secondary">
                  {this.props.neighborhood.data.city ? this.props.neighborhood.data.city.country_code : null}
                </Typography>
              </Grid>
            </Grid>
            </Paper>  
          </Grid>
        </Grow>


        
      </Grid>
    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    getNeighborhood:(payload) =>{
      dispatch(customers.neighborhood(payload))
    },
    getNeighborhoods:(payload) =>{
      dispatch(customers.neighborhoods(payload))
    }
  }
};

const mapStateToProps = (state) => {
  return {
    neighborhood: state.module_customers.neighborhood,
    neighborhoods: state.module_customers.neighborhoods
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent);

export default _component;
