import React, {Component} from 'react';
import {
  Paper,
  Grid,
} from '@mui/material';
import styles from './index.module.css';
import { Field } from 'react-final-form';
import { AutoComplete} from '../../../../components/inputs';
import { connect } from 'react-redux';
import { modules } from '../../../../store/actions';

import TableBusinessBranches from '../../../../components/table_business_branches';
import TableShoppers from '../../../../components/table_shoppers';

import Stepper from '../../../../components/stepper';

class FormComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
      state:null,
      step_error:null,
      step_feedback:""
    }
  }

  async componentDidMount(){
    // this.setState({loanding_brands: true});
    // await this.getBrands();
    // this.setState({loanding_brands: false});
  }

  async componentDidUpdate(prevProps) {
    // if (this.props.zip_code !== prevProps.zip_code) {
    //   await this.getNeighborhoods(this.props.zip_code);
    // }
  }


  onChangeHandlerState = (data) => {
    if(data){
      this.setState({
        state : data.value
      });
      this.props.getBusinessBranches({state:data.value});
      this.props.getShoppers({state:data.value});
      this.props.address(data);
    }
  }

  businessBranchesaAddress(data){
    const content =  `${data.city}`;
    return content;
  };

  shoppersAddress(data){
    const content =  `${data.city}`;
    return content;
  };

  onChangeHandlerStep = (data) => {
    this.props.onChangeStep(data.event);
  }

  isStepValid = (data) => {
    var valid = true;
    this.setState({step_error:null, step_feedback : ""});
    if(data.step === 0){
      const contracts_selected = Boolean(data.data.contracts.reduce(function (acc, obj) { return acc + obj.selected; }, false));
      valid = contracts_selected;
      if(!contracts_selected || data.data.contracts.length < 1){
        valid = false;
        this.setState({step_error:0, step_feedback : "Selecciona al menos un establecimiento"});
      }else{

        for (const contract of data.data.contracts) {
          if(contract.selected){
            if(!contract.month){
              valid = false;
              break;
            }
          }
        }
        if(!valid){
          this.setState({step_error:0, step_feedback : "El campo mes en los establecimientos es obligatorio"});
        }
      }
    }
    if(data.step === 1){
      const shoppers_selected = Boolean(data.data.shoppers.reduce(function (acc, obj) { return acc + obj.selected; }, false));
      if(!shoppers_selected){
        this.setState({step_error:1, step_feedback : "selecciona al menos un shopper"});
      }
      valid = shoppers_selected;
    }
    return valid;
  }
 
  
  render() {

    const columns_business_branches = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Establecimiento',sortable: true },
      { id: 'address_code', numeric: false, disablePadding: true, label: 'Municipio',sortable: true, render: (data)=>this.businessBranchesaAddress(data) },
      // { id: 'months', numeric: false, disablePadding: true, label: 'Totales',sortable: true },
      // { id: 'number_month', numeric: false, disablePadding: true, label: 'Totales',sortable: true },
    ];

    const columns_shoppers = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Nombre',sortable: true },
      { id: 'social_status', numeric: false, disablePadding: true, label: 'Nivel SE',sortable: true },
    ];

    const steps = [
      {id: 1, label : 'Elige los establecimientos', optional:false},
      {id: 2, label : 'Elige los shoppers', optional:false}
    ]
    return (
      
      <Grid item container spacing={3}>


        <Grid item xs={12}>
          {/* <Paper elevation={1} className={styles.paper}>
            <TableBusinessBranches
              title = {"Establecimientos"}
              data = {this.props.business_branches}
              columns={columns}
              loading={this.props.status_business_branches.loading}
              checkboxSelection
            />
          </Paper> */}
        </Grid>

        <Grid item xs={12}>
          <Stepper
            steps={steps}
            onSkip = {(data) => this.onChangeHandlerStep({event:data, data : this.props.formValues})}
            step_error={this.state.step_error}
            step_feedback={this.state.step_feedback}
            isStepValid={(step) => this.isStepValid({step:step, data : this.props.formValues} )}
          >
            <>
              <Paper className={styles.paper} variant="outlined">
                <Field
                  name="address_state"
                  component={AutoComplete}
                  label="Estado"
                  errors={this.props.errors}
                  type="text"
                  options={this.props.states}
                  loading={this.props.loanding_states}
                  onChange={(option) => this.onChangeHandlerState(option)}
                />
              </Paper>
              <Paper elevation={0} className={styles.paper}>
                <TableBusinessBranches
                  title = {"Establecimientos"}
                  data = {this.props.business_branches}
                  columns={columns_business_branches}
                  loading={this.props.status_business_branches.loading}
                  checkboxSelection
                />
              </Paper>
            </>
            <>
              <Paper elevation={0} className={styles.paper}>
                <TableShoppers
                  title = {"Shoppers"}
                  data = {this.props.shoppers}
                  columns={columns_shoppers}
                  loading={this.props.status_shoppers.loading}
                  checkboxSelection
                />
              </Paper>
            </>
          </Stepper>
        </Grid>



      </Grid>
    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    getShoppers:(payload) =>{
      dispatch(modules.contract_visits.shoppers(payload))
    },
    getBusinessBranches:(payload) =>{
      dispatch(modules.contract_visits.businessBranches(payload))
    }
  }
};

const mapStateToProps = (state) => {
  return {
    states: state.module_contract_visits.states,
    status_states: state.module_contract_visits.status_states,
    business_branches: state.module_contract_visits.business_branches,
    status_business_branches: state.module_contract_visits.status_business_branches,
    shoppers: state.module_contract_visits.shoppers,
    status_shoppers: state.module_contract_visits.status_shoppers,
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent);

export default _component;
