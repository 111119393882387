import usersSaga from '../../../sagas/external/users.saga';
import usersReducer from '../../reducers/external/users.reducer';
import {users as actions} from '../../actions/external';

export function getModule() {
    return {
        id: "external_users",
        reducerMap: {
          external_users: usersReducer,
        },
        sagas: [usersSaga],
        finalActions: [actions.reset()],
    }; 
}