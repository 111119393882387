export const CREATE = "[MODULE][PACKAGES] CREATE";
export const UPDATE = "[MODULE][PACKAGES] UPDATE";
export const REMOVE = "[MODULE][PACKAGES] REMOVE";
export const LIST = "[MODULE][PACKAGES] LIST";
export const ELEMENT = "[MODULE][PACKAGES] ELEMENT";

export const CREATE_REQUEST = "[MODULE][PACKAGES][CREATE] REQUEST";
export const CREATE_SUCCESS = "[MODULE][PACKAGES][CREATE] SUCCESS";
export const CREATE_FAILURE = "[MODULE][PACKAGES][CREATE] FAILURE";

export const UPDATE_REQUEST = "[MODULE][PACKAGES][UPDATE] REQUEST";
export const UPDATE_SUCCESS = "[MODULE][PACKAGES][UPDATE] SUCCESS";
export const UPDATE_FAILURE = "[MODULE][PACKAGES][UPDATE] FAILURE";

export const REMOVE_REQUEST = "[MODULE][PACKAGES][REMOVE] REQUEST";
export const REMOVE_SUCCESS = "[MODULE][PACKAGES][REMOVE] SUCCESS";
export const REMOVE_FAILURE = "[MODULE][PACKAGES][REMOVE] FAILURE";

export const LIST_REQUEST = "[MODULE][PACKAGES][LIST] REQUEST";
export const LIST_SUCCESS = "[MODULE][PACKAGES][LIST] SUCCESS";
export const LIST_FAILURE = "[MODULE][PACKAGES][LIST] FAILURE";


export const ELEMENT_REQUEST = "[MODULE][PACKAGES][ELEMENT] REQUEST";
export const ELEMENT_SUCCESS = "[MODULE][PACKAGES][ELEMENT] SUCCESS";
export const ELEMENT_FAILURE = "[MODULE][PACKAGES][ELEMENT] FAILURE";

export const CLEAR = "[MODULE][PACKAGES] CLEAR";

export const create = (payload)=> {
  return { type: CREATE, payload };
}

export const update = (payload)=> {
  return { type: UPDATE, payload };
}

export const remove = (payload)=> {
  return { type: REMOVE, payload };
}

export const list = ()=> {
  return { type: LIST  };
}

export const detail = (payload)=> {
  return { type: ELEMENT, payload };
}

export const reset = ()=> {
  return { type: CLEAR  };
}