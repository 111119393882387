import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import data_service from "../services/clients";
import { clients as actions } from '../store/actions/modules';
import { snackbarActions as snackbar} from '../store/actions';
import { failure, success } from "../utils/api_responses";


export default function* root() {
  yield all([
    fork(watcherList),
    fork(watcherDetail),
    fork(watcherCreate),
    fork(watcherChangePassword),
    fork(watcherUpdate),
  ])
}

function* watcherList() {
  yield takeEvery(actions.LIST, workerList);
}

function* watcherDetail() {
  yield takeEvery(actions.ELEMENT, workerDetail);
}

function* watcherCreate() {
  yield takeEvery(actions.CREATE, workerCreate);
}

function* watcherChangePassword() {
  yield takeEvery(actions.CHANGE_PASSWORD, workerChangePassword);
}

function* watcherUpdate() {
  yield takeEvery(actions.UPDATE, workerUpdate);
}


function* workerList() {
  try {
    yield put({ type: actions.LIST_REQUEST });
    const pending_payments = yield call(clients);
    yield put({ type: actions.LIST_SUCCESS ,payload: pending_payments});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.LIST_FAILURE, payload: e });
  }
}

function* workerDetail(action) {
  try {
    yield put({ type: actions.ELEMENT_REQUEST }); 
    const data = yield call(detail, action.payload);
    yield put({ type: actions.ELEMENT_SUCCESS ,payload: data});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.ELEMENT_FAILURE, payload: e });
  }
}

function* workerCreate(action) {
  try {
    yield put({ type: actions.CREATE_REQUEST }); 
    const response = yield call(create, action.payload);
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.CREATE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.CREATE_FAILURE, payload: e });
  }
}


function* workerChangePassword(action) {
  try {
    yield put({ type: actions.CHANGE_PASSWORD_REQUEST }); 
    const response = yield call(changePassword, action.payload);
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.CHANGE_PASSWORD_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.CHANGE_PASSWORD_FAILURE, payload: e });
  }
}

function* workerUpdate(action) {
  try {
    yield put({ type: actions.UPDATE_REQUEST }); 
    const response = yield call(update, action.payload);
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.UPDATE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.UPDATE_FAILURE, payload: e });
  }
}

function clients() {
  return new Promise( (resolve, reject) => {
    data_service.getAll()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function detail(payload) {
  return new Promise( (resolve, reject) => {
    data_service.get(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function create(payload) {
  return new Promise( (resolve, reject) => {
    data_service.create(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function update(payload) {
  return new Promise( (resolve, reject) => {
    data_service.update(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function changePassword(payload) {
  return new Promise( (resolve, reject) => {
    data_service.restore(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}