import React, {Component} from 'react';
import {
  Paper,
  Grid,
  Typography
} from '@mui/material';
import styles from './index.module.css';
import { Field } from 'react-final-form';
import {Input, RadioGroup, DatePicker, Select} from '../../../../components/inputs';

class FormComponent extends Component {

  render() {
    return (
      <Grid item container spacing={3}>
        <Grid item lg={12}>
          <Typography variant="h6" component="div">
            Datos Generales
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
            name="name"
            fullWidth
            component={Input}
            type="text"
            label="Nombre"
            errors={this.props.errors}
          />
          </Paper>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              waiting={this.props.waiting}
              name="last_name"
              fullWidth
              component={Input}
              type="text"
              label="Apellido"
              errors={this.props.errors}
            />
          </Paper>
        </Grid>
        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              waiting={this.props.waiting}
              name="sex"
              component={RadioGroup}
              label="Genero"
              errors={this.props.errors}
              type="text"
              options={[{value: 'Male',label: 'Hombre'},{value: 'Female',label: 'Mujer'}]}
            >
          </Field>
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              waiting={this.props.waiting}
              name="birthdate"
              component={DatePicker}
              label="Fecha de nacimiento"
              errors={this.props.errors}
              type="text"
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              waiting={this.props.waiting}
              name="children"
              component={RadioGroup}
              label="Hijos"
              errors={this.props.errors}
              type="text"
              options={[{value: '1',label: 'Si'},{value: '0',label: 'No'}]}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
              name="occupation"
              component={Select}
              label="Ocupación"
              errors={this.props.errors}
              type="text"
              options={[
                {value: 'Professional',label: 'Profesional'},
                {value: 'Worker',label:"Trabajador"},
                {value: 'Housewife',label:"Ama de casa"},
                {value: 'Unemployed',label:"Desempleado"},
                {value: 'Administrative',label:"Administrativo"},
                {value: 'Executive',label:"Ejecutivo"},
                {value: 'Owner',label:"Propietario"},
                {value: 'Entrepreneur',label:"Emprendedor"},
                {value: 'Non-administrative employee',label:"Trabajador no administrativo"},
                {value: 'Student',label:"Estudiante"},
                {value: 'Teacher',label:"Maestro"},
                {value: 'Artist',label:"Artista"},
                {value: 'Unspecified', label:"No especificado"}
              ]}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              waiting={this.props.waiting}
              name="email"
              fullWidth
              component={Input}
              type="text"
              label="Correo"
              errors={this.props.errors}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              waiting={this.props.waiting}
              name="feeding_behaviours"
              component={Select}
              label="Preferencias Alimentarias"
              errors={this.props.errors}
              type="text"
              options={[
                {value: 'Omnivore',label: 'Omnivoro'},
                {value: 'Vegan',label: 'Vegano'},
                {value: 'Vegetarian',label: 'Vegetariano'},
                {value: 'Not pig meat',label: 'No carne de cerdo'},
              ]}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              waiting={this.props.waiting}
              name="alcohol"
              component={RadioGroup}
              label="Alcohol"
              errors={this.props.errors}
              type="text"
              options={[{value: '1',label: 'Si'},{value: '0',label: 'No'}]}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              waiting={this.props.waiting}
              name="marital_status"
              component={Select}
              label="Estado Civil"
              errors={this.props.errors}
              type="text"
              options={[
                {value: 'Single',label: 'Soltero'},
                {value: 'Enaged',label: 'Comprometido'},
                {value: 'Married',label: 'Casado'},
                {value: 'Divorced',label: 'Divorciado'},
                {value: 'Widower',label: 'Viudo'},
              ]}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              waiting={this.props.waiting}
              name="phone"
              component={Input}
              label="Telefono"
              errors={this.props.errors}
              type="text"
            />
          </Paper>
        </Grid>
        
        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
              name="first_contact"
              component={Select}
              label="¿Cómo te enteraste de nosotros?"
              errors={this.props.errors}
              type="text"
              options={[
                {value: 'FB',label: 'Facebook'},
                {value: 'Survey',label: 'Encuesta'},
                {value: 'FB Index',label: 'FB Index'},
                {value: 'Invitation',label: 'Invitación'},
                {value: 'Unspecified',label: 'No especificado'},
              ]}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              waiting={this.props.waiting}
              name="enso_code"
              component={Input}
              label="Cash Tag"
              errors={this.props.errors}
              type="text"
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              waiting={this.props.waiting}
              name="type"
              component={Select}
              label="Tipo"
              errors={this.props.errors}
              type="text"
              options={[
                {value: 'Panelist',label: 'Panelista'},
                {value: 'Shopper',label: 'Shopper'},
                {value: 'Both',label: 'Ambos'}
              ]}
            />
          </Paper>
        </Grid>

      </Grid>
    );
  }

}

export default FormComponent;
