  
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormHelperText,
  FormControl,
  FormLabel,
  Slider,
  IconButton,
  Tooltip,
  Skeleton 
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles((theme) => ({
  formControl: {
    minWidth: '50%',
    // marginLeft: "5px !important",
    marginRight: "5px !important",
  },
}));

const SliderWrapper = ({input: {name, onChange, value, ...restInput}, inputOnChange, meta, clearable=false, waiting=false, ...rest}) => {
  // const serverError = rest.errors && rest.errors.hasOwnProperty.call(rest.errors, name) ? rest.errors[name]: null;
  // const showServerError =  Boolean(serverError && !meta.dirtySinceLastSubmit);
	const showError = Boolean(  ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched );
  const classes = useStyles();

  // function valueLabelFormat(value) {
  //   const marks = rest.marks ? rest.marks: [];
  //   console.log(marks);
  //   const mark = marks.find( x => x.value === value);
  //   return `${mark.label} - ${mark.value}`;
  // }

	return (
		<>
      <FormControl className={classes.formControl}  error={ Boolean(showError) } >
        <FormLabel required={rest.required}>{rest.label}
        { (clearable && ! waiting)?
          <IconButton
            onClick={ () => {onChange(null)}}
            edge="end"
            sx={{float: 'right', marginRight: '0px'}}
            size="small"
          >
            <Tooltip title={"Borrar respuesta"} >
              <ClearIcon fontSize="small"/>
            </Tooltip>
          </IconButton>:null}
        </FormLabel>
        {waiting?<Skeleton variant="rectangular"/> :
        <Slider
          {...rest}
          {...restInput}
          onChange={ (e, value) => {
            e.target.value = value;
            onChange(e);
            inputOnChange && inputOnChange(e);
          }}
          value={Array.isArray(value) ? value : Number(value)}
          // max={6}
          // valueLabelDisplay="auto"
          // valueLabelFormat={valueLabelFormat}
          step={rest.step ? rest.step: null}
          marks={rest.marks ? rest.marks: []}
        />
        }
        {showError &&
          <FormHelperText>
            {meta.error || meta.submitError}
          </FormHelperText>
        }
        {/* {showServerError  && <FormHelperText >{meta.error || meta.submitError || serverError}</FormHelperText> } */}
      </FormControl>
		</>
	);
};

export default SliderWrapper;
