import { Validators } from '@lemoncode/fonk';
import { createFinalFormValidation } from '@lemoncode/fonk-final-form';
import { requiredByField } from '@lemoncode/fonk-required-by-field-validator';
// requiredByField.setErrorMessage('El campo es requerido');
export const validationSchema = {
  field: {
    address_state:[Validators.required.validator],
    shoppers:[Validators.required.validator],
    contracts: [
      {
        validator: Validators.array,
        customArgs: { 
            field: {
              selected: [Validators.required],
              contract_id: [Validators.required],
              month: [
                {
                  validator: requiredByField.validator,
                  customArgs: { field: 'selected', condition: fieldValue => fieldValue === true},
                  message:"Este campo es obligatorio"
                },
              ]
          }
        }
      }
    ]
  }
};

export const formValidation = createFinalFormValidation(validationSchema);