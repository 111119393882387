import { createTheme } from '@mui/material/styles';
import { esES } from '@mui/material/locale';
// import {unstable_createMuiStrictModeTheme} from "@mui/material/styles";
// const _createTheme = process.env.NODE_ENV === 'production' ? createTheme : unstable_createMuiStrictModeTheme;
const theme = createTheme({
  palette: {
    primary: {
      // light: will be calculated from palette.primary.main,
      main: '#2C2E2E',
      // dark: will be calculated from palette.primary.main,
      // contrastText: will be calculated to contrast with palette.primary.main
    },
    secondary: {
      // light: '#0066ff',
      main: '#2A9D8F',
      // dark: will be calculated from palette.secondary.main,
      // contrastText: '#ffcc00',
    },
    background: {
      default: "white",
    },
    // Used by `getContrastText()` to maximize the contrast between
    // the background and the text.
    tonalOffset: 0.2,
  },

  components: {
    // Style sheet name ⚛️
    MuiTableSortLabel: {
      styleOverrides:{
        // Name of the rule
        root:{
          borderStyle: 'solid',
          borderWidth: '0px 0px 2px 0px',
          "&.Mui-active": {
            "fontWeight": "bold",
            "borderWidth": "0px 0px 2px 0px",
            "borderStyle": "solid",
            "borderColor": "#2A9D8F",
            "color": "#2A9D8F !important"
          }
        },
        icon: {
          // Some CSS
          display: 'none',
        }
        
      }
    },
    MuiTypography: {
      styleOverrides:{
        h6:{
          color: '#2A9D8F !important'
        },
        h5:{
          color: '#2A9D8F !important'
        },
        h4:{
          color: '#2A9D8F !important'
        }
      }
    },
    MuiButton: {
      styleOverrides:{
        root: {
          borderRadius: '24px',
        },
        containedSizeLarge: {
          // width: '150px'
        },
        containedSizeSmall: {
          padding: '4px 35px'
        },
        outlinedSizeSmall:{
          padding: '3px 32px'
        }
      }
    },

    MuiBackdrop: {
      // styleOverrides:{
      //   root: {
      //     backgroundColor: 'rgba(39,144,131,0.5)'
      //   }
      // }
    },

    MuiSpeedDial: {
      styleOverrides:{
        fab:{
          color: '#2c2e2e',
          backgroundColor: '#2c2e2e00',
          border: 'none',
          boxShadow: 'none'
        }
      }
    },

    MuiDialogActions:{
      styleOverrides:{
        root:{justifyContent: 'center'}
      }
    },

    MuiDrawer: {
      styleOverrides: {
        paper: {
          backgroundColor: '#2C2E2E',
          color : "white",
          borderRadius: '0px 95px 95px 0px',
          borderRight:'0px',
        },
        modal:{
          backgroundColor: "#2c2e2e00 !important"
        }
      }
    }
  },

},
esES
);

/**
 * 
calendario
#E76F51
#7B52AB
#21C7B2
#EEB52A
#41AB31
#2C2E2E
Oficiales
#2A9D8F -- verde 
#DBDCDC -- griss
#2C2E2E -- negro
 */
export default theme;

    