// action types
export const RESET = "[MODULE][VISITS] RESET";
//element actions
export const ELEMENT_REQUEST = "[MODULE][VISITS][ELEMENT] REQUEST";
export const ELEMENT_SUCCESS = "[MODULE][VISITS][ELEMENT] SUCCESS";
export const ELEMENT_FAILURE = "[MODULE][VISITS][ELEMENT] FAILURE";
//module actions
export const DETAIL = "[MODULE][VISITS] DETAIL";
export const PAYMENT = "[MODULE][VISITS] PAYMENT";
export const UPDATE = "[MODULE][VISITS] UPDATE";
export const ANSWERS = "[MODULE][VISITS] ANSWERS";
export const APPROVED = "[MODULE][VISITS] APPROVED";
export const UPLOAD_IMAGE = "[MODULE][VISITS] UPLOAD_IMAGE";
export const REMOVE_IMAGE = "[MODULE][VISITS] REMOVE_IMAGE";

export const UPDATE_REQUEST = "[MODULE][VISITS][UPDATE] REQUEST";
export const UPDATE_SUCCESS = "[MODULE][VISITS][UPDATE] SUCCESS";
export const UPDATE_FAILURE = "[MODULE][VISITS][UPDATE] FAILURE";

export const PAYMENT_REQUEST = "[MODULE][VISITS][PAYMENT] REQUEST";
export const PAYMENT_SUCCESS = "[MODULE][VISITS][PAYMENT] SUCCESS";
export const PAYMENT_FAILURE = "[MODULE][VISITS][PAYMENT] FAILURE";

export const SAVE_PAYMENT_REQUEST = "[MODULE][VISITS][SAVE_PAYMENT] REQUEST";
export const SAVE_PAYMENT_SUCCESS = "[MODULE][VISITS][SAVE_PAYMENT] SUCCESS";
export const SAVE_PAYMENT_FAILURE = "[MODULE][VISITS][SAVE_PAYMENT] FAILURE";

export const UPDATE_ANSWERS_REQUEST = "[MODULE][VISITS][UPDATE_ANSWERS] REQUEST";
export const UPDATE_ANSWERS_SUCCESS = "[MODULE][VISITS][UPDATE_ANSWERS] SUCCESS";
export const UPDATE_ANSWERS_FAILURE = "[MODULE][VISITS][UPDATE_ANSWERS] FAILURE";

export const APPROVED_REQUEST = "[MODULE][VISITS][APPROVED] REQUEST";
export const APPROVED_SUCCESS = "[MODULE][VISITS][APPROVED] SUCCESS";
export const APPROVED_FAILURE = "[MODULE][VISITS][APPROVED] FAILURE";

export const UPLOAD_IMAGE_REQUEST = "[MODULE][VISITS][UPLOAD_IMAGE] REQUEST";
export const UPLOAD_IMAGE_SUCCESS = "[MODULE][VISITS][UPLOAD_IMAGE] SUCCESS";
export const UPLOAD_IMAGE_FAILURE = "[MODULE][VISITS][UPLOAD_IMAGE] FAILURE";

export const REMOVE_IMAGE_REQUEST = "[MODULE][VISITS][REMOVE_IMAGE] REQUEST";
export const REMOVE_IMAGE_SUCCESS = "[MODULE][VISITS][REMOVE_IMAGE] SUCCESS";
export const REMOVE_IMAGE_FAILURE = "[MODULE][VISITS][REMOVE_IMAGE] FAILURE";

export const update = (payload)=> {
  return { type: UPDATE_REQUEST, payload };
}

export const detail = (payload)=> {
  return { type: DETAIL, payload};
}

export const reset = ()=> {
  return { type: RESET };
}

export const payment = (payload)=> {
  return { type: PAYMENT, payload };
}

export const savePayment = (payload)=> {
  return { type: SAVE_PAYMENT_REQUEST, payload };
}

export const answers = (payload)=> {
  return { type: ANSWERS, payload };
}

export const updateAnswers = (payload)=> {
  return { type: UPDATE_ANSWERS_REQUEST, payload };
}

export const approved = (payload)=> {
  return { type: APPROVED, payload };
}

export const uploadImage = (payload)=> {
  return { type: UPLOAD_IMAGE, payload };
}

export const removeImage = (payload)=> {
  return { type: REMOVE_IMAGE, payload };
}

