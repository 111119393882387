import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';


const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: '1 1 100%',
    },
  }));

const EnhancedTableToolbar = ({title}) => {
    const classes = useToolbarStyles();
    // let history = useHistory();
    return (
      <Toolbar
        className={clsx(classes.root)}
      >
          <Typography className={classes.title} variant="h5" id="tableTitle" component="div">
            {title}
          </Typography>
      </Toolbar>
    );
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
export default function MultipleResultTable({
    title,
    data,
    loading,
    ...props}) {

  return (
      data && Object.keys(data).map((key,i)=>(
    <TableContainer component={Paper} sx ={{padding: '16px', marginBottom:"30px"}} key={`${key}_${i}`}>
        <EnhancedTableToolbar
            title={data[key].criterio}
        />
        <Table sx={{ minWidth: 650 }} aria-label="simple table">
          <TableHead >
            <TableRow>
                <TableCell
                    align='left'
                    padding='normal'
                >Establecimiento</TableCell>
                {data[key].labels.map((headCell, k) => (
                <TableCell
                    key={`${i}_${k}`}
                    align='left'
                    padding='normal'
                >{ headCell}</TableCell>
                ))}
            </TableRow>
          </TableHead>

        <TableBody>
        {data[key].respuestas.length > 0 && data[key].respuestas.map((element,k)=>(
          <StyledTableRow key={`${key}_${k}`}>
              <TableCell
                  align='left'
                  padding='normal'
              >{element.label}</TableCell>
              {element.data.map((value, l) => (
              <TableCell
                  key={`${k}_${l}`}
                  align='center'
                  padding='normal'
              >{ value}</TableCell>
              ))}
          </StyledTableRow>
        ))} 
        </TableBody>
      </Table>
    </TableContainer>
    ))
  );
}