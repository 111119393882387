import React, {Component} from 'react';
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import FormRecord from '../form';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { formValidation } from './validator';
import { contracts as actions } from '../../../store/actions/modules';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  }
});

class CreateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
    }
  }
  componentDidMount(){
    this.props.getModuleData();
  }

  componentWillUnmount(){
  }

  componentDidUpdate(prevProps) {
    if (prevProps.save !== this.props.save) {
      if(this.props.save.success){
        this.props.history.push("/contracts");
      }
    }
  }



  onSubmit =  values => {
    this.props.createRecord({data: values});
  }

  render() {

    const buttonSx = {
      ...(this.props.save.loading && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    const maxWidth = false;

    const { classes } = this.props;


    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>

          <Grid container spacing={2} direction="column">

            <Form
              initialValues={this.state}
              validate={formValidation.validateForm}
              onSubmit={this.onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container item spacing={3} justifyContent="flex-end">
                    {/* Title */}
                    <Grid item xs>
                      <Toolbar className={styles['toolbar']}>
                        <Typography variant="h4" id="tableTitle" component="div">
                          Nuevo Contrato
                          <Typography variant="caption" display="block" gutterBottom>
                          * Campos requeridos
                          </Typography>
                        </Typography>
                      </Toolbar>
                    </Grid>
                    {/* Buttons */}
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => {this.props.history.push("/contracts")}}
                        disabled={this.props.save.loading}
                        color="primary">
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        sx={buttonSx}
                        disabled={this.props.save.loading}
                        className={classes.button}
                        type="submit"
                      >
                        Guardar
                        {this.props.save.loading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  {/* Form */}
                  <FormRecord waiting={this.props.save.loading}/>
                  
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                  {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}
                </form>
              )}
            />

          </Grid>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createRecord:(payload) =>{
      dispatch(actions.store(payload))
    },
    getModuleData:() =>{
      dispatch(actions.create())
    }
  }
};

const mapStateToProps = (state) => {
  console.log(state);
  return {
    save: state.module_contracts.save
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateComponent);


export default withRouter(withStyles(useStyles)(_component));
