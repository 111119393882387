import {visits as actions} from '../../actions/modules';

const initialState = {
  element: {},
  status_element: {
    loading: false,
    success: false,
  },
  shoppers: [],
  status_shoppers: {
    loading: false,
    success: false,
  },
  update: {
    loading: false,
    success: false,
    response: [],
  },
  payment: {
    loading: false,
    success: false,
    response: [],
  },
  update_answers: {
    loading: false,
    success: false,
    response: [],
  },
  approved: {
    loading: false,
    success: false,
    response: [],
  },
  upload_image: {
    loading: false,
    success: false,
    response: [],
  },
  remove_image: {
    loading: false,
    success: false,
    response: [],
  },
};

const reducer = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.ELEMENT_REQUEST:
    {
      return {
        ...state,
        status_element: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.ELEMENT_SUCCESS:
    {
      return {
        ...state,
        element: action.payload,
        status_element: {
          loading: false,
          success: true,
        },
      }
    }
    case actions.ELEMENT_FAILURE:
    {
      return {
        ...state,
        element: initialState.element,
        status_element: {
          loading: false,
          success: false,
        },
      }
    }
    case actions.UPDATE_REQUEST:
    {
      return {
        ...state,
        update: {
          loading: true,
          success: false,
          response: []
        },
      }
    }
    case actions.UPDATE_SUCCESS:
    {
      return {
        ...state,
        update: {
          loading: false,
          success: true,
          response: action.payload
        },
      }
    }
    case actions.UPDATE_FAILURE:
    {
      return {
        ...state,
        update: {
          loading: false,
          success: false,
          response: action.payload
        },
      }
    }
    case actions.PAYMENT_REQUEST:
    {
      return {
        ...state,
        status_element: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.PAYMENT_SUCCESS:
    {
      return {
        ...state,
        element: action.payload,
        status_element: {
          loading: false,
          success: true,
        },
      }
    }
    case actions.PAYMENT_FAILURE:
    {
      return {
        ...state,
        element: initialState.element,
        status_element: {
          loading: false,
          success: false,
        },
      }
    }
    case actions.SAVE_PAYMENT_REQUEST:
    {
      return {
        ...state,
        payment: {
          loading: true,
          success: false,
          response: []
        },
      }
    }
    case actions.SAVE_PAYMENT_SUCCESS:
    {
      return {
        ...state,
        payment: {
          loading: false,
          success: true,
          response: action.payload
        },
      }
    }
    case actions.SAVE_PAYMENT_FAILURE:
    {
      return {
        ...state,
        payment: {
          loading: false,
          success: false,
          response: action.payload
        },
      }
    }
    case actions.APPROVED_REQUEST:
    {
      return {
        ...state,
        approved: {
          loading: true,
          success: false,
          response: []
        },
      }
    }
    case actions.APPROVED_SUCCESS:
    {
      return {
        ...state,
        approved: {
          loading: false,
          success: true,
          response: action.payload
        },
      }
    }
    case actions.APPROVED_FAILURE:
    {
      return {
        ...state,
        approved: {
          loading: false,
          success: false,
          response: action.payload
        },
      }
    }
    case actions.UPLOAD_IMAGE_REQUEST:
    {
      return {
        ...state,
        upload_image: {
          loading: true,
          success: false,
          response: []
        },
      }
    }
    case actions.UPLOAD_IMAGE_SUCCESS:
    {
      return {
        ...state,
        upload_image: {
          loading: false,
          success: true,
          response: action.payload
        },
      }
    }
    case actions.UPLOAD_IMAGE_FAILURE:
    {
      return {
        ...state,
        upload_image: {
          loading: false,
          success: false,
          response: action.payload
        },
      }
    }
    case actions.REMOVE_IMAGE_REQUEST:
    {
      return {
        ...state,
        remove_image: {
          loading: true,
          success: false,
          response: []
        },
      }
    }
    case actions.REMOVE_IMAGE_SUCCESS:
    {
      return {
        ...state,
        remove_image: {
          loading: false,
          success: true,
          response: action.payload
        },
      }
    }
    case actions.REMOVE_IMAGE_FAILURE:
    {
      return {
        ...state,
        remove_image: {
          loading: false,
          success: false,
          response: action.payload
        },
      }
    }
    case actions.UPDATE_ANSWERS_REQUEST:
    {
      return {
        ...state,
        update_answers: {
          loading: true,
          success: false,
          response: []
        },
      }
    }
    case actions.UPDATE_ANSWERS_SUCCESS:
    {
      return {
        ...state,
        update_answers: {
          loading: false,
          success: true,
          response: action.payload
        },
      }
    }
    case actions.UPDATE_ANSWERS_FAILURE:
    {
      return {
        ...state,
        update_answers: {
          loading: false,
          success: false,
          response: action.payload
        },
      }
    }
    case actions.RESET:
    {
      return initialState;
    }
    default:
    {
      return state;
    }
  }
};

export default reducer;