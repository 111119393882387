import React, {Component} from 'react';
import {
  Container,
  Grid,
  Card,
  CardContent,
  CardHeader,
  Paper,
  Stack,
  Typography,
  Button,
  Skeleton,
  FormHelperText
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import { green } from '@mui/material/colors';
import { surveys as actions } from '../../../store/actions/modules';
import { connect } from 'react-redux';
import { Field, Form } from 'react-final-form';
import {Input, TextEditor,Select, Slider, TimePicker, DatePicker, RadioGroup, File} from '../../../components/inputs';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  list:{
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  },
  card:{
    width: '100%',
  }
});

class DetailComponent extends Component {
  constructor(props) {
    super(props);

    const types = {
      Binaria: { component: RadioGroup , type:"text" },
      Escala: { component: Slider , type:"text" },
      Abierta: { component: TextEditor , type:"text" },
      Imagen: { component: File , type:"file" },
      Hora: { component: TimePicker , type:"text" },
      Fecha: { component: DatePicker , type:"text" },
      Multiple: { component: Select , type:"text" },
      Numerica: { component: Input , type:"text" },
    };

    const marks  =[
      {
        value: 1,
        label: '😡'
      },
      {
        value: 2,
        label: '🙁'
      },
      {
        value: 3,
        label: '🤔'
      },
      {
        value: 4,
        label: '🙂'
      },
      {
        value: 5,
        label: '🥰'
      }
    ];

    const form  = {
      Binaria: true,
      Escala: 1,
      Abierta: null,
      Imagen: [],
      Hora: null,
      Fecha: null,
      Multiple: null,
      Numerica: null,
    };

    this.state = {
      slug:null,
      types: types,
      marks:marks,
      form:form
    }
  }
  
  async componentDidMount(){
    const {slug} = this.props.match.params;
    this.setState({
      slug: slug
    });
    this.props.getModuleData({slug: slug});
  }

  componentWillUnmount(){
    this.props.resetElement();
  }


  getTitle = (key) => {
    const catalog = this.props.catalogs.find( catalog => catalog.value === parseInt(key));
    return catalog ? catalog.text : null;
  }
  
  getOptions(question){
    let options = [];
    if(question.tipo === "Multiple"){
      if(question.opciones.length > 0){
        options = question.opciones.map( (element) => ({ text:element.opcion, value:element.opcion}))
        if(question.preset_opciones.otro){
          options.push({text:"Otro", value:"Otro"});
        }
      }
      if(question.preset_opciones.no_aplica){
        options.push({text:"No aplica", value:"NA"});
      }
    }
    if(question.tipo === "Binaria"){
      options=[{value: 'Sí',label: 'Sí'},{value: 'No',label: 'No'}];
      if(question.preset_opciones.no_aplica){
        options.push({label:"No aplica", value:"NA"});
      }
    }
    return options;
  }

  onSubmit = values => {
    // console.log(values);
  }

  render() {

    const maxWidth = false;

    const { classes } = this.props;


    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>
          <Card variant="outlined" className={classes.card}>
            <CardHeader 
              title={
                <Grid container item spacing={3} justifyContent="flex-end">
                    {/* Title */}
                    <Grid item xs>
                      <Typography variant="h4" id="tableTitle" component="div">
                        Encuesta
                      </Typography>
                    </Grid>
                    {/* Buttons */}
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => {this.props.history.push("/surveys")}}
                        color="primary"
                        disabled={this.props.status_element.loading}
                      >
                        Regresar
                      </Button>
                    </Grid>
                  </Grid>
              }
              subheader="Bienvenido al programa de evaluación de Servicio de Restaurante agradecemos tu interés, tu participación es de gran importancia para nosotros."
            />
            <CardContent>
              <Typography variant="overline" display="block" gutterBottom>* Pregunta requerida</Typography>
              <Form
                initialValues={this.state.form}
                onSubmit={this.onSubmit}
                render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
                <form onSubmit={handleSubmit} encType="multipart/form-data">
                  {
                    Object.keys(this.props.element.questions).map((key,i)=>{
                    return (
                      <Card variant="outlined" className={classes.card} key={i}>
                        <CardHeader title={this.getTitle(key)}/>
                        <CardContent>
                          { this.props.status_element.loading ?
                            <Skeleton variant="rectangular"/> 
                          :
                            <Grid item xs={12} container spacing={2}>
                            {
                              this.props.element.questions[key].length > 0
                              ?
                                this.props.element.questions[key].map((question, index) => (
                                <Grid item xs={12} key={index}>
                                  <Paper className={styles.paper} variant="outlined">
                                    <Stack spacing={3} direction="row" sx={{ mb: 1 }} alignItems="center">
                                      { question.tipo === "Escala"? <Typography sx={{display: { xs: 'none', sm: 'block' }}} variant="overline" display="block" gutterBottom>{question.etiquetas.inicial}</Typography>: null}
                                      <Field
                                        required
                                        name={question.tipo}
                                        component={this.state.types[question.tipo].component}
                                        type={this.state.types[question.tipo].type}
                                        label={question.titulo}
                                        //for slider
                                        marks ={this.state.marks}
                                        min={1}
                                        max={5}
                                        // for select
                                        options={ question.tipo === "Multiple" || question.tipo === "Binaria" ? this.getOptions(question) : null}
                                      />
                                      { question.tipo === "Escala"? <Typography sx={{display: { xs: 'none', sm: 'block' }}} variant="overline" display="block" gutterBottom>{question.etiquetas.final}</Typography>: null}
                                    </Stack>
                                      {/* { question.descripcion ? <FormHelperText> {question.descripcion} </FormHelperText>: null} */}
                                      { question.descripcion ? <FormHelperText> {question.descripcion} </FormHelperText>: null}
                                    </Paper>
                                </Grid>
                                ))
                              // <pre>{JSON.stringify(this.props.element.questions[key], 0, 2)}</pre>
                              :
                              null
                            }
                            </Grid>
                            
                          }
                        </CardContent>
                        </Card>
                      )
                    })
                  }
                </form>)}
              />
              
            </CardContent>
          </Card>



        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleData:(payload) =>{
      dispatch(actions.detail(payload))
    },
    resetElement: () => dispatch(actions.reset())
  }
};


const mapStateToProps = (state) => {
  return {
    status_element: state.module_surveys.status_element,
    element: state.module_surveys.element,
    catalogs:  state.module_surveys.catalogs
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailComponent);


export default withRouter(withStyles(useStyles)(_component));
