import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from '../../layouts/shared-components/Header';
import RightSiderBar from '../../layouts/shared-components/RightSiderBar';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {snackbarActions} from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';
import FloatingMenu from '../shared-components/floating-menu';
import Box from '@mui/material/Box';

const drawerWidth = 220;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const MainLayout = ({ children, ...props  }) => {

  const snackbarState = useSelector(state => state.snackbar);

  const backdropState = useSelector(state => state.backdrop);

  const hide_floating_menu = useSelector(state => state.floating_menu.hidden);
  
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(snackbarActions.snackbarOpen({
  //     message:'Oops, Se ha producido un error',
  //     severity:'error'
  //   }))
  // }, [])
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    dispatch(snackbarActions.snackbarClose())
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <Header/>
        <RightSiderBar></RightSiderBar>
        <Box component="main" sx={{ flexGrow: 1, p: 3, width: { xl: `calc(100% - ${drawerWidth}px)` } }}>
          <div className={classes.toolbar} />
          {/* backdrop */}
          <Backdrop className={classes.backdrop} open={backdropState.open}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {/* backdrop */}
          {/* feedback alert */}
          <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={handleClose}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity={snackbarState.severity}>
              {snackbarState.message}!
            </Alert>
          </Snackbar>
          {/* feedback alert */}
          {children}
          { ! hide_floating_menu &&<FloatingMenu/>}
        </Box>
      </Box>
    </React.Fragment>
  );
}

export default MainLayout;
