import { zodResolver } from '@hookform/resolvers/zod';
import * as z from 'zod';

export const RecordSchema = z.object({
    title: z.string().min(1, {
      message: "El título es requerido",
    }),
    // name: z.string(),
    questions:z.array(
        z.object({
          id: z.string().or(z.number()),
          categoria_id: z.object({
            value: z.string().or(z.number()),
            text: z.string(),
          },{
            required_error: "La categoria es requerida",
            invalid_type_error: "La categoria es requerida",
          }),
          titulo: z.string({
            required_error: "La pregunta es requerida",
            invalid_type_error: "La pregunta es requerida",
          }).min(1, {
            required_error: "La pregunta es requerida",
            invalid_type_error: "La pregunta es requerida",
          }),
          descripcion:z.nullable(z.string()),
          tipo: z.string({
            required_error: "El tipo es requerido",
            invalid_type_error: "El tipo es requerido",
          }),
          // prioridad: null,
          ponderacion: z.nullable(z.string().or(z.number())),
          no_pregunta: z.string().or(z.number()),
          obligatorio: z.boolean(),
          saltos: z.array(z.object({
            en_respuesta: z.string().or(z.number()),
            ir_a: z.string().or(z.number()),
          })),
          etiquetas: z.object({
            inicial: z.nullable(z.string().or(z.number())),
            final: z.nullable(z.string().or(z.number())),
          }),
          unidad: z.nullable(z.string().or(z.number())),
          invertir: z.nullable(z.boolean()),
          preset_opciones: z.object({
            no_aplica: z.nullable(z.boolean()),
            otro: z.nullable(z.boolean()),
          }),
          opciones: z.array(z.object({
            opcion: z.nullable(z.string({
              required_error: "El nombre de la opción es requerida",
              invalid_type_error: "El nombre de la opción es requerida",
            }).or(z.number())),
            ponderacion: z.nullable(z.string({
              required_error: "La ponderación de la opción es requerida",
              invalid_type_error: "La ponderación de la opción es requerida",
            }).or(z.number())),
          })),
          ver_resultados: z.boolean(),
          ver_encuesta: z.boolean()
            
        })
        // .refine((scheme) => {  return scheme.tipo === "Binaria" ? !isNaN(parseFloat(scheme.ponderacion) ): true}, {
        //   message: "La ponderación es requerida",
        //   path: ["ponderacion"], // path of error
        // })
        .refine((scheme) => { return scheme.tipo !== "Multiple" ? !isNaN(parseFloat(scheme.ponderacion) ): true}, {
          message: "La ponderación es requerida",
          path: ["ponderacion"], // path of error
        })
        .refine((scheme) => {  return scheme.tipo === "Multiple" ? scheme.opciones.length > 0: true}, {
          message: "Debes agregar opciones",
          path: ["tipo"], // path of error
        })
        .refine((scheme) => { return scheme.tipo === "Numerica" ? !!scheme.unidad: true}, {
          message: "La unidad es requerida",
          path: ["unidad"], // path of error
        })
        .refine((scheme) => { return scheme.tipo === "Escala" ? !!scheme.etiquetas.inicial: true}, {
          message: "La etiqueta es requerida",
          path: ["etiquetas.inicial"], // path of error
        })
        .refine((scheme) => { return scheme.tipo === "Escala" ? !!scheme.etiquetas.final: true}, {
          message: "La etiqueta es requerida",
          path: ["etiquetas.final"], // path of error
        })
    ).min(1)
});

export const ResolverSurveyCreateSchema = zodResolver(RecordSchema);