import MainComponent from "./Update";
import { getModule } from "./../../../store/modules/businessBranches.module";
import React from 'react';

import { DynamicModuleLoader } from "redux-dynamic-modules";

export default class NewUserDialog extends React.Component {
     render() {
        return (
            <DynamicModuleLoader modules={[getModule()]}>
                <MainComponent />
            </DynamicModuleLoader>
        );
    }
}