import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import service from "../services/visits";
import {success, failure} from "../utils/api_responses";
import {convertArrayToObject} from "../utils/helpers";
import { visits as actions } from '../store/actions/modules';
import { snackbarActions as snackbar} from '../store/actions';

export default function* root() {
  yield all([
    fork(watcherDetail),
    fork(watcherUpdate),
    fork(watcherPayment),
    fork(watcherSavePayment),
    fork(watcherAnswers),
    fork(watcherUpdateAnswers),
    fork(watcherApproved),
    fork(watcherUploadImage),
    fork(watcherRemoveImage),
  ])
}

function* watcherDetail() {
  yield takeEvery("[MODULE][VISITS] DETAIL", workerDetail);
}

function* watcherUpdate() {
  yield takeEvery("[MODULE][VISITS][UPDATE] REQUEST", workerUpdate);
}

function* watcherPayment() {
  yield takeEvery("[MODULE][VISITS] PAYMENT", workerPayment);
}

function* watcherSavePayment() {
  yield takeEvery("[MODULE][VISITS][SAVE_PAYMENT] REQUEST", workerSavePayment);
}

function* watcherAnswers() {
  yield takeEvery("[MODULE][VISITS] ANSWERS", workerAnswers);
}

function* watcherUpdateAnswers() {
  yield takeEvery("[MODULE][VISITS][UPDATE_ANSWERS] REQUEST", workerUpdateAnswers);
}

function* watcherApproved() {
  yield takeEvery("[MODULE][VISITS] APPROVED", workerApproved);
}

function* watcherUploadImage() {
  yield takeEvery("[MODULE][VISITS] UPLOAD_IMAGE", workerUploadImage);
}

function* watcherRemoveImage() {
  yield takeEvery(actions.REMOVE_IMAGE, workerRemoveImage);
}



function* workerDetail(action){
  try {
    yield put({ type: "[MODULE][VISITS] RESET" });
    yield put({ type: "[MODULE][VISITS][ELEMENT] REQUEST" });
    const element = yield call(getElement, action.payload);
    const data = getterVisit(element);
    yield put({ type: "[MODULE][VISITS][ELEMENT] SUCCESS", payload: data });
  } catch (e) {
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][VISITS][ELEMENT] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerUpdate(action) {
  try {
    //element
    const data = setterVisit(action.payload);
    const response = yield call(updateVisit, data);
    yield put({ type: "[MODULE][VISITS][UPDATE] SUCCESS", payload: response });
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:  response,
      severity:'success'
    }});
  } catch (e) {
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: "[SNACKBAR] OPEN", payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: "[MODULE][VISITS][UPDATE] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerPayment(action){
  try {
    yield put({ type: "[MODULE][VISITS] RESET" });
    yield put({ type: "[MODULE][VISITS][PAYMENT] REQUEST" });
    const element = yield call(getPayment, action.payload);
    yield put({ type: "[MODULE][VISITS][PAYMENT] SUCCESS", payload: element });
  } catch (e) {
    console.log(e);
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][VISITS][PAYMENT] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerSavePayment(action) {
  try {
    //element
    const data = setterPayment(action.payload);
    const response = yield call(savePayment, data);
    yield put({ type: "[MODULE][VISITS][SAVE_PAYMENT] SUCCESS", payload: response });
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:  response,
      severity:'success'
    }});
  } catch (e) {
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: "[SNACKBAR] OPEN", payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: "[MODULE][VISITS][SAVE_PAYMENT] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerAnswers(action){
  try {
    yield put({ type: "[MODULE][VISITS] RESET" });
    yield put({ type: "[MODULE][VISITS][ELEMENT] REQUEST" });
    const element = yield call(getAnswers, action.payload);
    const data = mutatorAnswers(element);
    yield put({ type: "[MODULE][VISITS][ELEMENT] SUCCESS", payload: data });
  } catch (e) {
    console.log(e);
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][VISITS][ELEMENT] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerUpdateAnswers(action) {
  try {
    //element
    var answers = action.payload.data.answers;
    const images = action.payload.data.images;
    for (const key in images) {
      if (Object.hasOwnProperty.call(images, key)) {
        const _images = images[key].images;
        const values = setterImages({sku: action.payload.sku, data: answers, key: key, images: _images});
        const response = yield call(uploadImage, values);
        const question_images = response.map( (elem, index) =>{ return {
          titulo: null,
          url: elem.image,
          ver_resultados: true
         }
        });
        delete answers[key].images;
        const old_answers = (answers[key].respuesta && answers[key].respuesta.length >0 )? answers[key].respuesta : []; 
        answers = {...answers, [key]: {...answers[key],respuesta:[...old_answers, ...question_images]}};
      }
    }
    const data = setterAnswers({sku: action.payload.sku, data: answers });
    console.log(data);
    const response = yield call(updateAnswers, data);
    yield put({ type: "[MODULE][VISITS][UPDATE_ANSWERS] SUCCESS", payload: response });
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:  response,
      severity:'success'
    }});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: "[SNACKBAR] OPEN", payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: "[MODULE][VISITS][UPDATE_ANSWERS] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}


function* workerApproved(action) {
  try {
    //element
    yield put({ type: "[MODULE][VISITS][APPROVED] REQUEST" });
    const response = yield call(approved, action.payload);
    yield put({ type: "[MODULE][VISITS][APPROVED] SUCCESS", payload: response });
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:  response,
      severity:'success'
    }});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: "[SNACKBAR] OPEN", payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: "[MODULE][VISITS][APPROVED] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerUploadImage(action) {
  try {
    //element
    yield put({ type: "[MODULE][VISITS][UPLOAD_IMAGE] REQUEST" });
    const data = setterImages(action.payload);
    const response = yield call(uploadImage, data);
    yield put({ type: "[MODULE][VISITS][UPLOAD_IMAGE] SUCCESS", payload: response });
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:  response,
      severity:'success'
    }});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: "[SNACKBAR] OPEN", payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: "[MODULE][VISITS][UPLOAD_IMAGE] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerRemoveImage(action) {
  try {
    //element
    yield put({ type: actions.REMOVE_IMAGE_REQUEST });
    const response = yield call(removeImage, {data: action.payload});
    yield put({ type: actions.REMOVE_IMAGE_SUCCESS, payload: response });
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:  response,
      severity:'success'
    }});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.REMOVE_IMAGE_FAILURE, payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}




  function getElement( payload) {
    return new Promise( (resolve, reject) => {
      service.edit(payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(failure(e));
        });
      });
    }

  function getPayment( payload) {
    return new Promise( (resolve, reject) => {
      service.payment(payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(failure(e));
        });
    });
  }

  function savePayment( payload) {
    return new Promise( (resolve, reject) => {
      service.savePayment(payload)
        .then((response) => {
          resolve(success(response));
        })
        .catch((e) => {
          reject(failure(e));
        });
    });
  }

  function getAnswers( payload) {
    return new Promise( (resolve, reject) => {
      service.answers(payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(failure(e));
        });
    });
  }

  function updateAnswers( payload) {
    return new Promise( (resolve, reject) => {
      service.updateAnswers(payload)
        .then((response) => {
          resolve(success(response));
        })
        .catch((e) => {
          reject(failure(e));
        });
    });
  }

  function updateVisit(payload) {
    return new Promise( (resolve, reject) => {
      service.update(payload)
      .then((response) => {
        resolve(success(response));
      })
      .catch((e) => {
        reject(failure(e));
      });
    });
  }

  function approved(payload) {
    return new Promise( (resolve, reject) => {
      service.approvedSurvey(payload)
      .then((response) => {
        resolve(success(response));
      })
      .catch((e) => {
        reject(failure(e));
      });
    });
  }

  function uploadImage(payload) {
    return new Promise( (resolve, reject) => {
      service.uploadImage(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e));
      });
    });
  }

  function removeImage(payload) {
    return new Promise( (resolve, reject) => {
      service.removeImage(payload)
      .then((response) => {
        resolve(success(response));
      })
      .catch((e) => {
        reject(failure(e));
      });
    });
  }


function getterVisit(element){
  const shopper = element.shoppers.find( item => item.value === element.shopper_id );
  const data = {
    ...element,
    ...{
      shopper_id: shopper ? {text: shopper.text, value: shopper.value} : null,
    }
  };
  return data;
}


function setterVisit({sku, data}){
  const values = {
    date: data.date,
    hour: data.hour,
    shopper_id: data.shopper_id.value,
    status: data.status
  };
  return {sku:sku, data: values};
}

function setterPayment({sku, data}){
  const values = {
	  amount:data.amount,
    payment_date: data.payment_date,
    bank_reference: data.bank_reference,
    comments: data.comments
  };
  return {sku:sku, data: values};
}

function setterAnswers({sku, data}){
  try {
    return {sku:sku, data: {answers: {respuestas :Object.values(data) }}};
  } catch (error) {
    throw error;
  }
}

function setterImages({sku, data, key, images}){
  // try {
    const question_id = data[key];
    var new_images = new FormData();
    new_images.append('visit_id', sku);
    for (var i = 0; i < images.length; i++) {
      new_images.append(`images[${i}]`, images[i]);
    }
    new_images.append('question_id', question_id.id);
    return {data: new_images};
  // } catch (error) {
  //   throw error;
  // }
}

function mutatorAnswers(data){
  let questions = [];
  questions = data.questions_json.reduce((acc, d) => {
    if (Object.keys(acc).includes(d.categoria_id)) return acc;

    acc[d.categoria_id] = data.questions_json.filter(g => g.categoria_id === d.categoria_id);
    return acc;
    }, {});
  const answers = data.answers_json.respuestas ? data.answers_json.respuestas : data.answers_json;
  const answers_json = convertArrayToObject(answers,'id','question_')
  return {...data, answers_json: {answers: answers_json, images:{}}, questions_json: questions};
}
