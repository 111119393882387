import {diary as actions} from '../../actions/modules';

const initialState = {
  schedules: {
    list:[],
    loading: false,
    success: false
  }
};

const navbar = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.SCHEDULES_REQUEST:
    {
      return {
        ...state,
        schedules:{
          list: [],
          loading: true,
          success: false
        }
      }
    }
    case actions.SCHEDULES_SUCCESS:
    {
      return {
        ...state,
        schedules:{
          list: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.SCHEDULES_FAILURE:
    {
      return {
        ...state,
        schedules:{
          list: [],
          loading: false,
          success: false
        }
      }
    }
    case actions.CLEAR:
    {
      return initialState;
    }
    default:
    {
      return state;
    }
  }
};

export default navbar;