import {FloatingMenu as actions} from '../actions';

const initialState = {
  hidden: true,
  options:[]
};

const navbar = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.HIDE:
    {
      return {
        ...state,
        hidden: action.payload.hide
      }
    }
    case actions.SET_OPTIONS:
    {
      return {
        ...state,
        hidden: action.payload.hidden,
        options: action.payload.options,
      }
    }
    case actions.CLEAR:
    {
      return initialState;
    }
    default:
    {
      return state;
    }
  }
};

export default navbar;