
export const success = (response)=> {
  let message = "Acción exitosa";
  if(response.data){
    message = response.data.message?  response.data.message : "Acción exitosa";
  }
  if(response.message){
    message = response.message?  response.message : "Acción exitosa";
  }
  return message;
}

export const failure = (e)=> {
  let messages = [];
  if(e.response){
    if(e.response.data.errors){
      for (const key in e.response.data.errors) {
        if (Object.hasOwnProperty.call(e.response.data.errors, key)) {
          const error = e.response.data.errors[key];
          messages.push(error[0]);
        }
      }
    }else{
      messages.push(e.response.data.message?  e.response.data.message : "Oops, Se ha producido un error");
    }
  }else{
    messages.push("ops, Se ha producido un error");
  }
  return messages;
}