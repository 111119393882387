export const SCHEDULES = "[MODULE][DIARY] SCHEDULES";
export const SCHEDULES_REQUEST = "[MODULE][DIARY][SCHEDULES] REQUEST";
export const SCHEDULES_SUCCESS = "[MODULE][DIARY][SCHEDULES] SUCCESS";
export const SCHEDULES_FAILURE = "[MODULE][DIARY][SCHEDULES] FAILURE";

export const CLEAR = "[MODULE][DIARY] CLEAR";

export const schedules = (payload)=> {
  return { type: SCHEDULES, payload };
}

export const clear = ()=> {
  return { type: CLEAR  };
}