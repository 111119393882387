import mainSaga from '../../sagas/payable_accounts.saga';
import mainReducer from '../reducers/modules/payable_accounts.reducer';
import {payableAccount as actions} from '../actions/modules';

export function getModule() {
    return {
        id: "module_payable_accounts",
        reducerMap: {
          module_payable_accounts: mainReducer,
        },
        sagas: [mainSaga],
        finalActions: [actions.clear()],
    }; 
}