import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import data_service from "../../services/shoppers";
import api_address from "../../services/address_picker";

import {shoppers as actions} from '../../store/actions/external';
import {snackbarActions as snackbar} from '../../store/actions';
import {failure, success} from "../../utils/api_responses";


export default function* root() {
  yield all([
    fork(watcherStates),
    fork(watcherCreate),
    fork(watcherCities)
  ])
}

function* watcherStates() {
  yield takeEvery(actions.STATES, workerSates);
}

function* watcherCreate() {
  yield takeEvery(actions.CREATE, workerCreate);
}

function* watcherCities() {
  yield takeEvery(actions.CITIES, workerCities);
}


function* workerSates(action) {
  try {
    yield put({ type: actions.STATES_REQUEST });
    const states = yield call(getStates);
    yield put({ type: actions.STATES_SUCCESS ,payload: states});

  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.STATES_FAILURE, payload: e });
  }
}

function* workerCreate(action) {
  try {
    yield put({ type: actions.CREATE_REQUEST }); 
    const response = yield call(create, mutator(action.payload));
    yield put({ type: actions.CREATE_SUCCESS ,payload: response});

    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:  response,
      severity:'success'
    }});

  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.CREATE_FAILURE, payload: e });
  }
}

function* workerCities(action) {
  try {
    yield put({ type: actions.CITIES_REQUEST});
    const cities = yield call(getCities, action.payload);
    yield put({ type: actions.CITIES_SUCCESS,payload: cities});

  } catch (e) {
    console.log(e);
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.CITIES_FAILURE, payload: e });
  }
}

function getStates() {
  return new Promise( (resolve, reject) => {
    api_address.states()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function getCities({state}) {
  return new Promise( (resolve, reject) => {
    api_address.cities("MX", state)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function create(data) {
  return new Promise( (resolve, reject) => {
    data_service.createExternal(data)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function mutator({data}){
  const new_data = {
    data:{
      ...data,
      ...{
        status: "Pending",
        address_code : {
          state: data.address_state ? data.address_state.value: null,
          address_code: data.address_city ? data.address_city.value: null,
          city: data.address_city ? data.address_city.text : null
        }
      }
    }
  };
  return new_data;
}