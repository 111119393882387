import {customers as actions} from '../../actions/modules';

const initialState = {
  list: {
    data:[],
    loading: false,
    success: false
  },
  element: {
    data:{},
    loading: false,
    success: false
  },
  neighborhoods: {
    data:[],
    loading: false,
    success: false
  },
  neighborhood: {
    data:{
      city: {id: "", country_code: "", state_code: "", name: ""},
      country_code: "",
      id: "",
      name: "",
      state_code: "",
      city_id: "",
      zip_code: ""
    },
    loading: false,
    success: false
  },
  save: {
    response: {},
    loading: false,
    success: false
  },
};

const navbar = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.LIST_REQUEST:
    {
      return {
        ...state,
        list:{
          data: [],
          loading: true,
          success: false
        }
      }
    }
    case actions.LIST_SUCCESS:
    {
      return {
        ...state,
        list:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.LIST_FAILURE:
    {
      return {
        ...state,
        list:{
          data: [],
          loading: false,
          success: false
        }
      }
    }
    case actions.ELEMENT_REQUEST:
    {
      return {
        ...state,
        element:{
          data: [],
          loading: true,
          success: false
        }
      }
    }
    case actions.ELEMENT_SUCCESS:
    {
      return {
        ...state,
        element:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.ELEMENT_FAILURE:
    {
      return {
        ...state,
        element:{
          data: [],
          loading: false,
          success: false
        }
      }
    }
    case actions.SAVE_REQUEST:
    {
      return {
        ...state,
        save:{
          response: {},
          loading: true,
          success: false
        }
      }
    }
    case actions.SAVE_SUCCESS:
    {
      return {
        ...state,
        save:{
          response: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.SAVE_FAILURE:
    {
      return {
        ...state,
        save:{
          response: {},
          loading: false,
          success: false
        }
      }
    }
    case actions.NEIGHBORHOOD_REQUEST:
    {
      return {
        ...state,
        neighborhood:{
          data: {},
          loading: true,
          success: false
        }
      }
    }
    case actions.NEIGHBORHOOD_SUCCESS:
    {
      return {
        ...state,
        neighborhood:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.NEIGHBORHOOD_FAILURE:
    {
      return {
        ...state,
        neighborhood:{
          data: {},
          loading: false,
          success: false
        }
      }
    }
    case actions.NEIGHBORHOODS_REQUEST:
    {
      return {
        ...state,
        neighborhoods:{
          data: [],
          loading: true,
          success: false
        }
      }
    }
    case actions.NEIGHBORHOODS_SUCCESS:
    {
      return {
        ...state,
        neighborhoods:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.NEIGHBORHOODS_FAILURE:
    {
      return {
        ...state,
        neighborhoods:{
          data: [],
          loading: false,
          success: false
        }
      }
    }
    case actions.CLEAR:
    {
      return initialState;
    }
    default:
    {
      return state;
    }
  }
};

export default navbar;