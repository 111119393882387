import React, {Component} from 'react';
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button, 
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import FormData from './form';
import { Form } from 'react-final-form';
import { surveys } from '../../../../store/actions/external';
import { connect } from 'react-redux';
import { formValidation } from './validator';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
});

class UpdateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token:null,
    }
  }
  
  async componentDidMount(){
    const { token} = this.props.match.params;
    this.props.getModuleData({token:token});
    this.setState({token:token});
  }


  onSubmit = async values => {

  }


  render() {

    const { classes } = this.props;


    return (
        <Container className={styles['client-container']}>

          <Grid container spacing={2} direction="column">
            <Form
              validate={values => formValidation.validateForm(values)}
              // initialValues={this.props.element}
              onSubmit={this.onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container item spacing={3} sm={6} xs={12}>
                    {/* Title */}
                    <Grid item xs>
                      <Toolbar className={styles['toolbar']}>
                        <Typography variant="h4" id="tableTitle" component="div">
                          {this.props.element.title}
                          <Typography variant="caption" display="block" gutterBottom>
                            Encuesta de prueba 
                          </Typography> 
                        </Typography>
                      </Toolbar>
                    </Grid>
                    {/* Buttons */}
                    <Grid item container sm={6} xs={12} justifyContent={{ xs: 'center', sm: 'flex-end' }}>
                      <Grid item>
                        <Button
                          variant="contained"
                          size="small"
                          disabled={true}
                          className={classes.button}
                          type="submit"
                        >
                          Guardar
                        </Button>
                      </Grid>
                    </Grid>
                  </Grid>
                  {/* Form */}
                  <FormData/>
                  
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                  {/* <pre>{JSON.stringify(errors, 0, 2)}</pre> */}

                </form>
              )}
            />
          </Grid>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleData:(payload) =>{
      dispatch(surveys.preview(payload))
    }
  }
};


const mapStateToProps = (state) => {
  // console.log(state);
  return {
    status_element: state.external_surveys.element,
    element: state.external_surveys.element.data
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateComponent);


export default withRouter(withStyles(useStyles)(_component));
