import resultsSaga from '../../sagas/results.saga';
import resultsReducer from '../reducers/modules/results.reducer';
import {results as actions} from '../actions/modules';
import {FloatingMenu} from '../actions';

export function getModule() {
    return {
        id: "module_results",
        reducerMap: {
          module_results: resultsReducer,
        },
        sagas: [resultsSaga],
        finalActions: [actions.reset(), FloatingMenu.clear()],
    }; 
}