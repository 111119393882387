import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import service from "../../services/visits";
import service_catalogs from "../../services/lists";

import {surveys as actions} from '../../store/actions/external';
import {snackbarActions as snackbar} from '../../store/actions';
import {success, failure} from "../../utils/api_responses";

export default function* root() {
  yield all([
    fork(watcherPreview),
    fork(watcherDetail),
    fork(watcherSave),
  ])
}

function* watcherPreview() {
  yield takeEvery(actions.PREVIEW, workerPreview);
}

function* watcherDetail() {
  yield takeEvery(actions.DETAIL, workerDetail);
}

function* watcherSave() {
  yield takeEvery(actions.SAVE, workerSave);
}

function* workerPreview(action){
  try {
    yield put({ type: actions.RESET });
    //catalogos
    yield put({ type: actions.CATALOGS_REQUEST });
    
    const catalogs = yield call(getCatalogs, {element:"Categories"});
    yield put({ type: actions.CATALOGS_SUCCESS,payload: catalogs});
    
    yield put({ type: actions.ELEMENT_REQUEST });
    const element = yield call(getPreview, action.payload);
    const data = mutatorPreview(element);
    yield put({ type: actions.ELEMENT_SUCCESS, payload: data  });
  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.ELEMENT_FAILURE, payload: e });
    yield put({ type: actions.CATALOGS_FAILURE, payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerDetail(action){
  try {
    yield put({ type: actions.RESET });
    //catalogos
    yield put({ type: actions.CATALOGS_REQUEST });
    
    const catalogs = yield call(getCatalogs, {element:"Categories"});
    yield put({ type: actions.CATALOGS_SUCCESS,payload: catalogs});
    
    yield put({ type: actions.ELEMENT_REQUEST });
    const element = yield call(getDetail, action.payload);
    const data = mutatorPreview(element);
    yield put({ type: actions.ELEMENT_SUCCESS, payload: data  });
  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.ELEMENT_FAILURE, payload: e });
    yield put({ type: actions.CATALOGS_FAILURE, payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerSave(action) {
  try {
    //element
    yield put({ type:actions.SAVE_REQUEST});
    var answers = action.payload.data.answers;
    const images = action.payload.data.images;
    for (const key in images) {
      if (Object.hasOwnProperty.call(images, key)) {
        const _images = images[key].images;
        const values = setterImages({token: action.payload.token, data: answers, key: key, images: _images});
        const response = yield call(uploadImage, values);
        var question_images = response.map( (elem, index) =>{ return { 
          titulo: null,
          url: elem.image,
          ver_resultados: true
         } 
        });
        delete answers[key].images;
        answers = {...answers, [key]: {...answers[key],respuesta:question_images}};
      }
    }
    const data = setterAnswers({token: action.payload.token, data: answers });
    const response = yield call(saveAnswers, data);
    yield put({ type: actions.SAVE_SUCCESS, payload: response });
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:  response,
      severity:'success'
    }});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type:actions.SAVE_FAILURE, payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

  function getPreview( payload) {
    return new Promise( (resolve, reject) => {
      service.getExternalPreviewSurvey(payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(failure(e));
        });
      });
    }
  
  function getDetail( payload) {
    return new Promise( (resolve, reject) => {
      service.getExternalSurvey(payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(failure(e));
        });
      });
    }

  function getCatalogs( payload) {
    return new Promise( (resolve, reject) => {
      service_catalogs.catalogs(payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(failure(e));
        });
      });
    }

    function saveAnswers( payload) {
      return new Promise( (resolve, reject) => {
        service.externalSaveAnswers(payload)
          .then((response) => {
            resolve(success(response));
          })
          .catch((e) => {
            reject(failure(e));
          });
      });
    }
    
  
    function uploadImage(payload) {
      return new Promise( (resolve, reject) => {
        service.uploadImage(payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(failure(e));
        });
      });
    }


  function mutatorPreview(element){
    let questions = [];
    questions = element.json_questions.reduce((acc, d) => {
      if (Object.keys(acc).includes(d.categoria_id)) return acc;
  
      acc[d.categoria_id] = element.json_questions.filter(g => g.categoria_id === d.categoria_id); 
      return acc;
      }, {});
    const answers = element.json_questions.reduce( (obj, item) => Object.assign(obj, { [`question_${item.id}`]: { id: item.id, respuesta:""} }), {});

    return {...element, json_questions: questions, answers:answers};
  }

  function setterAnswers({token, data}){
    try {
      return {token:token, data: {answers: {respuestas: Object.values(data)}}};
    } catch (error) {
      throw error;
    }
  }
  
  function setterImages({token, data, key, images}){
    // try {
      const question_id = data[key];
      var new_images = new FormData();
      new_images.append('token', token);
      for (var i = 0; i < images.length; i++) {
        new_images.append(`images[${i}]`, images[i]);
      }
      new_images.append('question_id', question_id.id);
      return {data: new_images};
    // } catch (error) {
    //   throw error;
    // }
  }