import http from '../utils/http-common';

const list = () => {
  return http.get("/customers");
};

const create = ({ data }) => {
  return http.post("/customers/store", data);
};

const detail = ({ slug }) => {
  return http.get(`/customers/${slug}/edit`);
};

const update = ({ slug, data }) => {
  return http.put(`/customers/${slug}/update`, data);
};

const remove = ({ slug }) => {
  return http.delete(`customers/${slug}/delete`);
};


const customersService = {
  list,
  detail,
  create,
  update,
  remove
};

export default customersService;