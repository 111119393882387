import { Validators } from '@lemoncode/fonk';
import { createFinalFormValidation } from '@lemoncode/fonk-final-form';

export const validationSchema = {
  field: {
    name: [Validators.required.validator],
    brand_id: [Validators.required.validator],
    neighborhood_id: [Validators.required.validator],
    street: [Validators.required.validator],
    ext_number: [Validators.required.validator],
    social_status: [Validators.required.validator],
    schedules: [
      {
        validator: Validators.required,
        customArgs: { 
            field: {
            days: [Validators.required],
            opening_time: [Validators.required],
            closing_time: [Validators.required],
          },
        },
      },
    ],
  }
};

export const formValidation = createFinalFormValidation(validationSchema);
