// action types
export const DATA_LIST_REQUEST = "[DATA_LIST] REQUEST";
export const DATA_LIST_SUCCESS = "[DATA_LIST] SUCCESS";
export const DATA_LIST_FAILURE = "[DATA_LIST] FAILURE";
export const DATA_LIST_RESET = "[DATA_LIST] RESET";


export const list = (payload)=> {
  return { type: DATA_LIST_REQUEST, payload:payload };
}

export const reset = ()=> {
  return { type: DATA_LIST_RESET };
}
