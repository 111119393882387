import React from 'react';
import {Container, Paper } from '@mui/material';
import { connect } from 'react-redux';
import { backdropActions, snackbarActions, dataList, apiCall } from '../../store/actions';
import data_service from "../../services/instructives";
import styles from './index.module.css';
import Table from '../../components/table';
import { withRouter } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmDialog from '../../components/dialog/Confirm';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';

class MainComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      dialog_open: false,
      element: null,
      record_slug:null,
      loading:false
    };
  }

  async componentDidMount() {
    this.initialLoad();
  }

  async initialLoad(){
    this.props.getList({service: data_service.list});
  }

  componentWillUnmount(){
    this.props.clearList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.api_call !== this.props.api_call) {
      if(this.props.api_call.success){
        this.initialLoad();
        this.setState({dialog_open: false, record_slug : null }); 
      }else{
        if(!this.props.api_call.loading){
          this.setState({dialog_open: false, record_slug : null }); 
        }
      }
    }
  }

  openDeleteConfirm(slug){
    this.setState({dialog_open: true, record_slug: slug});
  }
  
  deleteRecord(slug){
    this.props.deleteRecord({service: data_service.remove,slug:slug});
  }

  render() {

    const columns = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Nombre',sortable: true },
      { id: 'customer', numeric: false, disablePadding: true, label: 'Cliente',sortable: true },
      { id: 'bussiness_branch', numeric: false, disablePadding: true, label: 'Establecimiento',sortable: true }
    ];
    const maxWidth = false;
    return (
      <Container maxWidth={maxWidth} className={styles['client-container']}>
          {/* Dialog  */}
          <ConfirmDialog  
            open={this.state.dialog_open} 
            title={"Eliminar Instructivo"}
            loading={this.props.api_call.loading}
            confirm={() =>  this.deleteRecord(this.state.record_slug) } 
            cancel={() => this.setState({dialog_open: false, record_slug : null }) } 
            message={"¿Desea eliminar el siguiente registro?"}
            />
          {/* table */}
          <Paper elevation={1} className={styles.paper}>
            <Table
              title = {"Instructivos"}
              data = {this.props.data.elements}
              columns={columns}
              addAction ={(event) => {this.props.history.push("/instructives/create");}}
              actions={[
                {
                  icon: ContentCopyIcon,
                  tooltip: 'Copiar',
                  onClick: (event, row) => {this.props.history.push(`/instructives/${row.slug}/copy`)}
                },
                {
                  icon: InfoOutlinedIcon,
                  tooltip: 'Info',
                  onClick: (event, row) => {this.props.history.push(`/instructives/${row.slug}/detail`)}
                },
                {
                  icon: DeleteOutlineOutlinedIcon,
                  tooltip: 'Eliminar',
                  onClick: (event, row) => this.openDeleteConfirm(row.slug)
                },
                {
                  icon: EditOutlinedIcon,
                  tooltip: 'Editar',
                  onClick: (event, row) => {this.props.history.push(`/instructives/${row.slug}/update`)}
                }
              ]}
              loading={this.props.data.loading}
            />
          </Paper>
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    snackbarOpen: (payload) => {
      dispatch(snackbarActions.snackbarOpen(payload))
    },
    backdropOpen: () => {
      dispatch(backdropActions.backdropOpen())
    },
    backdropClose: () => {
      dispatch(backdropActions.backdropClose())
    },
    getList:(payload)=>{
      dispatch(dataList.list(payload));
    },
    clearList:()=>{
      dispatch(dataList.reset());
    },
    deleteRecord:(payload) =>{
      dispatch(apiCall.remove(payload))
    }
  }
};

const mapStateToProps = (state) => {
  return {
    data: state.data_list,
    api_call: state.api_call
  };
}


const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent);

export default withRouter(_component);