import React from 'react';
import {
  Container, 
  Grid,
  Toolbar,
  Typography,
  Button,
  IconButton,
  FormControl,
  FormLabel,
  FormGroup,
  Select,
  MenuItem,
  Checkbox,
  FormControlLabel,
  Paper,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { connect } from 'react-redux';
import { results } from '../../../store/actions/modules';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import ResultsComponent  from '../../../components/ResultsByBrand';
import { CopyToClipboard } from "react-copy-to-clipboard";
import {FloatingMenu} from '../../../store/actions';
//dialog


class MainComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      brand: null,
      survey: null,
      month:'',
      business_branches:[],
      dialog_open:false,
      copied: false
    };
  }

  async componentDidMount() {
    
    this.initialLoad();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.business_branches !== this.props.business_branches) {
      this.setState({business_branches: this.props.business_branches}); 
      // this.setState({business_branches: this.props.business_branches.map( (element,index) => { return element.value}) }); 
    }

    if (prevProps.results !== this.props.results) {
      this.setState({month:this.props.results.mes});
    }

    if (prevProps.share !== this.props.share) {
      if(this.props.share.success && this.props.share.element){
        this.setState({dialog_open:true});
      }
    }


  }

  initialLoad(){

    const {brand, survey} = this.props.match.params;
    this.setState({
      brand: brand,
      survey: survey
    });
    this.props.getModuleData({
      brand: brand,
      survey: survey
    });
  }

  getData(){
    this.props.getModuleData({
      brand: this.state.brand,
      survey: this.state.survey,
      month: this.state.month,
      data: this.state.business_branches
    });
  }

  componentWillUnmount(){
    this.props.clearModuleData();
    this.props.hideMenu();
  }

  hangleChangeMonth = (event) =>{
    const month = event.target.value;
    this.setState({month: month}, () => this.getData());
  }

  shareResults = (event) =>{
    this.props.getShare({
      brand: this.state.brand,
      survey: this.state.survey,
      month: this.state.month 
    });
  }

  handleChangeBusinessBranches = ({event,item}) => {
    const index = this.state.business_branches.findIndex( (element, x )=> element.bussiness_branch_id===item.bussiness_branch_id);
    var copy = [...this.state.business_branches];
    if (index > -1) {
      copy.splice(index, 1);
    }else{
      copy.push(item);
    }
    this.setState({ business_branches: copy }, () => this.getData());
  };

  render() {
    const buttonSx = {
      ...(this.props.status_results.loading && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    const maxWidth = false;


    return (
      <Container maxWidth={maxWidth} className={styles['client-container']}>
        
        
        <Dialog open={this.state.dialog_open} onClose={()=>{this.setState({dialog_open:false})}}>
            <DialogTitle>Compartir resultados</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Copia el siguiente enlace para compartir los resultados del estudio.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="Enlance"
                type="text"
                fullWidth
                id="shared_link"
                variant="standard"
                value={`${window.location.protocol}//${window.location.hostname}/external/results/byBrand/${this.props.share.element}-${this.state.business_branches.map( item => item.bussiness_branch_id ).join('-')}`}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.setState({dialog_open:false})}}>Cancel</Button>
              <CopyToClipboard text={`${window.location.protocol}//${window.location.hostname}/external/results/byBrand/${this.props.share.element}-${this.state.business_branches.map( item => item.bussiness_branch_id ).join('-')}`}
                onCopy={() => this.setState({copied: true})}>
                <Button onClick={()=>{this.setState({dialog_open:false})}}>Copiar</Button>
              </CopyToClipboard>
            </DialogActions>
        </Dialog>

        <Grid container spacing={2} direction="column">
          

              <Grid container item spacing={3} justifyContent="flex-end">
                {/* Title */}
                <Grid item xs>
                  <Toolbar className={styles['toolbar']}>
                    <IconButton onClick={ () => this.props.history.push(`/results`) }>
                      <ChevronLeftIcon />
                    </IconButton>
                    <Typography variant="h4" id="tableTitle" component="div">
                      {this.props.results.marca}
                    </Typography>
                  </Toolbar>
                </Grid>
                {/* Buttons */}
                
                <Grid item>
                  <FormControl label="Periodo" fullWidth size="small">
                    <Select  
                    disabled={this.props.status_results.loading}
                    value={this.state.month} onChange={event =>this.hangleChangeMonth(event)}>
                    {this.props.results.meses.map((option, index) => (
                        <MenuItem key={index} value={option.id}>{option.mes}</MenuItem>
                      ))}
                    </Select>
                </FormControl>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    sx={buttonSx}
                    onClick={event =>this.shareResults(event)}
                    // disabled={this.props.status_results.loading || this.props.status_element.loading}
                    disabled={this.props.status_results.loading || this.props.share.loading}
                    type="submit"
                  >
                    Compartir
                    { (this.props.status_results.loading || this.props.share.loading )&& (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: green[500],
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>

              <Grid item >
                <Paper elevation={0} sx={{padding: '16px',display: 'flex',overflow: 'auto',flexDirection: 'column'}}>
                  <FormControl component="fieldset">
                  <FormLabel component="legend">Establecimientos</FormLabel>
                    <FormGroup aria-label="position" row>
                    {
                      this.props.business_branches.map( (element,index) => { 
                        return (
                          <FormControlLabel
                            key={index}
                            disabled={this.props.status_results.loading}
                            control={
                              <Checkbox checked={Boolean(this.state.business_branches.find( (item, x) => item.bussiness_branch_id===element.bussiness_branch_id))} onChange={ (event) =>this.handleChangeBusinessBranches({event:event, item:element})} name="est[]" />
                            }
                            label={element.name}
                          />
                        )
                      })
                    }
                    </FormGroup>
                  </FormControl>
                </Paper>
              </Grid>


              {/* Form */}
        </Grid>

        <Grid item xs>
          <Toolbar className={styles['toolbar']}>
            <Typography variant="h4" id="tableTitle" component="div">
              {'PRESENTACIÓN DE RESULTADOS'}
              <Typography variant="caption" display="block" gutterBottom>
              {`El siguiente reporte muestra los resultados del estudio realizado a los restaurantes de ${this.props.results.marca} durante el mes ${this.props.results.mes}`}
              </Typography>
            </Typography>
          </Toolbar>
        </Grid>

        <Grid item >
          <ResultsComponent
            global_results={this.props.results.resultados_globales}
            catalog_results={this.props.results.resultados_categorias}
            multiple_results={this.props.results.resultados_multiple}
            catalogs={this.props.results.categorias}
            business_branches={this.props.results.establecimientos}
            loading={this.props.status_results.loading}
          />

          
        </Grid>

        


      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleData:(payload)=>{
      dispatch(results.byBrand(payload));
    },
    getList:()=>{
      dispatch(results.brands());
    },
    clearModuleData:()=>{
      dispatch(results.reset());
    },
    hideMenu:()=>{
      dispatch(FloatingMenu.clear());
    },
    getShare:(payload)=>{
      dispatch(results.shareByBrand(payload));
    }
  }
};

const mapStateToProps = (state) => {
  return {
    business_branches: state.module_results.business_branches.list,
    status_business_branches: state.module_results.business_branches,
    status_list: state.module_results.brands_list,
    results: state.module_results.results_by_brand.element,
    status_results: state.module_results.results_by_brand,
    share: state.module_results.share,
  };
}


const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent);

export default withRouter(_component);