// action types
export const RESET = "[MODULE][CONTRACT_VISITS] RESET";
//element actions
export const ELEMENT_REQUEST = "[MODULE][CONTRACT_VISITS][ELEMENT] REQUEST";
export const ELEMENT_SUCCESS = "[MODULE][CONTRACT_VISITS][ELEMENT] SUCCESS";
export const ELEMENT_FAILURE = "[MODULE][CONTRACT_VISITS][ELEMENT] FAILURE";
//catalogs actions
export const SHOPPERS_REQUEST = "[MODULE][CONTRACT_VISITS][SHOPPERS] REQUEST";
export const SHOPPERS_SUCCESS = "[MODULE][CONTRACT_VISITS][SHOPPERS] SUCCESS";
export const SHOPPERS_FAILURE = "[MODULE][CONTRACT_VISITS][SHOPPERS] FAILURE";
//business actions
export const BUSINESS_BRANCHES_REQUEST = "[MODULE][CONTRACT_VISITS][BUSINESS_BRANCHES] REQUEST";
export const BUSINESS_BRANCHES_SUCCESS = "[MODULE][CONTRACT_VISITS][BUSINESS_BRANCHES] SUCCESS";
export const BUSINESS_BRANCHES_FAILURE = "[MODULE][CONTRACT_VISITS][BUSINESS_BRANCHES] FAILURE";
//states actions
export const STATES_REQUEST = "[MODULE][CONTRACT_VISITS][STATES] REQUEST";
export const STATES_SUCCESS = "[MODULE][CONTRACT_VISITS][STATES] SUCCESS";
export const STATES_FAILURE = "[MODULE][CONTRACT_VISITS][STATES] FAILURE";
//module actions
export const DETAIL = "[MODULE][CONTRACT_VISITS] DETAIL";
export const CREATE = "[MODULE][CONTRACT_VISITS] CREATE";
export const UPDATE = "[MODULE][CONTRACT_VISITS] UPDATE";
export const RESEND = "[MODULE][CONTRACT_VISITS] RESEND";
export const INFO = "[MODULE][CONTRACT_VISITS] INFO";

export const SEND_INVITATION = "[MODULE][CONTRACT_VISITS] SEND_INVITATION";

export const SEND_INVITATION_REQUEST = "[MODULE][CONTRACT_VISITS][SEND_INVITATION] REQUEST";
export const SEND_INVITATION_SUCCESS = "[MODULE][CONTRACT_VISITS][SEND_INVITATION] SUCCESS";
export const SEND_INVITATION_FAILURE = "[MODULE][CONTRACT_VISITS][SEND_INVITATION] FAILURE";
//data list actions
export const LIST_REQUEST = "[MODULE][CONTRACT_VISITS][LIST] REQUEST";
export const LIST_SUCCESS = "[MODULE][CONTRACT_VISITS][LIST] SUCCESS";
export const LIST_FAILURE = "[MODULE][CONTRACT_VISITS][LIST] FAILURE";

export const RESEND_REQUEST = "[MODULE][CONTRACT_VISITS][RESEND] REQUEST";
export const RESEND_SUCCESS = "[MODULE][CONTRACT_VISITS][RESEND] SUCCESS";
export const RESEND_FAILURE = "[MODULE][CONTRACT_VISITS][RESEND] FAILURE";

export const INFO_REQUEST = "[MODULE][CONTRACT_VISITS][INFO] REQUEST";
export const INFO_SUCCESS = "[MODULE][CONTRACT_VISITS][INFO] SUCCESS";
export const INFO_FAILURE = "[MODULE][CONTRACT_VISITS][INFO] FAILURE";

export const list = (payload)=> {
  return { type: LIST_REQUEST, payload };
}

export const update = (payload)=> {
  return { type: UPDATE, payload };
}

export const create = ()=> {
  return { type: CREATE };
}

export const moduleSendInvitation = ()=> {
  return { type: SEND_INVITATION };
}

export const sendInvitation = (payload)=> {
  return { type: SEND_INVITATION_REQUEST, payload};
}

export const detail = (payload)=> {
  return { type: DETAIL, payload};
}

export const reset = ()=> {
  return { type: RESET };
}

export const shoppers = (payload )=> {
  return { type: SHOPPERS_REQUEST,payload };
}

export const businessBranches = (payload )=> {
  return { type: BUSINESS_BRANCHES_REQUEST,payload };
}

export const states = (payload )=> {
  return { type: STATES_REQUEST,payload };
}

export const resend = (payload)=> {
  return { type: RESEND, payload };
}

export const info = (payload)=> {
  return { type: INFO, payload };
}
