import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import lists_service from "../services/lists";
import address_service from "../services/address_picker";
import service from "../services/visits";
import {success, failure} from "../utils/api_responses";
import {contract_visits as actions} from '../store/actions/modules';
import { snackbarActions } from "../store/actions";

export default function* root() {
  yield all([
    fork(watcherList),
    fork(watcherDetail),
    fork(watcherUpdate),
    fork(watcherCreate),
    fork(watcherShoppers),
    fork(watcherBusinessBranches),
    fork(watcherModuleSendInvitation),
    fork(watcherSendInvitation),
    fork(watcherResend),
    fork(watcherInfo),
  ])
}

function* watcherList() {
  yield takeEvery("[MODULE][CONTRACT_VISITS][LIST] REQUEST", workerList);
}

function* watcherDetail() {
  yield takeEvery("[MODULE][CONTRACT_VISITS] DETAIL", workerDetail);
}

function* watcherUpdate() {
  yield takeEvery("[MODULE][CONTRACT_VISITS] UPDATE", workerUpdate);
}

function* watcherCreate() {
  yield takeEvery("[MODULE][CONTRACT_VISITS] CREATE", workerCreate);
}

function* watcherShoppers() {
  yield takeEvery("[MODULE][CONTRACT_VISITS][SHOPPERS] REQUEST", workerShoppers);
}

function* watcherBusinessBranches() {
  yield takeEvery("[MODULE][CONTRACT_VISITS][BUSINESS_BRANCHES] REQUEST", workerBusinessBranches);
}

function* watcherModuleSendInvitation() {
  yield takeEvery("[MODULE][CONTRACT_VISITS] SEND_INVITATION", workerModuleSendInvitation);
}

function* watcherSendInvitation() {
  yield takeEvery("[MODULE][CONTRACT_VISITS][SEND_INVITATION] REQUEST", workerSendInvitation);
}

function* watcherResend() {
  yield takeEvery("[MODULE][CONTRACT_VISITS] RESEND", workerResend);
}

function* watcherInfo() {
  yield takeEvery(actions.INFO, workerInfo);
}

function* workerList(action) {
  try {
    const payload = yield call(getList);
    yield put({ type: "[MODULE][CONTRACT_VISITS][LIST] SUCCESS", payload: payload });
  } catch (e) {
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][CONTRACT_VISITS][LIST] FAILURE", payload: e });

    
  }
}


function* workerDetail(action){
  try {
    // yield put({ type: "[MODULE][CONTRACT_VISITS] RESET" });
    yield put({ type: actions.ELEMENT_REQUEST });
    //element
    const element = yield call(getElement, action.payload);
    yield put({ type: actions.ELEMENT_SUCCESS, payload: element });
  } catch (e) {
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.ELEMENT_FAILURE , payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerUpdate(action) {
  try {
    yield put({ type: "[MODULE][CONTRACT_VISITS] RESET" });
    yield put({ type: "[MODULE][CONTRACT_VISITS][ELEMENT] REQUEST" });
    //element
    const element = yield call(getElement, action.payload);
    //brands
    // yield put({ type: "[MODULE][CONTRACT_VISITS][BRANDS] REQUEST"});
    //business_branches
    // yield put({ type: "[MODULE][CONTRACT_VISITS][BUSINESS_BRANCHES] REQUEST"});
    const business_branches = yield call(getBusinessBranches,{slug:element.brand_id});
    yield put({ type: "[MODULE][CONTRACT_VISITS][BUSINESS_BRANCHES] SUCCESS",payload: business_branches});
    
    //customers
    yield put({ type: "[MODULE][CONTRACT_VISITS][CUSTOMERS] REQUEST" });
    const customers = yield call(getCustomers);
    yield put({ type: "[MODULE][CONTRACT_VISITS][CUSTOMERS] SUCCESS",payload: customers});
   
    const data = mutator(customers,business_branches,element);
    yield put({ type: "[MODULE][CONTRACT_VISITS][ELEMENT] SUCCESS", payload: data });
  } catch (e) {
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][CONTRACT_VISITS][CUSTOMERS] FAILURE", payload: e });
    yield put({ type: "[MODULE][CONTRACT_VISITS][BUSINESS_BRANCHES] FAILURE", payload: e });
    yield put({ type: "[MODULE][CONTRACT_VISITS][BRANDS] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerCreate() {
  try {
    yield put({ type: "[MODULE][CONTRACT_VISITS] RESET" });
    //customers
    yield put({ type: "[MODULE][CONTRACT_VISITS][CUSTOMERS] REQUEST" });
    const customers = yield call(getCustomers);
    yield put({ type: "[MODULE][CONTRACT_VISITS][CUSTOMERS] SUCCESS",payload: customers});
    
  } catch (e) {
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][CONTRACT_VISITS][CUSTOMERS] FAILURE", payload: e });
  }
}

function* workerShoppers(action) {
  try {
    // yield put({ type: "[MODULE][CONTRACT_VISITS][BRANDS] REQUEST" });
    const shoppers = yield call(getShoppers, action.payload);
    yield put({ type: "[MODULE][CONTRACT_VISITS][SHOPPERS] SUCCESS",payload: shoppers});
  } catch (e) {
    console.log(e);
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][CONTRACT_VISITS][SHOPPERS] FAILURE", payload: e });
  }
}

function* workerBusinessBranches(action) {
  try {
    // yield put({ type: "[MODULE][CONTRACT_VISITS][BUSINESS_BRANCHES] REQUEST" });
    const neighborhoods = yield call(getBusinessBranches, action.payload);
    yield put({ type: "[MODULE][CONTRACT_VISITS][BUSINESS_BRANCHES] SUCCESS",payload: neighborhoods});
  } catch (e) {
    console.log(e);
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][CONTRACT_VISITS][BUSINESS_BRANCHES] FAILURE", payload: e });
  }
}

function* workerModuleSendInvitation(action) {
  try {
    yield put({ type: "[MODULE][CONTRACT_VISITS][STATES] REQUEST" });
    const states = yield call(getStates);
    yield put({ type: "[MODULE][CONTRACT_VISITS][STATES] SUCCESS",payload: states});
  } catch (e) {
    console.log(e);
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][CONTRACT_VISITS][STATES] FAILURE", payload: e });
  }
}

function* workerSendInvitation(action) {
  try {
    const response = yield call(sendInvitation, action.payload);

    yield put({ type: "[MODULE][CONTRACT_VISITS][SEND_INVITATION] SUCCESS",payload: response});
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:  response,
      severity:'success'
    } });
    
  } catch (e) {
    console.log(e);
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][CONTRACT_VISITS][SEND_INVITATION] FAILURE", payload: e });
  }
}

function* workerResend(action) {
  try {
    //element
    yield put({ type: "[MODULE][CONTRACT_VISITS][RESEND] REQUEST" });
    const response = yield call(resend, action.payload); 
    yield put({ type: "[MODULE][CONTRACT_VISITS][RESEND] SUCCESS", payload: response });
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:  response,
      severity:'success'
    }});
  } catch (e) {
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: "[SNACKBAR] OPEN", payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: "[MODULE][CONTRACT_VISITS][RESEND] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerInfo(action){
  try {
    yield put({ type: actions.INFO_REQUEST });
    //element
    const element = yield call(getInfo, action.payload);
    yield put({ type: actions.INFO_SUCCESS, payload: getterInfo(element) });
  } catch (e) {
    yield put({ type: snackbarActions.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.INFO_FAILURE , payload: e });
  } finally{
    
  }
}



function getList() {
  return new Promise( (resolve, reject) => {
    service.list()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getElement( payload) {
  return new Promise( (resolve, reject) => {
    service.detail(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getInfo( payload) {
  return new Promise( (resolve, reject) => {
    service.info(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getCustomers() {
  return new Promise( (resolve, reject) => {
    lists_service.customers()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getShoppers(payload) {
  return new Promise( (resolve, reject) => {
    service.shopperBussinessByState(payload)
      .then((response) => {
        resolve(response.data.shoppers);
      })
      .catch((e) => {
        reject(e)
      });
  });
}


function getBusinessBranches(payload) {
  return new Promise( (resolve, reject) => {
    service.businessBranchesByState(payload) 
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e) 
      });
  });
}

function getStates() {
  return new Promise( (resolve, reject) => {
    address_service.states("MX") 
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function sendInvitation(payload) {
  return new Promise( (resolve, reject) => {
    service.sendInvitation(payload) 
      .then((response) => {
        resolve(success(response));
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function resend(payload) {
  return new Promise( (resolve, reject) => {
    service.resend(payload)
    .then((response) => {
      resolve(success(response));
    })
    .catch((e) => {
      reject(failure(e));
    });
  });
}


function mutator(customers,brands,business_branches,element){

  const customer = customers.find( item => item.value === element.customer_id );
  const bussiness_branch = business_branches.find( item => item.value === element.bussiness_branch_id );
  const brand = brands.find( item => item.value === element.brand_id );

  const data = {
    ...element, 
    ...{
      customer_id: customer ? {text: customer.text, value: customer.value} : null,
      bussiness_branch_id: bussiness_branch ? {text: bussiness_branch.text, value: bussiness_branch.value} : null,
      brand_id: brand ? {text: brand.text, value: brand.value} : null
    }
  };

  return data;
}


function getterInfo(data){
  return {
    ...data,
    default: data.diff > data.months ? 1 : (data.diff ? data.diff : 1),
    options: Array(data.months).fill().map((e,i) => {return {text: `mes ${i+1}`, value:(i+1)}})
  }
}