import Module from "./BusinessBranches";
import { getModule } from "../../store/modules/businessBranches.module";
import { DynamicModuleLoader } from "redux-dynamic-modules-react";
import React, {Component} from 'react';


class IndexComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      modules:[getModule()]
    }
  }

  componentDidMount(){
  }
  
  componentWillUnmount(){
    this.setState({modules:[]});
  }
  render() {
    return (
        <DynamicModuleLoader modules={this.state.modules}>
            <Module/>
        </DynamicModuleLoader>
    );
  }
}

export default IndexComponent;
