export const OPEN_BACKDROP = '[BACKDROP] OPEN';
export const CLOSE_BACKDROP = '[BACKDROP] CLOSE';

export const backdropOpen = () =>
{
  return {
    type: OPEN_BACKDROP
  }
}

export const backdropClose = () =>
{
  return {
    type: CLOSE_BACKDROP
  }
}