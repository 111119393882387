//actions module
export const LOGIN = '[AUTH] LOGIN';
export const LOGOUT = '[AUTH] LOGOUT';
export const CHECK_TOKEN = '[AUTH] CHECK_TOKEN';
export const USER = '[AUTH] USER';

//request actions
export const LOGIN_REQUEST = '[AUTH][LOGIN] REQUEST';
export const LOGIN_SUCCESS = '[AUTH][LOGIN] SUCCESS';
export const LOGIN_FAILURE = '[AUTH][LOGIN] FAILURE';

export const LOGOUT_REQUEST = '[AUTH][LOGOUT] REQUEST';
export const LOGOUT_SUCCESS = '[AUTH][LOGOUT] SUCCESS';
export const LOGOUT_FAILURE = '[AUTH][LOGOUT] FAILURE';

export const USER_REQUEST = '[AUTH][USER] REQUEST';
export const USER_SUCCESS = '[AUTH][USER] SUCCESS';
export const USER_FAILURE = '[AUTH][USER] FAILURE';


export const CLEAR = '[AUTH] CLEAR';
 
export const login = (payload) =>
{
  return {
    type: LOGIN,
    payload: payload
  }
}

export const logout = () =>
{
  return {
    type: LOGOUT,
  }
}

export const user = () =>
{
  return {
    type: USER,
  }
}

export const clear = () =>
{
  return {
    type: CLEAR
  }
}