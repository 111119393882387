import React, {Component} from 'react';
import {
  Paper,
  Grid,
  Typography,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import {AutoComplete} from '../../../components/inputs';
import { Field , FormSpy } from 'react-final-form';
import { shoppers } from '../../../store/actions/modules';
import { connect } from 'react-redux';
import { OnChange } from 'react-final-form-listeners';

const useStyles = (theme) => ({
  formControl: {
    minWidth: '100%',
  }
});

class Form extends Component {

  constructor(props) {
    super(props);

    this.state = {
      state:null,
      city:null,
      allowed:false
    }
  }

  onChangeHandlerState = (option) =>{
    this.setState({allowed:true});
    if(option){
      this.props.getCities({state: option.value});
    }
  }


  async componentDidMount() {
  }


  render() {

    return (
      <Grid item container spacing={3}>
        <Grid item lg={12}>
          <Typography variant="h6" id="tableTitle" component="div">
            Dirección
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">

          <Field
            required
            name="address_state"
            component={AutoComplete}
            label="Estado"
            type="text"
            options={this.props.states.data}
            loading={this.props.states.loading}
            waiting={this.props.waiting}
            onChange={(option) => this.onChangeHandlerState(option)}
          />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            name="address_city"
            component={AutoComplete}
            label="Municipio"
            type="text"
            options={this.props.cities.data}
            loading={this.props.cities.loading}
            waiting={this.props.waiting}
          />
          </Paper>
        </Grid>
        <FormSpy
          render={({ form, values }) => (
            <>
            <OnChange name="address_state">
              {value => {
                form.reset({
                  ...values,
                  address_city: this.props.cities.data.find( city => city.value === (values.address_city ? values.address_city.value: null) ) ? values.address_city : null,
                });
              }}
            </OnChange>
            </>
          )}
        />
      </Grid>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getCities:(payload) =>{
      dispatch(shoppers.cities(payload))
    }
  }
};

const mapStateToProps = (state) => {
  return {
    states: state.module_shoppers.states,
    cities: state.module_shoppers.cities
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(Form);

export default withStyles(useStyles)(_component);
