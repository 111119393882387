import http from '../utils/http-common';

const login = ({data}) => {
  return http.post("login", data);
};

const user = () =>{
  return http.get('getUser');
}

const logout = () => {
  return http.post('logout');
};

const service = {
  login,
  user,
  logout
};

export default service;