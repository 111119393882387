import contractsSaga from '../../sagas/contracts.saga';
import contractsReducer from '../reducers/modules/contracts.reducer';
import {contracts as actions} from '../actions/modules';

export function getContractsModule() {
    return {
        id: "module_contracts",
        reducerMap: {
          module_contracts: contractsReducer,
        },
        sagas: [contractsSaga],
        finalActions: [actions.reset()],
    }; 
}