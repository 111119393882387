// action types
export const RESET = "[EXTERNAL][SURVEYS] RESET";
export const SAVE = "[EXTERNAL][SURVEYS] SAVE";
//element actions
export const ELEMENT_REQUEST = "[EXTERNAL][SURVEYS][ELEMENT] REQUEST";
export const ELEMENT_SUCCESS = "[EXTERNAL][SURVEYS][ELEMENT] SUCCESS";
export const ELEMENT_FAILURE = "[EXTERNAL][SURVEYS][ELEMENT] FAILURE";
//module actions
//catalogs actions
export const CATALOGS_REQUEST = "[EXTERNAL][SURVEYS][CATALOGS] REQUEST";
export const CATALOGS_SUCCESS = "[EXTERNAL][SURVEYS][CATALOGS] SUCCESS";
export const CATALOGS_FAILURE = "[EXTERNAL][SURVEYS][CATALOGS] FAILURE";

export const SAVE_REQUEST = "[EXTERNAL][SURVEYS][SAVE] REQUEST";
export const SAVE_SUCCESS = "[EXTERNAL][SURVEYS][SAVE] SUCCESS";
export const SAVE_FAILURE = "[EXTERNAL][SURVEYS][SAVE] FAILURE";

export const PREVIEW = "[EXTERNAL][SURVEYS] PREVIEW";
export const DETAIL = "[EXTERNAL][SURVEYS] DETAIL";

export const preview = (payload)=> {
  return { type: PREVIEW, payload};
}

export const detail = (payload)=> {
  return { type: DETAIL, payload};
}

export const save = (payload)=> {
  return { type: SAVE, payload};
}

export const reset = ()=> {
  return { type: RESET };
}
