import React from 'react';
import { BrowserRouter as Router, Switch, Route, Redirect } from 'react-router-dom';
import { ThemeProvider, StyledEngineProvider } from '@mui/material/styles';
import Login from './pages/Login';
import Home from './pages/Home';
//clients
import Clients from './pages/Clients';
import ClientCreate from './pages/Clients/Create';
import ClientUpdate from './pages/Clients/Update';
import ClientRestore from './pages/Clients/Restore';
import ClientDetail from './pages/Clients/Detail';
//shopers
import Shoppers from './pages/Shoppers';
import ShopperCreate from './pages/Shoppers/Create';
import ShopperEdit from './pages/Shoppers/Edit';
import ShopperDetail from './pages/Shoppers/Detail';
//catalogs
import Catalogs from './pages/Catalogs';
import CatalogCreate from './pages/Catalogs/Create';
import CatalogUpdate from './pages/Catalogs/Update';
//packages
import Packages from './pages/Packages';
import PackageCreate from './pages/Packages/Create';
import PackageUpdate from './pages/Packages/Update';
import PackageDetail from './pages/Packages/Detail';
//customers
import Customers from './pages/Customers';
import CustomerCreate from './pages/Customers/Create';
import CustomerUpdate from './pages/Customers/Update';
//brands
import Brands from './pages/Brands';
import BrandCreate from './pages/Brands/Create';
import BrandUpdate from './pages/Brands/Update';
//Bussiness Branches
import BusinessBranches from './pages/BusinessBranches';
import BusinessBranchCreate from './pages/BusinessBranches/Create';
import BusinessBranchUpdate from './pages/BusinessBranches/Update';
import BusinessBranchDetail from './pages/BusinessBranches/Detail';
//Bussiness Branches
import Surveys from './pages/Surveys';
import SurveyCreate from './pages/Surveys/Create';
import SurveyUpdate from './pages/Surveys/Update';
import SurveyDetail from './pages/Surveys/Detail';
import SurveyCopy from './pages/Surveys/Copy';
//Contracts
import Contracts from './pages/Contracts';
import ContractCreate from './pages/Contracts/Create';
import ContractUpdate from './pages/Contracts/Update';
import ContractDetail from './pages/Contracts/Detail';
import ContractRenew from './pages/Contracts/Renew';
//Instructives
import Instructives from './pages/Instructives';
import InstructiveCreate from './pages/Instructives/Create';
import InstructiveUpdate from './pages/Instructives/Update';
import InstructiveDetail from './pages/Instructives/Detail';
import InstructiveRenew from './pages/Instructives/Copy';
//Visits
import Visits from './pages/Visits';
import SendInvitation from './pages/Visits/SendInvitation';
import VisitsContract from './pages/Visits/ContractVisits';
import VisitUpdate from './pages/Visits/Update';
import VisitPayment from './pages/Visits/Payment';
import VisitAnswers from './pages/Visits/Answers';

//external
import RegisterVisit from './pages/External/shoppers/registerVisit';
import PreviewSurvey from './pages/External/shoppers/previewSurvey';
import Survey from './pages/External/shoppers/Survey';
import CreateShopper from './pages/External/shoppers/Create';
import ExternalResultsByBrand from './pages/External/results/ByBrand';
import ExternalResultsByBusinessBranch from './pages/External/results/ByBusinessBranch';
import ExternalUserCreate from './pages/External/users/Create';

//Results
import Results from './pages/Results';
import ResultsByBrand from './pages/Results/ByBrand';
import ResultsByBusinessBranch from './pages/Results/ByBusinessBranch';

//Diary
import Diary from './pages/Diary';

// Payable accounts
import PayableAccounts  from './pages/PayableAccounts';
//test component
import TestComponent from './pages/test';
import  NotFound  from './pages/Errors/Error404';
import theme from './configs/theme';
import { Provider } from "react-redux";
import store from './store';
//layouts
import MainRoute from './routes/main';
import AuthRoute from './routes/auth';
import ExternalRouter from './routes/external';
import { Validators } from '@lemoncode/fonk';

function App() {

  Validators.required.setErrorMessage('Este campo es obligatorio');
  Validators.email.setErrorMessage('Debe de tener un formato de correo valido');
  Validators.minLength.setErrorMessage('El valor proporcionado no cumple con la longitud mínima.');
  Validators.maxLength.setErrorMessage('El valor proporcionado no cumple con la longitud máxima.');

  return (
    <div className="App">
    <Provider store={store}>
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={theme}>
          <Router>
            <Switch>
              <Route exact path="/"><Redirect to="/results" /></Route>  
              <AuthRoute exact component={Login} path="/login" />
              <MainRoute exact component={Home} path="/home"/>
              <Route exact component={TestComponent} path="/test"/>
              {/* clientes routes */}
              <MainRoute exact component={Clients} path="/clients"/>
              <MainRoute exact component={ClientCreate} path="/clients/create"/>
              <MainRoute exact component={ClientUpdate} path="/clients/:slug/update"/>
              <MainRoute exact component={ClientRestore} path="/clients/:slug/restore"/>
              <MainRoute exact component={ClientDetail} path="/clients/:slug/detail"/>
              {/* Shoppers routers */}
              <MainRoute exact component={Shoppers} path='/shoppers'/>
              <MainRoute exact component={ShopperCreate} path='/shoppers/create'/>
              <MainRoute exact component={ShopperEdit} path='/shoppers/:slug/update'/>
              <MainRoute exact component={ShopperDetail} path='/shoppers/:slug/detail'/>
              {/* Catalogs */}
              <MainRoute exact component={Catalogs} path='/catalogs'/>
              <MainRoute exact component={CatalogCreate} path='/catalogs/create'/>
              <MainRoute exact component={CatalogUpdate} path='/catalogs/:slug/update'/>
              {/* Packages */}
              <MainRoute exact component={Packages} path='/packages'/>
              <MainRoute exact component={PackageCreate} path='/packages/create'/>
              <MainRoute exact component={PackageUpdate} path='/packages/:slug/update'/>
              <MainRoute exact component={PackageDetail} path='/packages/:slug/detail'/>
              {/* Customers */}
              <MainRoute exact component={Customers} path='/customers'/>
              <MainRoute exact component={CustomerCreate} path='/customers/create'/>
              <MainRoute exact component={CustomerUpdate} path='/customers/:slug/update'/>
              {/* Brands */}
              <MainRoute exact component={Brands} path='/brands'/>
              <MainRoute exact component={BrandCreate} path='/brands/create'/>
              <MainRoute exact component={BrandUpdate} path='/brands/:slug/update'/>
              {/* Business Branches */}
              <MainRoute exact component={BusinessBranches} path='/businessBranches'/>
              <MainRoute exact component={BusinessBranchCreate} path='/businessBranches/create'/>
              <MainRoute exact component={BusinessBranchUpdate} path='/businessBranches/:slug/update'/>
              <MainRoute exact component={BusinessBranchDetail} path='/businessBranches/:slug/detail'/>
              {/* Surveys */}
              <MainRoute exact component={Surveys} path='/surveys'/>
              <MainRoute exact component={SurveyCreate} path='/surveys/create'/>
              <MainRoute exact component={SurveyUpdate} path='/surveys/:slug/update'/>
              <MainRoute exact component={SurveyDetail} path='/surveys/:slug/detail'/>
              <MainRoute exact component={SurveyCopy} path='/surveys/:slug/copy'/>
              {/* Contracts **/}
              <MainRoute exact component={Contracts} path='/contracts'/>
              <MainRoute exact component={ContractCreate} path='/contracts/create'/>
              <MainRoute exact component={ContractUpdate} path='/contracts/:slug/update'/>
              <MainRoute exact component={ContractDetail} path='/contracts/:slug/detail'/>
              <MainRoute exact component={ContractRenew} path='/contracts/:slug/renew'/>
              {/* Instructives **/}
              <MainRoute exact component={Instructives} path='/instructives'/>
              <MainRoute exact component={InstructiveCreate} path='/instructives/create'/>
              <MainRoute exact component={InstructiveUpdate} path='/instructives/:slug/update'/>
              <MainRoute exact component={InstructiveDetail} path='/instructives/:slug/detail'/>
              <MainRoute exact component={InstructiveRenew} path='/instructives/:slug/copy'/>
              {/* Visits */}
              {/* Not found route */}
              <MainRoute exact component={Visits} path='/visits'/>
              <MainRoute exact component={SendInvitation} path='/visits/invitation'/>
              <MainRoute exact component={VisitsContract} path='/visits/:sku/contractVisits'/>
              <MainRoute exact component={VisitUpdate} path='/visits/:sku_contract/contractVisits/:sku_visit/update'/>
              <MainRoute exact component={VisitPayment} path='/visits/:sku_contract/contractVisits/:sku_visit/payment'/>
              <MainRoute exact component={VisitAnswers} path='/visits/:sku_contract/contractVisits/:sku_visit/answers'/>
              {/* External routes */}
              <ExternalRouter exact component={RegisterVisit} path='/external/shoppers/registerVisit/:token'/>
              <ExternalRouter exact component={PreviewSurvey} path='/external/shoppers/previewSurvey/:token'/>
              <ExternalRouter exact component={Survey} path='/external/shoppers/survey/:token'/>
              <ExternalRouter exact component={CreateShopper} path='/external/shoppers/create'/>
              <ExternalRouter exact component={ExternalUserCreate} path='/external/users/create'/>
              <ExternalRouter exact component={ExternalResultsByBrand} path='/external/results/byBrand/:token'/>
              <ExternalRouter exact component={ExternalResultsByBusinessBranch} path='/external/results/byBusinessBranch/:token'/>
              {/* Results routes */}
              <MainRoute exact component={Results} path='/results'/>
              <MainRoute exact component={ResultsByBrand} path='/results/byBrand/:brand/:survey'/>
              <MainRoute exact component={ResultsByBusinessBranch} path='/results/byBusinessBranch/:contract'/>
              {/* Diary Routes */}
              <MainRoute exact component={Diary} path='/diary'/>
              {/** Payable Accounnts */}
              <MainRoute exact component={PayableAccounts} path='/payableAccounts'/>

              <Route path='*' component={NotFound}/>
            </Switch>
          </Router>
        </ThemeProvider>
      </StyledEngineProvider> 
    </Provider>
    </div>
  );
}

export default App;