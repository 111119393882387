import {snackbarActions }  from '../actions';

const initialState = {
  open:false,
  message:'',
  severity:'success'
};

const navbar = function (state = initialState, action) {
  switch ( action.type )
  {
    case snackbarActions.OPEN_SNACKBAR:
    {
      return {
        ...state,
        open: true,
        message: action.payload.message,
        severity: action.payload.severity
      }
    }
    case snackbarActions.CLOSE_SNACKBAR:
    {
      return {
        ...state,
        open:false,
        message: state.message,
        severity: state.severity
      }
    }
    default:
    {
      return state;
    }
  }
};

export default navbar;