import React, {Component} from 'react';
import {
  Paper,
  Grid,
  Typography,
} from '@mui/material';
import styles from './index.module.css';
import { Field } from 'react-final-form';
import {Input, AutoComplete, DatePicker} from '../../../components/inputs';
import { connect } from 'react-redux';

class FormComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  
  render() {
    return (
      <Grid item container spacing={3}>
        <Grid item lg={12}>
          <Typography variant="h6" component="div">
            Datos Generales
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            {...((this.props.renew )?{disabled:true}: null)}
            waiting={this.props.waiting}
            name="bussiness_branch_id"
            component={AutoComplete}
            label="Establecimiento"
            type="text"
            options={this.props.business_branches}
            loading={this.props.status_business_branches.loading}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              {...((this.props.renew )?{disabled:true}: null)}
              required
              waiting={this.props.waiting}
              {...((this.props.values && !this.props.values.change_contract_beginning)?{disabled:true}: null)}
              name="contract_beginning"
              component={DatePicker}
              label="Inicio de contrato"
              type="text"
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            waiting={this.props.waiting}
            name="package_id"
            component={AutoComplete}
            label="Paquete"
            type="text"
            options={this.props.packages}
            loading={this.props.status_packages.loading}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            {...((this.props.renew )?{disabled:true}: null)}
            {...((this.props.values && !this.props.values.change_survey)?{disabled:true}: null)}
            name="survey_id"
            waiting={this.props.waiting}
            component={AutoComplete}
            label="Encuesta"
            type="text"
            options={this.props.surveys}
            loading={this.props.status_surveys.loading}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
            required
            name="consumption_expenses"
            fullWidth
            component={Input}
            type="text"
            label="Inversión para consumo"
          />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            {...((this.props.renew )?{disabled:true}: null)}
            waiting={this.props.waiting}
            name="discuount"
            fullWidth
            component={Input}
            type="text"
            label="Descuento"
          />
          </Paper>
        </Grid>
        

      </Grid>
    );
  }

}


const mapStateToProps = (state) => {
  return {
    business_branches:  state.module_contracts.business_branches,
    packages:  state.module_contracts.packages,
    surveys:  state.module_contracts.surveys,
    status_business_branches: state.module_contracts.status_business_branches,
    status_packages: state.module_contracts.status_packages,
    status_surveys: state.module_contracts.status_surveys,
  };
}

const _component = connect(
  mapStateToProps,
  null
)(FormComponent);

export default _component;
