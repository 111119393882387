import mainSaga from '../../sagas/brands.saga';
import mainReducer from '../reducers/modules/brands.reducer';
import {brands as actions} from '../actions/modules';

export function getModule() {
    return {
        id: "module_brands",
        reducerMap: {
          module_brands: mainReducer,
        },
        sagas: [mainSaga],
        finalActions: [actions.reset()],
    }; 
}