import React, {Component} from 'react';
import {
  Paper,
  Grid,
  Typography,
} from '@mui/material';
import styles from './index.module.css';
import { Field } from 'react-final-form';
import { DatePicker, Input} from '../../../../components/inputs';
import { connect } from 'react-redux';
class FormComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  render() {
    return (
      
      <Grid item container spacing={3}>
        <Grid item lg={12}>
          <Typography variant="h6" component="div">
            Datos Generales
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              required
              waiting={this.props.status_element.loading}
              name="amount"
              component={Input}
              label="Monto"
              type="text"
            />
          </Paper>
        </Grid>
        
        <Grid item xs={6} sm={3} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              required
              waiting={this.props.status_element.loading}
              name={`payment_date`}
              component={DatePicker}
              label="Fecha"
              errors={this.props.errors}
              type="text"
            />
          </Paper>
        </Grid>


        <Grid item xs={6} sm={3} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              required
              waiting={this.props.status_element.loading}
              name={`bank_reference`}
              component={Input}
              label="Referencia"
              errors={this.props.errors}
              type="text"
            />
          </Paper>
        </Grid>

        
        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              waiting={this.props.status_element.loading}
              name={`comments`}
              component={Input}
              label="Comentarios"
              type="text"
            />
          </Paper>
        </Grid>

      </Grid>
    );
  }

}

const mapStateToProps = (state) => {
  return {
    status_element: state.module_visits.status_element,
    element: state.module_visits.element
  };
}

const _component = connect(
  mapStateToProps,
  null
)(FormComponent);

export default _component;
