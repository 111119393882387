import http from '../utils/http-common';

const getAll = () => {
  return http.get("/clients");
};

const get = ({slug}) => {
  return http.get(`/clients/${slug}/edit`);
};

const create = ({data}) => {
  return http.post("/clients/store", data);
};

const update = ({slug, data}) => {
  return http.put(`/clients/${slug}/update`, data);
};


const restore = ({slug, data}) => {
  return http.put(`clients/${slug}/restore`, data);
};

const clientsService = {
  getAll,
  get,
  create,
  update,
  restore
};

export default clientsService;