export const OPEN_SNACKBAR = '[SNACKBAR] OPEN';
export const CLOSE_SNACKBAR = '[SNACKBAR] CLOSE';

export const snackbarOpen = (payload) =>
{
  return {
    type: OPEN_SNACKBAR,
    payload: payload
  }
}

export const snackbarClose = (payload) =>
{
  return {
    type: CLOSE_SNACKBAR,
    payload: payload
  }
}