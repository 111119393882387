import { Validators } from '@lemoncode/fonk';
import { createFinalFormValidation } from '@lemoncode/fonk-final-form';

export const validationSchema = {
  field: {
    name:[Validators.required.validator],
    phone:[Validators.required.validator],
    email:[Validators.required.validator, Validators.email.validator],
    status:[Validators.required.validator]
  }
};

export const formValidation = createFinalFormValidation(validationSchema);

