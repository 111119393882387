export const addMonths = (date, months) => {
  var d = date.getDate();
  date.setMonth(date.getMonth() + +months);
  if (date.getDate() !== d) {
    date.setDate(0);
  }
  return date;
}

export const startDay = (date) => {
  return new Date(date.setUTCHours(0,0,0,0));
}

export const endDay = (date) => {
  return new Date(date.setUTCHours(23,59,59,999));
}

export const startMonth  = (date) => {
  return new Date(date.getFullYear(), date.getMonth(), 1);
}

export const endMonth  = (date) => {
  return endDay(new Date(date.getFullYear(), date.getMonth() + 1, 0));
}


export const dateToString = (date) =>{
  return new Date(date.getTime() - (date.getTimezoneOffset() * 60000 ))
            .toISOString()
            .split("T")[0];
}