import React, {Component} from 'react';
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import FormData from '../form';
import FormSchedules from '../form/schedules';
import { Form } from 'react-final-form';
import { business_branches as actions } from '../../../store/actions/modules';
import { connect } from 'react-redux';
import { formValidation } from './validator';
import { FieldArray } from 'react-final-form-arrays';
import arrayMutators from 'final-form-arrays';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
});

class UpdateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug:null,
    }
  }
  
  async componentDidMount(){
    const {slug} = this.props.match.params;
    this.setState({
      slug: slug
    });
    this.props.getModuleData({slug: slug});
  }

  componentWillUnmount(){
  }

  componentDidUpdate(prevProps) {
    if (prevProps.save !== this.props.save) {
      if(this.props.save.success){
        this.props.history.push("/businessBranches");
      }
    }
  }

  onSubmit = values => {
    this.props.updateRecord({data: values, neighborhood: this.props.neighborhood, slug:this.state.slug});
  }


  createEmptySchedule = () => ({
    days: [],
    name: '',
    quantity: '',
    price: '',
  });

  render() {

    const maxWidth = false;

    const { classes } = this.props;


    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>

          <Grid container spacing={2} direction="column">

            <Form
              validate={formValidation.validateForm}
              initialValues={this.props.element.data}
              onSubmit={this.onSubmit}
              mutators={{ ...arrayMutators }}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container item spacing={3} justifyContent="flex-end">
                    {/* Title */}
                    <Grid item xs>
                      <Toolbar className={styles['toolbar']}>
                        <Typography variant="h4" id="tableTitle" component="div">
                          Editar Establecimiento
                          <Typography variant="caption" display="block" gutterBottom>
                          * Campos requeridos
                          </Typography>
                        </Typography>
                      </Toolbar>
                    </Grid>
                    {/* Buttons */}
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => {this.props.history.push("/businessBranches")}}
                        disabled={this.props.element.loading || this.props.save.loading}
                        color="primary">
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        disabled={this.props.element.loading || this.props.save.loading}
                        type="submit"
                        className={classes.button}
                        color="primary"
                      >
                        Guardar
                        {(this.props.element.loading || this.props.save.loading) && <CircularProgress size={24} className={classes.buttonProgress} />}
                      </Button>
                    </Grid>
                  </Grid>
                  {/* Form */}
                  <FormData waiting={this.props.element.loading || this.props.save.loading}/>
                  
                  <FieldArray
                    name="schedules"
                    render={({ fields }) => (
                      <React.Fragment>
                        {/* buttons */}
                        <Grid container item spacing={3} justifyContent="space-between">
                          <Grid item>
                            <Typography variant="h6" component="div">
                              Horarios
                            </Typography>
                          </Grid>
                          <Grid item>
                            <Button
                              variant="outlined"
                              size="small"
                              className={classes.button}
                              onClick={(e) => { fields.pop()  }}
                              disabled = { (values.schedules.length < 2) ||  (this.props.element.loading || this.props.save.loading)}
                              color="primary">
                              Eliminar
                            </Button>
                            <Button
                              variant="contained"
                              size="small"
                              className={classes.button}
                              onClick={(e) => {  fields.push(this.createEmptySchedule() ) }}
                              color="primary"
                              disabled = {this.props.element.loading || this.props.save.loading}
                            >
                              Agregar
                            </Button>
                          </Grid>
                        </Grid>
                        {/* buttons */}
                        {values.schedules.map((_, index) => (
                          <FormSchedules key={index} index={index} waiting={this.props.element.loading || this.props.save.loading}/>
                        ))}
                      </React.Fragment>
                    )}/>
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}

                </form>
              )}
            />

          </Grid>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateRecord:(payload) =>{
      dispatch(actions.update(payload))
    },
    getModuleData:(payload) =>{
      dispatch(actions.edit(payload))
    }
  }
};


const mapStateToProps = (state) => {
  return {
    neighborhood:  state.module_business_branches.neighborhood,
    save: state.module_business_branches.save,
    element: state.module_business_branches.element
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateComponent);


export default withRouter(withStyles(useStyles)(_component));
