import React, {Component} from 'react';
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import data_service from "../../../services/instructives";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import FormData from '../form';
import { Form } from 'react-final-form';
import { snackbarActions, backdropActions, apiCall, modules } from '../../../store/actions';
import { connect } from 'react-redux';
import { formValidation } from './validator';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
});

class UpdateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug:null
    }
  }
  
  async componentDidMount(){
    const {slug} = this.props.match.params;
    this.setState({
      slug: slug
    });
    this.props.getModuleData({slug: slug});
  }

  componentWillUnmount(){
  }

  componentDidUpdate(prevProps) {
    if (prevProps.api_data !== this.props.api_data) {
      if(this.props.api_data.success){
        this.props.history.push("/instructives");
      }
    }
    if (prevProps.module !== this.props.module.element) {
      
    }
  }

  async handleFormSubmit(data){
    this.props.updateRecord({service: data_service.update, slug: this.state.slug, data: data })
  }

  onSubmit = async values => {
    const data = {
      ...values, 
      ...{
        bussiness_branch_id: values.bussiness_branch_id.value,
        customer_id: values.customer_id.value,
        brand_id: values.brand_id.value
      }
    };
    try {
      await this.handleFormSubmit(data);
    } catch (error) {
      
    }
  }


  render() {

    const buttonSx = {
      ...(this.props.api_data.loading && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    const maxWidth = false;

    const { classes } = this.props;


    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>

          <Grid container spacing={2} direction="column">

            <Form
              validate={values => formValidation.validateForm(values)}
              initialValues={this.props.module.element}
              onSubmit={this.onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container item spacing={3} justifyContent="flex-end">
                    {/* Title */}
                    <Grid item xs>
                      <Toolbar className={styles['toolbar']}>
                        <Typography variant="h4" id="tableTitle" component="div">
                          Editar Instructivo
                          <Typography variant="caption" display="block" gutterBottom>
                          * Campos requeridos
                          </Typography>
                        </Typography>
                      </Toolbar>
                    </Grid>
                    {/* Buttons */}
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => {this.props.history.push("/instructives")}}
                        disabled={this.props.api_data.loading }
                        color="primary">
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        sx={buttonSx}
                        disabled={this.props.api_data.loading || this.props.module.status_element.loading}
                        className={classes.button}
                        type="submit"
                      >
                        Guardar
                        {this.props.api_data.loading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  {/* Form */}
                  <FormData waiting={this.props.api_data.loading || this.props.module.status_element.loading}/>
                  
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                  {/* <pre>{JSON.stringify(errors, 0, 2)}</pre> */}

                </form>
              )}
            />

          </Grid>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    snackbarOpen: (payload) => {
      dispatch(snackbarActions.snackbarOpen(payload))
    },
    backdropOpen: () => {
      dispatch(backdropActions.backdropOpen())
    },
    backdropClose: () => {
      dispatch(backdropActions.backdropClose())
    },
    updateRecord:(payload) =>{
      dispatch(apiCall.update(payload))
    },
    getModuleData:(payload) =>{
      dispatch(modules.instructives.update(payload))
    }
  }
};


const mapStateToProps = (state) => {
  return {
    api_data: state.api_call,
    module: state.module_instructives
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateComponent);


export default withRouter(withStyles(useStyles)(_component));
