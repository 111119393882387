import React, {Component} from 'react';
import {
  Paper,
  Grid,
  Typography,
} from '@mui/material';
import styles from './index.module.css';
import { Field } from 'react-final-form';
import {Input, Select} from '../../../components/inputs';

class FormComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }
  
  render() {
    return (
      <>
        <Grid item lg={12}>
          <Typography variant="h6" component="div">
            Datos Generales
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            waiting={this.props.waiting}
            name="name"
            component={Input}
            label="Nombre"
            type="text"
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              required
              waiting={this.props.waiting}
              name="phone"
              component={Input}
              label="Telefono"
              type="text"
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            waiting={this.props.waiting}
            name="email"
            component={Input}
            label="Correo"
            type="text"
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            name="status"
            waiting={this.props.waiting}
            component={Select}
            options={[{value:"Active",label:"Activo"}, {value:"Inactive",label:"Inactivo"}]}
            label="Estado"
            type="text"
            />
          </Paper>
        </Grid>

        {/* <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
            required
            name="password"
            fullWidth
            component={Input}
            type="password"
            label="Contraseña"
          />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
            name="password_confirmation"
            fullWidth
            component={Input}
            type="password"
            label="Contraseña"
          />
          </Paper>
        </Grid> */}
        

      </>
    );
  }

}

export default FormComponent;
