import http from '../utils/http-common';

const list = () => {
  return http.get("/visits/pendingPayment");
};

const export_file = () => {
  return http.get(`/visits/exportPayments`);
};


const service = {
  list,
  export_file
};

export default service;