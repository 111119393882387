import {payableAccount as actions} from '../../actions/modules';

const initialState = {
  list: {
    data:[],
    loading: false,
    success: false
  },
  export_file: {
    data:{},
    loading: false,
    success: false
  }
};

const navbar = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.LIST_REQUEST:
    {
      return {
        ...state,
        list:{
          data: [],
          loading: true,
          success: false
        }
      }
    }
    case actions.LIST_SUCCESS:
    {
      return {
        ...state,
        list:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.LIST_FAILURE:
    {
      return {
        ...state,
        list:{
          data: [],
          loading: false,
          success: false
        }
      }
    }
    case actions.EXPORT_FILE_REQUEST:
    {
      return {
        ...state,
        export_file:{
          data: {},
          loading: true,
          success: false
        }
      }
    }
    case actions.EXPORT_FILE_SUCCESS:
    {
      return {
        ...state,
        export_file:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.EXPORT_FILE_FAILURE:
    {
      return {
        ...state,
        export_file:{
          data: {},
          loading: false,
          success: false
        }
      }
    }
    case actions.CLEAR:
    {
      return initialState;
    }
    default:
    {
      return state;
    }
  }
};

export default navbar;