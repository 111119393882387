import {contracts as actions} from '../../actions/modules';

const initialState = {
  list: {
    data:[],
    loading: false,
    success: false
  },
  save: {
    response: {},
    loading: false,
    success: false
  },
  element: {
    bussiness_branch_id: "",
    contract_beginning: "",
    package_id: "",
    survey_id: "",
    consumption_expenses: "",
    discuount: null,
    change_contract_beginning: true,
    change_survey: true
  },
  status_element: {
    loading: false,
    success: false,
  },
  business_branches: [],
  status_business_branches: {
    loading: false,
    success: false,
  },
  surveys: [],
  status_surveys: {
    loading: false,
    success: false,
  },
  packages: [],
  status_packages: {
    loading: false,
    success: false,
  },
};

const reducer = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.LIST_REQUEST:
    {
      return {
        ...state,
        list:{
          data: [],
          loading: true,
          success: false
        }
      }
    }
    case actions.LIST_SUCCESS:
    {
      return {
        ...state,
        list:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.LIST_FAILURE:
    {
      return {
        ...state,
        list:{
          data: [],
          loading: false,
          success: false
        }
      }
    }
    case actions.SAVE_REQUEST:
    {
      return {
        ...state,
        save:{
          response: {},
          loading: true,
          success: false
        }
      }
    }
    case actions.SAVE_SUCCESS:
    {
      return {
        ...state,
        save:{
          response: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.SAVE_FAILURE:
    {
      return {
        ...state,
        save:{
          response: {},
          loading: false,
          success: false
        }
      }
    }
    case actions.ELEMENT_REQUEST:
    {
      return {
        ...state,
        status_element: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.ELEMENT_SUCCESS:
    {
      return {
        ...state,
        element: action.payload,
        status_element: {
          loading: false,
          success: true,
        },
      }
    }
    case actions.ELEMENT_FAILURE:
    {
      return {
        ...state,
        element: initialState.element,
        status_element: {
          loading: false,
          success: false,
        },
      }
    }
    case actions.BUSINESS_BRANCHES_REQUEST:
    {
      return {
        ...state,
        status_business_branches: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.BUSINESS_BRANCHES_SUCCESS:
    {
      return {
        ...state,
        status_business_branches: {
          loading: false,
          success: true,
        },
        business_branches: action.payload
      }
    }
    case actions.BUSINESS_BRANCHES_FAILURE:
    {
      return {
        ...state,
        status_business_branches: {
          loading: false,
          success: false,
        },
        business_branches: null
      }
    }
    case actions.PACKAGES_REQUEST:
    {
      return {
        ...state,
        status_packages: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.PACKAGES_SUCCESS:
    {
      return {
        ...state,
        status_packages: {
          loading: false,
          success: true,
        },
        packages: action.payload
      }
    }
    case actions.PACKAGES_FAILURE:
    {
      return {
        ...state,
        status_packages: {
          loading: false,
          success: false,
        },
        packages: null
      }
    }
    case actions.SURVEYS_REQUEST:
    {
      return {
        ...state,
        status_surveys: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.SURVEYS_SUCCESS:
    {
      return {
        ...state,
        status_surveys: {
          loading: false,
          success: true,
        },
        surveys: action.payload
      }
    }
    case actions.SURVEYS_FAILURE:
    {
      return {
        ...state,
        status_surveys: {
          loading: false,
          success: false,
        },
        surveys: null
      }
    }
    case actions.RESET:
    {
      return {
        ...initialState,
        status_element: {
          loading: false,
          success: false,
        },
        element: initialState.element
      }
    }
    default:
    {
      return state;
    }
  }
};

export default reducer;