import surveysSaga from '../../sagas/surveys.saga';
import surveysReducer from '../reducers/modules/surveys.reducer';
import {surveys as actions} from '../actions/modules';

export function getModule() {
    return {
        id: "module_surveys",
        reducerMap: {
          module_surveys: surveysReducer,
        },
        sagas: [surveysSaga],
        finalActions: [actions.reset()],
    }; 
}