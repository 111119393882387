import MainComponent from "./Detail";
import { getModule } from "./../../../store/modules/businessBranches.module";
import { DynamicModuleLoader } from "redux-dynamic-modules-react";
import React from 'react';


export default function Dynamic() {
  return (
      <DynamicModuleLoader modules={[getModule()]}>
          <MainComponent />
      </DynamicModuleLoader>
  );
}
