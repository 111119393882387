import React from 'react';
import {Container, Paper } from '@mui/material';
import { connect } from 'react-redux';
import styles from './index.module.css';
import Table from '../../components/table';
import { withRouter } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmDialog from '../../components/dialog/Confirm';
import { catalogs } from '../../store/actions/modules';

class Catalogs extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      dialog_open: false,
      record_slug:null
    };
  }

  componentDidMount() {
    this.initialLoad();
  }

  initialLoad(){
    this.props.getList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.remove !== this.props.remove) {
      if(this.props.remove.success){
        this.initialLoad();
        this.setState({dialog_open: false, record_slug : null }); 
      }else{
        if(!this.props.remove.loading){
          this.setState({dialog_open: false, record_slug : null }); 
        }
      }
    }
  }

  openDeleteConfirm(slug){
    this.setState({dialog_open: true, record_slug: slug});
  }

  deleteRecord(slug){
    this.props.deleteRecord({slug:slug});
  }

  render() {

    const columns = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Nombre',sortable: true }
    ];
    const maxWidth = false;
    return (
      <Container maxWidth={maxWidth} className={styles['client-container']}>
          {/* Dialog  */}
          <ConfirmDialog  
            open={this.state.dialog_open} 
            loading={this.props.remove.loading}
            title={"Eliminar Categoría"} 
            confirm={() =>  this.deleteRecord(this.state.record_slug) } 
            cancel={() => this.setState({dialog_open: false, record_slug : null }) } 
            message={"¿Desea eliminar el siguiente registro?"}
            />
          {/* table */}
          <Paper elevation={1} className={styles.paper}>
            <Table
              title = {"Categorías"}
              data = {this.props.list.data}
              columns={columns}
              addAction ={(event) => {this.props.history.push("/catalogs/create");}}
              actions={[
                {
                  icon: DeleteOutlineOutlinedIcon,
                  tooltip: 'Eliminar',
                  onClick: (event, row) => this.openDeleteConfirm(row.slug)
                },
                {
                  icon: EditOutlinedIcon,
                  tooltip: 'Editar',
                  onClick: (event, row) => {this.props.history.push(`/catalogs/${row.slug}/update`)}
                }
              ]}
              loading={this.props.list.loading}
            />
          </Paper>
      </Container>
    )
  }
}


const mapStateToProps = (state) => {
  return {
    list: state.module_catalogs.list,
    remove: state.module_catalogs.remove
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getList: () =>{
      dispatch(catalogs.list());
    },
    deleteRecord:(payload)=>{
      dispatch(catalogs.remove(payload));
    }
  }
};


const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(Catalogs);

export default withRouter(_component);