import { Route, Redirect } from 'react-router-dom';
import { useSelector } from 'react-redux';
import MainLayout from '../../layouts/main-layout';
import token_service from '../../services/token';
import { useHistory } from "react-router-dom";
import { useEffect } from 'react';
import './index.css';

const MainRoute = ({ component: Component, ...rest }) => {
  
  const auth_data = useSelector(state => state.auth);
  let history = useHistory();

  useEffect(() => {
    if (auth_data.logout.success && ! auth_data.logout.loading) {
      if(!token_service.getLocalAccessToken()){
        history.push("/login");
      }
    }
  }, [auth_data.logout, history]);

  return (
    <Route
      {...rest}
      render={({ location, ...props }) => (
        token_service.getLocalAccessToken() ? (
        <MainLayout>
          <Component {...props} />
        </MainLayout>
        ) : (
          <Redirect
            to={{
              pathname: "/login",
              state: { from: location }
            }}
          />
        )
      )}
    />
  );
};

export default MainRoute;