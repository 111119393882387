import { Validators } from '@lemoncode/fonk';
import { createFinalFormValidation } from '@lemoncode/fonk-final-form';

export const validationSchema = {
  field: {
    name:[Validators.required.validator]
  }
};

export const formValidation = createFinalFormValidation(validationSchema);

