import {surveys as actions} from '../../actions/modules';

const initialState = {
  list: {
    data:[],
    loading: false,
    success: false
  },
  save: {
    response: {},
    loading: false,
    success: false
  },
  element: {
    title: null,
    questions: [
        {
          id: null,
          categoria_id: null,
          titulo: null,
          descripcion: "",
          tipo: null,
          prioridad: null,
          ponderacion: null,
          no_pregunta: 0,//eliminar
          obligatorio: true,
          saltos: [],
          etiquetas: {
            inicial: null,
            final: null
          },
          unidad: null,
          invertir: false,
          preset_opciones: {
            no_aplica: false,
            otro: false
          },
          opciones: [
            {
              opcion: null,
              ponderacion: null
            }
          ],
          ver_resultados: true,
          ver_encuesta: true,
          skip_to:null 
      }
    ]
  },
  status_element: {
    loading: false,
    success: false,
  },
  catalogs: [],
  status_catalogs: {
    loading: false,
    success: false,
  }
};

const reducer = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.LIST_REQUEST:
    {
      return {
        ...state,
        list:{
          data: [],
          loading: true,
          success: false
        }
      }
    }
    case actions.LIST_SUCCESS:
    {
      return {
        ...state,
        list:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.LIST_FAILURE:
    {
      return {
        ...state,
        list:{
          data: [],
          loading: false,
          success: false
        }
      }
    }
    case actions.SAVE_REQUEST:
    {
      return {
        ...state,
        save:{
          response: {},
          loading: true,
          success: false
        }
      }
    }
    case actions.SAVE_SUCCESS:
    {
      return {
        ...state,
        save:{
          response: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.SAVE_FAILURE:
    {
      return {
        ...state,
        save:{
          response: {},
          loading: false,
          success: false
        }
      }
    }
    case actions.ELEMENT_REQUEST:
    {
      return {
        ...state,
        status_element: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.ELEMENT_SUCCESS:
    {
      return {
        ...state,
        element: action.payload,
        status_element: {
          loading: false,
          success: true,
        },
      }
    }
    case actions.ELEMENT_FAILURE:
    {
      return {
        ...state,
        element: initialState.element,
        status_element: {
          loading: false,
          success: false,
        },
      }
    }
    case actions.CATALOGS_REQUEST:
    {
      return {
        ...state,
        status_brands: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.CATALOGS_SUCCESS:
    {
      return {
        ...state,
        status_brands: {
          loading: false,
          success: true,
        },
        catalogs: action.payload
      }
    }
    case actions.CATALOGS_FAILURE:
    {
      return {
        ...state,
        status_brands: {
          loading: false,
          success: false,
        },
        catalogs: null
      }
    }
    case actions.RESET:
    {
      return initialState;
    }
    default:
    {
      return state;
    }
  }
};

export default reducer;