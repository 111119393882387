import mainSaga from '../../sagas/packages.saga';
import mainReducer from '../reducers/modules/packages.reducer';
import {packages as actions} from '../actions/modules';

export function getModule() {
    return {
        id: "module_packages",
        reducerMap: {
          module_packages: mainReducer,
        },
        sagas: [mainSaga],
        finalActions: [actions.reset()],
    }; 
}