export const CREATE = "[MODULE][CLIENTS] CREATE";
export const LIST = "[MODULE][CLIENTS] LIST";
export const CHANGE_PASSWORD = "[MODULE][CLIENTS] CHANGE_PASSWORD";
export const UPDATE = "[MODULE][CLIENTS] UPDATE";
export const ELEMENT = "[MODULE][CLIENTS] ELEMENT";

export const CREATE_REQUEST = "[MODULE][CLIENTS][CREATE] REQUEST";
export const CREATE_SUCCESS = "[MODULE][CLIENTS][CREATE] SUCCESS";
export const CREATE_FAILURE = "[MODULE][CLIENTS][CREATE] FAILURE";

export const LIST_REQUEST = "[MODULE][CLIENTS][LIST] REQUEST";
export const LIST_SUCCESS = "[MODULE][CLIENTS][LIST] SUCCESS";
export const LIST_FAILURE = "[MODULE][CLIENTS][LIST] FAILURE";

export const CHANGE_PASSWORD_REQUEST = "[MODULE][CLIENTS][CHANGE_PASSWORD] REQUEST";
export const CHANGE_PASSWORD_SUCCESS = "[MODULE][CLIENTS][CHANGE_PASSWORD] SUCCESS";
export const CHANGE_PASSWORD_FAILURE = "[MODULE][CLIENTS][CHANGE_PASSWORD] FAILURE";

export const UPDATE_REQUEST = "[MODULE][CLIENTS][UPDATE] REQUEST";
export const UPDATE_SUCCESS = "[MODULE][CLIENTS][UPDATE] SUCCESS";
export const UPDATE_FAILURE = "[MODULE][CLIENTS][UPDATE] FAILURE";

export const ELEMENT_REQUEST = "[MODULE][CLIENTS][ELEMENT] REQUEST";
export const ELEMENT_SUCCESS = "[MODULE][CLIENTS][ELEMENT] SUCCESS";
export const ELEMENT_FAILURE = "[MODULE][CLIENTS][ELEMENT] FAILURE";

export const CLEAR = "[MODULE][CLIENTS] CLEAR";

export const create = (payload)=> {
  return { type: CREATE, payload };
}

export const update = (payload)=> {
  return { type: UPDATE, payload };
}

export const list = ()=> {
  return { type: LIST  };
}

export const element = (payload)=> {
  return { type: ELEMENT, payload };
}

export const changePassword = (payload)=> {
  return { type: CHANGE_PASSWORD, payload };
}

export const reset = ()=> {
  return { type: CLEAR  };
}