import { Validators } from '@lemoncode/fonk';
import { createFinalFormValidation } from '@lemoncode/fonk-final-form';

export const validationSchema = {
  field: {
    name:[Validators.required.validator],
    bussiness_branch_id:[Validators.required.validator],
    customer_id:[Validators.required.validator],
    brand_id:[Validators.required.validator],
    contents:[Validators.required.validator],
  }
};

export const formValidation = createFinalFormValidation(validationSchema);

