import React, {Component} from 'react';
import {
  Paper,
  Grid,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import {Select, TimePicker} from '../../../components/inputs';
import { Field } from 'react-final-form';

const useStyles = (theme) => ({
  formControl: {
    minWidth: '100%',
  }
});

class Form extends Component {

  render() {

    return (
      <Grid item container spacing={3}>
        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              required
              waiting={this.props.waiting}
              name={`schedules[${this.props.index}].days`}
              component={Select}
              label="Días"
              type="text"
              options={[
                {value: 'domingo',label: 'Domingo'},
                {value: 'sabado',label: 'Sabado'},
                {value: 'viernes',label: 'Viernes'},
                {value: 'jueves',label: 'Jueves'},
                {value: 'miercoles',label: 'Miercoles'},
                {value: 'martes',label: 'Martes'},
                {value: 'lunes',label: 'Lunes'}
              ]}
              multiple
            />
          </Paper>
        </Grid>

        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              required
              waiting={this.props.waiting}
              name={`schedules[${this.props.index}].opening_time`}
              component={TimePicker}
              label="Hora de apertura"
              errors={this.props.errors}
              type="text"
            />
          </Paper>
        </Grid>

        <Grid item xs={3} sm={3} md={3} lg={3} xl={3}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              required
              waiting={this.props.waiting}
              name={`schedules[${this.props.index}].closing_time`}
              component={TimePicker}
              label="Hora de cierre"
              errors={this.props.errors}
              type="text"
            />
          </Paper>
        </Grid>

      </Grid>
    );
  }
}

export default withStyles(useStyles)(Form);
