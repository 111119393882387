import * as React from 'react';
import ImageGallery from 'react-image-gallery';
import './index.css';
export default class MyGallery extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      images: [],
      open: false,
      img_url: ''
    };
  }
  render() {
    return (
      <ImageGallery
        items={
            this.props.images.map((image, key) => { return {
              original:`${image[this.props.index]}`, 
              fullscreen: `${image[this.props.index]}`,
              thumbnail:`${image[this.props.index]}`, 
              description :image.titulo,
              thumbnailLabel: image.visita ?  `Visita # ${image.visita}` :  `Evidencia # ${key + 1}`,
              originalWidth: '350px',
              originalHeight: '350px',
              thumbnailHeight: '80px',
              thumbnailWidth: '20px',
            }
          }) 
        } 
        showPlayButton={false}
      />
    )
  }
}

MyGallery.defaultProps = {
  index: "url",
  images:[],
  open: false,
  img_url: ''
}