import mainSaga from '../../sagas/customers.saga';
import mainReducer from '../reducers/modules/customers.reducer';
import {customers as actions} from '../actions/modules';

export function getModule() {
    return {
        id: "module_customers",
        reducerMap: {
          module_customers: mainReducer,
        },
        sagas: [mainSaga],
        finalActions: [actions.reset()],
    }; 
}