import React, {Component} from 'react';
import {
  Paper,
  Grid,
  Typography,
  Grow
} from '@mui/material';
import styles from './index.module.css';
import { Field } from 'react-final-form';
import {Input, AutoComplete, Slider, DebounceInput} from '../../../components/inputs';
// import SearchIcon from '@mui/icons-material/Search';
// import InputAdornment from '@mui/material/InputAdornment';
// import IconButton from '@mui/material/IconButton';
import { connect } from 'react-redux';
import { modules } from '../../../store/actions';

class FormComponent extends Component {

  constructor(props) {
    super(props);

    this.state = {
      zip_code:null,
      city: null,
      social_status:[
        {
          value: 0,
          label: 'E',
        },
        {
          value: 1,
          label: 'D',
        },
        {
          value: 2,
          label: 'D+',
        },
        {
          value: 3,
          label: 'C-',
        },
        {
          value: 4,
          label: 'C',
        },
        {
          value: 5,
          label: 'C+',
        },
        {
          value: 6,
          label: 'A/B',
        }
      ]
    }
  }

  async componentDidMount(){
    // this.setState({loanding_brands: true});
    // await this.getBrands();
    // this.setState({loanding_brands: false});
  }



  handleSearchNeighborhood = async () => {
    this.props.getNeighborhoods(this.state.zip_code);
  }

  handleChange = e => {
    const { value } = e;
    if(value){
      this.props.getNeighborhoods(value);
    }
    // this.setState(prevState => ({
    //   zip_code: value
    // }));
  };

  onChangeHandlerNeighborhood = async(option) =>{
    if(option){
      this.props.getNeighborhood(option.value);
    }else{
      this.setState( ({
        city: null
      }));
    }
  };

  async componentDidUpdate(prevProps) {
    if (this.props.zip_code !== prevProps.zip_code) {
    //   await this.getNeighborhoods(this.props.zip_code);
    }
  }
  
  render() {
    return (
      <Grid item container spacing={3}>
        <Grid item lg={12}>
          <Typography variant="h6" component="div">
            Datos Generales
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
            required
            name="name"
            fullWidth
            component={Input}
            type="text"
            label="Nombre"
          />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
            required
            name="brand_id"
            component={AutoComplete}
            label="Marca"
            type="text"
            options={this.props.brands}
            loading={this.props.status_brands.loading}
            />
          </Paper>
        </Grid>


        <Grid item lg={12}>
          <Typography variant="h6" component="div">
            Dirección
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
            required
            name="zip_code"
            fullWidth
            component={DebounceInput}
            type="text"
            label="Codigo Postal"
            inputOnChange={this.handleChange}
          />
          </Paper>
        </Grid>

        

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              required
              waiting={this.props.waiting}
              name="neighborhood_id"
              component={AutoComplete}
              label="Colonia"
              errors={this.props.errors}
              type="text"
              options={this.props.neighborhoods}
              loading={this.props.status_neighborhoods.loading}
              // setValue={this.state.neighborhood}
              onChange={(option) => this.onChangeHandlerNeighborhood(option)}
            />
          </Paper>  
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
            required
            name="street"
            fullWidth
            component={Input}
            type="text"
            label="Calle"
            errors={this.props.errors}
          />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
            name="ext_number"
            fullWidth
            component={Input}
            type="text"
            label="Número Exterior"
            errors={this.props.errors}
          />
          </Paper>
        </Grid>

        <Grow in={this.props.neighborhood.city && true}>
          <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
            <Paper className={styles.paper} variant="outlined">
            <Grid container spacing={3}>
              <Grid item xs={4}>
                <Typography className={styles.title} color="textSecondary" gutterBottom>
                  Municipio
                </Typography>
                <Typography variant="h6" component="h2" color="secondary">
                  {this.props.neighborhood.city ? this.props.neighborhood.city.name : null}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={styles.title} color="textSecondary" gutterBottom>
                  Estado
                </Typography>
                <Typography variant="h6" component="h2" color="secondary">
                  {this.props.neighborhood.city ? this.props.neighborhood.city.state_code : null}
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Typography className={styles.title} color="textSecondary" gutterBottom>
                  País 
                </Typography>
                <Typography variant="h6" component="h2" color="secondary">
                  {this.props.neighborhood.city ? this.props.neighborhood.city.country_code : null}
                </Typography>
              </Grid>
            </Grid>
            </Paper>  
          </Grid>
        </Grow>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
            required
            name="social_status"
            component={Slider}
            min={0}
            max={6}
            type="text"
            label="Rango Socioeconómico"
            marks ={this.state.social_status}
            // firstMark = {[1,2]}
          />
          </Paper>
        </Grid>

      </Grid>
    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    getNeighborhoods:(payload) =>{
      dispatch(modules.business_branches.neighborhoods(payload))
    },
    getNeighborhood:(payload) =>{
      dispatch(modules.business_branches.neighborhood(payload))
    }
  }
};

const mapStateToProps = (state) => {
  return {
    brands:  state.module_business_branches.brands,
    neighborhoods:  state.module_business_branches.neighborhoods,
    neighborhood:  state.module_business_branches.neighborhood,
    status_brands: state.module_business_branches.status_brands,
    status_neighborhoods: state.module_business_branches.status_neighborhoods,
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent);

export default _component;
