export const LIST = "[MODULE][PAYABLE_ACCOUNTS] LIST";
export const EXPORT_FILE = "[MODULE][PAYABLE_ACCOUNTS] EXPORT_FILE";

export const LIST_REQUEST = "[MODULE][PAYABLE_ACCOUNTS][LIST] REQUEST";
export const LIST_SUCCESS = "[MODULE][PAYABLE_ACCOUNTS][LIST] SUCCESS";
export const LIST_FAILURE = "[MODULE][PAYABLE_ACCOUNTS][LIST] FAILURE";

export const EXPORT_FILE_REQUEST = "[MODULE][PAYABLE_ACCOUNTS][EXPORT_FILE] REQUEST";
export const EXPORT_FILE_SUCCESS = "[MODULE][PAYABLE_ACCOUNTS][EXPORT_FILE] SUCCESS";
export const EXPORT_FILE_FAILURE = "[MODULE][PAYABLE_ACCOUNTS][EXPORT_FILE] FAILURE";

export const CLEAR = "[MODULE][PAYABLE_ACCOUNTS] CLEAR";

export const list = ()=> {
  return { type: LIST };
}

export const exportFile = ()=> {
  return { type: EXPORT_FILE  };
}

export const clear = ()=> {
  return { type: CLEAR  };
}