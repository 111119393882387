import visitsSaga from '../../sagas/visits.saga';
import visitsReducer from '../reducers/modules/visits.reducer';
import {contract_visits as actions} from '../actions/modules';

export function getModule() {
    return {
        id: "module_visits",
        reducerMap: {
          module_visits: visitsReducer,
        },
        sagas: [visitsSaga],
        finalActions: [actions.reset()],
    }; 
}