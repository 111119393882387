import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import data_service from "../services/payable_accounts";
import {payableAccount as actions} from '../store/actions/modules';
import {snackbarActions as snackbar} from '../store/actions';
import {failure} from "../utils/api_responses";


export default function* root() {
  yield all([
    fork(watcherList),
    fork(watcherExportFile)
  ])
}

function* watcherList() {
  yield takeEvery(actions.LIST, workerList);
}

function* watcherExportFile() {
  yield takeEvery(actions.EXPORT_FILE, workerExportFile);
}


function* workerList(action) {
  try {
    yield put({ type: actions.LIST_REQUEST });
    const pending_payments = yield call(pendingPayments);
    yield put({ type: actions.LIST_SUCCESS ,payload: pending_payments});
  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.LIST_FAILURE, payload: e });
  }
}

function* workerExportFile(action) {
  try {
    yield put({ type: actions.EXPORT_FILE_REQUEST }); 
    const pending_payments = yield call(exportFile);
    yield put({ type: actions.EXPORT_FILE_SUCCESS ,payload: pending_payments});
  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.EXPORT_FILE_FAILURE, payload: e });
  }
}

function pendingPayments() {
  return new Promise( (resolve, reject) => {
    data_service.list()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function exportFile() {
  return new Promise( (resolve, reject) => {
    data_service.export_file()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}
