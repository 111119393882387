import {surveys as actions} from '../../actions/external';

const initialState = {
  element: {
    data:{
      slug:"",
      title:"",
      json_questions:[],
      answers:{
        answers:{},
        images:{},
      },
    },
    loading: false,
    success: false,
  },
  catalogs: {
    data: [],
    loading: false,
    success: false,
  },
  save: {
    response:{},
    loading: false,
    success: false,
  }
};

const reducer = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.ELEMENT_REQUEST:
    {
      return {
        ...state,
        element: {
          ...initialState.element,
          loading: true,
          success: false,
        },
      }
    }
    case actions.ELEMENT_SUCCESS:
    {
      return {
        ...state,
        element: {
          data: action.payload,
          loading: false,
          success: true,
        },
      }
    }
    case actions.ELEMENT_FAILURE:
    {
      return {
        ...state,
        element: {
          ...initialState.element,
          loading: false,
          success: false,
        },
      }
    }
    case actions.SAVE_REQUEST:
    {
      return {
        ...state,
        save: {
          response:{},
          loading: true,
          success: false,
        },
      }
    }
    case actions.SAVE_SUCCESS:
    {
      return {
        ...state,
        save: {
          response:action.payload,
          loading: false,
          success: true,
        },
      }
    }
    case actions.SAVE_FAILURE:
    {
      return {
        ...state,
        save: {
          response: action.payload,
          loading: false,
          success: false,
        },
      }
    }
    case actions.CATALOGS_REQUEST:
    {
      return {
        ...state,
        catalogs: {
          ...initialState.catalogs,
          loading: true,
          success: false,
        },
      }
    }
    case actions.CATALOGS_SUCCESS:
    {
      return {
        ...state,
        catalogs: {
          data: action.payload,
          loading: false,
          success: true,
        },
      }
    }
    case actions.CATALOGS_FAILURE:
    {
      return {
        ...state,
        catalogs: {
          ...initialState.catalogs,
          loading: false,
          success: false,
        }
      }
    }
    case actions.RESET:
    {
      return initialState;
    }
    default:
    {
      return state;
    }
  }
};

export default reducer;