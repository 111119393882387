import React, {Component} from 'react';
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import FormData from './form';
import { Form } from 'react-final-form';
import { modules } from '../../../store/actions';
import { connect } from 'react-redux';
import { formValidation } from './validator';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
});

class SendInvitationComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      step:0,
      address_state:null,
      contracts:[],
      shoppers:[],
    }
  }
  
  async componentDidMount(){
    this.props.getModuleData();
  }

  componentWillUnmount(){
    this.props.resetData();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.api_data !== this.props.api_data) {
      if(this.props.api_data.success){
        this.props.history.push("/visits");
      }
    }

    if (prevProps.business_branches !== this.props.business_branches) {
      this.setState({
        contracts:this.props.business_branches.map((row, index) => {
          return { selected:false, contract_id:row.contract_id, month:row.number_month > row.months ? row.months : (row.number_month ? row.number_month : 1 )}
        })
      });
    }
    if (prevProps.shoppers !== this.props.shoppers) {
      this.setState({
        shoppers:this.props.shoppers.map((row, index) => {
          return { selected:false, sku:row.sku}
        })
      });
    }

  }

  async handleFormSubmit(data){
    this.props.createRecord(data);
  }

  onSubmit = async values => {
    const data = {
      ...{
        contracts: values.contracts.filter(elem => elem.selected === true).map(x => { return {contract: x.contract_id , month: x.month} }),
        shoppers: values.shoppers.filter(elem => elem.selected === true).map(x => x.sku),
      }
    };
    try {
      await this.handleFormSubmit({state: values.address_state.value,data:data});
    } catch (error) {
      
    }
  }

  


  render() {

    const buttonSx = {
      ...(this.props.api_data.loading && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    const maxWidth = false;

    const { classes } = this.props;

    var step = 0;
    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>

          <Grid container spacing={2} direction="column">

            <Form
              validate={values => formValidation.validateForm(values)}
              initialValues={this.state}
              onSubmit={this.onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container item spacing={3} justifyContent="flex-end">
                    {/* Title */}
                    <Grid item xs>
                      <Toolbar className={styles['toolbar']}>
                        <Typography variant="h4" id="tableTitle" component="div">
                          Envío de invitaciones
                        </Typography>
                      </Toolbar>
                    </Grid>
                    {/* Buttons */}
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => {this.props.history.push("/visits")}}
                        disabled={this.props.api_data.loading }
                        color="primary">
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        sx={buttonSx}
                        disabled={this.props.api_data.loading || step !==2}
                        className={classes.button}
                        type="submit"
                      >
                        Enviar
                        {this.props.api_data.loading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  {/* Form */}
                  <FormData 
                    formValues={values} 
                    address={(option) => this.setState({address_state: option})}
                    onChangeStep={(event) => step = event.step}
                  />
                  {/* <pre>{JSON.stringify(step, 0, 2)}</pre> */}
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}

                </form>
              )}
            />

          </Grid>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createRecord:(payload) =>{
      dispatch(modules.contract_visits.sendInvitation(payload))
    },
    getModuleData:() =>{
      dispatch(modules.contract_visits.moduleSendInvitation())
    },
    resetData:() =>{
      dispatch(modules.contract_visits.reset())
    }
  }
};


const mapStateToProps = (state) => {
  return {
    api_data: state.module_contract_visits.send_invitation,
    business_branches: state.module_contract_visits.business_branches,
    shoppers: state.module_contract_visits.shoppers
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(SendInvitationComponent);


export default withRouter(withStyles(useStyles)(_component));
