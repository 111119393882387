import React, {Component} from 'react';
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button, 
  Alert,
  AlertTitle,
  Zoom,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import FormData from './form';
import { Form } from 'react-final-form';
import { visits } from '../../../../store/actions/external';
import { connect } from 'react-redux';
import { formValidation } from './validator';
import Timeline from './Timeline';
const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
});

class UpdateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token:null,
      success:false,
      submitting:false,
    }
  }
  
  async componentDidMount(){
    const { token} = this.props.match.params;
    this.props.getModuleData({token:token});
    this.setState({token:token});
  }

  componentWillUnmount(){
  }

  componentDidUpdate(prevProps) {
    if (prevProps.update_request !== this.props.update_request) {
      if(this.props.update_request.success){
        this.setState({success:true,submitting:true});
      }else{
        this.setState({success:false, submitting:false});
      }
    }
    if (prevProps.element !== this.props.element) {
      if(this.props.element === "Agotadas"){
        this.setState({success:false, submitting:true});
      }
    }

    if (prevProps.status_element !== this.props.status_element) {
      if(!this.props.status_element.success && !this.props.status_element.loading){
        this.setState({success:false,submitting:true});
      }
    }

  }

  onSubmit = async values => {
    this.setState({submitting:true});
    this.props.save({token: this.state.token, data: values })
  }


  render() {

    const buttonSx = {
      ...(this.props.update_request.loading && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    const maxWidth = false;

    const { classes } = this.props;


    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>

          <Grid container spacing={2} direction="column">
            { (this.state.submitting && !this.props.update_request.loading) ?
               ( this.state.success) ? 

               <Zoom in={this.state.success }>
                <Alert severity="success">
                  <AlertTitle>Gracias !</AlertTitle>
                  Hemos recibido tu solicitud de evaluación, espera a que alguien del equipo Index confirme tu visita a través del envío de instructivo y cuestionario.
                  En caso de que tu evaluación esté en puerta y no hayas recibido tu correo de confirmación, te pedimos que te comuniques vía WhatsApp para solicitar la confirmación o re agendar tu visita.
                  Recuerda que si tu evaluación no ha sido confirmada, no podrás realizarla. 
                  <br></br><strong>WhatsApp 444 196 2460</strong>
                </Alert>
               </Zoom>

               
               :
               <Alert severity="error">
               <AlertTitle>Error</AlertTitle>
               Lo sentimos, ya no quedan espacion disponibles para este estudio, espera la invitavión para próximos estudios. — <strong>Gracias!</strong>
             </Alert>

            : 

            <Form
              validate={values => formValidation.validateForm(values)}
              initialValues={this.props.element}
              onSubmit={this.onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container item spacing={3}>
                    {/* Title */}
                    <Grid item sm={6} xs={12}>
                      <Toolbar className={styles['toolbar']}>
                        <Typography variant="h4" id="tableTitle" component="div">
                          Registrar visita
                          
                        </Typography>
                      </Toolbar> 
                    </Grid>
                    {/* Buttons */}
                    <Grid item container sm={6} xs={12} justifyContent={{ xs: 'center', sm: 'flex-end' }}>
                      <Grid item>
                          <Button
                            variant="contained"
                            size="small"
                            sx={buttonSx}
                            disabled={this.props.update_request.loading || this.props.status_element.loading}
                            className={classes.button}
                            type="submit"
                          >
                            Guardar
                            {(this.props.update_request.loading || this.props.status_element.loading )&& (
                              <CircularProgress
                                size={24}
                                sx={{
                                  color: green[500],
                                  position: 'absolute',
                                  top: '50%',
                                  left: '50%',
                                  marginTop: '-12px',
                                  marginLeft: '-12px',
                                }}
                              />
                            )}
                          </Button>
                      </Grid>

                    </Grid>
                  </Grid>
                  {/* Form */}
                  <Grid item>
                    <Typography variant="caption" display="block" gutterBottom>
                      * Los horarios en que puedes hacer tus visitas son: 
                    </Typography>
                  </Grid>
                  <Grid item>
                    <Timeline items={this.props.element.schedules}/>
                  </Grid>

                  <FormData waiting={this.props.update_request.loading || this.props.status_element.loading}/>
                  

                  <Grid item xs>
                    <Toolbar className={styles['toolbar']}>
                      <Typography variant="caption" display="block" gutterBottom>
                      *Para cualquier aclaración o cambio en tu visita, contacte con tu administrador.
                      </Typography>
                    </Toolbar>
                  </Grid>
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                  {/* <pre>{JSON.stringify(errors, 0, 2)}</pre> */}

                </form>
              )}
            /> 

           

            }
          </Grid>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    save:(payload) =>{
      dispatch(visits.save(payload))
    },
    getModuleData:(payload) =>{
      dispatch(visits.detail(payload))
    }
  }
};


const mapStateToProps = (state) => {
  console.log(state);
  return {
    update_request: state.external_visits.status_save,
    status_element: state.external_visits.status_element,
    element: state.external_visits.element
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateComponent);


export default withRouter(withStyles(useStyles)(_component));
