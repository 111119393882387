import * as React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material/styles';
import Box from '@mui/material/Box';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TablePagination from '@mui/material/TablePagination';
import TableRow from '@mui/material/TableRow';
import TableSortLabel from '@mui/material/TableSortLabel';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Paper from '@mui/material/Paper';
import IconButton from '@mui/material/IconButton';
import Tooltip from '@mui/material/Tooltip';
import { visuallyHidden } from '@mui/utils';
import Skeleton from '@mui/material/Skeleton';
import { Field, FormSpy } from 'react-final-form';
import { Checkbox as Checkboxtest } from '../inputs';
import { OnChange } from 'react-final-form-listeners';



function descendingComparator(a, b, orderBy) {
  if (b[orderBy] < a[orderBy]) {
    return -1;
  }
  if (b[orderBy] > a[orderBy]) {
    return 1;
  }
  return 0;
}

function getComparator(order, orderBy) {
  return order === 'desc'
    ? (a, b) => descendingComparator(a, b, orderBy)
    : (a, b) => -descendingComparator(a, b, orderBy);
}

// This method is created for cross-browser compatibility, if you don't
// need to support IE11, you can use Array.prototype.sort() directly
function stableSort(array, comparator) {
  const stabilizedThis = array.map((el, index) => [el, index]);
  stabilizedThis.sort((a, b) => {
    const order = comparator(a[0], b[0]);
    if (order !== 0) {
      return order;
    }
    return a[1] - b[1];
  });
  return stabilizedThis.map((el) => el[0]);
}


function EnhancedTableHead({columns, checkboxSelection, ...props}) {
  const {  order, orderBy,  onRequestSort } = props;
  const createSortHandler = (property) => (event) => {
    onRequestSort(event, property);
  };

  return (
    <TableHead>
      <TableRow>
        {/* <TableCell padding="checkbox">
          <Checkbox
            color="primary"
            indeterminate={numSelected > 0 && numSelected < rowCount}
            checked={rowCount > 0 && numSelected === rowCount}
            onChange={onSelectAllClick}
            inputProps={{
              'aria-label': 'select all desserts',
            }}
          />
        </TableCell> */}
        {checkboxSelection? 
        <>
        <TableCell padding="checkbox">
          <Field
            name="all_shoppers"
            component={Checkboxtest}
            type="checkbox"
          />
        </TableCell>
        <FormSpy
          render={({ form, values }) => (
            <>
            <OnChange name="all_shoppers">
              {value => {
                form.reset({
                  ...values,
                  shoppers:values.shoppers.map((row, index) => {
                    return { ...row, selected:value}
                  })
                });
              }}
            </OnChange>
            </>
          )}
        />
        </>
        :null}

        {columns.map((headCell) => (
          <TableCell
            key={headCell.id}
            align={headCell.numeric ? 'right' : 'left'}
            padding={headCell.disablePadding ? 'none' : 'normal'}
            sortDirection={orderBy === headCell.id ? order : false}
          >
            <TableSortLabel
              active={orderBy === headCell.id}
              direction={orderBy === headCell.id ? order : 'asc'}
              onClick={createSortHandler(headCell.id)}
            >
              {headCell.label}
              {orderBy === headCell.id ? (
                <Box component="span" sx={visuallyHidden}>
                  {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                </Box>
              ) : null}
            </TableSortLabel>
          </TableCell>
        ))}

      </TableRow>
    </TableHead>
  );
}

EnhancedTableHead.propTypes = {
  numSelected: PropTypes.number.isRequired,
  onRequestSort: PropTypes.func.isRequired,
  onSelectAllClick: PropTypes.func.isRequired,
  order: PropTypes.oneOf(['asc', 'desc']).isRequired,
  orderBy: PropTypes.string.isRequired,
  rowCount: PropTypes.number.isRequired,
  checkboxSelection: PropTypes.bool.isRequired
};

const EnhancedTableToolbar = (props) => {
  const { numSelected } = props;

  return (
    <Toolbar
      sx={{
        pl: { sm: 2 },
        pr: { xs: 1, sm: 1 },
        ...(numSelected > 0 && {
          bgcolor: (theme) =>
            alpha(theme.palette.primary.main, theme.palette.action.activatedOpacity),
        }),
      }}
    >
      {numSelected > 0 ? (
        <Typography
          sx={{ flex: '1 1 100%' }}
          color="inherit"
          variant="subtitle1"
          component="div"
        >
          {numSelected} selected
        </Typography>
      ) : (
        <Typography
          sx={{ flex: '1 1 100%' }}
          variant="h6"
          id="tableTitle"
          component="div"
        >
          Shoppers
        </Typography>
      )}

      {/* {numSelected > 0 ? (
        <Tooltip title="Delete">
          <IconButton>
            <DeleteIcon />
          </IconButton>
        </Tooltip>
      ) : (
        <Tooltip title="Filter list">
          <IconButton>
            <FilterListIcon />
          </IconButton>
        </Tooltip>
      )} */}
    </Toolbar>
  );
};

EnhancedTableToolbar.propTypes = {
  numSelected: PropTypes.number.isRequired,
};

export default function EnhancedTable({title, data, columns,actions, checkboxSelection = false, ...props}) {
  const [order, setOrder] = React.useState('asc');
  const [orderBy, setOrderBy] = React.useState('calories');
  const [selected, setSelected] = React.useState([]);
  const [page, setPage] = React.useState(0);
  // const [dense, setDense] = React.useState(false);
  const [rows, setRows] = React.useState(data);

  React.useEffect(() => {
    setRows(data);
  }, [data]);

  const [rowsPerPage, setRowsPerPage] = React.useState(5);

  const handleRequestSort = (event, property) => {
    const isAsc = orderBy === property && order === 'asc';
    setOrder(isAsc ? 'desc' : 'asc');
    setOrderBy(property);
  };

  const handleSelectAllClick = (event) => {
    if (event.target.checked) {
      const newSelecteds = rows.map((n) => n.name);
      setSelected(newSelecteds);
      return;
    }
    setSelected([]);
  };


  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  // const handleChangeDense = (event) => {
  //   setDense(event.target.checked);
  // };

  const isSelected = (name) => selected.indexOf(name) !== -1;

  // Avoid a layout jump when reaching the last page with empty rows.
  const emptyRows =
    page > 0 ? Math.max(0, (1 + page) * rowsPerPage - rows.length) : 0;

  const skeletonArray = Array(10).fill('');

  const renderRow = (data,render) =>{
    let content = JSON.stringify(data); 
    if(render){
      content =  render(data);
    }
    return content;
  }


  const getIndex = (id) => {
    const index = data.findIndex( elem => elem.sku === id);
    return index;
  }

  return (
    <Box sx={{ width: '100%' }}>
      <Paper sx={{ width: '100%', mb: 2 }} elevation={0}>
        <EnhancedTableToolbar numSelected={selected.length} />
        <TableContainer>
          <Table
            sx={{ minWidth: 750 }}
            aria-labelledby="tableTitle"
          >
            <EnhancedTableHead
              numSelected={selected.length}
              order={order}
              orderBy={orderBy}
              onSelectAllClick={handleSelectAllClick}
              onRequestSort={handleRequestSort}
              rowCount={rows.length}
              columns={columns}
              checkboxSelection={checkboxSelection}
            />
            <TableBody>
              {/* if you don't need to support IE11, you can replace the `stableSort` call with:
                 rows.slice().sort(getComparator(order, orderBy)) */}
              {props.loading ? 
                skeletonArray.map((item, index) => (
                  <TableRow key={index}>
                    {columns.map((column, index) => {
                      return (
                      <TableCell key={index}><Skeleton /></TableCell>
                      )
                      })
                    }
                    {(actions && actions.length) && 
                      <TableCell key={index}><Skeleton /></TableCell>
                    }
                  </TableRow>
                ))
                :
                  rows.length > 0 ? 
                  stableSort(rows, getComparator(order, orderBy))
                  .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                  .map((row, index) => {
                    const isItemSelected = isSelected(row.name);
                    return (
                      <TableRow
                        hover
                        // onClick={(event) => handleClick(event, row.name)}
                        role="checkbox"
                        aria-checked={isItemSelected}
                        tabIndex={-1}
                        key={index}
                        selected={isItemSelected}
                      >

                      <TableCell padding="checkbox">
                        <Field
                          // name={`shoppers[${index}].selected`}
                          name={`shoppers[${getIndex(row.sku)}].selected`}
                          component={Checkboxtest}
                          type="checkbox"
                          defaultValue={false}
                          // value={row.sku}
                        />
                      </TableCell>

                      {/* { props.loading ? null :
                        <FormSpy
                          render={({ form, values }) => (
                            <>
                            <OnChange name={`shoppers[${getIndex(row.sku)}].selected`}>
                              {value => {
                                if(value){
                                  values.shoppers.splice(getIndex(row.sku), 1, {selected:value, sku:row.sku});
                                }else{
                                  // values.contracts.splice(index, 1);
                                }
                                form.reset({
                                  ...values,
                                });
                              }}
                            </OnChange>
                            </>
                          )}
                        />
                      } */}
                    {/* {checkboxSelection? :null} */}
                        {/* <TableCell padding="checkbox">
                          <Checkbox
                            color="primary"
                            checked={isItemSelected}
                            inputProps={{
                              'aria-labelledby': labelId,
                            }}
                          />
                        </TableCell> */}
                        {columns.map((column, index) => {
                          return (
                            <TableCell key={index} align={column.numeric ? 'right' : 'left'} >
                              {
                                typeof row[column.id] === "object" && row[column.id] !== null ? 
                                row[column.id] = renderRow(row[column.id],column.render)
                                : row[column.id]
                              }
                              </TableCell>
                          )
                          }
                        )}

                        {actions && actions.length?
                        (
                          <TableCell align="right">
                            {
                              actions.map((action, index) => (
                                <Tooltip title={action.tooltip} key={index}>
                                  <IconButton 
                                    size="small" 
                                    sx={{padding: 10}}
                                    variant="outlined" 
                                    aria-label="delete"
                                    color="secondary"
                                    onClick={(event) => { action.onClick(event, row)}}
                                  >
                                    <action.icon />
                                  </IconButton>
                                </Tooltip>
                              ))
                            }
                          </TableCell>
                        )
                        :null
                        }
                      </TableRow>
                    );
                  })
                  :
                  emptyRows > 0 && (
                    <TableRow style={{ height: 53 * emptyRows }}>
                      <TableCell colSpan={columns.length + (actions && actions.length ? 1: 0) }>
                      </TableCell>
                    </TableRow>
                  )
                }
            </TableBody>
          </Table>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={rows.length}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </Paper>
      {/* <FormControlLabel
        control={<Switch checked={dense} onChange={handleChangeDense} />}
        label="Dense padding"
      /> */}
    </Box>
  );
}
