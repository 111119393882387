import dataList from '../../sagas/data_list.saga';
import apiCall from '../../sagas/api_call.saga';
import navbar from '../reducers/navbar.reducer';
import snackbar from '../reducers/snackbar.reducer';
import backdrop from '../reducers/backdrop.reducer';
import api_call from '../reducers/api_call.reducer';
import data_list from '../reducers/data_list.reducer';
import floating_menu from '../reducers/floating_menu.reducer';
import auth_reducer from '../reducers/auth.reducer';
import auth_saga from '../../sagas/auth.saga';
// import modules from './modules';

export function getMainModule() {
    return {
        // Unique id of the module
        id: "main",
        // Maps the Store key to the reducer
        reducerMap: {
          navbar: navbar,
          snackbar:snackbar,
          backdrop:backdrop,
          api_call:api_call,
          data_list:data_list,
          floating_menu:floating_menu,
          auth: auth_reducer
        },
        // This module uses redux-saga middleware
        // This property will be be used by the SagaExtension
        // to run sagas for the moduleD
        sagas: [dataList,apiCall,auth_saga]
    }; 
}