import {results as actions} from '../../actions/external';

const initialState = {
  business_branches:{
    list: [],
    loading: false,
    success: false,
  },
  results_by_brand:{
    element:{
      categorias: [],
      establecimientos: {},
      marca: "",
      mes: "",
      meses: [],
      resultados_categorias: {},
      resultados_globales: [],
      resultados_multiple: []
    },
    loading: false,
    success: false,
  },
  results_by_business_branch:{
    element:{
      resultado_multiple: [],
      resultados_abiertas: {},
      resultados_categoria: [],
      resultados_comentarios:[],
      resultados_extra: {establecimiento: "", encuesta: '', meses: [], mes: ''},
      resultados_globales: [],
      resultados_imagenes: [],
      resultados_meses: {},
      resultados_numericos: {},
    },
    loading: false,
    success: false,
  }
};


const reducer = function (state = initialState, action) {
  switch (action.type) {
    case actions.BY_BRAND_REQUEST:
    {
      return {
        ...state,
        results_by_brand: {
          ...initialState.results_by_brand,
          loading: true,
          success: false,
        },
      }
    }
    case actions.BY_BRAND_FAILURE:
    {
      return {
        ...state,
        results_by_brand: {
          ...initialState.results_by_brand,
          loading: false,
          success: false,
        },
      }
    }
    case actions.BY_BRAND_SUCCESS:
    {
      return {
        ...state,
        results_by_brand: {
          element: action.payload,
          loading: false,
          success: true,
        },
      }
    }
    case actions.BY_BUSINESS_BRANCH_REQUEST:
    {
      return {
        ...state,
        results_by_business_branch: {
          ...initialState.results_by_business_branch,
          loading: true,
          success: false,
        },
      }
    }
    case actions.BY_BUSINESS_BRANCH_FAILURE:
    {
      return {
        ...state,
        results_by_business_branch: {
          ...initialState.results_by_business_branch,
          loading: false,
          success: false,
        },
      }
    }
    case actions.BY_BUSINESS_BRANCH_SUCCESS:
    {
      return {
        ...state,
        results_by_business_branch: {
          element: action.payload,
          loading: false,
          success: true,
        },
      }
    }
    case actions.SHARE_REQUEST:
    {
      return {
        ...state,
        share: {
          ...state.share,
          loading: true,
          success: false,
        },
      }
    }
    case actions.BUSINESS_BRANCHES_REQUEST:
    {
      return {
        ...state,
        business_branches: {
          ...state.business_branches,
          loading: true,
          success: false,
        },
      }
    }
    case actions.BUSINESS_BRANCHES_FAILURE:
    {
      return {
        ...state,
        business_branches: {
          ...state.business_branches,
          loading: false,
          success: false,
        },
      }
    }
    case actions.BUSINESS_BRANCHES_SUCCESS:
    {
      return {
        ...state,
        business_branches: {
          list: action.payload,
          loading: false,
          success: true,
        },
      }
    }
    case actions.RESET:{
      return {
        ...initialState
      }
    }
    default:{return state;}
  }

}

export default reducer;
