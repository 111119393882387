import mainSaga from '../../../sagas/external/shoppers.saga';
import mainReducer from '../../reducers/external/shoppers.reducer';
import {shoppers as actions} from '../../actions/external';

export function getModule() {
    return {
        id: "external_shoppers",
        reducerMap: {
          external_shoppers: mainReducer,
        },
        sagas: [mainSaga],
        finalActions: [actions.reset()],
    }; 
}