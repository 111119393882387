import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import data_service from "../services/instructives";
import lists_service from "../services/lists";

export default function* root() {
  yield all([
    fork(watcherDetail),
    fork(watcherUpdate),
    fork(watcherCreate),
    fork(watcherBrands),
    fork(watcherBusinessBranches),
  ])
}

function* watcherDetail() {
  yield takeEvery("[MODULE][INSTRUCTIVES] DETAIL", workerDetail);
}

function* watcherUpdate() {
  yield takeEvery("[MODULE][INSTRUCTIVES] UPDATE", workerUpdate);
}

function* watcherCreate() {
  yield takeEvery("[MODULE][INSTRUCTIVES] CREATE", workerCreate);
}

function* watcherBrands() {
  yield takeEvery("[MODULE][INSTRUCTIVES][BRANDS] REQUEST", workerBrands);
}

function* watcherBusinessBranches() {
  yield takeEvery("[MODULE][INSTRUCTIVES][BUSINESS_BRANCHES] REQUEST", workerBusinessBranches);
}


function* workerDetail(action){
  try {
    yield put({ type: "[MODULE][INSTRUCTIVES] RESET" });
    yield put({ type: "[MODULE][INSTRUCTIVES][ELEMENT] REQUEST" });
    //element
    const element = yield call(getElement, action.payload);
    //brands
    // yield put({ type: "[MODULE][INSTRUCTIVES][BRANDS] REQUEST"});
    const brands = yield call(getBrands,{slug:element.customer_id});
    yield put({ type: "[MODULE][INSTRUCTIVES][BRANDS] SUCCESS",payload: brands});
    
    //business_branches
    // yield put({ type: "[MODULE][INSTRUCTIVES][BUSINESS_BRANCHES] REQUEST"});
    const business_branches = yield call(getBusinessBranches,{slug:element.brand_id});
    yield put({ type: "[MODULE][INSTRUCTIVES][BUSINESS_BRANCHES] SUCCESS",payload: business_branches});
    
    //customers
    // yield put({ type: "[MODULE][INSTRUCTIVES][CUSTOMERS] REQUEST" });
    const customers = yield call(getCustomers);
    yield put({ type: "[MODULE][INSTRUCTIVES][CUSTOMERS] SUCCESS",payload: customers});
   
    const data = mutator(customers,brands,business_branches,element);
    yield put({ type: "[MODULE][INSTRUCTIVES][ELEMENT] SUCCESS", payload: data });
  } catch (e) {
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][INSTRUCTIVES][CUSTOMERS] FAILURE", payload: e });
    yield put({ type: "[MODULE][INSTRUCTIVES][BUSINESS_BRANCHES] FAILURE", payload: e });
    yield put({ type: "[MODULE][INSTRUCTIVES][BRANDS] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerUpdate(action) {
  try {
    yield put({ type: "[MODULE][INSTRUCTIVES] RESET" });
    yield put({ type: "[MODULE][INSTRUCTIVES][ELEMENT] REQUEST" });
    //element
    const element = yield call(getElement, action.payload);
    //brands
    // yield put({ type: "[MODULE][INSTRUCTIVES][BRANDS] REQUEST"});
    const brands = yield call(getBrands,{slug:element.customer_id});
    yield put({ type: "[MODULE][INSTRUCTIVES][BRANDS] SUCCESS",payload: brands});
    
    //business_branches
    // yield put({ type: "[MODULE][INSTRUCTIVES][BUSINESS_BRANCHES] REQUEST"});
    const business_branches = yield call(getBusinessBranches,{slug:element.brand_id});
    yield put({ type: "[MODULE][INSTRUCTIVES][BUSINESS_BRANCHES] SUCCESS",payload: business_branches});
    
    //customers
    yield put({ type: "[MODULE][INSTRUCTIVES][CUSTOMERS] REQUEST" });
    const customers = yield call(getCustomers);
    yield put({ type: "[MODULE][INSTRUCTIVES][CUSTOMERS] SUCCESS",payload: customers});
   
    const data = mutator(customers,brands,business_branches,element);
    yield put({ type: "[MODULE][INSTRUCTIVES][ELEMENT] SUCCESS", payload: data });
  } catch (e) {
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][INSTRUCTIVES][CUSTOMERS] FAILURE", payload: e });
    yield put({ type: "[MODULE][INSTRUCTIVES][BUSINESS_BRANCHES] FAILURE", payload: e });
    yield put({ type: "[MODULE][INSTRUCTIVES][BRANDS] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerCreate() {
  try {
    yield put({ type: "[MODULE][INSTRUCTIVES] RESET" });
    //customers
    yield put({ type: "[MODULE][INSTRUCTIVES][CUSTOMERS] REQUEST" });
    const customers = yield call(getCustomers);
    yield put({ type: "[MODULE][INSTRUCTIVES][CUSTOMERS] SUCCESS",payload: customers});
    
  } catch (e) {
    console.log(e, "here");
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][INSTRUCTIVES][CUSTOMERS] FAILURE", payload: e });
  }
}

function* workerBrands(action) {
  try {
    // yield put({ type: "[MODULE][INSTRUCTIVES][BRANDS] REQUEST" });
    const neighborhoods = yield call(getBrands, action.payload);
    yield put({ type: "[MODULE][INSTRUCTIVES][BRANDS] SUCCESS",payload: neighborhoods});
  } catch (e) {
    console.log(e);
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][INSTRUCTIVES][BRANDS] FAILURE", payload: e });
  }
}

function* workerBusinessBranches(action) {
  try {
    // yield put({ type: "[MODULE][INSTRUCTIVES][BUSINESS_BRANCHES] REQUEST" });
    const neighborhoods = yield call(getBusinessBranches, action.payload);
    yield put({ type: "[MODULE][INSTRUCTIVES][BUSINESS_BRANCHES] SUCCESS",payload: neighborhoods});
  } catch (e) {
    console.log(e);
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][INSTRUCTIVES][BUSINESS_BRANCHES] FAILURE", payload: e });
  }
}


function getElement( payload) {
  return new Promise( (resolve, reject) => {
    data_service.detail(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getCustomers() {
  return new Promise( (resolve, reject) => {
    lists_service.customers()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getBrands(payload) {
  return new Promise( (resolve, reject) => {
    lists_service.brandsByCustomer(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}


function getBusinessBranches(payload) {
  return new Promise( (resolve, reject) => {
    lists_service.businessBranchesByBrand(payload) 
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}


function mutator(customers,brands,business_branches,element){

  const customer = customers.find( item => item.value === element.customer_id );
  const bussiness_branch = business_branches.find( item => item.value === element.bussiness_branch_id );
  const brand = brands.find( item => item.value === element.brand_id );

  const data = {
    ...element, 
    ...{
      customer_id: customer ? {text: customer.text, value: customer.value} : null,
      bussiness_branch_id: bussiness_branch ? {text: bussiness_branch.text, value: bussiness_branch.value} : null,
      brand_id: brand ? {text: brand.text, value: brand.value} : null
    }
  };

  return data;
}
