import {instructives as actions} from '../../actions/modules';

const initialState = {
  element: {
    name: null,
    bussiness_branch_id: null,
    contents: null
  },
  status_element: {
    loading: false,
    success: false,
  },
  customers: [],
  status_customers: {
    loading: false,
    success: false,
  },
  brands: [],
  status_brands: {
    loading: false,
    success: false,
  },
  business_branches: [],
  status_business_branches: {
    loading: false,
    success: false,
  },
};

const reducer = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.ELEMENT_REQUEST:
    {
      return {
        ...state,
        status_element: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.ELEMENT_SUCCESS:
    {
      return {
        ...state,
        element: action.payload,
        status_element: {
          loading: false,
          success: true,
        },
      }
    }
    case actions.ELEMENT_FAILURE:
    {
      return {
        ...state,
        element: initialState.element,
        status_element: {
          loading: false,
          success: false,
        },
      }
    }
    case actions.BUSINESS_BRANCHES_REQUEST:
    {
      return {
        ...state,
        status_business_branches: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.BUSINESS_BRANCHES_SUCCESS:
    {
      return {
        ...state,
        status_business_branches: {
          loading: false,
          success: true,
        },
        business_branches: action.payload
      }
    }
    case actions.BUSINESS_BRANCHES_FAILURE:
    {
      return {
        ...state,
        status_business_branches: {
          loading: false,
          success: false,
        },
        business_branches: null
      }
    }
    case actions.CUSTOMERS_REQUEST:
    {
      return {
        ...state,
        status_customer: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.CUSTOMERS_SUCCESS:
    {
      return {
        ...state,
        status_customer: {
          loading: false,
          success: true,
        },
        customers: action.payload
      }
    }
    case actions.CUSTOMERS_FAILURE:
    {
      return {
        ...state,
        status_customer: {
          loading: false,
          success: false,
        },
        customers: null
      }
    }
    case actions.BRANDS_REQUEST:
    {
      return {
        ...state,
        status_brands: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.BRANDS_SUCCESS:
    {
      return {
        ...state,
        status_brands: {
          loading: false,
          success: true,
        },
        brands: action.payload
      }
    }
    case actions.BRANDS_FAILURE:
    {
      return {
        ...state,
        status_brands: {
          loading: false,
          success: false,
        },
        brands: null
      }
    }
    case actions.RESET:
    {
      return {
        ...initialState,
        status_element: {
          loading: false,
          success: false,
        },
        element: initialState.element
      }
    }
    default:
    {
      return state;
    }
  }
};

export default reducer;