import { Validators } from '@lemoncode/fonk';
import { createFinalFormValidation } from '@lemoncode/fonk-final-form';

export const validationSchema = {
  field: {
    email:[Validators.required.validator, Validators.email.validator],
    password:[Validators.required.validator],
    password_confirmation:[Validators.required.validator]
  }
};

export const formValidation = createFinalFormValidation(validationSchema);

