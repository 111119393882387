// action types
export const RESET = "[MODULE][INSTRUCTIVES] RESET";
//element actions
export const ELEMENT_REQUEST = "[MODULE][INSTRUCTIVES][ELEMENT] REQUEST";
export const ELEMENT_SUCCESS = "[MODULE][INSTRUCTIVES][ELEMENT] SUCCESS";
export const ELEMENT_FAILURE = "[MODULE][INSTRUCTIVES][ELEMENT] FAILURE";
//catalogs actions
export const CUSTOMERS_REQUEST = "[MODULE][INSTRUCTIVES][CUSTOMERS] REQUEST";
export const CUSTOMERS_SUCCESS = "[MODULE][INSTRUCTIVES][CUSTOMERS] SUCCESS";
export const CUSTOMERS_FAILURE = "[MODULE][INSTRUCTIVES][CUSTOMERS] FAILURE";
//catalogs actions
export const BRANDS_REQUEST = "[MODULE][INSTRUCTIVES][BRANDS] REQUEST";
export const BRANDS_SUCCESS = "[MODULE][INSTRUCTIVES][BRANDS] SUCCESS";
export const BRANDS_FAILURE = "[MODULE][INSTRUCTIVES][BRANDS] FAILURE";
//business actions
export const BUSINESS_BRANCHES_REQUEST = "[MODULE][INSTRUCTIVES][BUSINESS_BRANCHES] REQUEST";
export const BUSINESS_BRANCHES_SUCCESS = "[MODULE][INSTRUCTIVES][BUSINESS_BRANCHES] SUCCESS";
export const BUSINESS_BRANCHES_FAILURE = "[MODULE][INSTRUCTIVES][BUSINESS_BRANCHES] FAILURE";
//module actions
export const DETAIL = "[MODULE][INSTRUCTIVES] DETAIL";
export const CREATE = "[MODULE][INSTRUCTIVES] CREATE";
export const UPDATE = "[MODULE][INSTRUCTIVES] UPDATE";

export const update = (payload)=> {
  return { type: UPDATE, payload };
}

export const create = ()=> {
  return { type: CREATE };
}

export const detail = (payload)=> {
  return { type: DETAIL, payload};
}

export const reset = ()=> {
  return { type: RESET };
}

export const brands = (payload )=> {
  return { type: BRANDS_REQUEST,payload };
}

export const businessBranches = (payload )=> {
  return { type: BUSINESS_BRANCHES_REQUEST,payload };
}
