import React, {Component} from 'react';
import {
  Paper,
  Grid,
  Typography,
} from '@mui/material';
import styles from './index.module.css';
import { Field, FormSpy } from 'react-final-form';
import {Input, AutoComplete, TextEditor} from '../../../components/inputs';
import { connect } from 'react-redux';
import { modules } from '../../../store/actions';
import { OnChange } from 'react-final-form-listeners';
class FormComponent extends Component {

  constructor(props) {
    super(props);
    this.state = {
    }
  }

  async componentDidMount(){
    // this.setState({loanding_brands: true});
    // await this.getBrands();
    // this.setState({loanding_brands: false});
  }

  async componentDidUpdate(prevProps) {
    // if (this.props.zip_code !== prevProps.zip_code) {
    //   await this.getNeighborhoods(this.props.zip_code);
    // }
  }

  onChangeHandlerCustomer = (data) => {
    if(data){
      this.props.getBrands({slug:data.value});
    }
  }

  onChangeHandlerBrand = (data) => {
    if(data){
      this.props.getBusinessBranches({slug:data.value});
    }
  }
  
  render() {
    return (
      
      <Grid item container spacing={3}>
        <Grid item lg={12}>
          <Typography variant="h6" component="div">
            Datos Generales
          </Typography>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            {...((this.props.renew )?{disabled:true}: null)}
            waiting={this.props.waiting}
            name="name"
            fullWidth
            component={Input}
            type="text"
            label="Nombre"
          />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
            <Field
              required
              waiting={this.props.waiting}
              name="customer_id"
              component={AutoComplete}
              label="Cliente"
              type="text"
              options={this.props.customers}
              loading={this.props.status_customers.loading}
              onChange={(option) => this.onChangeHandlerCustomer(option)}
            />
          </Paper>
        </Grid>

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            {...((this.props.renew )?{disabled:true}: null)}
            {...((this.props.values && !this.props.values.change_survey)?{disabled:true}: null)}
            name="brand_id"
            waiting={this.props.waiting}
            component={AutoComplete}
            label="Marcas"
            type="text"
            options={this.props.brands}
            loading={this.props.status_brands.loading}
            onChange={(option) => this.onChangeHandlerBrand(option)}
            />
          </Paper>
        </Grid>
        {
          this.props.waiting ? null :
          <FormSpy
            render={({ form, values }) => (
              <>
              <OnChange name="customer_id">
                {value => {
                  form.reset({
                    ...values,
                    customer_id: value,
                    brand_id: null,
                    bussiness_branch_id: null,
                  });
                }}
              </OnChange>
              <OnChange name="brand_id">
                {value => {
                  form.reset({
                    ...values,
                    brand_id: value,
                  });
                }}
              </OnChange>
              </>
            )}
          />
        }

        <Grid item xs={6} sm={6} md={3} lg={3} xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            {...((this.props.renew )?{disabled:true}: null)}
            waiting={this.props.waiting}
            name="bussiness_branch_id"
            component={AutoComplete}
            label="Establecimiento"
            type="text"
            options={this.props.business_branches}
            loading={this.props.status_business_branches.loading}
            />
          </Paper>
        </Grid>

        <Grid item xs={12}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            waiting={this.props.waiting}
            required
            name="contents"
            fullWidth
            component={TextEditor}
            type="text"
            label="Contenido"
          />
          </Paper>
        </Grid>

      </Grid>
    );
  }

}

const mapDispatchToProps = (dispatch) => {
  return {
    getBrands:(payload) =>{
      dispatch(modules.instructives.brands(payload))
    },
    getBusinessBranches:(payload) =>{
      dispatch(modules.instructives.businessBranches(payload))
    }
  }
};

const mapStateToProps = (state) => {
  return {
    loading: state.module_instructives.status_element.loading,
    business_branches:  state.module_instructives.business_branches,
    customers:  state.module_instructives.customers,
    brands:  state.module_instructives.brands,
    status_business_branches: state.module_instructives.status_business_branches,
    status_customers: state.module_instructives.status_customers,
    status_brands: state.module_instructives.status_brands,
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(FormComponent);

export default _component;
