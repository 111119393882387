import React from 'react';
import {Container, Paper } from '@mui/material';
import { connect } from 'react-redux';
import { modules } from '../../store/actions';
import styles from './index.module.css';
import Table from '../../components/table';
import { withRouter } from "react-router-dom";
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';

class MainComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      dialog_open: false,
      element: null,
      record_slug:null,
      loading:false
    };
  }

  async componentDidMount() {
    this.initialLoad();
  }

  async initialLoad(){
    this.props.getList();
  }

  componentWillUnmount(){
    this.props.clearList();
  }

  address(data){
    const content =  `${data.city}, ${data.state}`;
    return content;
  };

  render() {

    const columns = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Establecimiento',sortable: true },
      { id: 'total_pendings', numeric: false, disablePadding: true, label: 'Pendientes',sortable: true, render: (data)=>  data ? data : "0" },
      { id: 'total_visits', numeric: false, disablePadding: true, label: 'Totales',sortable: true },
      { id: 'address_code', numeric: true, disablePadding: false, label: 'Dirección',sortable: true, render: (data)=>this.address(data)}
    ];
    const maxWidth = false;
    return (
      <Container maxWidth={maxWidth} className={styles['client-container']}>
          {/* table */}
          <Paper elevation={1} className={styles.paper}>
            <Table
              title = {"Visitas"}
              data = {this.props.list}
              columns={columns}
              addAction ={(event) => {this.props.history.push("/visits/invitation");}}
              titleAction={"Invitar"}
              actions={[
                {
                  icon: InfoOutlinedIcon,
                  tooltip: 'Info',
                  onClick: (event, row) => {this.props.history.push(`/visits/${row.sku}/contractVisits`)}
                }
              ]}
              loading={this.props.status_list.loading}
            />
          </Paper>
      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getList:()=>{
      dispatch(modules.contract_visits.list());
    },
    clearList:()=>{
      dispatch(modules.contract_visits.reset());
    }
  }
};

const mapStateToProps = (state) => {
  return {
    list: state.module_contract_visits.list,
    status_list: state.module_contract_visits.status_list,
  };
}


const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent);

export default withRouter(_component);