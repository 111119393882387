import React, {Component} from 'react';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import withStyles from '@mui/styles/withStyles';
import logo from '../../../../assets/images/logotipo_color.svg';
import useScrollTrigger from '@mui/material/useScrollTrigger';
import PropTypes from 'prop-types';


const useStyles = (theme) => ({
  toolbar: {
    // minHeight: 128,
    // alignItems: 'flex-start',
    paddingLeft: '160px',
    paddingTop: theme.spacing(1),
    paddingBottom: theme.spacing(2),
    paddingRight: '0px !important'
  },
  appBar: {
  },

  speedDial: {
    // position: 'absolute',
    '&.MuiSpeedDial-directionUp, &.MuiSpeedDial-directionLeft': {
      bottom: '4px',
      right: theme.spacing(2),
    },
    '&.MuiSpeedDial-directionDown, &.MuiSpeedDial-directionRight': {
      top: theme.spacing(2),
      left: theme.spacing(2),
    },
    fab: {
      color: '#2c2e2e',
      backgroundColor: '#2c2e2e00',
      border: '1px solid rgba(44, 46, 46, 0.5)',
      boxShadow: 'none'
    }
  },
  avatar:{
    width: '100%',
    height: '100%',
    color: "#2c2e2e",
    backgroundColor: "inherit"
  },
  paper:{
    outlined:{
      borderRadius: '24px 0px 0px 24px'
    },
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  git: {
    fontStyle: 'normal',
    fontWeight: 'normal',
    fontSize: 16,
    lineHeight: '26px',
    marginLeft: 7,
  },
  flexGrow: {
    flexGrow: 1,
  },
  grow: {
    flexGrow: 1,
  },
  menuButton:{
    padding: '0px !important',
    marginTop: 'auto'
  }
});

function ElevationScroll(props) {
  const { children, window } = props;
  // Note that you normally won't need to set the window ref as useScrollTrigger
  // will default to window.
  // This is only being set here because the demo is in an iframe.
  const trigger = useScrollTrigger({
    disableHysteresis: true,
    threshold: 0,
    target: window ? window() : undefined,
  });

  return React.cloneElement(children, {
    elevation: trigger ? 4 : 0,
  });
}

ElevationScroll.propTypes = {
  children: PropTypes.element.isRequired,
  /**
   * Injected by the documentation to work in an iframe.
   * You won't need it on your project.
   */
  window: PropTypes.func,
};


class Header extends Component {
  constructor(props) {
    // console.log(props);
    super(props);
    this.state = {
      open: true,
      speed_dial_open: false,
      speed_dial_hidden: false,
      anchorEl: null
    };
  }

  componentDidUpdate(previousProps, previousState) {
    // console.log(previousState);
    
    if (previousState.open !== this.state.open) {
    }
      // if (previousProps.data !== this.props.data) {
      //     this.setState({/*....*/})
      // }
  }

  render() {
    const { classes } = this.props;
    
    return (
      <ElevationScroll {...this.props}>
        <AppBar color="inherit" position="fixed" className={classes.appBar}>
          <Toolbar className={classes.toolbar}>
            <img src={logo} width="236px" alt={""} height = "35px"/>
            <div className={classes.grow} />
          </Toolbar>
        </AppBar>
      </ElevationScroll>
    );
  }
}

// const mapDispatchToProps = (dispatch) => {
//   return {
//       navbarOpenFolded: () => dispatch(navbarActions.navbarOpenFolded())
//   }
// };


// const mapStateToProps = (state, ownProps = {}) => {
//   console.log(state) // state
//   console.log(ownProps) // {}
// }

// const mapStateToProps = state => ({
//   navbar_open: state.navbar.foldedOpen
// });

// const _component = connect(
//   mapStateToProps,
//   mapDispatchToProps
// )(Header);

export default withStyles(useStyles)(Header)