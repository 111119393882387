import React from 'react';
import {
  Container, 
  Grid,
  Toolbar,
  Typography,
  Button,
  IconButton,
  FormControl,
  Select,
  MenuItem,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from '@mui/material';
import { connect } from 'react-redux';
import { results } from '../../../store/actions/modules';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import ResultsComponent  from '../../../components/ResultsByBusinessBranch';
import { CopyToClipboard } from "react-copy-to-clipboard";
import {FloatingMenu} from '../../../store/actions';


class MainComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      contract: null,
      month:'',
      dialog_open:false
    };
  }

  async componentDidMount() {
    
    this.initialLoad();
  }

  componentDidUpdate(prevProps) {

    if (prevProps.results !== this.props.results) {
      this.setState({month:this.props.results.resultados_extra.mes});
    }

    if (prevProps.share !== this.props.share) {
      if(this.props.share.success && this.props.share.element){
        this.setState({dialog_open:true});
      }
    }

  }

  initialLoad(){

    const {contract} = this.props.match.params;
    this.setState({
      contract: contract
    });
    this.props.getModuleData({
      contract: contract
    });
  }

  getData(){
    this.props.getModuleData({
      contract: this.state.contract,
      month: this.state.month,
    });
  }

  componentWillUnmount(){
    this.props.clearList();
    this.props.hideMenu();
  }

  hangleChangeMonth = (event) =>{
    const month = event.target.value;
    this.setState({month: month}, () => this.getData());
  }

  shareResults = (event) =>{
    this.props.getShare({
      contract: this.state.contract,
      month: this.state.month
    });
  }


  render() {
    const buttonSx = {
      ...(this.props.status_results.loading && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    const maxWidth = false;


    return (
      <Container maxWidth={maxWidth} className={styles['client-container']}>
          {/* <Paper elevation={1} className={styles.paper}>
          
          </Paper> */}

          <Dialog open={this.state.dialog_open} onClose={()=>{this.setState({dialog_open:false})}}>
            <DialogTitle>Compartir resultados</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Copia el siguiente enlace para compartir los resultados del estudio.
              </DialogContentText>
              <TextField
                autoFocus
                margin="dense"
                label="Enlance"
                type="text"
                fullWidth
                id="shared_link"
                variant="standard"
                value={`${window.location.protocol}//${window.location.hostname}/external/results/byBusinessBranch/${this.props.share.element}`}
              />
            </DialogContent>
            <DialogActions>
              <Button onClick={()=>{this.setState({dialog_open:false})}}>Cancel</Button>
              <CopyToClipboard text={`${window.location.protocol}//${window.location.hostname}/external/results/byBusinessBranch/${this.props.share.element}`}>
                <Button onClick={()=>{this.setState({dialog_open:false})}}>Copiar</Button>
              </CopyToClipboard>
            </DialogActions>
        </Dialog>

        <Grid container spacing={2} direction="column">

              <Grid container item spacing={3} justifyContent="flex-end">
                {/* Title */}
                <Grid item xs>
                  <Toolbar className={styles['toolbar']}>
                    <IconButton onClick={ () => this.props.history.push(`/results`) }>
                      <ChevronLeftIcon />
                    </IconButton>
                    <Typography variant="h4" id="tableTitle" component="div">
                      {this.props.results.resultados_extra.encuesta}
                    </Typography>
                  </Toolbar>
                </Grid>
                {/* Buttons */}
                
                <Grid item>
                  <FormControl label="Periodo" fullWidth size="small">
                    <Select  
                    disabled={this.props.status_results.loading}
                    value={this.state.month} onChange={event =>this.hangleChangeMonth(event)}>
                    {this.props.results.resultados_extra.meses.map((option, index) => (
                        <MenuItem key={index} value={option.id}>{option.mes}</MenuItem>
                      ))}
                    </Select>
                </FormControl>
                </Grid>

                <Grid item>
                  <Button
                    variant="contained"
                    size="small"
                    sx={buttonSx}
                    onClick={event =>this.shareResults(event)}
                    // disabled={this.props.status_results.loading || this.props.status_element.loading}
                    disabled={this.props.status_results.loading || this.props.share.loading}
                    type="submit"
                  >
                    Compartir
                    {(this.props.status_results.loading || this.props.share.loading ) && (
                      <CircularProgress
                        size={24}
                        sx={{
                          color: green[500],
                          position: 'absolute',
                          top: '50%',
                          left: '50%',
                          marginTop: '-12px',
                          marginLeft: '-12px',
                        }}
                      />
                    )}
                  </Button>
                </Grid>
              </Grid>
        </Grid>

        <Grid item xs>
          <Toolbar className={styles['toolbar']}>
            <Typography variant="h4" id="tableTitle" component="div">
              {'PRESENTACIÓN DE RESULTADOS'}
              <Typography variant="caption" display="block" gutterBottom>
              {`El siguiente reporte muestra los resultados del estudio realizado a los restaurantes de ${this.props.results.resultados_extra.establecimiento} durante el mes ${this.props.results.resultados_extra.mes}`}
              </Typography>
            </Typography>
          </Toolbar>
        </Grid>

        <Grid item >
          <ResultsComponent
            global_results={this.props.results.resultados_globales}
            catalog_results={this.props.results.resultados_categoria}
            numeric_results={this.props.results.resultados_numericos}
            open_questions_results={this.props.results.resultados_abiertas}
            commentaries={this.props.results.resultados_comentarios}
            images={this.props.results.resultados_imagenes}
            multiple_results={this.props.results.resultado_multiple}
            loading={this.props.status_results.loading}
          />
        </Grid>


      </Container>
    )
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleData:(payload)=>{
      dispatch(results.byBusinessBranch(payload));
    },
    clearList:()=>{
      dispatch(results.reset());
    },
    getShare:(payload)=>{
      dispatch(results.shareByBusinessBranch(payload));
    },
    hideMenu:()=>{
      dispatch(FloatingMenu.clear());
    }
  }
};

const mapStateToProps = (state) => {
  return {
    results: state.module_results.results_by_business_branch.element,
    status_results: state.module_results.results_by_business_branch,
    share: state.module_results.share,
  };
}


const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent);

export default withRouter(_component);