import {useRef, useEffect, createRef} from "react";
import { VariableSizeList  } from "react-window";
import { useFieldArray, useForm } from "react-hook-form";
import AutoSizer from "react-virtualized-auto-sizer";

import { useTheme } from "@mui/styles";

import { useParams, useHistory  } from "react-router-dom";

import { useDispatch, useSelector } from 'react-redux';

import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button,
} from '@mui/material';
import styles from './index.module.css';
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import FormRecord from '../form';
import FormQuestions from '../form/questions';
import { surveys  as actions} from '../../../store/actions/modules';
// import { formValidation } from './validator';
import { ResolverSurveyCreateSchema } from "../../../schemas/survey.create.scheme";
import ListQuestions from '../form/list-questions';


const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
});

const id = (length = 6) => {
  return new Date().getTime().toString().slice(-length);
}

const createEmptyQuestion = ( key ) => {
  const _key = key ? key :id(); 
  return {
    id: _key,
    categoria_id: null,
    titulo: null,
    descripcion: "",
    tipo: null,
    prioridad: null,
    ponderacion: null,
    no_pregunta: 0,//eliminar
    obligatorio: true,
    saltos: [],
    etiquetas: {
      inicial: null,
      final: null
    },
    unidad: null,
    invertir: false,
    preset_opciones: {
      no_aplica: false,
      otro: false
    },
    opciones: [
      {
        opcion: null,
        ponderacion: null
      }
    ],
    ver_resultados: true,
    ver_encuesta: true
    // skip_to:null
  }
};
// getID = (questions) =>{
//   var id = questions.length;
//   while( questions.find( question => question.id === id ) ){
//     id++;
//   }
//   return id;
// }


function UpdateComponent(props) {
  const theme = useTheme();

  const classes = useStyles(theme);
  const dispatch = useDispatch();
  
  const catalogs = useSelector(state => state.module_surveys.catalogs);
  const save = useSelector(state => state.module_surveys.save);
  const element = useSelector(state => state.module_surveys.element);
  const status_element = useSelector(state => state.module_surveys.status_element);
  const getModuleData = (payload) => dispatch(actions.edit(payload));
  const createRecord = (payload) => dispatch(actions.store(payload));

  const {slug} = useParams();

  // const items_test = Array.from(Array(50).keys()).map((i) => ( createEmptyQuestion() ))

  const location = useHistory();
  //variables for forms
  const { control, getValues, handleSubmit, setValue } = useForm({
    resolver: ResolverSurveyCreateSchema,
    defaultValues: element,
    mode: 'onBlur'
  });

  const onSubmit = (data) => {
    createRecord({slug: slug, data: data});
  }

  const submit = () => {
		if (formRef.current) {
			formRef.current.dispatchEvent(
				new Event('submit', { cancelable: true, bubbles: true })
			)
		}
	}

  const { fields, remove, append, move } = useFieldArray({ control, name: "questions" });
  const formRef = useRef(null);

  //item size

  useEffect( () => {
    getModuleData({slug: slug});
  } , []); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect( () => {
    if(save.success){
      location.push("/surveys");
      dispatch(actions.reset());
    }
  } , [save, status_element]); // eslint-disable-line react-hooks/exhaustive-deps


  useEffect( () => {
    if(!status_element.loading && status_element.success){
      // console.log(element);
      setValue('title', element.title );
      setValue('questions', element.questions);
    }
  } , [status_element.loading, status_element.success]); // eslint-disable-line react-hooks/exhaustive-deps

  const virtual_list = createRef();

  const addQuestion= (index) =>{
    virtual_list.current.resetAfterIndex(0, true);
    append(createEmptyQuestion());
    virtual_list.current.scrollToItem(fields.length - 1 , "end");
  };

  const allowEdit = (data) =>{
    return Boolean(element.questions.findIndex( x => x.id === data.id) >= 0 );
  }

  const rowHeights = useRef({});

  function setRowHeight(index, size) {
    virtual_list.current.resetAfterIndex(0);
    rowHeights.current = { ...rowHeights.current, [index]: size };
  }

  function getRowHeight(index) {
    return rowHeights.current[index] + 8 || 600;
  }

  function scrollTo(index){
    if (fields.length > 1 && Number.isInteger(index)){
      virtual_list.current.scrollToItem(index, "end");
    }
  }

  function moveTo(oldIndex, newIndex){
    move(oldIndex, newIndex);
  }

  return (
    <Container maxWidth={false} className={styles['client-container']}>

    <Grid container spacing={3} direction="column">
      <Grid container item spacing={3} justifyContent="flex-end">
        {/* Title */}
        <Grid item xs>
          <Toolbar className={styles['toolbar']}>
            <Typography variant="h4" id="tableTitle" component="div">
              Editar encuesta
              <Typography variant="caption" display="block" gutterBottom>
              * Campos requeridos
              </Typography>
            </Typography>
          </Toolbar>
        </Grid>
        {/* Buttons */}
        <Grid item>
          <Button
            variant="outlined"
            size="small"
            sx={[classes.button]}
            onClick={() => {location.push("/surveys")}}
            disabled={save.loading}
            color="primary">
            Cancelar
          </Button>
          <Button
            variant="contained"
            size="small"
            disabled={save.loading}
            type="submit"
            sx={[classes.button]}
            onClick={submit}
            color="primary"
          >
            Guardar
            {save.loading && <CircularProgress size={24} sx={[classes.buttonProgress]} />}
          </Button>
        </Grid>
      </Grid>
      {/* Form */}
      <Grid item xs>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <FormRecord control={control} waiting={save.loading ||status_element.loading} />
        </form>
      </Grid>
      <Grid container item  justifyContent="flex-end">
        <Grid item xs>
          <Typography variant="h6" component="div">
            Preguntas
          </Typography>
        </Grid>
        <Grid item xs>
          <ListQuestions waiting={save.loading ||status_element.loading}  questions={fields} onDelete={ (index) => remove(index)} onClick={(index) => scrollTo(index)} onMove={(oldIndex, newIndex) => moveTo(oldIndex, newIndex)}/>
        </Grid>
      </Grid>
      <Grid item sx={{widht:"100% !important", height:"500px"}}>
        <AutoSizer>
          {({height, width}) => (
            <VariableSizeList
              width={width}
              ref={virtual_list}
              height={height}
              itemSize={getRowHeight}
              itemCount={fields.length}
              itemData={fields}
              itemKey={(i) => fields[i].id}
            >
              {({ style, index, data }) => {
                const questions = getValues()["questions"];
                return (
                  <form ref={formRef} style={style} onSubmit={handleSubmit(onSubmit)}>
                    <FormQuestions setRowHeight={(index,ref) => setRowHeight(index,ref)} control={control} 
                    waiting={save.loading ||status_element.loading} 
                    edit={allowEdit(questions[index])}
                    catalogs={catalogs} index={index} remove={remove} questions={questions.map((question,index) => ({text:`Pregunta ${index + 1}`, value: question.id}))}/>
                  </form>
                );
              }}
            </VariableSizeList>
          )}
        </AutoSizer>
      </Grid>
      <Grid container item spacing={3} sx={{marginTop: '5px'}} justifyContent="center" alignItems="center">
        <Grid item>
          <Button
            variant="contained"
            size="small"
            sx={[classes.button]}
            onClick={() => addQuestion((fields.length))}
            // onClick={() => append(createEmptyQuestion())}
            color="primary"
            disabled={save.loading}
            >
            Agregar pregunta
          </Button>
        </Grid>
      </Grid>
      {/* title */}
      {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
      {/* <pre>{JSON.stringify(errors, null, 2)}</pre> */}

      </Grid>
    </Container>
  );
}


export default UpdateComponent;