// import InboxIcon from '@mui/icons-material/MoveToInbox';
import ChatOutlinedIcon from '@mui/icons-material/ChatOutlined';
import LibraryBooksOutlinedIcon from '@mui/icons-material/LibraryBooksOutlined';
import AccountBoxOutlinedIcon from '@mui/icons-material/AccountBoxOutlined';
import StorageOutlinedIcon from '@mui/icons-material/StorageOutlined';
import PermContactCalendarOutlinedIcon from '@mui/icons-material/PermContactCalendarOutlined';
import CopyrightOutlinedIcon from '@mui/icons-material/CopyrightOutlined';
import StoreMallDirectoryOutlinedIcon from '@mui/icons-material/StoreMallDirectoryOutlined';
import QuestionAnswerOutlinedIcon from '@mui/icons-material/QuestionAnswerOutlined';
import AssignmentOutlinedIcon from '@mui/icons-material/AssignmentOutlined';
import ListOutlinedIcon from '@mui/icons-material/ListOutlined';
import StoreOutlinedIcon from '@mui/icons-material/StoreOutlined';
import BubbleChartIcon from '@mui/icons-material/BubbleChart';
import ManageAccountsIcon from '@mui/icons-material/ManageAccounts';
import LiveHelpIcon from '@mui/icons-material/LiveHelp';
import BarChartIcon from '@mui/icons-material/BarChart';
import DateRangeOutlinedIcon from '@mui/icons-material/DateRangeOutlined';
import PaymentsOutlinedIcon from '@mui/icons-material/PaymentsOutlined';

const Routes = [
  // {
  //   path: '/home',
  //   sidebarName: 'Home',
  //   // component: Home,
  //   layouts : 'main',
  //   icon : InboxIcon
  // },
  
  {
    sidebarName: 'Evaluaciones',
    layouts : 'main',
    icon : BubbleChartIcon,
    childs:[
      {
        path: '/visits',
        sidebarName: 'Visitas',
        // component: Standings,
        layouts : 'main',
        icon : StoreOutlinedIcon
      },
      {
        path: '/results',
        sidebarName: 'Resultados',
        // component: Standings,
        layouts : 'main',
        icon : BarChartIcon
      },
      {
        path: '/diary',
        sidebarName: 'Agenda',
        // component: Standings,
        layouts : 'main',
        icon : DateRangeOutlinedIcon
      },
      {
        path: '/payableAccounts',
        sidebarName: 'Cuentas por pagar',
        // component: Standings,
        layouts : 'main',
        icon : PaymentsOutlinedIcon
      },
      
    ]
  },
  {
    sidebarName: 'Clientes',
    layouts : 'main',
    icon : ManageAccountsIcon,
    childs:[
      {
        path: '/customers',
        sidebarName: 'Clientes',
        // component: Standings,
        layouts : 'main',
        icon : PermContactCalendarOutlinedIcon
      },
      {
        path: '/brands',
        sidebarName: 'Marcas',
        // component: Standings,
        layouts : 'main',
        icon : CopyrightOutlinedIcon
      },
      {
        path: '/businessBranches',
        sidebarName: 'Establecimientos',
        // component: Standings,
        layouts : 'main',
        icon : StoreMallDirectoryOutlinedIcon
      },
      {
        path: '/packages',
        sidebarName: 'Paquetes',
        // component: Standings,
        layouts : 'main',
        icon : StorageOutlinedIcon
      },
      {
        path: '/contracts',
        sidebarName: 'Contratos',
        // component: Standings,
        layouts : 'main',
        icon : AssignmentOutlinedIcon
      },
      {
        path: '/instructives',
        sidebarName: 'Instructivos',
        // component: Standings,
        layouts : 'main',
        icon : ListOutlinedIcon
      }
      
    ]
  },
  {
    sidebarName: 'Cuestionarios',
    layouts : 'main',
    icon : LiveHelpIcon,
    childs:[
      {
        path: '/surveys',
        sidebarName: 'Encuestas',
        // component: Standings,
        layouts : 'main',
        icon : QuestionAnswerOutlinedIcon
      },
      {
        path: '/catalogs',
        sidebarName: 'Categorías',
        // component: Standings,
        layouts : 'main',
        icon : LibraryBooksOutlinedIcon
      }
    ]
  },
  {
    path: '/clients',
    sidebarName: 'Usuarios',
    // component: Standings,
    layouts : 'main',
    icon : AccountBoxOutlinedIcon
  },
  {
    path: '/shoppers',
    sidebarName: 'Shoppers',
    // component: Standings,
    layouts : 'main',
    icon : ChatOutlinedIcon
  }
  
];

export default Routes;