import React from 'react';
import {Container, Paper } from '@mui/material';
import { connect } from 'react-redux';
import { brands } from '../../store/actions/modules';
import data_service from "../../services/brands";
import styles from './index.module.css';
import Table from '../../components/table';
import { withRouter } from "react-router-dom";
import EditOutlinedIcon from '@mui/icons-material/EditOutlined';
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined';
import ConfirmDialog from '../../components/dialog/Confirm';

class MainComponent extends React.Component {

  constructor(props) {
    super(props);

    this.state = {
      data: [],
      dialog_open: false,
      element: null,
      record_slug:null,
      loading:false
    };
  }

  async componentDidMount() {
    this.initialLoad();
  }

  async initialLoad(){
    this.props.getList({service: data_service.list});
  }

  componentWillUnmount(){
    // this.props.clearList();
  }

  componentDidUpdate(prevProps) {
    if (prevProps.remove !== this.props.remove) {
      if(this.props.remove.success){
        this.initialLoad();
        this.setState({dialog_open: false, record_slug : null }); 
      }else{
        if(!this.props.remove.loading){
          this.setState({dialog_open: false, record_slug : null }); 
        }
      }
    }
  }

  openDeleteConfirm(slug){
    this.setState({dialog_open: true, record_slug: slug});
  }
  
  deleteRecord(slug){
    this.props.deleteRecord({slug:slug});
  }

  render() {

    const columns = [
      { id: 'name', numeric: false, disablePadding: true, label: 'Nombre',sortable: true },
      { id: 'customer', numeric: true, disablePadding: true, label: 'Cliente',sortable: true },
      { id: 'bussiness_branches', numeric: true, disablePadding: true, label: 'Establecimientos',sortable: true },
    ];
    const maxWidth = false;
    return (
      <Container maxWidth={maxWidth} className={styles['client-container']}>
          {/* Dialog  */}
          <ConfirmDialog  
            open={this.state.dialog_open} 
            title={"Eliminar Marca"}
            loading={this.props.remove.loading}
            confirm={() =>  this.deleteRecord(this.state.record_slug) } 
            cancel={() => this.setState({dialog_open: false, record_slug : null }) } 
            message={"¿Desea eliminar el siguiente registro?"}
            />
          {/* table */}
          <Paper elevation={1} className={styles.paper}>
            <Table
              title = {"Marcas"}
              data = {this.props.list.data}
              columns={columns}
              addAction ={(event) => {this.props.history.push("/brands/create");}}
              actions={[
                {
                  icon: DeleteOutlineOutlinedIcon,
                  tooltip: 'Eliminar',
                  onClick: (event, row) => this.openDeleteConfirm(row.slug)
                },
                {
                  icon: EditOutlinedIcon,
                  tooltip: 'Editar',
                  onClick: (event, row) => {this.props.history.push(`/brands/${row.slug}/update`)}
                }
              ]}
              loading={this.props.list.loading}
            />
          </Paper>
      </Container>
    )
  }
}

const mapStateToProps = (state) => {
  return {
    list: state.module_brands.list,
    remove: state.module_brands.remove
  };
}

const mapDispatchToProps = (dispatch) => {
  return {
    getList: () =>{
      dispatch(brands.list());
    },
    deleteRecord:(payload)=>{
      dispatch(brands.remove(payload));
    }
  }
};


const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(MainComponent);

export default withRouter(_component);