import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import CssBaseline from '@mui/material/CssBaseline';
import Header from '../../layouts/shared-components/ExternalHeader';
import FloatingMenu from '../shared-components/floating-menu';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import {snackbarActions} from '../../store/actions';
import { useDispatch, useSelector } from 'react-redux';
import Backdrop from '@mui/material/Backdrop';
import CircularProgress from '@mui/material/CircularProgress';

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  drawer: {
    width: drawerWidth,
    flexShrink: 0,
  },
  drawerPaper: {
    width: drawerWidth,
  },
  // necessary for content to be below app bar
  toolbar: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(3),
  },
  backdrop: {
    zIndex: theme.zIndex.drawer + 1,
    color: '#fff',
  },
}));

const Alert = React.forwardRef(function Alert(props, ref) {
  return <MuiAlert elevation={6} ref={ref} variant="filled" {...props} />;
});


const ExternalLayout = ({ children, ...props  }) => {

  const snackbarState = useSelector(state => state.snackbar);

  const backdropState = useSelector(state => state.backdrop);

  const hide_floating_menu = useSelector(state => state.floating_menu.hidden);
  
  const dispatch = useDispatch();

  // useEffect(() => {
  //   dispatch(snackbarActions.snackbarOpen({
  //     message:'Oops, Se ha producido un error',
  //     severity:'error'
  //   }))
  // }, [])
  
  const handleClose = (event, reason) => {
    if (reason === 'clickaway') {
      return;
    }
  
    dispatch(snackbarActions.snackbarClose())
  };

  const classes = useStyles();

  return (
    <React.Fragment>
      <div className={classes.root}>
        <CssBaseline />
        <Header/>
        <main className={classes.content}>
          <div className={classes.toolbar} />
          {/* backdrop */}
          <Backdrop className={classes.backdrop} open={backdropState.open}>
            <CircularProgress color="inherit" />
          </Backdrop>
          {/* backdrop */}
          {/* feedback alert */}
          <Snackbar open={snackbarState.open} autoHideDuration={6000} onClose={handleClose}  anchorOrigin={{ vertical: 'top', horizontal: 'center' }}>
            <Alert onClose={handleClose} severity={snackbarState.severity}>
              {snackbarState.message}!
            </Alert>
          </Snackbar>
          {/* feedback alert */}
          {children}
          { ! hide_floating_menu &&<FloatingMenu/>}
        </main>
      </div>
    </React.Fragment>
  );
}

export default ExternalLayout;
