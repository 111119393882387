import http from '../utils/http-common';

const list = () => {
  return http.get("/brands");
};

const create = ({ data }) => {
  return http.post("/brands/store", data);
};

const detail = ({ slug }) => {
  return http.get(`/brands/${slug}/edit`);
};

const update = ({ slug, data }) => {
  return http.put(`/brands/${slug}/update`, data);
};

const remove = ({ slug }) => {
  return http.delete(`brands/${slug}/delete`);
};


const brandsService = {
  list,
  detail,
  create,
  update,
  remove
};

export default brandsService;