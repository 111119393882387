import * as React from 'react';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import Link from '@mui/material/Link';
import Grid from '@mui/material/Grid';
import Box from '@mui/material/Box';
import logo from '../../../assets/images/logotipo_blanco.svg';
import Typography from '@mui/material/Typography';
import Container from '@mui/material/Container';
import {Paper, CircularProgress} from '@mui/material';
import { Form } from 'react-final-form';
import FormComponent from './form';
import { green } from '@mui/material/colors';
import { formValidation } from './validator';
import { useDispatch, useSelector } from 'react-redux';
import { auth } from '../../store/actions';
import square_login_form from '../../../assets/images/Cuadro.png';
import sublogo from '../../../assets/images/YNTECH.png';

function Copyright(props) {
  return (
    <Typography variant="body2" color="text.secondary" align="center" {...props}>
      <img src={sublogo} alt={""}  width="120px"  align="middle"/>
    </Typography>
  );
}

export default function SignIn() {

  const loading = false;
  const handleSubmitForm = (values) => {
    dispatch(auth.login({data:values}));
  };

  const buttonSx = {
    ...(loading && {
      bgcolor: green[500],
      '&:hover': {
        bgcolor: green[700],
      },
    }),
  };

  const auth_data = useSelector(state => state.auth);
  // const test = useSelector(state => console.log(state.auth));

  const dispatch = useDispatch();

  return (
    <Container component="main">
      <CssBaseline />
      <Box
        component={Paper}
        sx={{
          // marginTop:10,
          marginTop:15,
          marginBottom:10,
          display: 'flex',
          flexDirection: 'row',
          alignItems: 'center',
          // width: '290%'
          height: "700px"
        }}
        elevation={3}
      >
        <Box component={"div"} sx={{ display: { xs: 'none', md: 'block' }, width: '100%', height:"100%",backgroundColor: '#2A9D8F', backgroundImage: `url(${square_login_form})` }}>
            <Grid  spacing={2}
              container
              direction="row"
              justifyContent="center"
              alignItems="flex-end"
              sx={{ height: '100%'}}

            >
              <Grid item xs={12} sx={{textAlign: 'center'}}>
                <img src={logo} alt={""} height = "65px" align="middle"/>
              </Grid>
              <Grid item xs={12}>
                <Copyright sx={{ mt: 0 }} />
              </Grid>
            </Grid>
        </Box>
        <Box component="div" sx={{ margin: '10px 50px 15px', width: '100%', display: "flex",
    justifyContent: "center",
    alignItems: "center" }}>
            <Form
              // initialValues={{
              //     "username": "daniel@gmail.com",
              //     "password": "123123123"
              // }}
              validate={formValidation.validateForm}
              onSubmit={handleSubmitForm}
              render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
                <form onSubmit={handleSubmit}>

                  <Grid container spacing={2}>
                    <FormComponent wating={auth_data.login.loading} />
                    <Grid item xl={12}>
                        <Button
                          fullWidth
                          variant="contained"
                          size="small"
                          sx={buttonSx}
                          disabled={auth_data.login.loading}
                          // className={classes.button}
                          type="submit"
                        >
                          Iniciar sesión
                          {auth_data.login.loading && (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                              }}
                            />
                          )}
                        </Button>
                      </Grid>
                      <Grid item container justifyContent="center">
                        <Grid item>
                          { !auth_data.login.loading &&
                          <Link href="#" variant="body2">
                            ¿Olvidaste tu contraseña?
                          </Link>
                          }
                        </Grid>
                      </Grid>
                  </Grid>

                </form>
              )}
            />
        </Box>
      </Box>
    </Container>
  );
}