import React, {Component} from 'react';
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button, 
  Alert,
  AlertTitle,
  Zoom,
  CircularProgress
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import FormData from './form';
import { Form } from 'react-final-form';
import { surveys } from '../../../../store/actions/external';
import { connect } from 'react-redux';
import { green } from '@mui/material/colors';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
});

class ModuleComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      token:null,
      success:true,
      submitting:false,
      showError: false
    }
  }
  
  componentDidMount(){
    const { token} = this.props.match.params;
    this.props.getModuleData({token:token});
    this.setState({token:token});
  }

  componentDidUpdate(prevProps) {
    if (prevProps.save !== this.props.save) {
      if(this.props.save.success){
        this.setState({success:true,submitting:true});
      }else{
        this.setState({success:false, submitting:false});
      }
    }

    if (prevProps.status_element !== this.props.status_element) {
      if(!this.props.status_element.success && !this.props.status_element.loading){
        this.setState({success:false,submitting:true});
      }
      if(this.props.status_element.success && !this.props.status_element.loading){
        this.setState({success:true,submitting:false});
      }
    }

  }


  onSubmit = values => {
    this.setState({submitting:true});
    this.props.saveSurvey({token: this.state.token, data: values});
  }


  render() {

    const buttonSx = {
      ...( (this.props.status_element.loading || this.props.save.loading) && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    const { classes } = this.props;


    return (
        <Container className={styles['client-container']}>

          <Grid container spacing={2} direction="column">

          { (this.state.submitting && !this.props.save.loading) ?
               ( this.state.success) ? 

               <Zoom in={this.state.success }>
                <Alert severity="success">
                  <AlertTitle>Gracias !</AlertTitle>
                  Tus respuestas han quedado registradas — <strong>hasta pronto!</strong>
                </Alert>
               </Zoom>
               
               :
               <Alert severity="error">
                  <AlertTitle>Error</AlertTitle>
                  Esta encuesta ya no está disponible. — <strong>Gracias!</strong>
              </Alert>

            : 

            <Form
              // validate={values => formValidation.validateForm(values)}
              mutators={{
                clearAnswer: (args, state, utils) => {
                  utils.changeValue(state, args[0], () => null)
                }
              }}
              initialValues={{answers: this.props.element.answers}}
              onSubmit={this.onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
                <form onSubmit={handleSubmit}>
                   <Grid container item spacing={3}>
                    {/* Title */}
                    <Grid item xs>
                      <Toolbar className={styles['toolbar']}>
                        <Typography variant="h4" id="tableTitle" component="div">
                          {this.props.element.title}
                          <Typography variant="caption" display="block" gutterBottom>
                            Encuesta 
                          </Typography> 
                        </Typography>
                      </Toolbar>
                    </Grid>
                    {/* Buttons */}
                    <Grid item container sm={6} xs={12} justifyContent={{ xs: 'center', sm: 'flex-end' }}>
                      <Grid item>
                        { (this.state.showError && Object.keys(errors).length > 0) &&
                          <Alert severity="error">
                            <AlertTitle>Debes responder todas las preguntas obligatorias</AlertTitle>
                          </Alert>
                        }
                      </Grid>
                      <Grid item>
                      
                      <Button
                          variant="contained"
                          size="small"
                          sx={buttonSx}
                          disabled={ this.props.save.loading || this.props.status_element.loading || !this.props.status_element.success}
                          className={classes.button}
                          onClick={() => this.setState({showError:true})}
                          type="submit"
                        >
                          Guardar
                          { (this.props.save.loading || this.props.status_element.loading) && (
                            <CircularProgress
                              size={24}
                              sx={{
                                color: green[500],
                                position: 'absolute',
                                top: '50%',
                                left: '50%',
                                marginTop: '-12px',
                                marginLeft: '-12px',
                              }}
                            />
                          )}
                        </Button>
                    </Grid>
                    
                    </Grid>
                  </Grid>
                  {/* Form */}
                  <FormData formValues={values} clearAnswer={ (field) => form.mutators.clearAnswer(field) } waiting={this.props.status_element.loading || this.props.save.loading}/>
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                  {/* <pre>{JSON.stringify(pristine, 0, 2)}</pre> */}
                </form>
              )}
            /> 
            }
          </Grid>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleData:(payload) =>{
      dispatch(surveys.detail(payload))
    },
    saveSurvey:(payload) =>{
      dispatch(surveys.save(payload))
    }
  }
};


const mapStateToProps = (state) => {
  // console.log(state.external_surveys.element);
  return {
    save: state.external_surveys.save,
    status_element: state.external_surveys.element,
    element: state.external_surveys.element.data
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(ModuleComponent);


export default withRouter(withStyles(useStyles)(_component));
