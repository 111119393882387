import * as React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import clsx from 'clsx';
import makeStyles from '@mui/styles/makeStyles';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import { styled } from '@mui/material/styles';
import Box from '@mui/material/Box';

const useToolbarStyles = makeStyles((theme) => ({
    root: {
      paddingLeft: theme.spacing(2),
      paddingRight: theme.spacing(1),
    },
    title: {
      flex: '1 1 100%',
    },
  }));

const EnhancedTableToolbar = ({title}) => {
    const classes = useToolbarStyles();
    // let history = useHistory();
    return (
      <Toolbar
        className={clsx(classes.root)}
      >
          <Typography className={classes.title} variant="h5" id="tableTitle" component="div">
            {title}
          </Typography>
      </Toolbar>
    );
  };

  const StyledTableRow = styled(TableRow)(({ theme }) => ({
    '&:nth-of-type(odd)': {
      backgroundColor: theme.palette.action.hover,
    },
    // hide last border
    '&:last-child td, &:last-child th': {
      border: 0,
    },
  }));
  
export default function BasicTable({
    catalog_id,
    title,
    data,
    columns,
    open_answers,
    loading,
    ...props}) {

  const open_questions =  data.filter(x=> x.hasOwnProperty('tipo'));
  return (
    <TableContainer component={Paper} sx ={{padding: '16px', marginBottom:"30px"}}>
        {title&& 
        <EnhancedTableToolbar
            title={title}
        />
        }

        {/* { open_questions.length > 0 ?
           
           <Table sx={{ minWidth: 650 }} aria-label="simple table">
        <TableHead>
            <TableRow>
            <TableCell
                align='left'
                padding='normal'
            >Criterio</TableCell>
            <TableCell
                align='left'
                padding='normal'
            >Respuestas</TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
          {open_questions.map((row, row_key) => (
              <StyledTableRow
                  hover
                  key={row_key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
              >
                  <TableCell align='left'>
                      {row['criterio'] }
                  </TableCell> 
                  <TableCell align='right'>
                    {(open_answers[row.id]) && 
                      open_answers[row.id].respuestas.map((respuesta, j) => {
                      return (
                          <TableRow key={`${row.id}_${j}`} align={'right'} >
                            <TableCell colSpan={4} align={'left'} >
                              <div dangerouslySetInnerHTML={{__html: respuesta}}/>
                            </TableCell> 
                          </TableRow> 
                          )
                      })
                    }
                  </TableCell> 

              </StyledTableRow>
          ))}
        </TableBody>
      </Table>
          
          : null
        } */}
        
        <Table sx={{ minWidth: 650 }} aria-label="simple table">

        { open_questions.length > 0 ? 
        
        <><TableHead>
            <TableRow>
              <TableCell
                align='left'
                padding='normal'
              >Criterio</TableCell>
              <TableCell
                align='center'
                padding='normal'
                colSpan={3}
              >Respuestas</TableCell>
            </TableRow>
          </TableHead><TableBody>
              {open_questions.map((row, row_key) => (
                <StyledTableRow
                  hover
                  key={row_key}
                  sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
                >
                  <TableCell align='left'>
                    {row['criterio']}
                  </TableCell>
                  <TableCell align='left' colSpan={3}>
                    {(open_answers[row.id]) &&
                      open_answers[row.id].respuestas.map((respuesta, j) => {
                        return (
                          <Box key={`${row.id}_${j}`} sx={{borderBottom: "1px solid rgba(224, 224, 224, 1)", textAlign:"center"}}>
                            <div style={{ marginBottom: '10px' }}>
                              <div dangerouslySetInnerHTML={{ __html: respuesta }} />
                            </div>
                          </Box>
                        );
                      })}
                  </TableCell>

                </StyledTableRow>
              ))}
            </TableBody></>
        
        : null}
        <TableHead>
            <TableRow>
            {columns.map((headCell) => (
            <TableCell
                key={headCell.id}
                align={headCell.numeric ? 'right' : 'left'}
                padding={'normal'}
            >{ headCell.label}</TableCell>
            ))}
            </TableRow>
        </TableHead>
        <TableBody>
          {data.filter(x=> ! x.hasOwnProperty('tipo')).map((row, row_key) => (
            <StyledTableRow
                hover
                key={row_key}
              sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
            >
                {columns.map((column, index) => {
                    return (
                        <TableCell key={index} align={column.numeric ? 'right' : 'left'} >
                            {row[column.id] }
                        </TableCell> 

                    )
                })}
            </StyledTableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
}