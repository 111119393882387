export const CREATE = "[MODULE][CATALOGS] CREATE";
export const UPDATE = "[MODULE][CATALOGS] UPDATE";
export const REMOVE = "[MODULE][CATALOGS] REMOVE";
export const LIST = "[MODULE][CATALOGS] LIST";
export const ELEMENT = "[MODULE][CATALOGS] ELEMENT";

export const CREATE_REQUEST = "[MODULE][CATALOGS][CREATE] REQUEST";
export const CREATE_SUCCESS = "[MODULE][CATALOGS][CREATE] SUCCESS";
export const CREATE_FAILURE = "[MODULE][CATALOGS][CREATE] FAILURE";

export const UPDATE_REQUEST = "[MODULE][CATALOGS][UPDATE] REQUEST";
export const UPDATE_SUCCESS = "[MODULE][CATALOGS][UPDATE] SUCCESS";
export const UPDATE_FAILURE = "[MODULE][CATALOGS][UPDATE] FAILURE";

export const REMOVE_REQUEST = "[MODULE][CATALOGS][REMOVE] REQUEST";
export const REMOVE_SUCCESS = "[MODULE][CATALOGS][REMOVE] SUCCESS";
export const REMOVE_FAILURE = "[MODULE][CATALOGS][REMOVE] FAILURE";

export const LIST_REQUEST = "[MODULE][CATALOGS][LIST] REQUEST";
export const LIST_SUCCESS = "[MODULE][CATALOGS][LIST] SUCCESS";
export const LIST_FAILURE = "[MODULE][CATALOGS][LIST] FAILURE";


export const ELEMENT_REQUEST = "[MODULE][CATALOGS][ELEMENT] REQUEST";
export const ELEMENT_SUCCESS = "[MODULE][CATALOGS][ELEMENT] SUCCESS";
export const ELEMENT_FAILURE = "[MODULE][CATALOGS][ELEMENT] FAILURE";

export const CLEAR = "[MODULE][CATALOGS] CLEAR";

export const create = (payload)=> {
  return { type: CREATE, payload };
}

export const update = (payload)=> {
  return { type: UPDATE, payload };
}

export const remove = (payload)=> {
  return { type: REMOVE, payload };
}

export const list = ()=> {
  return { type: LIST  };
}

export const detail = (payload)=> {
  return { type: ELEMENT, payload };
}

export const reset = ()=> {
  return { type: CLEAR  };
}