export const HIDE = '[FLOATING_MENU] HIDE';
export const SET_OPTIONS = '[FLOATING_MENU] OPEN';
export const CLEAR = '[FLOATING_MENU] CLEAR';

export const setOptions = (payload) =>
{
  return {
    type: SET_OPTIONS,
    payload: payload
  }
}

export const clear = () =>
{
  return {
    type: CLEAR,
  }
}

export const hide = (payload) =>
{
  return {
    type: CLEAR,
    payload: payload
  }
}