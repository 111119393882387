import {users as actions} from '../../actions/external';

const initialState = {
  save: {
    response:{},
    loading: false,
    success: false
  },
  element: {
    data:{},
    loading: false,
    success: false
  },
};

const reducer = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.ELEMENT_REQUEST:
    {
      return {
        ...state,
        element: {
          ...initialState.element,
          loading: true,
          success: false,
        },
      }
    }
    case actions.ELEMENT_SUCCESS:
    {
      return {
        ...state,
        element: {
          element: action.payload,
          loading: false,
          success: true,
        },
      }
    }
    case actions.ELEMENT_FAILURE:
    {
      return {
        ...state,
        element: {
          ...initialState.element,
          loading: false,
          success: false,
        },
      }
    }
    case actions.SAVE_REQUEST:
    {
      return {
        ...state,
        save: {
          ...state.save,
          loading: true,
          success: false,
        },
      }
    }
    case actions.SAVE_FAILURE:
    {
      return {
        ...state,
        save: {
          ...state.save,
          loading: false,
          success: false,
        },
      }
    }
    case actions.SAVE_SUCCESS:
    {
      return {
        ...state,
        save: {
          response: action.payload,
          loading: false,
          success: true,
        },
      }
    }
    case actions.RESET:
    {
      return initialState;
    }
    default:
    {
      return state;
    }
  }
};

export default reducer;