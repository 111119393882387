import React, {Component} from 'react';
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import FormRecord from '../form';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { formValidation } from '../../../validations/CustomerCreate';
import { customers } from '../../../store/actions/modules';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
});

class PackageCreate extends Component {
  _isMounted =  false;
  constructor(props) {
    super(props);

    this.state = {
      form:{
        // name: "cLiente uNo",
        // neighborhood_id: {
        //   value:106179,
        //   text:"Lomas 2a Sección"
        // },
        // zip_code:'78394',
        // street: "dEl cApaTaz",
        // ext_number: 500,
        // phone: "4444444444",
        // email: "customER@HoTmail.com"
      }
    }
  }

  componentDidUpdate(prevProps) {
    if (prevProps.save !== this.props.save) {
      if(this.props.save.success){
        this.props.history.push("/customers");
      }
    }
  }

  onSubmit = values => {
    this.props.createRecord({
      data: values
    });
  }

  render() {

    const maxWidth = false;

    const { classes } = this.props;


    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>

          <Grid container spacing={2} direction="column">

            <Grid item xs>
              <Toolbar className={styles['toolbar']}>
                <Typography variant="h4" id="tableTitle" component="div">
                Nuevo Cliente
                <Typography variant="caption" display="block" gutterBottom>
                * Campos requeridos
              </Typography>
                </Typography>
              </Toolbar>
            </Grid>

            <Form
              initialValues={this.state.form}
              validate={formValidation.validateForm}
              onSubmit={this.onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values }) => (
                <form onSubmit={handleSubmit}>
                  {/* Catalog */}
                  <FormRecord values={values} waiting={this.props.save.loading}/>
                {/* Buttons */}
                <Grid container item spacing={3}>
                  <Grid item>
                    <Button
                      variant="outlined"
                      size="small"
                      className={classes.button}
                      onClick={() => {this.props.history.push("/customers")}}
                      color="primary">
                      Cancelar
                    </Button>
                    <Button
                      variant="contained"
                      size="small"
                      disabled={this.props.save.loading}
                      type="submit"
                      className={classes.button}
                      color="primary"
                    >
                      Guardar
                      {this.props.save.loading && <CircularProgress size={24} className={classes.buttonProgress} />}
                    </Button>
                  </Grid>
                </Grid>
                {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                </form>
              )}
            />

          </Grid>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    createRecord:(payload) =>{
      dispatch(customers.create(payload))
    }
  }
};

const mapStateToProps = (state) => {
  return {
    save: state.module_customers.save
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(PackageCreate);


export default withRouter(withStyles(useStyles)(_component));
