import {contract_visits as actions} from '../../actions/modules';

const initialState = {
  element: [],
  status_element: {
    loading: false,
    success: false,
  },
  shoppers: [],
  status_shoppers: {
    loading: false,
    success: false,
  },
  list: [],
  status_list: {
    loading: false,
    success: false,
  },
  business_branches: [],
  status_business_branches: {
    loading: false,
    success: false,
  },
  states: [],
  status_states: {
    loading: false,
    success: false,
  },
  send_invitation: {
    loading: false,
    success: false,
    response: [],
  },
  resend: {
    loading: false,
    success: false,
    response: {},
  },
  info: {
    loading: false,
    success: false,
    data: {
      sku: "",
      name: "",
      diff: "",
      months: "",
      default: "",
      options: []
    },
  }
};

const reducer = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.ELEMENT_REQUEST:
    {
      return {
        ...state,
        status_element: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.ELEMENT_SUCCESS:
    {
      return {
        ...state,
        element: action.payload,
        status_element: {
          loading: false,
          success: true,
        },
      }
    }
    case actions.ELEMENT_FAILURE:
    {
      return {
        ...state,
        element: initialState.element,
        status_element: {
          loading: false,
          success: false,
        },
      }
    }
    case actions.BUSINESS_BRANCHES_REQUEST:
    {
      return {
        ...state,
        status_business_branches: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.BUSINESS_BRANCHES_SUCCESS:
    {
      return {
        ...state,
        status_business_branches: {
          loading: false,
          success: true,
        },
        business_branches: action.payload
      }
    }
    case actions.BUSINESS_BRANCHES_FAILURE:
    {
      return {
        ...state,
        status_business_branches: {
          loading: false,
          success: false,
        },
        business_branches: null
      }
    }
    case actions.STATES_REQUEST:
    {
      return {
        ...state,
        status_states: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.STATES_SUCCESS:
    {
      return {
        ...state,
        status_states: {
          loading: false,
          success: true,
        },
        states: action.payload
      }
    }
    case actions.STATES_FAILURE:
    {
      return {
        ...state,
        status_states: {
          loading: false,
          success: false,
        },
        states: []
      }
    }
    case actions.SHOPPERS_REQUEST:
    {
      return {
        ...state,
        status_shoppers: {
          loading: true,
          success: false,
        },
        shoppers: []
      }
    }
    case actions.SHOPPERS_SUCCESS:
    {
      return {
        ...state,
        status_shoppers: {
          loading: false,
          success: true,
        },
        shoppers: action.payload
      }
    }
    case actions.SHOPPERS_FAILURE:
    {
      return {
        ...state,
        status_shoppers: {
          loading: false,
          success: false,
        },
        shoppers: []
      }
    }
    case actions.LIST_REQUEST:
    {
      return {
        ...state,
        status_list: {
          loading: true,
          success: false,
        },
      }
    }
    case actions.LIST_SUCCESS:
    {
      return {
        ...state,
        status_list: {
          loading: false,
          success: true,
        },
        list: action.payload
      }
    }
    case actions.LIST_FAILURE:
    {
      return {
        ...state,
        status_list: {
          loading: false,
          success: false,
        },
        list: []
      }
    }
    case actions.SEND_INVITATION_REQUEST:
    {
      return {
        ...state,
        send_invitation: {
          loading: true,
          success: false,
          response: []
        },
      }
    }
    case actions.SEND_INVITATION_SUCCESS:
    {
      return {
        ...state,
        send_invitation: {
          loading: false,
          success: true,
          response: action.payload
        },
      }
    }
    case actions.SEND_INVITATION_FAILURE:
    {
      return {
        ...state,
        send_invitation: {
          loading: false,
          success: false,
          response: action.payload
        },
      }
    }
    case actions.RESEND_REQUEST:
    {
      return {
        ...state,
        resend: {
          loading: true,
          success: false,
          response: []
        },
      }
    }
    case actions.RESEND_SUCCESS:
    {
      return {
        ...state,
        resend: {
          loading: false,
          success: true,
          response: action.payload
        },
      }
    }
    case actions.RESEND_FAILURE:
    {
      return {
        ...state,
        resend: {
          loading: false,
          success: false,
          response: action.payload
        },
      }
    }
    case actions.INFO_REQUEST:
    {
      return {
        ...state,
        info: {
          ...initialState.info,
          loading: true,
          success: false,
        },
      }
    }
    case actions.INFO_SUCCESS:
    {
      return {
        ...state,
        info: {
          loading: false,
          success: true,
          data: action.payload
        },
      }
    }
    case actions.INFO_FAILURE:
    {
      return {
        ...state,
        info: {
          ...initialState.info,
          loading: false,
          success: false
        },
      }
    }
    case actions.RESET:
    {
      return initialState;
    }
    default:
    {
      return state;
    }
  }
};

export default reducer;