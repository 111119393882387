import http from '../utils/http-common';
import http_file from '../utils/http-files';

const list = () => {
  return http.get("/visits");
};

const detail = ({ sku,month }) => {
  return http.get(`/visits/${sku}/${month}/details`);
};

const info = ({ sku }) => {
  return http.get(`/visits/${sku}/info`);
};

const edit = ({ sku }) => {
  return http.get(`/visits/${sku}/edit`);
};

const update = ({ sku, data }) => {
  return http.put(`/visits/${sku}/update`, data);
};

const payment = ({ sku }) => {
  return http.get(`/visits/${sku}/payment`);
};

const savePayment = ({ sku, data }) => {
  return http.post(`/visits/${sku}/payment`, data);
};

const answers = ({ sku }) => {
  return http.get(`/visits/${sku}/getAnswers`);
};

const updateAnswers = ({ sku, data }) => {
  return http.put(`/visits/${sku}/updateAnswers`, data);
};

const businessBranchesByState = ({state}) => {
  return http.get(`/visits/${state}/getBussinessBranches`);
};

const shopperBussinessByState = ({state}) => {
  return http.get(`/visits/${state}/getShopperBussiness`);
};

const sendInvitation = ({state, data}) => {
  return http.post(`/visits/${state}/sendInvitation`, data);
};

const approvedSurvey = ({data}) => {
  return http.put(`/visits/approved`, data);
};

const resend = ({data}) => {
  return http.put(`/visits/resend`, data);
};

const getExternalVisitData = ({token}) => {
  return http.get(`/external/registerVisit/${token}`);
};

const saveExternalVisit = ({token, data}) => {
  return http.put(`/external/registerVisit/${token}`, data);
};

const getExternalPreviewSurvey = ({token}) => {
  return http.get(`/external/preview/${token}`);
};

const getExternalSurvey = ({token}) => {
  return http.get(`/getSurvey/${token}`);
};

const externalSaveAnswers = ({token, data}) => {
  return http.post(`/visits/answers/${token}`, data);
};

const uploadImage = ({data}) => {
  return http_file.post(`/surveys/uploadImage`, data);
};

const removeImage = ({data}) => {
  return http.put(`/surveys/deleteImage`, data);
};

const schedules = ({start_date, end_date}) => {
  return http.get(`/visits/getDiary/${start_date}/${end_date}`);
};

const service = {
  list,
  detail,
  edit,
  update,
  businessBranchesByState,
  shopperBussinessByState,
  sendInvitation,
  payment,
  savePayment,
  answers,
  updateAnswers,
  approvedSurvey,
  resend,
  getExternalVisitData,
  getExternalPreviewSurvey,
  getExternalSurvey,
  saveExternalVisit,
  uploadImage,
  removeImage,
  externalSaveAnswers,
  schedules,
  info
};

export default service;