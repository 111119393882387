  
import React from 'react';
import makeStyles from '@mui/styles/makeStyles';
import {
  FormHelperText,
  FormControl,
  FormLabel,
  MenuItem,
  Select,
  Skeleton,
  IconButton,
  Tooltip  
} from '@mui/material';
import ClearIcon from '@mui/icons-material/Clear';

const useStyles = makeStyles((theme) => ({
  radioGroup:{
    flexDirection: 'row',
    marginTop: '6px'
  },
  formControl: {
    minWidth: '100%',
  }
}));

const SelectWrapper = ({input: {name, onChange, value, ...restInput}, meta, multiple=false, clearable=false, waiting=false, ...rest}) => {
  // const serverError = rest.errors && rest.errors.hasOwnProperty.call(rest.errors, name) ? rest.errors[name]: null;
  // const showServerError =  Boolean(serverError && !meta.dirtySinceLastSubmit);
	const showError = Boolean( ((meta.submitError && !meta.dirtySinceLastSubmit) || meta.error) && meta.touched );
  const classes = useStyles();

  const finalValue = multiple && !value ? [] : value;
  const labelId = `select-input-${name}`;


  // React.useEffect(() => {
  //     if(! rest.options.find( option => option.value === value )){
  //       const _value = multiple && !value ? [] : value;
  //       onChange(_value);
  //     }
  // }, [rest.options, value, onChange, multiple]);



	return (
		<>
    <FormControl className={classes.formControl} variant="standard" error={showError } >
      <FormLabel required={rest.required}>{rest.label}
      {(clearable && ! waiting)?
      <IconButton
        onClick={ () => {onChange(null)}}
        edge="end"
        sx={{float: 'right', marginRight: '0px'}}
        size="small"
      >
        <Tooltip title={"Borrar respuesta"} >
          <ClearIcon fontSize="small"/>
        </Tooltip>
      </IconButton>:null}
      </FormLabel>
      {waiting?<Skeleton variant="rectangular"/> :
        <Select
          labelId={labelId}
          {...rest}
          multiple={multiple}
          name={name}
          // onChange={onChange}
          onChange={(event) => {
            onChange(event);
            rest.onChange && rest.onChange(event.target);
            }
          }
          // isclearable={"true"}
          inputProps={restInput}
          // value={console.log(value)}
          value={finalValue}
            // value={ multiple ? (Array.isArray(value) ? value : [] ) : value}
        >
          {(rest.options ? rest.options: []).map((option, index) => (
            <MenuItem key={index} value={option.value}>{option.label || option.text}</MenuItem>
          ))
          }
        </Select>
      }
      {showError &&
          <FormHelperText>
            {meta.error || meta.submitError}
          </FormHelperText>
        }
      {/* {showServerError  && <FormHelperText >{meta.error || meta.submitError || serverError}</FormHelperText> } */}
      </FormControl>
		</>
	);
};

export default SelectWrapper;
