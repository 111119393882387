import {brands as actions} from '../../actions/modules';

const initialState = {
  list: {
    data:[],
    loading: false,
    success: false
  },
  customers: {
    data:[],
    loading: false,
    success: false
  },
  element: {
    data:{},
    loading: false,
    success: false
  },
  create: {
    response: {},
    loading: false,
    success: false
  },
  update: {
    response: {},
    loading: false,
    success: false
  },
  remove: {
    response: {},
    loading: false,
    success: false
  }
};

const reducer = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.LIST_REQUEST:
    {
      return {
        ...state,
        list:{
          data: [],
          loading: true,
          success: false
        }
      }
    }
    case actions.LIST_SUCCESS:
    {
      return {
        ...state,
        list:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.LIST_FAILURE:
    {
      return {
        ...state,
        list:{
          data: [],
          loading: false,
          success: false
        }
      }
    }
    case actions.CUSTOMERS_REQUEST:
    {
      return {
        ...state,
        customers:{
          data: [],
          loading: true,
          success: false
        }
      }
    }
    case actions.CUSTOMERS_SUCCESS:
    {
      return {
        ...state,
        customers:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.CUSTOMERS_FAILURE:
    {
      return {
        ...state,
        customers:{
          data: [],
          loading: false,
          success: false
        }
      }
    }
    case actions.ELEMENT_REQUEST:
    {
      return {
        ...state,
        element:{
          data: [],
          loading: true,
          success: false
        }
      }
    }
    case actions.ELEMENT_SUCCESS:
    {
      return {
        ...state,
        element:{
          data: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.ELEMENT_FAILURE:
    {
      return {
        ...state,
        element:{
          data: [],
          loading: false,
          success: false
        }
      }
    }
    case actions.CREATE_REQUEST:
    {
      return {
        ...state,
        create:{
          response: {},
          loading: true,
          success: false
        }
      }
    }
    case actions.CREATE_SUCCESS:
    {
      return {
        ...state,
        create:{
          response: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.CREATE_FAILURE:
    {
      return {
        ...state,
        create:{
          response: {},
          loading: false,
          success: false
        }
      }
    }
    case actions.REMOVE_REQUEST:
    {
      return {
        ...state,
        remove:{
          response: {},
          loading: true,
          success: false
        }
      }
    }
    case actions.REMOVE_SUCCESS:
    {
      return {
        ...state,
        remove:{
          response: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.REMOVE_FAILURE:
    {
      return {
        ...state,
        remove:{
          response: {},
          loading: false,
          success: false
        }
      }
    }
    case actions.UPDATE_REQUEST:
    {
      return {
        ...state,
        update:{
          response: {},
          loading: true,
          success: false
        }
      }
    }
    case actions.UPDATE_SUCCESS:
    {
      return {
        ...state,
        update:{
          response: action.payload,
          loading: false,
          success: true
        }
      }
    }
    case actions.UPDATE_FAILURE:
    {
      return {
        ...state,
        update:{
          response: {},
          loading: false,
          success: false
        }
      }
    }
    case actions.CLEAR:
    {
      return initialState;
    }
    default:
    {
      return state;
    }
  }
};

export default reducer;