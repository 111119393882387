import {dataList as actions} from '../actions';

const initialState = {
  loading: false,
  success: false,
  elements: [],
};

const reducer = function (state = initialState, action) {
  switch ( action.type )
  {
    case actions.DATA_LIST_REQUEST:
    {
      return {
        ...state,
        loading: true,
        success: false,
      }
    }
    case actions.DATA_LIST_SUCCESS:
    {
      return {
        ...state,
        loading: false,
        success: true,
        elements: action.payload
      }
    }
    case actions.DATA_LIST_FAILURE:
    {
      return {
        ...state,
        loading: false,
        success: false,
        elements: []
      }
    }
    case actions.DATA_LIST_RESET:
    {
      return {
        ...state,
        elements: []
      }
    }
    default:
    {
      return state;
    }
  }
};

export default reducer;