import Chip from '@mui/material/Chip';
import Stack from '@mui/material/Stack';
import DeleteIcon from '@mui/icons-material/Delete';
import { ReactSortable } from "react-sortablejs";

const ListCuestionsComponent = ({
  questions = [],
  onClick,
  onDelete,
  onMove,
  waiting = false,
  ...props
}) => {
  const handleClick = (i) => {
    onClick(i);
  };

  const handleDelete = (i) => {
    onDelete(i);
  };

  return (
    <Stack direction="row" sx={{overflowX: 'auto', maxHeight:"100px", maxWidth:"650px"}}>
      {!waiting? 
        <ReactSortable
          list={questions}
          setList={() => {}}
          animation={200}
          delayOnTouchStart={true}
          onEnd={(evt) => {onMove(evt.oldIndex,evt.newIndex)}}
          direction={'horizontal'}
          scroll={true} // Enable the plugin. Can be HTMLElement.
          forceAutoscrollFallback={false} // force autoscroll plugin to enable even when native browser autoscroll is available
          scrollSensitivity={30} // px, how near the mouse must be to an edge to start scrolling.
          scrollSpeed={10} // px, speed of the scrolling
          bubbleScroll={true}// apply autoscroll to all parent elements, allowing for easier movement
        >
          {   questions.map((field, i) => (
            <Chip
              sx={{margin:"10px"}}
              color="primary"
              key={i}
              label={`Pregunta ${i + 1}`}
              onClick={()=>handleClick(i)}
              onDelete={() => handleDelete(i)}
              deleteIcon={<DeleteIcon />}
            />
          ))}
          </ReactSortable>
      :null}
    </Stack>
  );
}

ListCuestionsComponent.defaultProps = {
  questions: [],
  onClick: () => {},
  onDelete: () => {},
  onMove: () => {},
};

export default ListCuestionsComponent;