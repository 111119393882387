import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import service from "../../services/visits";
import {success, failure} from "../../utils/api_responses";

export default function* root() {
  yield all([
    fork(watcherDetail),
    fork(watcherSave),
  ])
}

function* watcherDetail() {
  yield takeEvery("[EXTERNAL][VISITS] DETAIL", workerDetail);
}

function* watcherSave() {
  yield takeEvery("[EXTERNAL][VISITS][SAVE] REQUEST", workerSave);
}


function* workerDetail(action){
  try {
    yield put({ type: "[EXTERNAL][VISITS] RESET" });
    yield put({ type: "[EXTERNAL][VISITS][ELEMENT] REQUEST" });
    const element = yield call(getElement, action.payload);
    yield put({ type: "[EXTERNAL][VISITS][ELEMENT] SUCCESS", payload: element });
  } catch (e) {
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[EXTERNAL][VISITS][ELEMENT] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerSave(action) {
  try {
    //element
    const data = setterVisit(action.payload);
    const response = yield call(save, data);
    yield put({ type: "[EXTERNAL][VISITS][SAVE] SUCCESS", payload: response });
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:  response,
      severity:'success'
    }});
  } catch (e) {
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: "[SNACKBAR] OPEN", payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: "[EXTERNAL][VISITS][SAVE] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}


  function getElement( payload) {
    return new Promise( (resolve, reject) => {
      service.getExternalVisitData(payload)
        .then((response) => {
          resolve(response.data);
        })
        .catch((e) => {
          reject(failure(e));
        });
      });
    }

  function save( payload) {
    return new Promise( (resolve, reject) => {
      service.saveExternalVisit(payload)
        .then((response) => {
          resolve(success(response));
        })
        .catch((e) => {
          reject(failure(e));
        });
    });
  }


  function setterVisit({token, data}){
    const values = {
      email: data.email,
      date: data.date,
      hour: data.hour,
      status: "Programmed"
    };
    return {token:token, data: values};
  }