import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import data_service from "../services/visits";
import {diary as actions} from '../store/actions/modules';

import {snackbarActions as snackbar} from '../store/actions';
import {failure} from "../utils/api_responses";


export default function* root() {
  yield all([
    fork(watcherSchedule)
  ])
}

function* watcherSchedule() {
  yield takeEvery(actions.SCHEDULES, workerSchedule);
}

function* workerSchedule(action) {
  try {
    yield put({ type: actions.SCHEDULES_REQUEST });
    const schedules_list = yield call(schedules, action.payload);
    yield put({ type: actions.SCHEDULES_SUCCESS ,payload: schedules_list});
  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.SCHEDULES_FAILURE, payload: e });
  }
}


function schedules(payload) {
  return new Promise( (resolve, reject) => {
    data_service.schedules(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}
