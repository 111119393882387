import visitsSaga from '../../../sagas/external/visits.saga';
import visitsReducer from '../../reducers/external/visits.reducer';
import {visits as actions} from '../../actions/external';

export function getModule() {
    return {
        id: "external_visits",
        reducerMap: {
          external_visits: visitsReducer,
        },
        sagas: [visitsSaga],
        finalActions: [actions.reset()],
    }; 
}