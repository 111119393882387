import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import data_service from "../services/customers";
import api_address from "../services/address_picker";
import { customers as actions } from '../store/actions/modules';
import { snackbarActions as snackbar} from '../store/actions';
import { failure, success } from "../utils/api_responses";


export default function* root() {
  yield all([
    fork(watcherList),
    fork(watcherEdit),
    fork(watcherDetail),
    fork(watcherCreate),
    fork(watcherRemove),
    fork(watcherUpdate),
    fork(watcherNeighborhood),
    fork(watcherNeighborhoods),
  ])
}

function* watcherList() {
  yield takeEvery(actions.LIST, workerList);
}

function* watcherEdit() {
  yield takeEvery(actions.EDIT, workerEdit);
}

function* watcherDetail() {
  yield takeEvery(actions.ELEMENT, workerDetail);
}

function* watcherCreate() {
  yield takeEvery(actions.CREATE, workerCreate);
}

function* watcherRemove() {
  yield takeEvery(actions.REMOVE, workerRemove);
}

function* watcherUpdate() {
  yield takeEvery(actions.UPDATE, workerUpdate);
}

function* watcherNeighborhood() {
  yield takeEvery(actions.NEIGHBORHOOD, workerNeighborhood);
}

function* watcherNeighborhoods() {
  yield takeEvery(actions.NEIGHBORHOODS, workerNeighborhoods);
}


function* workerList() {
  try {
    
    yield put({ type: actions.LIST_REQUEST });
    const packages = yield call(list);
    yield put({ type: actions.LIST_SUCCESS ,payload: packages});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.LIST_FAILURE, payload: e });
  }
}

function* workerEdit(action) {
  try {
    
    yield put({ type: actions.ELEMENT_REQUEST });
    const customer = yield call(list, action.payload);
    yield put({ type: actions.ELEMENT_SUCCESS ,payload: customer});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.ELEMENT_FAILURE, payload: e });
  }
}

function* workerDetail(action) {
  try {
    yield put({ type: actions.ELEMENT_REQUEST }); 
    
    const element = yield call(detail, action.payload);
    yield put({ type: actions.NEIGHBORHOOD_REQUEST });
    const neighborhood = yield call(getNeighborhood, element.neighborhood_id);
    yield put({ type: actions.NEIGHBORHOOD_SUCCESS, payload: neighborhood});
    const neighborhoods = yield call(getNeighborhoods, neighborhood.zip_code);
    yield put({ type: actions.NEIGHBORHOODS_REQUEST});
    yield put({ type: actions.NEIGHBORHOODS_SUCCESS, payload: neighborhoods });
    yield put({ type: actions.ELEMENT_SUCCESS ,payload: getter(element, neighborhood)});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.ELEMENT_FAILURE, payload: e });
    yield put({ type: actions.NEIGHBORHOOD_FAILURE });
    yield put({ type: actions.NEIGHBORHOODS_FAILURE});

  }
}

function* workerCreate(action) {
  try {
    yield put({ type: actions.SAVE_REQUEST }); 
    const response = yield call(create, setter(action.payload) );
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.SAVE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.SAVE_FAILURE, payload: e });
  }
}


function* workerUpdate(action) {
  try {
    yield put({ type: actions.SAVE_REQUEST }); 
    const response = yield call(update, setter(action.payload));
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.SAVE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.SAVE_FAILURE, payload: e });
  }
}

function* workerRemove(action) {
  try {
    yield put({ type: actions.SAVE_REQUEST }); 
    const response = yield call(remove, action.payload);
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.SAVE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.SAVE_FAILURE, payload: e });
  }
}

function* workerNeighborhoods(action) {
  try {
    console.log("here");
    yield put({ type: actions.NEIGHBORHOODS_REQUEST });
    const neighborhoods = yield call(getNeighborhoods, action.payload);
    yield put({ type: actions.NEIGHBORHOODS_SUCCESS,payload: neighborhoods});
  } catch (e) {
    console.log(e);
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.NEIGHBORHOODS_FAILURE, payload: e });
  }
}

function* workerNeighborhood(action) {
  try {
    yield put({ type: actions.NEIGHBORHOOD_REQUEST });
    const neighborhood = yield call(getNeighborhood, action.payload);
    yield put({ type: actions.NEIGHBORHOOD_SUCCESS,payload: neighborhood});
  } catch (e) {
    console.log(e);
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.NEIGHBORHOOD_FAILURE, payload: e });
  }
}

function list() {
  return new Promise( (resolve, reject) => {
    data_service.list()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function detail(payload) {
  return new Promise( (resolve, reject) => {
    data_service.detail(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function create(payload) {
  return new Promise( (resolve, reject) => {
    data_service.create(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function update(payload) {
  return new Promise( (resolve, reject) => {
    data_service.update(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function remove(payload) {
  return new Promise( (resolve, reject) => {    
    data_service.remove(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function getNeighborhood(zip_code) {
  return new Promise( (resolve, reject) => {
    api_address.neighborhood_detail(zip_code)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getNeighborhoods(neighborhood_id) {
  return new Promise( (resolve, reject) => {
    api_address.neighborhoods(neighborhood_id)
      .then((response) => {
        resolve(response.data.map(function(element){
          return {
            text:element.name,
            value:element.id
          };
       }));
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getter(data, neighborhood){
  return {
    ...data,
    neighborhood_id: neighborhood ? {text: neighborhood.name, value: neighborhood.id} : null,
    zip_code: neighborhood ? neighborhood.zip_code : null,
  }
}

function setter({data,...rest}){
  return {
    ...rest,
    data:{
      ...data,
      neighborhood_id: data.neighborhood_id.value,
    }
  }
}

