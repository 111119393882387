import {backdropActions} from '../actions';

const initialState = {
  open: false,
};

const navbar = function (state = initialState, action) {
  switch ( action.type )
  {
    case backdropActions.OPEN_BACKDROP:
    {
      return {
        ...state,
        open: true
      }
    }
    case backdropActions.CLOSE_BACKDROP:
    {
      return {
        ...state,
        open: false
      }
    }
    default:
    {
      return state;
    }
  }
};

export default navbar;