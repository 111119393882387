import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import data_service from "../../services/results";
import {results as actions} from '../../store/actions/external';
import {snackbarActions as snackbar} from '../../store/actions';
import { FloatingMenu } from '../../store/actions';
import {failure} from "../../utils/api_responses";


export default function* root() {
  yield all([
    fork(watcherByBrand),
    fork(watcherByBusinessBranch),
  ])
}

function* watcherByBrand() {
  yield takeEvery(actions.BY_BRAND, workerByBrand);
}

function* watcherByBusinessBranch() {
  yield takeEvery(actions.BY_BUSINESS_BRANCH, workerByBusinessBranch);
}

function* workerByBrand(action) {
  try {
    yield put({ type: actions.RESET });
    //business_branches
    yield put({ type: actions.BY_BRAND_REQUEST });

    const preData = yield call(preDataByBrand,action.payload);
    const data = setterPreDataByBrand(preData);
    const results = yield call(byBrand,data);

    yield put({ type: FloatingMenu.SET_OPTIONS, payload: {hidden:false, 
      options:[ {id:"global_results", label:"Resultados Globales"}, ...results.categorias.map( (element, i) => { return {id: `catalog_${element.id}`,label:element.nombre } })]} 
    });

    yield put({ type: actions.BY_BRAND_SUCCESS ,payload: results});
    
  } catch (e) {
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.BY_BRAND_FAILURE, payload: e });

    yield put({ type: actions.BUSINESS_BRANCHES_FAILURE });

  }
}

function* workerByBusinessBranch(action) {
  try {
    yield put({ type: actions.RESET });
    yield put({ type: FloatingMenu.CLEAR });
    yield put({ type: actions.BY_BUSINESS_BRANCH_REQUEST});
    //business_branches
    const preData = yield call(preDataByBusinessBranch,action.payload);
    const data = setterPreDataByBusinessBranch(preData);
    const results = yield call(byBusinessBranch,data);

    yield put({ type: FloatingMenu.SET_OPTIONS, payload: {hidden:false, 
      options:[ {id:"global_results", label:"Resultados Globales"}
      , ...results.resultados_categoria.filter(item => item.resultados && item.resultados.length).map( (element, i) => { return {id: `catalog_${element.id}`,label:element.categoria } }),
      {id:"commentaries", label:"Comentarios"},
      {id:"images", label:"Imagenes"},
    ]} 
    });

    yield put({ type: actions.BY_BUSINESS_BRANCH_SUCCESS ,payload: results});
  } catch (e) {
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.BY_BUSINESS_BRANCH_FAILURE, payload: e });

  }
}

function preDataByBrand(payload) {
  return new Promise( (resolve, reject) => {
    data_service.externalByBrand(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function preDataByBusinessBranch(payload) {
  return new Promise( (resolve, reject) => {
    data_service.externalByBusinessBranch(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function byBrand(payload) {
  return new Promise( (resolve, reject) => {
    data_service.byBrand(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function byBusinessBranch(payload) {
  return new Promise( (resolve, reject) => {
    data_service.byBusinessBranch(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function setterPreDataByBrand(response){
  return {
    brand:response.brand_id,
    survey:response.survey_id,
    month:response.month,
    data :{
      params:{
        est: response.businessBranches.map((element, index) => { 
          return element.bussiness_branch_id
        })
      }
    }
  };
}

function setterPreDataByBusinessBranch(response){
  return {
    contract:response.contract_id,
    month:response.month,
  };
}

