import React, {Component} from 'react';
import {
  Paper,
  Grid,
  Typography
} from '@mui/material';
import styles from './index.module.css';
import {RadioGroup, Select } from '../../../components/inputs';
import { Field } from 'react-final-form';

class Form extends Component {

  render() {

    return (
      <Grid item container spacing={3}>
        <Grid item lg={12}>
          <Typography variant="h6" id="tableTitle" component="div">
            Estudio Socioeconómico
          </Typography>
        </Grid>
        <Grid item xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            waiting={this.props.waiting}
            name="social_test.last_study"
            component={Select}
            label="Último Grado de estudios"
            type="text"
            options={
            [
              {value:'Sin instrucción', label:'Sin instrucción'},
              {value:'Preescolar', label:'Preescolar'},
              {value:'Primaria incompleta', label:'Primaria incompleta'},
              {value:'Primaria completa', label:'Primaria completa'},
              {value:'Secundaria incompleta', label:'Secundaria incompleta'},
              {value:'Secundaria completa', label:'Secundaria completa'},
              {value:'Preparatoria incompleta', label:'Preparatoria incompleta'},
              {value:'Preparatoria completa', label:'Preparatoria completa'},
              {value:'Licenciatura incompleta', label:'Licenciatura incompleta'},
              {value:'Licenciatura completa', label:'Licenciatura completa'},
              {value:'Posgrado',label:'Posgrado'},
            ]
          }
          />
          </Paper>
        </Grid>

        <Grid item xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            waiting={this.props.waiting}
            name="social_test.toilets"
            component={Select}
            label="¿Cuántos baños completos con regadera y W.C. (excusado) hay en tu vivienda?"
            type="text"
            options={
            [
              {value:'0', label:'0'},
              {value:'1', label:'1'},
              {value:'2 ó más', label:'2 ó más'}
            ]
          }
          />
          </Paper>
        </Grid>

        <Grid item xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            waiting={this.props.waiting}
            name="social_test.cars"
            component={Select}
            label="¿Cuántos automóviles o camionetas tienen en tu hogar incluyendo camionetas cerradas, o con cabina o caja?"
            type="text"
            options={
            [
              {value:'0', label:'0'},
              {value:'1', label:'1'},
              {value:'2 ó más', label:'2 ó más'}
            ]
          }
          />
          </Paper>
        </Grid>

        <Grid item xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            waiting={this.props.waiting}
              name="social_test.internet"
              component={RadioGroup}
              label="Sin tomar en cuenta la conexión móvil que pudiera tener desde algún celular ¿tu hogar cuenta con Internet?"
              type="text"
              options={[{value: 'Sí tiene',label: 'Si'},{value: 'No tiene',label: 'No'}]}
            >
          </Field>
          </Paper>
        </Grid>

        <Grid item xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            waiting={this.props.waiting}
            name="social_test.workers"
            component={Select}
            label="De todas las personas de más de 14 años que viven en el hogar, ¿cuántas trabajaron en el último mes?"
            type="text"
            options={
            [
              {value:'0', label:'0'},
              {value:'1', label:'1'},
              {value:'2', label:'2'},
              {value:'3', label:'3'},
              {value:'4 ó más', label:'4 ó más'}
            ]}
            >
          </Field>
          </Paper>
        </Grid>

        <Grid item xl={6}>
          <Paper className={styles.paper} variant="outlined">
          <Field
            required
            waiting={this.props.waiting}
            name="social_test.rooms"
            component={Select}
            label="En esta vivienda, ¿cuántos cuartos se usan para dormir sin contar pasillos ni baños?"
            type="text"
            options={
            [
              {value:'0', label:'0'},
              {value:'1', label:'1'},
              {value:'2', label:'2'},
              {value:'3', label:'3'},
              {value:'4 ó más', label:'4 ó más'}
            ]}
            >
          </Field>
          </Paper>
        </Grid>
      </Grid>
    );
  }

}

export default Form;
