import instructivesSaga from '../../sagas/instructives.saga';
import instructivesReducer from '../reducers/modules/instructives.reducer';
import {instructives as actions} from '../actions/modules';
export function getIntructivesModule() {
    return {
        // Unique id of the module
        id: "module_instructives",
        // Maps the Store key to the reducer
        reducerMap: {
          module_instructives: instructivesReducer,
        },
        // This module uses redux-saga middleware
        // This property will be be used by the SagaExtension
        // to run sagas for the moduleD
        // initialActions: [TodoActions.initializeTodos()],
        sagas: [instructivesSaga],
        finalActions: [actions.reset()],
    }; 
}