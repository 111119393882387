export const CREATE = "[MODULE][CUSTOMER] CREATE";
export const UPDATE = "[MODULE][CUSTOMER] UPDATE";
export const EDIT = "[MODULE][CUSTOMER] EDIT";
export const REMOVE = "[MODULE][CUSTOMER] REMOVE";
export const LIST = "[MODULE][CUSTOMER] LIST";
export const ELEMENT = "[MODULE][CUSTOMER] ELEMENT";
export const NEIGHBORHOODS = "[MODULE][CUSTOMER] NEIGHBORHOODS";
export const NEIGHBORHOOD = "[MODULE][CUSTOMER] NEIGHBORHOOD";

export const SAVE_REQUEST = "[MODULE][CUSTOMER][SAVE] REQUEST";
export const SAVE_SUCCESS = "[MODULE][CUSTOMER][SAVE] SUCCESS";
export const SAVE_FAILURE = "[MODULE][CUSTOMER][SAVE] FAILURE";

export const LIST_REQUEST = "[MODULE][CUSTOMER][LIST] REQUEST";
export const LIST_SUCCESS = "[MODULE][CUSTOMER][LIST] SUCCESS";
export const LIST_FAILURE = "[MODULE][CUSTOMER][LIST] FAILURE";

export const ELEMENT_REQUEST = "[MODULE][CUSTOMER][ELEMENT] REQUEST";
export const ELEMENT_SUCCESS = "[MODULE][CUSTOMER][ELEMENT] SUCCESS";
export const ELEMENT_FAILURE = "[MODULE][CUSTOMER][ELEMENT] FAILURE";

export const NEIGHBORHOODS_REQUEST = "[MODULE][CUSTOMER][NEIGHBORHOODS] REQUEST";
export const NEIGHBORHOODS_SUCCESS = "[MODULE][CUSTOMER][NEIGHBORHOODS] SUCCESS";
export const NEIGHBORHOODS_FAILURE = "[MODULE][CUSTOMER][NEIGHBORHOODS] FAILURE";

export const NEIGHBORHOOD_REQUEST = "[MODULE][CUSTOMER][NEIGHBORHOOD] REQUEST";
export const NEIGHBORHOOD_SUCCESS = "[MODULE][CUSTOMER][NEIGHBORHOOD] SUCCESS";
export const NEIGHBORHOOD_FAILURE = "[MODULE][CUSTOMER][NEIGHBORHOOD] FAILURE";

export const CLEAR = "[MODULE][CUSTOMER] CLEAR";

export const create = (payload)=> {
  return { type: CREATE, payload };
}

export const update = (payload)=> {
  return { type: UPDATE, payload };
}

export const remove = (payload)=> {
  return { type: REMOVE, payload };
}

export const edit = (payload)=> {
  return { type: EDIT, payload };
}

export const neighborhoods = (payload)=> {
  return { type: NEIGHBORHOODS, payload };
}

export const neighborhood = (payload)=> {
  return { type: NEIGHBORHOOD, payload };
}

export const list = ()=> {
  return { type: LIST  };
}

export const detail = (payload)=> {
  return { type: ELEMENT, payload };
}

export const reset = ()=> {
  return { type: CLEAR  };
}