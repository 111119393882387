import surveysSaga from '../../../sagas/external/surveys.saga';
import surveysReducer from '../../reducers/external/surveys.reducer';
import {surveys as actions} from '../../actions/external';

export function getModule() {
    return {
        id: "external_surveys",
        reducerMap: {
          external_surveys: surveysReducer,
        },
        sagas: [surveysSaga],
        finalActions: [actions.reset()],
    }; 
}