import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import data_service from "../services/shoppers";
import api_address from "../services/address_picker";

import {shoppers as actions} from '../store/actions/modules';
import {snackbarActions as snackbar} from '../store/actions';
import {failure, success} from "../utils/api_responses";


export default function* root() {
  yield all([
    fork(watcherStates),
    fork(watcherList),
    fork(watcherDetail),
    fork(watcherCreate),
    fork(watcherUpdate),
    fork(watcherDelete),
    fork(watcherCities)
  ])
}

function* watcherList() {
  yield takeEvery(actions.LIST, workerList);
}

function* watcherDetail() {
  yield takeEvery(actions.DETAIL, workerDetail);
}

function* watcherStates() {
  yield takeEvery(actions.STATES, workerSates);
}

function* watcherCreate() {
  yield takeEvery(actions.CREATE, workerCreate);
}

function* watcherUpdate() {
  yield takeEvery(actions.UPDATE, workerUpdate);
}

function* watcherDelete() {
  yield takeEvery(actions.REMOVE, workerDelete);
}

function* watcherCities() {
  yield takeEvery(actions.CITIES, workerCities);
}

function* workerList() {
  try {
    yield put({ type: actions.LIST_REQUEST });
    const shoopers = yield call(getShoppers);
    yield put({ type: actions.LIST_SUCCESS ,payload: shoopers});

  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.LIST_FAILURE, payload: e });
  }
}

function* workerDetail(action) {
  try {
    yield put({ type: actions.DETAIL_REQUEST });
    const shoopers = yield call(detail,action.payload);
    // 
    //states
    yield put({ type: actions.STATES_REQUEST });
    const states = yield call(getStates);
    yield put({ type: actions.STATES_SUCCESS ,payload: states});
    //cities
    yield put({ type: actions.CITIES_REQUEST});
    const cities = shoopers.address_code ? yield call(getCities, {state: shoopers.address_code.state}) : {};
    yield put({ type: actions.CITIES_SUCCESS,payload: cities});
    //shoppers
    yield put({ type: actions.DETAIL_SUCCESS ,payload: getter({data:shoopers, states:states, cities: cities })});

  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.DETAIL_FAILURE, payload: e });
    yield put({ type: actions.STATES_FAILURE, payload: e });

  }
}

function* workerSates(action) {
  try {
    yield put({ type: actions.STATES_REQUEST });
    const states = yield call(getStates);
    yield put({ type: actions.STATES_SUCCESS ,payload: states});
  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.STATES_FAILURE, payload: e });
  }
}

function* workerCreate(action) {
  try {
    yield put({ type: actions.CREATE_REQUEST }); 
    const response = yield call(create, setter(action.payload));
    yield put({ type: actions.CREATE_SUCCESS ,payload: response});

    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:  response,
      severity:'success'
    }});

  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.CREATE_FAILURE, payload: e });
  }
}

function* workerUpdate(action) {
  try {
    yield put({ type: actions.UPDATE_REQUEST }); 
    const response = yield call(update, setter(action.payload));
    yield put({ type: actions.UPDATE_SUCCESS ,payload: response});

    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:  response,
      severity:'success'
    }});

  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.UPDATE_FAILURE, payload: e });
  }
}

function* workerDelete(action) {
  try {
    yield put({ type: actions.REMOVE_REQUEST }); 
    const response = yield call(deleteRecord, action.payload);
    yield put({ type: actions.REMOVE_SUCCESS ,payload: response});

    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:  response,
      severity:'success'
    }});

  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.REMOVE_FAILURE, payload: e });
  }
}

function* workerCities(action) {
  try {
    yield put({ type: actions.CITIES_REQUEST});
    const cities = yield call(getCities, action.payload);
    yield put({ type: actions.CITIES_SUCCESS,payload: cities});

  } catch (e) {
    console.log(e);
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.CITIES_FAILURE, payload: e });
  }
}

function getShoppers() {
  return new Promise( (resolve, reject) => {
    data_service.list()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function getStates() {
  return new Promise( (resolve, reject) => {
    api_address.states()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function getCities({state}) {
  return new Promise( (resolve, reject) => {
    api_address.cities("MX", state)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function create(data) {
  return new Promise( (resolve, reject) => {
    data_service.create(data)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function update(data) {
  return new Promise( (resolve, reject) => {
    data_service.update(data)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function detail(data) {
  return new Promise( (resolve, reject) => {
    data_service.detail(data)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function deleteRecord(data) {
  return new Promise( (resolve, reject) => {
    data_service.remove(data)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}



function setter({data,...rest}){
  const new_data = {
    ...rest,
    data:{
      ...data,
      ...{
        address_code : {
          state: data.address_state ? data.address_state.value: null,
          address_code: data.address_city ? data.address_city.value: null,
          city: data.address_city ? data.address_city.text : null
        }
      }
    }
  };
  return new_data;
}

function getter({data, states, cities}){
  const state = states.find( state => state.value === data.address_code.state );
  const citiy = cities.find( city => city.value === data.address_code.address_code );
  delete data.address_code;
  const new_data = {
      ...data,
      address_state: state ? {text: state.text, value: state.value} : null,
      address_city: citiy ? {text: citiy.text, value: citiy.value} : null,
  };
  return new_data;
}