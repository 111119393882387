import MyComponent  from "./test";
import { getIntructivesModule } from "./../../store/modules/instructives.module";
import { DynamicModuleLoader } from "redux-dynamic-modules-react";
import * as React from "react";

export default function Dynamic() {
    return (
        <DynamicModuleLoader modules={[getIntructivesModule()]}>
            <MyComponent />
        </DynamicModuleLoader>
    );
}