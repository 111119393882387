// action types
export const RESET = "[MODULE][CONTRACTS] RESET";
//element actions
export const ELEMENT_REQUEST = "[MODULE][CONTRACTS][ELEMENT] REQUEST";
export const ELEMENT_SUCCESS = "[MODULE][CONTRACTS][ELEMENT] SUCCESS";
export const ELEMENT_FAILURE = "[MODULE][CONTRACTS][ELEMENT] FAILURE";
//business actions
export const BUSINESS_BRANCHES_REQUEST = "[MODULE][CONTRACTS][BUSINESS_BRANCHES] REQUEST";
export const BUSINESS_BRANCHES_SUCCESS = "[MODULE][CONTRACTS][BUSINESS_BRANCHES] SUCCESS";
export const BUSINESS_BRANCHES_FAILURE = "[MODULE][CONTRACTS][BUSINESS_BRANCHES] FAILURE";
//packages actions
export const PACKAGES_REQUEST = "[MODULE][CONTRACTS][PACKAGES] REQUEST";
export const PACKAGES_SUCCESS = "[MODULE][CONTRACTS][PACKAGES] SUCCESS";
export const PACKAGES_FAILURE = "[MODULE][CONTRACTS][PACKAGES] FAILURE";
//surveys actions
export const SURVEYS_REQUEST = "[MODULE][CONTRACTS][SURVEYS] REQUEST";
export const SURVEYS_SUCCESS = "[MODULE][CONTRACTS][SURVEYS] SUCCESS";
export const SURVEYS_FAILURE = "[MODULE][CONTRACTS][SURVEYS] FAILURE";

export const SAVE_REQUEST = "[MODULE][SURVEYS][SAVE] REQUEST";
export const SAVE_SUCCESS = "[MODULE][SURVEYS][SAVE] SUCCESS";
export const SAVE_FAILURE = "[MODULE][SURVEYS][SAVE] FAILURE";

export const LIST_REQUEST = "[MODULE][SURVEYS][LIST] REQUEST";
export const LIST_SUCCESS = "[MODULE][SURVEYS][LIST] SUCCESS";
export const LIST_FAILURE = "[MODULE][SURVEYS][LIST] FAILURE";


//module actions
export const DETAIL = "[MODULE][CONTRACTS] DETAIL";
export const CREATE = "[MODULE][CONTRACTS] CREATE";
export const UPDATE = "[MODULE][CONTRACTS] UPDATE";
export const RENEW = "[MODULE][CONTRACTS] RENEW";
export const EDIT = "[MODULE][CONTRACTS] EDIT";
export const STORE = "[MODULE][CONTRACTS] STORE";
export const REMOVE = "[MODULE][CONTRACTS] REMOVE";
export const LIST = "[MODULE][CONTRACTS] LIST";

export const list = ()=> {
  return { type: LIST  };
}

export const create = ()=> {
  return { type: CREATE };
}

export const edit = (payload)=> {
  return { type: EDIT, payload };
}

export const store = (payload)=> {
  return { type: STORE, payload };
}

export const update = (payload)=> {
  return { type: UPDATE, payload };
}

export const renew = (payload)=> {
  return { type: RENEW, payload };
}

export const remove = (payload)=> {
  return { type: REMOVE, payload };
}

export const detail = (payload)=> {
  return { type: DETAIL, payload};
}

export const reset = ()=> {
  return { type: RESET };
}

export const surveys = ()=> {
  return { type: SURVEYS_REQUEST };
}

export const packages = ()=> {
  return { type: PACKAGES_REQUEST };
}

export const businessBranches = ()=> {
  return { type: BUSINESS_BRANCHES_REQUEST };
}
