// src/js/store/index.js

// import { createStore } from "redux";
// import rootReducer from './reducers/index';

// const store = createStore(rootReducer);

// export default store;

// import { createStore, applyMiddleware } from 'redux';
// import {  applyMiddleware } from 'redux';
import { createStore } from "redux-dynamic-modules";
// import { createStore, IModuleStore } from "redux-dynamic-modules";
import 'regenerator-runtime/runtime';
// import createSagaMiddleware from 'redux-saga';
import {getMainModule} from './modules/main.module';

// import reducer from './reducers';
// import mySaga from './../sagas';
import { getSagaExtension } from "redux-dynamic-modules-saga";
// create the saga middleware
// const sagaMiddleware = createSagaMiddleware();
// mount it on the Store
const store = createStore({
  extensions: [ getSagaExtension()]
},
  // reducer,
  // applyMiddleware(sagaMiddleware),
  getMainModule()
)

// then run the saga
// sagaMiddleware.run(mySaga)

export default store;