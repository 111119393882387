import React, {Component} from 'react';
import {
  Container, 
  Grid, 
  Toolbar, 
  Typography,
  Button,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';

import FormRecord from '../form/form';
import FormPassword from '../form/password';
import { Form } from 'react-final-form';
import { connect } from 'react-redux';
import { formValidation } from '../../../validations/ClientsCreate';
import { clients } from '../../../store/actions/modules';


const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  formControl: {
    minWidth: '100%',
  },
});

class ClientCreate extends Component {
  
  constructor(props) {
    super(props);
    let form ={
      // email: "daniel@gmail.com",
      // name: "nuevochoper",
      // password: "12345678",
      // password_confirmation: "12345678",
      // phone: "123456789012",
      // status: "Active",
    };
    this.state = {
      form:form
    }
  }
  
  componentDidUpdate(prevProps) {
    if (prevProps.create !== this.props.create) {
      if(this.props.create.success){
        this.props.history.push("/clients");
      }
    }
  }

  onSubmit = async values => {
    this.props.save({data: values});
  }
  
  render() {
    
    const maxWidth = false;
    
    const { classes } = this.props;

    const buttonSx = {
      ...(this.props.create.loading && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };
    

    return (
      <Container maxWidth={maxWidth} className={styles['client-container']}>
        <Grid container spacing={2} direction="column">
          <Grid item xs>
            <Toolbar className={styles['toolbar']}>
              <Typography variant="h4" id="tableTitle" component="div">
              Nuevo Usuario
              <Typography variant="caption" display="block" gutterBottom>
              * Campos requeridos
            </Typography>
              </Typography>
            </Toolbar>
          </Grid>

          <Form
            initialValues={this.state.form}
            validate={formValidation.validateForm}
            onSubmit={this.onSubmit}
            render={({ handleSubmit, form, submitting, pristine, values }) => (
              <form onSubmit={handleSubmit}>
                {/* Shopper */}
              <Grid item container spacing={3}>
                <FormRecord waiting={this.props.create.loading} />
                <FormPassword waiting={this.props.create.loading} />
              </Grid>
              {/* Buttons */}
              <Grid container item spacing={3}>
                <Grid item>
                  <Button
                    variant="outlined"
                    size="small"
                    className={classes.button}
                    onClick={() => {this.props.history.push("/clients")}}
                    color="primary">
                    Cancelar
                  </Button>
                  <Button
                    variant="contained"
                    size="small"
                    sx={buttonSx}
                    disabled={this.props.create.loading}
                    type="submit"
                  >
                  Guardar
                  {this.props.create.loading && (
                    <CircularProgress
                      size={24}
                      sx={{
                        color: green[500],
                        position: 'absolute',
                        top: '50%',
                        left: '50%',
                        marginTop: '-12px',
                        marginLeft: '-12px',
                      }}
                    />
                  )}
                </Button>
                </Grid>
              </Grid>
              {/* <pre>{JSON.stringify(this.props, 0, 2)}</pre> */}
              </form>
            )}
          />
        </Grid>
    </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    save:(payload)=>{
      dispatch(clients.create(payload))
    }
  }
};


const mapStateToProps = (state) => {
  return {
    create: state.module_clients.create
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(ClientCreate);

export default withRouter(withStyles(useStyles)(_component))
