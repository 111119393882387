import React, {Component} from 'react';
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import CircularProgress from '@mui/material/CircularProgress';
import { green } from '@mui/material/colors';
import FormData from './form';
import { Form } from 'react-final-form';
import { modules } from '../../../store/actions';
import { connect } from 'react-redux';
import { formValidation } from './validator';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
});

class UpdateComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sku:null,
      sku_contract: null
    }
  }
  
  async componentDidMount(){
    const {sku_contract, sku_visit} = this.props.match.params;
    this.props.getModuleData({sku: sku_visit});
    this.setState({sku:sku_visit, sku_contract : sku_contract})
  }

  componentWillUnmount(){
  }

  componentDidUpdate(prevProps) {
    if (prevProps.update_request !== this.props.update_request) {
      if(this.props.update_request.success){
        this.props.history.push(`/visits/${this.state.sku_contract}/contractVisits`);
      }
    }
    if (prevProps.element !== this.props.element) {
      
    }
  }

  onSubmit = async values => {
    this.props.updateRecord({sku: this.state.sku, data: values })
  }


  render() {

    const buttonSx = {
      ...(this.props.update_request.loading && {
        bgcolor: green[500],
        '&:hover': {
          bgcolor: green[700],
        },
      }),
    };

    const maxWidth = false;

    const { classes } = this.props;


    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>

          <Grid container spacing={2} direction="column">

            <Form
              validate={values => formValidation.validateForm(values)}
              initialValues={this.props.element}
              onSubmit={this.onSubmit}
              render={({ handleSubmit, form, submitting, pristine, values, errors }) => (
                <form onSubmit={handleSubmit}>
                  <Grid container item spacing={3} justifyContent="flex-end">
                    {/* Title */}
                    <Grid item xs>
                      <Toolbar className={styles['toolbar']}>
                        <Typography variant="h4" id="tableTitle" component="div">
                          Editar visita
                          <Typography variant="caption" display="block" gutterBottom>
                          * Campos requeridos
                          </Typography>
                        </Typography>
                      </Toolbar>
                    </Grid>
                    {/* Buttons */}
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => {this.props.history.push(`/visits/${this.state.sku_contract}/contractVisits`)}}
                        disabled={this.props.update_request.loading }
                        color="primary">
                        Cancelar
                      </Button>
                      <Button
                        variant="contained"
                        size="small"
                        sx={buttonSx}
                        disabled={this.props.update_request.loading || this.props.status_element.loading}
                        className={classes.button}
                        type="submit"
                      >
                        Guardar
                        {this.props.update_request.loading && (
                          <CircularProgress
                            size={24}
                            sx={{
                              color: green[500],
                              position: 'absolute',
                              top: '50%',
                              left: '50%',
                              marginTop: '-12px',
                              marginLeft: '-12px',
                            }}
                          />
                        )}
                      </Button>
                    </Grid>
                  </Grid>
                  {/* Form */}
                  <FormData/>
                  
                  {/* <pre>{JSON.stringify(values, 0, 2)}</pre> */}
                  {/* <pre>{JSON.stringify(errors, 0, 2)}</pre> */}

                </form>
              )}
            />

          </Grid>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    updateRecord:(payload) =>{
      dispatch(modules.visits.update(payload))
    },
    getModuleData:(payload) =>{
      dispatch(modules.visits.detail(payload))
    }
  }
};


const mapStateToProps = (state) => {
  return {
    update_request: state.module_visits.update,
    status_element: state.module_visits.status_element,
    element: state.module_visits.element
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(UpdateComponent);


export default withRouter(withStyles(useStyles)(_component));
