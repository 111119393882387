import http from '../utils/http-common';

const list = () => {
  return http.get("/surveys");
};

const create = ({ data }) => {
  return http.post("/surveys/store", data);
};

const detail = ({ slug }) => {
  return http.get(`/surveys/${slug}/edit`);
};

const update = ({ slug, data }) => {
  return http.put(`/surveys/${slug}/update`, data);
};

const remove = ({ slug }) => {
  return http.delete(`surveys/${slug}/delete`);
};


const BussinessBranchesService = {
  list,
  detail,
  create,
  update,
  remove
};

export default BussinessBranchesService;