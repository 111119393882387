import http from '../utils/http-common';

const list = () => {
  return http.get("/shoppers");
};

const detail = ({slug}) => {
  return http.get(`/shoppers/${slug}/edit`);
};

const create = ({data}) => {
  return http.post("/shoppers/store", data);
};

const createExternal = ({data}) => {
  return http.post("/external/shoppers/store", data);
};

const update = ({slug, data}) => {
  return http.put(`/shoppers/${slug}/update`, data);
};

const remove = ({slug}) => {
  return http.delete(`shoppers/${slug}/delete`);
};


const shoppersService = {
  list,
  detail,
  create,
  update,
  remove,
  createExternal
};

export default shoppersService;