// action types
export const API_CALL_REQUEST = "API_CALL_REQUEST";
export const API_CALL_SUCCESS = "API_CALL_SUCCESS";
export const API_CALL_FAILURE = "API_CALL_FAILURE";


export const list = (payload)=> {
  return { type: API_CALL_REQUEST, payload:payload };
}

export const create = (payload)=> {
  return { type: API_CALL_REQUEST, payload:{data:payload.data}, service: payload.service };
}

export const update = (payload)=> {
  return { type: API_CALL_REQUEST, payload:{slug:payload.slug, data:payload.data}, service: payload.service };
}

export const detail = (payload)=> {
  return { type: API_CALL_REQUEST, payload:{slug:payload.slug }, service: payload.service  };
}

export const remove = (payload)=> {
  return { type: API_CALL_REQUEST, payload:{slug:payload.slug }, service: payload.service  };
}