import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import data_service from "../services/contracts";
import lists_service from "../services/lists";
import { contracts as actions } from '../store/actions/modules';
import { snackbarActions as snackbar} from '../store/actions';
import { failure, success } from "../utils/api_responses";

export default function* root() {
  yield all([
    fork(watcherList),
    fork(watcherCreate),
    fork(watcherEdit),
    fork(watcherDetail),
    fork(watcherUpdate),
    fork(watcherRenew),
    fork(watcherStore),
    fork(watcherRemove),
  ])
}

function* watcherList() {
  yield takeEvery(actions.LIST, workerList);
}

function* watcherCreate() {
  yield takeEvery(actions.CREATE, workerCreate);
}

function* watcherDetail() {
  yield takeEvery(actions.DETAIL, workerDetail);
}

function* watcherStore() {
  yield takeEvery(actions.STORE, workerStore);
}

function* watcherUpdate() {
  yield takeEvery(actions.UPDATE, workerUpdate);
}

function* watcherRenew() {
  yield takeEvery(actions.RENEW, workerRenew);
}

function* watcherEdit() {
  yield takeEvery(actions.EDIT, workerEdit);
}

function* watcherRemove() {
  yield takeEvery(actions.REMOVE, workerRemove);
}

function* workerList() {
  try {
    yield put({ type: actions.LIST_REQUEST });
    const surveys = yield call(list);
    yield put({ type: actions.LIST_SUCCESS ,payload: surveys});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.LIST_FAILURE, payload: e });
  }
}



function* workerDetail(action){
  try {

    yield put({ type: "[MODULE][CONTRACTS] RESET" });
    yield put({ type: "[MODULE][CONTRACTS][ELEMENT] REQUEST" });
    //business branches
    yield put({ type: "[MODULE][CONTRACTS][BUSINESS_BRANCHES] REQUEST" });
    const business_branches = yield call(getBusinessBranches);
    yield put({ type: "[MODULE][CONTRACTS][BUSINESS_BRANCHES] SUCCESS",payload: business_branches});
    //surveys
    yield put({ type: "[MODULE][CONTRACTS][SURVEYS] REQUEST"});
    const surveys = yield call(getSurveys);
    yield put({ type: "[MODULE][CONTRACTS][SURVEYS] SUCCESS",payload: surveys});
    //packages
    yield put({ type: "[MODULE][CONTRACTS][PACKAGES] REQUEST"});
    const packages = yield call(getPackages);
    yield put({ type: "[MODULE][CONTRACTS][PACKAGES] SUCCESS",payload: packages});
   
    const element = yield call(getElement, action.payload);
    const data = mutator(packages,surveys,business_branches,element);
    yield put({ type: "[MODULE][CONTRACTS][ELEMENT] SUCCESS", payload: data });
  } catch (e) {
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][CONTRACTS][BUSINESS_BRANCHES] FAILURE", payload: e });
    yield put({ type: "[MODULE][CONTRACTS][PACKAGES] FAILURE", payload: e });
    yield put({ type: "[MODULE][CONTRACTS][SURVEYS] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerStore(action) {
  try {
    yield put({ type: actions.SAVE_REQUEST }); 
    const response = yield call(store, setterStore(action.payload) );
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.SAVE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.SAVE_FAILURE, payload: e });
  }
}

function* workerUpdate(action) {
  try {
    yield put({ type: actions.SAVE_REQUEST }); 
    const response = yield call(update, setterStore(action.payload) );
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.SAVE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.SAVE_FAILURE, payload: e });
  }
}

function* workerRenew(action) {
  try {
    yield put({ type: actions.SAVE_REQUEST }); 
    const response = yield call(renew, setterStore(action.payload) );
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.SAVE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.SAVE_FAILURE, payload: e });
  }
}

function* workerRemove(action) {
  try {
    yield put({ type: actions.SAVE_REQUEST }); 
    const response = yield call(remove, action.payload);
    yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
      message:  response,
      severity:'success'
    }});
    yield put({ type: actions.SAVE_SUCCESS ,payload: response});
  } catch (e) {
    console.log(e);
    for (const key in e) {
      if (Object.hasOwnProperty.call(e, key)) {
        const error = e[key];
        yield put({ type: snackbar.OPEN_SNACKBAR , payload: {
          message:  error,
          severity:'error'
        } });
      }
    }
    yield put({ type: actions.SAVE_FAILURE, payload: e });
  }
}

function* workerEdit(action) {
  try {
    yield put({ type: "[MODULE][CONTRACTS] RESET" });
    yield put({ type: "[MODULE][CONTRACTS][ELEMENT] REQUEST" });
    //business branches
    yield put({ type: "[MODULE][CONTRACTS][BUSINESS_BRANCHES] REQUEST" });
    const business_branches = yield call(getBusinessBranches);
    yield put({ type: "[MODULE][CONTRACTS][BUSINESS_BRANCHES] SUCCESS",payload: business_branches});
    //surveys
    yield put({ type: "[MODULE][CONTRACTS][SURVEYS] REQUEST"});
    const surveys = yield call(getSurveys);
    yield put({ type: "[MODULE][CONTRACTS][SURVEYS] SUCCESS",payload: surveys});
    //packages
    yield put({ type: "[MODULE][CONTRACTS][PACKAGES] REQUEST"});
    const packages = yield call(getPackages);
    yield put({ type: "[MODULE][CONTRACTS][PACKAGES] SUCCESS",payload: packages});
   
    const element = yield call(getElement, action.payload);
    const data = mutator(packages,surveys,business_branches,element);
    yield put({ type: "[MODULE][CONTRACTS][ELEMENT] SUCCESS", payload: data });
  } catch (e) {
    console.log(e);
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][CONTRACTS][BUSINESS_BRANCHES] FAILURE", payload: e });
    yield put({ type: "[MODULE][CONTRACTS][PACKAGES] FAILURE", payload: e });
    yield put({ type: "[MODULE][CONTRACTS][SURVEYS] FAILURE", payload: e });
  } finally{
    yield put({ type: "[BACKDROP] CLOSE"});
  }
}

function* workerCreate() {
  try {
    yield put({ type: "[MODULE][CONTRACTS] RESET" });
    //business branches
    yield put({ type: "[MODULE][CONTRACTS][BUSINESS_BRANCHES] REQUEST" });
    const business_branches = yield call(getBusinessBranches);
    yield put({ type: "[MODULE][CONTRACTS][BUSINESS_BRANCHES] SUCCESS",payload: business_branches});
    //surveys
    yield put({ type: "[MODULE][CONTRACTS][SURVEYS] REQUEST"});
    const surveys = yield call(getSurveys);
    yield put({ type: "[MODULE][CONTRACTS][SURVEYS] SUCCESS",payload: surveys});
    //packages
    yield put({ type: "[MODULE][CONTRACTS][PACKAGES] REQUEST"});
    const packages = yield call(getPackages);
    yield put({ type: "[MODULE][CONTRACTS][PACKAGES] SUCCESS",payload: packages});
  } catch (e) {
    yield put({ type: "[SNACKBAR] OPEN", payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: "[MODULE][CONTRACTS][BUSINESS_BRANCHES] FAILURE", payload: e });
    yield put({ type: "[MODULE][CONTRACTS][PACKAGES] FAILURE", payload: e });
    yield put({ type: "[MODULE][CONTRACTS][SURVEYS] FAILURE", payload: e });
  }
}

function list() {
  return new Promise( (resolve, reject) => {
    data_service.list()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function store(payload) {
  return new Promise( (resolve, reject) => {
    data_service.create(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function update(payload) {
  return new Promise( (resolve, reject) => {
    data_service.update(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function renew(payload) {
  return new Promise( (resolve, reject) => {
    data_service.renew(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function remove(payload) {
  return new Promise( (resolve, reject) => {    
    data_service.remove(payload)
      .then((response) => {
        resolve(success(response.data));
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function getElement( payload) {
  return new Promise( (resolve, reject) => {
    data_service.detail(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getPackages() {
  return new Promise( (resolve, reject) => {
    lists_service.packages()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function getSurveys() {
  return new Promise( (resolve, reject) => {
    lists_service.surveys()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}


function getBusinessBranches() {
  return new Promise( (resolve, reject) => {
    lists_service.businessBranches()
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(e)
      });
  });
}

function setterStore({data, ...rest}){
  return{
    ...rest,
    data:{ 
      ...data, 
      ...{
        bussiness_branch_id: data.bussiness_branch_id.value,
        package_id: data.package_id.value,
        survey_id: data.survey_id.value
      }
    }
  }
}

function mutator(packages,surveys,business_branches,element){

  const _package = packages.find( item => item.value === element.package_id );
  const survey = surveys.find( item => item.value === element.survey_id );
  const business_branch = business_branches.find( item => item.value === element.bussiness_branch_id );

  const data = {
    ...element, 
    ...{
      package_id: _package ? {text: _package.text, value: _package.value} : null,
      survey_id: survey ? {text: survey.text, value: survey.value} : null,
      bussiness_branch_id: business_branch ? {text: business_branch.text, value: business_branch.value} : null,
    }
  };

  return data;
}
