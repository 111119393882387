import { takeEvery, call, put, fork, all  } from "redux-saga/effects";
import data_service from "../services/results";
import {results as actions} from '../store/actions/modules';
import {snackbarActions as snackbar} from '../store/actions';
import { FloatingMenu } from '../store/actions';
import {failure} from "../utils/api_responses";


export default function* root() {
  yield all([
    fork(watcherBrandsList),
    fork(watcherByBrand),
    fork(watcherShareByBrand),
    fork(watcherByBusinessBranch),
    fork(watcherShareByBusinessBranch),
  ])
}

function* watcherBrandsList() {
  yield takeEvery(actions.BRANDS_LIST, workerBrandsList);
}

function* watcherByBrand() {
  yield takeEvery(actions.BY_BRAND, workerByBrand);
}

function* watcherShareByBrand() {
  yield takeEvery(actions.SHARE_BY_BRAND, workerShareByBrand);
}

function* watcherByBusinessBranch() {
  yield takeEvery(actions.BY_BUSINESS_BRANCH, workerByBusinessBranch);
}

function* watcherShareByBusinessBranch() {
  yield takeEvery(actions.SHARE_BY_BUSINESS_BRANCH, workerShareByBusinessBranch);
}


function* workerBrandsList() {
  try {
    yield put({ type: actions.RESET });
    //brands_list
    yield put({ type: actions.BRANDS_LIST_REQUEST });
    const brands_list = yield call(getBrands);
    yield put({ type: actions.BRANDS_LIST_SUCCESS ,payload: brands_list});
    
  } catch (e) {
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.BRANDS_LIST_FAILURE, payload: e });
  }
}

function* workerByBrand(action) {
  try {
    // yield put({ type: actions.RESET });
    //business_branches
    yield put({ type: actions.BY_BRAND_REQUEST });

    var data = action.payload.data;
    if(!action.payload.data){

      yield put({ type: actions.BUSINESS_BRANCHES_REQUEST });

      const business_branches = yield call(getBusinessBranches,action.payload);

      yield put({ type: actions.BUSINESS_BRANCHES_SUCCESS, payload: business_branches });
      // yield put({ type: actions.BUSINESS_BRANCHES_SUCCESS, payload: getterBusinessBranches(business_branches) });
      data = setterBusinessBranches(business_branches);
    }else{
      data = setterBusinessBranches(action.payload.data);
    }
    const results = yield call(byBrand,{...action.payload, data:data});

    yield put({ type: FloatingMenu.SET_OPTIONS, payload: {hidden:false, 
      options:[ {id:"global_results", label:"Resultados Globales"}, ...results.categorias.map( (element, i) => { return {id: `catalog_${element.id}`,label:element.nombre } })]} 
    });

    yield put({ type: actions.BY_BRAND_SUCCESS ,payload: results});
    
  } catch (e) {
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.BY_BRAND_FAILURE, payload: e });

    yield put({ type: actions.BUSINESS_BRANCHES_FAILURE });

  }
}


function* workerByBusinessBranch(action) {
  try {
    yield put({ type: actions.RESET });
    yield put({ type: FloatingMenu.CLEAR });
    //business_branches
    yield put({ type: actions.BY_BUSINESS_BRANCH_REQUEST });
    const results = yield call(byBusinessBranch,action.payload);

    yield put({ type: FloatingMenu.SET_OPTIONS, payload: {hidden:false, 
      options:[ {id:"global_results", label:"Resultados Globales"}
      , ...results.resultados_categoria.filter(item => item.resultados && item.resultados.length).map( (element, i) => { return {id: `catalog_${element.id}`,label:element.categoria } }),
      {id:"commentaries", label:"Comentarios"},
      {id:"images", label:"Imagenes"},
    ]} 
    });


    yield put({ type: actions.BY_BUSINESS_BRANCH_SUCCESS ,payload: results});
  } catch (e) {
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.BY_BUSINESS_BRANCH_FAILURE, payload: e });

  }
}

function* workerShareByBrand(action) {
  try {
    //response
    yield put({ type: actions.SHARE_REQUEST });
    const response = yield call(shareByBrand, action.payload);
    yield put({ type: actions.SHARE_SUCCESS ,payload: response});
    
  } catch (e) {
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.SHARE_FAILURE, payload: e });
  }
}

function* workerShareByBusinessBranch(action) {
  try {
    //response
    yield put({ type: actions.SHARE_REQUEST });
    const response = yield call(shareByBusinessBranch, action.payload);
    yield put({ type: actions.SHARE_SUCCESS ,payload: response});
    
  } catch (e) {
    yield put({ type: snackbar.OPEN_SNACKBAR, payload: {
      message:'Oops, Se ha producido un error',
      severity:'error'
    } });
    yield put({ type: actions.SHARE_FAILURE, payload: e });
  }
}


function getBrands(payload) {
  return new Promise( (resolve, reject) => {
    data_service.brands(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function getBusinessBranches(payload) {
  return new Promise( (resolve, reject) => {
    data_service.businessBranches(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function byBrand(payload) {
  return new Promise( (resolve, reject) => {
    data_service.byBrand(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function shareByBrand(payload) {
  return new Promise( (resolve, reject) => {
    data_service.shareByBrand(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function shareByBusinessBranch(payload) {
  return new Promise( (resolve, reject) => {
    data_service.ShareByBusinessBranch(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function byBusinessBranch(payload) {
  return new Promise( (resolve, reject) => {
    data_service.byBusinessBranch(payload)
      .then((response) => {
        resolve(response.data);
      })
      .catch((e) => {
        reject(failure(e))
      });
  });
}

function setterBusinessBranches(list){
  return {
    params:{
      est: list.map((element, index) => { 
        return element.bussiness_branch_id
      })
    }
  };
}

