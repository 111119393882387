import http from '../utils/http-common';

const list = () => {
  return http.get("/contracts");
};

const create = ({ data }) => {
  return http.post("/contracts/store", data);
};

const detail = ({ slug }) => {
  return http.get(`/contracts/${slug}/edit`);
};

const update = ({ slug, data }) => {
  return http.put(`/contracts/${slug}/update`, data);
};

const remove = ({ slug }) => {
  return http.delete(`contracts/${slug}/delete`);
};

const renew = ({ slug, data }) => {
  return http.put(`contracts/${slug}/renew`,data);
};


const service = {
  list,
  detail,
  create,
  update,
  remove,
  renew
};

export default service;