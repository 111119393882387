import React, {Component} from 'react';
import {
  Container,
  Grid,
  Toolbar,
  Typography,
  Button,
  Card,
  CardContent,
} from '@mui/material';
import withStyles from '@mui/styles/withStyles';
import styles from './index.module.css';
import { withRouter } from "react-router-dom";
import { green } from '@mui/material/colors';
import { modules } from '../../../store/actions';
import { connect } from 'react-redux';
import Skeleton from '@mui/material/Skeleton';

const useStyles = (theme) => ({
  button: {
    margin: theme.spacing(1),
  },
  buttonProgress: {
    color: green[500],
    position: 'absolute',
    top: '50%',
    left: '50%',
    marginTop: -12,
    marginLeft: -12,
  },
  buttonSuccess: {
    backgroundColor: green[500],
    '&:hover': {
      backgroundColor: green[700],
    },
  },
  list:{
    width: '100%',
    maxWidth: 360,
    backgroundColor: theme.palette.background.paper,
  }
});

class DetailComponent extends Component {
  constructor(props) {
    super(props);
    this.state = {
      slug:null,
    }
  }
  
  async componentDidMount(){
    const {slug} = this.props.match.params;
    this.setState({
      slug: slug
    });
    this.props.getModuleData({slug: slug});
  }

  htmlDecode = (input)=>{
    var e = document.createElement('div');
    e.innerHTML = input;
    return e.childNodes.length === 0 ? "" : e.childNodes[0].nodeValue;
  }

  render() {

    const maxWidth = false;

    const { classes } = this.props;


    return (
        <Container maxWidth={maxWidth} className={styles['client-container']}>

            <Card variant="outlined">
              <CardContent>
                <Grid container spacing={2} direction="column" justifyContent="space-between">

                  {/* <Grid item lg={12}>
                    <Typography variant="h6" component="div">
                      Datos Generales
                    </Typography>
                  </Grid> */}

                  <Grid container item spacing={3} justifyContent="flex-end">
                    {/* Title */}
                    <Grid item xs>
                      <Toolbar className={styles['toolbar']}>
                        <Typography variant="h4" id="tableTitle" component="div">
                          Datos Generales
                        </Typography>
                      </Toolbar>
                    </Grid>
                    {/* Buttons */}
                    <Grid item>
                      <Button
                        variant="outlined"
                        size="small"
                        className={classes.button}
                        onClick={() => {this.props.history.push("/instructives")}}
                        color="primary">
                        Regresar
                      </Button>
                    </Grid>
                  </Grid>

                  <Grid item xs={12} sm container spacing={1}>

                    <Grid item xs={2}>
                      <Typography variant="overline" display="block" gutterBottom>
                        Nombre
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {
                        this.props.status_element.loading 
                        ? <Skeleton variant="text" /> 
                        : <Typography variant="button" display="block" gutterBottom>{this.props.element.name}</Typography>
                      }
                      {/* <Skeleton variant="text" />  */}
                    </Grid>

                    <Grid item xs={2}>
                      <Typography variant="overline" display="block" gutterBottom>
                        Establecimiento
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {
                        this.props.status_element.loading 
                        ? <Skeleton variant="text" /> 
                        : <Typography variant="button" display="block" gutterBottom>{this.props.element.bussiness_branch}</Typography>
                      }
                    </Grid>

                    <Grid item xs={2}>
                      <Typography variant="overline" display="block" gutterBottom>
                        Cliente
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {
                        this.props.status_element.loading ? <Skeleton variant="text" /> : 
                        <Typography variant="button" display="block" gutterBottom>
                          {this.props.element.customer}
                        </Typography>
                      }
                    </Grid>

                    <Grid item xs={2}>
                      <Typography variant="overline" display="block" gutterBottom>
                        Marca
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {
                        this.props.status_element.loading ? <Skeleton variant="text" /> : 
                        <Typography variant="button" display="block" gutterBottom>
                          {this.props.element.brand}
                        </Typography>
                      }
                    </Grid>

                    <Grid item xs={2}>
                      <Typography variant="overline" display="block" gutterBottom>
                        Contenido
                      </Typography>
                    </Grid>
                    <Grid item xs={10}>
                      {
                        this.props.status_element.loading ? <Skeleton variant="text" /> : 
                        <>
                          {/* {this.props.element.contents} */}
                          <div dangerouslySetInnerHTML={{__html: this.props.element.contents}} />
                        </>
                      }
                    </Grid>

                  </Grid>
                </Grid>
              </CardContent>
            </Card>
        </Container>
    );
  }
}

const mapDispatchToProps = (dispatch) => {
  return {
    getModuleData:(payload) =>{
      dispatch(modules.instructives.detail(payload))
    }
  }
};


const mapStateToProps = (state) => {
  return {
    status_element: state.module_instructives.status_element,
    element: state.module_instructives.element
  };
}

const _component = connect(
  mapStateToProps,
  mapDispatchToProps
)(DetailComponent);


export default withRouter(withStyles(useStyles)(_component));
